import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const SocialMediaLinkModal = (props: any) => {
    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [socialLinks, setSocialLinks] = useState({
        fb_url: '',
        insta_url: '',
        linkedIn_url: '',
        tw_url: ''
    });

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setSocialLinks({ ...socialLinks, [name]: value });
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleSocialMediaLinks = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        setActive(true);
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    facebook_link: socialLinks.fb_url,
                    instagram_link: socialLinks.insta_url,
                    linkedin_link: socialLinks.linkedIn_url,
                    twitter_link: socialLinks.tw_url
                }
                let result = await axios.post(`${baseURL}/api/subscriber/update-social_media_links`, body);
                messageTxt(result.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    useEffect(() => {
        setSocialLinks((prevData) => ({
            ...prevData,
            fb_url: data?.fb_url || '',
            insta_url: data?.insta_url || '',
            linkedIn_url: data?.linkedIn_url || '',
            tw_url: data?.tw_url || ''
        }));
    },[data])


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <form className='px-5 social_form'>
                        <div className="row p-0 d-flex justify-content-between align-items-center">
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Facebook account url</label>
                                <input type="text" onChange={handleChangeValue} name='fb_url' value={socialLinks.fb_url} className="form-control mb-3" placeholder='eg: https://www.facebook.com/' id="exampleFormControlInput1" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Instagram account url</label>
                                <input type="text" onChange={handleChangeValue} name='insta_url' value={socialLinks.insta_url} className="form-control mb-3" placeholder='eg: https://www.instagram.com/' id="exampleFormControlInput1" />
                            </div>
                        </div>

                        <div className='row p-0 mb-5 d-flex justify-content-between align-items-center'>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Linkedin account url</label>
                                <input type="text" onChange={handleChangeValue} name='linkedIn_url' value={socialLinks.linkedIn_url} className="form-control mb-3" placeholder='eg: https://in.linkedin.com/?src=go-pa&trk=sem-ga_' id="exampleFormControlInput1" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Twitter account url</label>
                                <input type="text" onChange={handleChangeValue} name='tw_url' value={socialLinks.tw_url} className="form-control mb-3" placeholder='eg: https://twitter.com/i/flow/login' id="exampleFormControlInput1" />
                            </div>
                        </div>

                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                            <button
                                className='py-2 px-2 bg-danger rounded font_weight_600'
                                style={{
                                    width: '150px'
                                }}
                                onClick={handleSocialMediaLinks}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default SocialMediaLinkModal
