import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PiCaretRightBold } from 'react-icons/pi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from '../../utils/api';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import { getAllInstituteListAPI } from '../../utils/utils';
import { Table } from 'react-bootstrap';
import InstituteModal from '../myprofilemodals/InstituteModal';

const FilmInstituteList = () => {

    LoadingOverlay.propTypes = undefined;
    const myInstituteListRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [allInstitute, setAllInstitute] = useState<any>([]);

    const fetchAllInstitutes = async () => {
        setActive(true);
        const data = await getAllInstituteListAPI();
        setAllInstitute(data);
        setActive(false);
    };

    const handleAddInstituteModal = () => {
        fetchAllInstitutes();
        setOpenAdd(!openAdd);
    }

    useEffect(() => {
        const element = myInstituteListRef.current;

        const handleShow = () => {
            fetchAllInstitutes();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log(allInstitute)
    // }, [allInstitute])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='personal_project_form_accordian_section theme_color mt-3'>

                <div className="accordion accordion-flush" id={`filmInstituteList`}>
                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id={`flush-headingfilmInstituteList`}>
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapsefilmInstituteList`} aria-expanded="false" aria-controls={`flush-collapsefilmInstituteList`}>
                                Film institute
                            </button>
                        </h2>
                        <div id={`flush-collapsefilmInstituteList`} ref={myInstituteListRef} className="accordion-collapse collapse" aria-labelledby={`flush-headingfilmInstituteList`} data-bs-parent={`#filmInstituteList`}>
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openAdd &&
                                    <InstituteModal handleModalBox={handleAddInstituteModal} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddInstituteModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {allInstitute ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>
                                                    ID
                                                </th>
                                                <th>
                                                    Institute name
                                                </th>
                                                <th>
                                                    Cover image
                                                </th>
                                                <th>
                                                    Banner image
                                                </th>
                                                <th>
                                                    Total course
                                                </th>
                                                <th>
                                                    City
                                                </th>
                                                <th className='text-center'>
                                                    View project
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{allInstitute.id}</td>
                                                <td>{allInstitute.institute_name}</td>
                                                <td>
                                                    <div
                                                        style={{
                                                            height: '100px',
                                                            width: '200px'
                                                        }}
                                                    >
                                                        <img src={`${baseURL}/${allInstitute.cover}`} alt={allInstitute.institute_name} height={'100%'} width={'100%'} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            height: '100px',
                                                            width: '200px'
                                                        }}
                                                    >
                                                        <img src={`${baseURL}/${allInstitute.banner}`} alt={allInstitute.institute_name} height={'100%'} width={'100%'} />
                                                    </div>
                                                </td>
                                                <td>
                                                    {allInstitute?.courses?.length}
                                                </td>
                                                <td>
                                                    {allInstitute.city}
                                                </td>
                                                <td className='text-center'>
                                                    <Link to={`/profile/film-institute-detail/${removeDotReplaceSpaceToHyphen(allInstitute.institute_name)}-${allInstitute.id}`} className='text-decoration-none'>
                                                        <button
                                                            className='px-2 font22 border-0'
                                                            style={{
                                                                color: 'white',
                                                                width: '150px',
                                                            }}
                                                        >
                                                            <PiCaretRightBold className='text-white' />
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white text-center font_weight_600"
                                    >
                                        No data found.
                                    </p>
                                }
                                {/* <table className="table table-dark table-striped border">
                                    <thead>
                                        <tr>
                                            <th>
                                                ID
                                            </th>
                                            <th>
                                                Institute name
                                            </th>
                                            <th>
                                                Cover image
                                            </th>
                                            <th>
                                                Banner image
                                            </th>
                                            <th>
                                                Total course
                                            </th>
                                            <th>
                                                City
                                            </th>
                                            <th className='text-center'>
                                                View project
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="table-active">
                                            <td>{allInstitute.id}</td>
                                            <td>{allInstitute.institute_name}</td>
                                            <td>
                                                <div
                                                    style={{
                                                        height: '100px',
                                                        width: '200px'
                                                    }}
                                                >
                                                    <img src={`${baseURL}/${allInstitute.cover}`} alt={allInstitute.institute_name} height={'100%'} width={'100%'} />
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        height: '100px',
                                                        width: '200px'
                                                    }}
                                                >
                                                    <img src={`${baseURL}/${allInstitute.banner}`} alt={allInstitute.institute_name} height={'100%'} width={'100%'} />
                                                </div>
                                            </td>
                                            <td>
                                                {allInstitute?.courses?.length}
                                            </td>
                                            <td>
                                                {allInstitute.city}
                                            </td>
                                            <td className='text-center'>
                                                <Link to={`/profile/film-institute-detail/${removeDotReplaceSpaceToHyphen(allInstitute.institute_name)}-${allInstitute.id}`} className='text-decoration-none'>
                                                    <button
                                                        className='px-2 font22 border-0'
                                                        style={{
                                                            color: 'white',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <PiCaretRightBold className='text-white' />
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </LoadingOverlay>
    )
}

export default FilmInstituteList
