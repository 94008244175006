import React from 'react';
import './style.common.slider.css';
import MovieCard from '../moviecard/MovieCard';
import { movieCoverImage } from '../../utils/constaints';
import Slider from "react-slick";
import OnlyImages from '../moviecard/OnlyImages';

const CommonSlider = (props: any) => {

    const { heading, leftIndicator, horizontalImage, onlyImage, data } = props;
    // console.log(data);


    const settings = {
        dots: false,
        arrows: false,
        infinite: data?.length > 8,
        speed: 1000,
        slidesToShow: data?.length < 8 ? data?.length : 8,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    infinite: data?.length > 7,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    infinite: data?.length > 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    infinite: data?.length > 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    infinite: data?.length > 4,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    infinite: data?.length > 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    infinite: data?.length > 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    infinite: data?.length > 1,
                },
            },
        ],
    };

    return (
        <>
            {onlyImage !== true ?
                <Slider {...settings}>
                    {
                        data?.map((e: any) => {
                            return (
                                <div key={e?.id} style={{ width: 'fit-content' }}>
                                    <MovieCard id={e?.id} cover={e?.cover} title={e?.title} genres={e?.genres} dob={e?.date} watch={e?.watch} like={e?.like} rating={e?.rating} cdbs_rating={e?.cdbs_rating} />
                                </div>
                            );
                        })
                    }
                </Slider>
                :
                <Slider {...settings}>
                    {
                        Object.values(data)?.map((val: any, idx: any) => {
                            return (
                                <div key={idx} style={{ width: 'fit-content' }}>
                                    <OnlyImages image={val} />
                                </div>
                            );
                        })
                    }
                </Slider>
            }
        </>
    )
}

export default CommonSlider
