import React from 'react'

const ContinueWatchingCard = (props: any) => {
    const { id, title, image } = props;
    return (
        <div
            className='event_award_section pointer my-3'
            style={{
                height: '160px',
                width: '300px'
            }}
        >
            <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
        </div>
    )
}

export default ContinueWatchingCard
