import React from 'react';
import ReactPlayer from 'react-player/lazy';

const VideoPlayer = (props: any) => {
    const { videoUrl, width, height, cover } = props;
    return (
        <ReactPlayer
            light={true}
            controls={true}
            url={videoUrl}
            height={height}
            width={width}
            playing={true}
        />
    )
}

export default VideoPlayer
