import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const TooltipHover = (props: any) => {
    const { text, place, id } = props;
    return (
        <ReactTooltip
            id={id ? id : 'my-tooltip-1'}
            place={place ? place : 'bottom'}
            content={text ? text : 'Hello world! I am a Tooltip'}
        />
    )
}

export default TooltipHover
