import React, { useEffect, useState } from 'react';
import './menu.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineCalendar } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import { formatDate, getSingleFestivals, giveVoteforNominees } from '../../utils/utils';
import SubmitMovieFilmfestival from '../../components/submitmoviemodal/SubmitMovieFilmfestival';
import { extractIdFromUrl } from '../../utils/jsfunctions';

const FilmFestivalDetail = () => {

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const params = useParams();
    const [isActive, setActive] = useState(false);
    const [festivalData, setFestivalData] = useState<any>([]);
    const { id } = params;
    const [dateTime, setDateTime] = useState<any>([]);
    const [openSubmitMovieModal, setOpenSubmitMovieModal] = useState(false);

    const getDetail = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const data = await getSingleFestivals(urlId);
        setFestivalData(data);
        setActive(false);
    } 

    const giveVote = async (ec_id: any, nominee_id: any) => {
        setActive(true);
        const message = await giveVoteforNominees(ec_id, nominee_id);
        messageTxt(message);
        setActive(false);
    }

    useEffect(() => {
        getDetail();
    }, []);

    const openMovieSubmitModal = (val: any) => {
        setOpenSubmitMovieModal(val);
    }

    useEffect(() => {
        if (festivalData?.start_date) {
            const data = new Date(`${festivalData?.start_date}T${festivalData.start_time}`);
            setDateTime(formatDate(data));
        }
    }, [festivalData])

    // useEffect(() => {
    //     console.log('festival', festivalData);
    // }, [festivalData])

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                {openSubmitMovieModal ?
                    <SubmitMovieFilmfestival handlerModalBox={openMovieSubmitModal} />
                    : null
                }
                <div className='mt-lg-5 mt-md-0 mt-0'>
                    <div className='row m-0 p-0 mt-4'>
                        <div className='col-12 m-0 p-0'>
                            <div>
                                <img src={`${baseURL}/${festivalData.banner_image?.path}`} alt='film festival' className='object-fit-contain' width={'100%'} height={'100%'} />
                            </div>
                        </div>
                    </div>

                    <div className='mt-3 d-flex justify-content-between align-items-center theme_color'>
                        <ul className='left_div d-flex align-items-center m-0'>
                            <li>
                                <a className=''>
                                    Tickets
                                </a>
                            </li>
                            <li>
                                <a href='#about' className=''>
                                    About
                                </a>
                            </li>
                            <li>
                                <a href='#photos' className=''>
                                    Photos
                                </a>
                            </li>
                            <li>
                                <a href='#publicvoting' className=''>
                                    Public Voting
                                </a>
                            </li>
                        </ul>
                    </div>

                    <p className='m-0 text-warning font22 fw-bold my-4'>
                        {festivalData?.name}
                    </p>

                    <div className='mb-4'>
                        <p className='text-white d-flex align-items-center m-0 mb-3'>
                            <AiOutlineCalendar className='me-2' />{dateTime}
                            <CiLocationOn className='mx-3' />{festivalData.city}
                        </p>
                    </div>

                    <div className='row m-0 p-0'>
                        <div className='col-lg-4 col-12 p-3 theme_color mb-4'>
                            <div
                                style={{
                                    height: '200px',
                                }}
                            >
                                <img src={`${baseURL}/${festivalData.banner_image?.path}`} alt={'addfestival1'} className='object-fit-contain' width={'100%'} height={'100%'} />
                            </div>
                        </div>

                        <div className='col-lg-5 col-12 p-0 pt-3 theme_color mb-4'>
                            <p className='m-0 text-white font14 mt-2'>
                                {festivalData?.name}
                            </p>
                            <div className='text-white font14 my-3'>
                                <p className='font14'>
                                    <b className='text-warning'>Venue :</b> {festivalData?.address}
                                </p>
                                {/* <p className='font14'>
                                    <b className='text-warning'>Price :</b> <span className='ms-4'>Rs 600</span>
                                </p>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <p className='text-white m-0'>Qty</p>
                                    <select className="form-select ms-4 w-25 bg-transparent text-white text-center" aria-label="Default select example">
                                        <option className='text-dark' selected>2</option>
                                        <option className='text-dark' value="1">3</option>
                                        <option className='text-dark' value="2">4</option>
                                        <option className='text-dark' value="3">5</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>

                        <div className='col-lg-3 col-12 d-flex justify-content-center align-items-center theme_color mb-4'>
                            <div className='w-100'>
                                <div>
                                    {/* <button
                                        className='py-1 fw-bold text-white py-3 mt-1'
                                        style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                        type="submit"
                                    >
                                        Buy Tickets
                                    </button> */}
                                    <button
                                        className='fw-bold text-white mt-1 rounded'
                                        style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                        onClick={() => setOpenSubmitMovieModal(!openSubmitMovieModal)}
                                    >
                                        Submit the Movie
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <p className='m-0 text-white fw-bold' id='about'>About</p>
                        <p className='m-0 text-white font14 ps-lg-4 ps-md-4 ps-0 mt-2'>
                            {festivalData.about_us}
                        </p>
                    </div>

                    <p className='m-0 text-white my-4 fw-bold' id='photos'>Photos</p>
                    <div className='row m-0 p-0 px-4'>
                        {Array.isArray(festivalData?.images) &&
                            festivalData?.images.map((val: any) => {
                                return (
                                    <div className='col-lg-4 col-md-6 col-12 mb-4' key={val.id}>
                                        <div
                                            style={{
                                                height: '260px',
                                                width: '350px'
                                            }}
                                        >
                                            <img src={`${baseURL}/${val.path}`} alt={val.path} className='object-fit-contain hover_zoom pointer' width={'100%'} height={'100%'} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <p className='m-0 text-white my-4 font20 fw-bold' id='publicvoting'>Public Voting</p>

                    {Array.isArray(festivalData?.categories) &&
                        festivalData?.categories.map((value: any, i: number) => {
                            return (
                                <div key={i} className='mt-3'>
                                    <div className="accordion accordion-flush" id={`nomination_${i}`}>
                                        <div className="accordion-item rounded">
                                            <h2 className="accordion-header" id={`flush-heading_${i}`}>
                                                <button
                                                    className="accordion-button collapsed rounded accordian_border_with_color text-white"
                                                    style={{ backgroundColor: '#3a3a3c' }}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapse-nomination_${i}`}
                                                    aria-expanded="false"
                                                    aria-controls={`flush-collapse-nomination_${i}`}
                                                >
                                                    {value.name}
                                                </button>
                                            </h2>
                                            <div id={`flush-collapse-nomination_${i}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading_${i}`} data-bs-parent={`#nomination_${i}`}>
                                                <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                                                    <p className='m-0 text-white text-center'>Nominees </p>
                                                    <div className='row m-0 p-0 mt-4'>
                                                        {value?.nominees && Array.isArray(value?.nominees) &&
                                                            value?.nominees.map((val: any) => {
                                                                return (
                                                                    <div className='col-lg-3 col-md-4 col-12 mb-4' key={val.id}>
                                                                        <div
                                                                            style={{
                                                                                height: '362px',
                                                                                width: '290px'
                                                                            }}
                                                                        >
                                                                            <img src={`${baseURL}/${val.cover}`} className='object-fit-contain' alt={val.name} width={'100%'} height={'100%'} />
                                                                        </div>
                                                                        <button
                                                                            className='border-0 text-white w-100 py-2 mt-2 fw-bold rounded'
                                                                            style={{ backgroundColor: 'red', width: '280px !important' }}
                                                                            onClick={() => giveVote(val.event_sub_category_id, val.id)}
                                                                        >
                                                                            Vote
                                                                        </button>
                                                                        <p className='m-0 text-warning mt-3'>
                                                                            Public Voting: <span className='text-white'>{val.votes.length}</span>
                                                                        </p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}



                    {Array.isArray(festivalData?.results) &&
                        <>
                            <p className='m-0 text-white my-4 font20 fw-bold'>Competition</p>
                            {festivalData?.results.map((value: any, i: number) => {
                                return (
                                    <div className="accordion accordion-flush" id={`accordionFlushExample${value.id}`} key={value.id}>
                                        <div className="accordion-item rounded mb-3">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed rounded border text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseTwo${value.id}`} aria-expanded="true" aria-controls={`flush-collapseTwo${value.id}`}>
                                                    {value.name}
                                                </button>
                                            </h2>
                                            <div id={`flush-collapseTwo${value.id}`} className="accordion-collapse collapse mb-1 show" aria-labelledby="flush-headingTwo" data-bs-parent={`#accordionFlushExample${value.id}`}>
                                                <div className="accordion-body theme_color mb-5">
                                                    <div className='row m-0 p-0 px-4'>
                                                        <p className='text-warning m-0 mt-2 mb-3 fw-bold'>Winners</p>
                                                        {Array.isArray(value?.sub_categories) && (
                                                            value?.sub_categories.map((items: any, idx: any) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            Array.isArray(items.nominations) && (
                                                                                items?.nominations.map((val: any, idx: any) => {
                                                                                    return (
                                                                                        <>
                                                                                            {val.result === '1' || val.result === '2' || val.result === '3' ?
                                                                                                <div className='col-lg-3 col-md-3 col-12 mb-4' key={val.id}>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            height: '340px',
                                                                                                            width: '256px'
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={`${baseURL}/${val.cover}`} className='object-fit-contain' alt={val.name} width={'100%'} height={'100%'} />
                                                                                                    </div>
                                                                                                    <p className='m-0 mt-2 text-center text-white font18 fw-bold'>
                                                                                                        ({val.name})
                                                                                                    </p>
                                                                                                    {
                                                                                                        val.result === '1' ?
                                                                                                            <p className='m-0 mt-3 text-center text-warning font18 fw-bold'>
                                                                                                                {val.result}st {items.name}
                                                                                                            </p>
                                                                                                            : val.result === '2' ?
                                                                                                                <p className='m-0 mt-3 text-center text-warning font18 fw-bold'>
                                                                                                                    {val.result}nd {items.name}
                                                                                                                </p>
                                                                                                                : val.result === '3' ?
                                                                                                                    <p className='m-0 mt-3 text-center text-warning font18 fw-bold'>
                                                                                                                        {val.result}rd {items.name}
                                                                                                                    </p>
                                                                                                                    : null
                                                                                                    }
                                                                                                    <p className='m-0 mt-3 text-white font16'>
                                                                                                        {val.description}
                                                                                                    </p>
                                                                                                </div>
                                                                                                : null
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        )}
                                                    </div>
                                                    <div className='row m-0 p-0 px-4'>
                                                        <p className='text-warning m-0 mt-3 mb-2 fw-bold'>Nominees</p>
                                                        {Array.isArray(value?.sub_categories) && (
                                                            value?.sub_categories.map((items: any, idx: any) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            Array.isArray(items.nominations) && (
                                                                                items?.nominations.map((val: any, idx: any) => {
                                                                                    return (
                                                                                        <div className='col-lg-3 col-md-3 col-12 mb-4' key={val.id}>
                                                                                            <div
                                                                                                style={{
                                                                                                    height: '340px',
                                                                                                    width: '256px'
                                                                                                }}
                                                                                            >
                                                                                                <img src={`${baseURL}/${val.cover}`} alt={val.name} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                                                            </div>
                                                                                            <p className='m-0 mt-2 text-center text-white font18 fw-bold'>
                                                                                                ({val.name})
                                                                                            </p>
                                                                                            <p className='m-0 mt-3 text-white font16'>
                                                                                                {val.description}
                                                                                            </p>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            </LoadingOverlay>
            <ToastContainer />
        </>
    )
}

export default FilmFestivalDetail