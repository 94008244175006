import React, { useEffect } from 'react';
import './style.imagecard.css';
import { baseURL } from '../../utils/api';
import { Link } from 'react-router-dom';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const ImageCard = (props: any) => {
    const { id, cover, title, date, language, smallImage } = props;

    return (
        <>
            {smallImage !== true ?
                <div className='d-flex justify-content-center pointer'>
                    <div className="cardImage" key={id}>
                        <div className='image_language_box'>
                            <Link to={`/menu/film-festival-details/${removeDotReplaceSpaceToHyphen(title)}-${id}`} >
                                <img src={`${baseURL}/${cover}`} className={`hirizontal-card-image ${language ? 'opacity-50' : ''} rounded-4 object-fit-contain`} style={{ maxWidth: '100%'}} alt={title} />
                            </Link>
                            {
                                language ? <div className='m-0 bottom-left-image'>{language}</div> : null
                            }
                        </div>
                        {
                            date ? <p className='m-0 text-white font14 my-2 ps-2'>{date}</p> : null
                        }
                        {
                            title ? <p className='m-0 text-white font_weight_600 font16 ps-2'>{props.title}</p> : null
                        }
                    </div>
                </div>
                :
                <div className='small_image_box'>
                    <img src={`${baseURL}/${cover}`} className='small-card-image object-fit-contain' alt={title} />
                    <div className='m-0 bottom-left-image font14'>{title}</div>
                </div>
            }
        </>
    )
}

export default ImageCard
