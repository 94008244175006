import React, { useState } from 'react';
import './style.modalbox.css';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { user } from '../../states/reducers';
import axios from 'axios';
import { baseURL } from '../../utils/api';
import { useNavigate } from 'react-router-dom';

const LogoutModal = (props: any) => {

  const { handleModalBox } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const connection_id = localStorage.getItem('connection_id');
  const auth_code = localStorage.getItem('auth_code');

  const [show, setShow] = useState(true);

  const handlClosedModal = () => {
    handleModalBox(false);
    setShow(false);
  }

  let body = {
    connection_id,
    auth_code
  }

  const handleConfirm = async () => {
    await axios.post(`${baseURL}/api/sign-out`, { data: body });
    dispatch(user({}))
    localStorage.removeItem('auth_code');
    handleModalBox(true);
    navigate('/');
    window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={handlClosedModal}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <div className='d-flex justify-content-center align-items-center'>
        <div className='p-5 w-100'>
          <Modal.Body className='p-0 text-center text-white fs-5'>
            Are you Sure Do you want to  Sign-out
          </Modal.Body>
          <div className='d-flex justify-content-between align-items-center mt-4 px-4'>
            <button
              className='modal_box_cancel_button py-2 border fw-bold rounded bg-transparent font16'
              style={{ width: '150px' }}
              onClick={handlClosedModal}
            >
              Cancel
            </button>
            <button
              className='modal_box_signout_button py-2 bg-danger fw-bold rounded border font16'
              onClick={handleConfirm}
            >
              Sign-Out
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LogoutModal
