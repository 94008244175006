import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { ImCross } from 'react-icons/im';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Table } from 'react-bootstrap';
import ProjectSongModal from '../myprofilemodals/ProjectSongModal';
import { IoTrash } from 'react-icons/io5';
import { getSongLinkListAPI } from '../../utils/utils';
import { FiEdit } from 'react-icons/fi';

const SongsForm = (props: any) => {

    const { project_data, project_id } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [getId, setId] = useState<any>('');
    const songRef = useRef<HTMLDivElement | null>(null);
    const [songList, setSongList] = useState<any>([]);
    const [songLinks, setsongLinks] = useState({ name: '', youtube_link: '', singers: '' });

    const getList = async () => {
        setActive(true);
        const reviewList = await getSongLinkListAPI(project_id);
        setSongList(reviewList);
        setActive(false);
    }

    // const handleChange = (e: any, i: any) => {
    //     const { name, value } = e.target;
    //     const updatedsongLinksData = [...songLinks];
    //     updatedsongLinksData[i] = { ...updatedsongLinksData[i], [name]: value };
    //     setsongLinks(updatedsongLinksData);
    // };

    const handleDeleteData = async (e: any, id: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id
                }
                let result = await axios(`${baseURL}/api/project/destroy-song`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getList();
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    // const handleAddSongUpdate = async (e: any) => {
    //     e.preventDefault();
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 project_id: project_id,
    //                 project_songs: JSON.stringify(songLinks)
    //             }
    //             let result = await axios(`${baseURL}/api/project/add-songs`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const takeSongValuesForUpdate = (val: any) => {
        const data = val;
        setsongLinks({
            ...songLinks,
            name: data.name,
            youtube_link: data.youtube_link,
            singers: data.singers
        });
        setId(data.id);
        setOpenFormModal(!openFormModal);
    }

    const handleModalForm = () => {
        getList();
        setOpenFormModal(!openFormModal);
    }

    const handleAddSongModal = () => {
        setOpenAdd(!openAdd);
        getList();
    }

    useEffect(() => {
        const element = songRef.current;

        const handleShow = () => {
            getList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    useEffect(() => {
        // console.log('effect', teaserLinks);
    }, [songLinks, project_data])

    return (
        <>
            <div className="accordion accordion-flush mt-4" id="songs">
                <div className="accordion-item rounded">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesongs" aria-expanded="false" aria-controls="flush-collapsesongs">
                            Songs Links
                        </button>
                    </h2>
                    <div id="flush-collapsesongs" ref={songRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#songs">
                        <div className="accordion-body accordian_border_with_color border-top-0 movie_link_form theme_color">

                            <LoadingOverlay
                                active={isActive}
                                spinner
                                text='Loading your content...'
                            >
                                {openFormModal &&
                                    <ProjectSongModal handleModalBox={handleModalForm} dataId={getId} data={songLinks} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <ProjectSongModal handleModalBox={handleAddSongModal} project_id={project_id} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddSongModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(songList) && songList.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Song name</th>
                                                <th>Song link</th>
                                                <th>Singer name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(songList) && songList.length > 0 &&
                                                songList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.name}</td>
                                                            <td>{val.youtube_link}</td>
                                                            <td>{val.singers}</td>
                                                            <td>
                                                                <div
                                                                    className='font20'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => takeSongValuesForUpdate(val)} />
                                                                    <IoTrash className='pointer ms-4' onClick={(e) => handleDeleteData(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white text-center font_weight_600"
                                    >
                                        No data found.
                                    </p>
                                }
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default SongsForm
