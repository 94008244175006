import React from 'react';
import './newscss.css';
import { baseURL } from '../../utils/api';

const TopSliderCard = (props: any) => {
    const { image, text } = props;
    return (
        <div
            className='card_hover_text position-relative pointer'
            style={{
                height: '370px',
                width: '290px'
            }}
        >
            <img src={`${image}`} className='object-fit-contain' alt={text} width={'100%'} height={'100%'} />
            <div
                className='text_section'
            >
                <p
                    className='m-0 text-white'
                >
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </p>
            </div>
        </div>
    )
}

export default TopSliderCard
