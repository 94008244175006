import React, { useState, useEffect } from 'react';
import { BiCamera } from "react-icons/bi";
import PersonalInformationForm from '../../components/myprofileforms/PersonalInformationForm';
import BiographyForm from '../../components/myprofileforms/BiographyForm';
import SocialMediaLinks from '../../components/myprofileforms/SocialMediaLinks';
import ProjectDetailForm from '../../components/myprofileforms/ProjectDetailForm';
import CreateNewProjectForm from '../../components/myprofileforms/CreateNewProjectForm';
import ProfessionAccordian from '../../components/profession/ProfessionAccordian';
import KycForm from '../../components/myprofileforms/KycForm';
import { useSelector } from 'react-redux';
import FilmFraternityPersonalForm from '../../components/myprofileforms/FilmFraternityPersonalForm';
import UserAward from '../../components/myprofileforms/UserAward';
import WhoIntroduceYou from '../../components/myprofileforms/WhoIntroduceYou';
import GiveRefference from '../../components/myprofileforms/GiveRefference';
import PropertyAssets from '../../components/myprofileforms/PropertyAssets';
import { Link } from 'react-router-dom';
import { baseURL } from '../../utils/api';
import DiscographyForm from '../../components/myprofileforms/DiscographyForm';
import { getProfessionListAPI } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import WrittenScripts from '../../components/myprofileforms/WrittenScripts';
import FamilyForm from '../../components/myprofileforms/FamilyForm';
import SubscriberIndustry from '../../components/myprofileforms/SubscriberIndustry';
import HappyMovementForm from '../../components/myprofileforms/HappyMovementForm';
import OtherAchievements from '../../components/myprofileforms/OtherAchievements';
import ControversyForm from '../../components/myprofileforms/ControversyForm';
import ShowreelForm from '../../components/myprofileforms/ShowreelForm';
import PoliticalInvolmentForm from '../../components/myprofileforms/PoliticalInvolmentForm';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import CreateMasterClassForm from '../../components/myprofileforms/CreateMasterClassForm';
import MasterClassList from '../../components/myprofileforms/MasterClassList';
import CreateFilmInstitute from '../../components/myprofileforms/CreateFilmInstitute';
import FilmInstituteList from '../../components/myprofileforms/FilmInstituteList';
import PhysicalStats from '../../components/myprofileforms/PhysicalStats';
import ImagesCroper from '../../components/imagecroper/ImagesCroper';

const MyProfile = () => {

    const profile = useSelector((state: any) => state.user.user);
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(true);
    const { id, subscriber_id, name, avatar, subscriber_type_id, first_name, last_name, phone, profession_id } = profile;
    const [professionList, setProfessionList] = useState<any>([]);
    const [professionName, setProfessionName] = useState<any>();

    const getSelectedProfessionList = async () => {
        setActive(true);
        try {
            const data = await getProfessionListAPI();
            const selectedProfessionIds = profession_id || [];
            const selectedProfession = data?.filter((val: any) => selectedProfessionIds.includes(val.id.toString()));
            setProfessionList(selectedProfession);
            const selectedProfessionName = selectedProfession?.map((val: any) => val.title);
            setProfessionName(selectedProfessionName);
            setActive(false);
        } catch (error) {
            console.error('Error fetching master list:', error);
            setActive(false);
        }
    };

    const handleCropComplete = (base64Image: string) => {
        // Handle the cropped image data here
        console.log('Cropped image base64 data:', base64Image);
    };

    useEffect(() => {
        getSelectedProfessionList();
    }, [profile])

    useEffect(() => {
        // console.log('aaam', professionList);
    }, [professionList])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='mt-5'>
                <div className='row m-0 p-0 theme_color'>
                    <div className='col-12 py-3 d-grid justify-content-center align-items-center'>
                        <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(name)}-${subscriber_id}`} className='text-decoration-none text-center'>
                            <div className='text-center d-grid justify-content-center'>
                                <div
                                    className='d-flex justify-content-center align-items-center'
                                    style={{
                                        width: '110px',
                                        height: '110px',
                                    }}
                                >{avatar ?
                                    <img src={`${baseURL}/${avatar}`} alt={name} className='rounded-circle' width={'100%'} height={'100%'} />
                                    :
                                    // <img src='../images/myprofileimage.png' alt={name} className='rounded-circle' width={'100%'} height={'100%'} />
                                    <div
                                        className='d-flex justify-content-center align-items-center rounded-circle'
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#6b6868'
                                        }}
                                    >
                                        <span
                                            className='font_weight_600 font40 text-white'
                                        >
                                            {name ? name?.charAt(0).toUpperCase() : ''}
                                        </span>
                                    </div>
                                    }
                                    {/* <div
                                    className='position-absolute bg-dark text-white rounded-circle d-flex justify-content-center align-items-center'
                                    style={{
                                        right: '0px',
                                        bottom: '5px',
                                        width: '25px',
                                        height: '25px'
                                    }}
                                >
                                    <BiCamera />
                                </div> */}
                                </div>
                                {
                                    first_name ? <p className='m-0 my-2 text-white font14'>{first_name} {last_name}</p> : <p className='m-0 my-2 text-white font14'>{name}</p>
                                }
                            </div>
                        </Link>
                        {Array.isArray(professionList) && professionList.length > 0 ?
                            <p className='m-0 text-white font14'>
                                {
                                    professionList?.map((val: any, i: number) => {
                                        return (
                                            <span key={val.id}>
                                                {val.title}{i !== professionList.length - 1 && ','} &nbsp;
                                            </span>
                                        )
                                    })
                                }
                            </p>
                            : null
                        }
                    </div>
                </div>

                {subscriber_type_id !== 1 ?
                    <PersonalInformationForm />
                    :
                    <>
                        <FilmFraternityPersonalForm />
                        {professionName?.includes('Actor') &&
                            <PhysicalStats />
                        }
                        <BiographyForm />
                        <SocialMediaLinks />
                        <KycForm />
                        <ProfessionAccordian />
                        <ProjectDetailForm />
                        {/* <CreateNewProjectForm /> */}
                        <DiscographyForm />
                        <WrittenScripts />
                        <ShowreelForm />
                        <UserAward />
                        <OtherAchievements />
                        <ControversyForm />
                        <PoliticalInvolmentForm />
                        <FamilyForm />
                        <PropertyAssets />

                        <HappyMovementForm />
                        <WhoIntroduceYou />
                        <GiveRefference />
                        <SubscriberIndustry />
                        <MasterClassList />
                        {/* <CreateMasterClassForm /> */}
                        <FilmInstituteList />
                        {/* <CreateFilmInstitute /> */}
{/*                         
                        <h1>Image Cropper</h1>
                        <ImagesCroper onCropComplete={handleCropComplete} /> */}
                    </>
                }
            </div>
        </LoadingOverlay>
    )
}

export default MyProfile
