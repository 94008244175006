import React, { useState, useEffect } from 'react';
import '../signinmodal/Signinmodal.css';
import './newscss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { IoMdStar } from 'react-icons/io';
import { FaRegStar } from 'react-icons/fa';
import { BiLike } from 'react-icons/bi';

const EntertainmentModal = (props: any) => {

    const { id, handleModalBox } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            fullscreen={true}
        // size={`lg`}
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-white'>iframe modal</Modal.Title>
            </Modal.Header>
            <div className="text-white px-3">
                asif
            </div>
        </Modal>
    )
}

export default EntertainmentModal
