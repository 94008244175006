import React, { useState, useEffect } from 'react';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { isValidEmail } from '../../utils/jsfunctions';

const TicketBookingModal = (props: any) => {

    const { handleModalBox, eventTtime, id } = props;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [show, setShow] = useState(true);
    const [thanksMessage, setThanksMessage] = useState(false);
    const [hideButton, setHideButton] = useState(false);
    const [detail, setDetail] = useState({
        email: '',
        quantity: '',
        first_name: '',
        last_name: '',
        phone: '',
        event_date: '',
        event_time: eventTtime,
    });

    const maxMobileNumber = 10;

    const handlClosedModal = () => {
        handleModalBox(false);
        setShow(false);
        setThanksMessage(false);
        setHideButton(false);
    }

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            if (value.length <= maxMobileNumber) {
                setDetail({ ...detail, phone: value });
            }
        } else {
            setDetail({ ...detail, [name]: value });
        }
    }

    const handleBookedTicket = async (e: any) => {
        e.preventDefault();
        setActive(true);
        setHideButton(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { email, quantity, first_name, last_name, phone, event_date, event_time } = detail;

        if (email === '' || quantity === '' || event_date === '') {
            messageTxt('Please fill email, quantity and select date');
            setActive(false);
            setHideButton(false);
        } else if (phone.length > 10 || phone.length < 10) {
            messageTxt('Please enter 10 digit phone number');
            setActive(false);
            setHideButton(false);
        } else if (!isValidEmail(email)) {
            messageTxt('Please enter valid email');
            setActive(false);
            setHideButton(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    email,
                    quantity,
                    first_name,
                    last_name,
                    phone,
                    event_date,
                    event_time,
                    event_id: id
                }

                const res = await axios(`${baseURL}/api/event/book_ticket`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setThanksMessage(true);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
                setHideButton(false);
            }
        }
    }

    // useEffect(() => {
    //     console.log(detail);
    // }, [detail])


    return (

        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`d-flex justify-content-center align-items-center opacity-75`}
            // fullscreen={true}
            size={`lg`}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className='text-white'
                >
                    Book ticket
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {thanksMessage ?
                    <div>
                        <h1
                            className='text-warning'
                        >
                            Thank for choosing cinemaDBS.
                        </h1>
                        <h3
                            className='text-white'
                        >
                            Ticket sent on your given email.
                        </h3>
                    </div>
                    :
                    <LoadingOverlay
                        active={isActive}
                        spinner
                        text='Loading your content...'
                    >
                        <div className="row p-0 d-flex justify-content-between align-items-center create_crew_form ticket_booking_modal">
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="email" className="form-label text-white m-0">Email</label>
                                <input type="email" onChange={handleChangeValue} name='email' value={detail.email} className="form-control mb-3" id="email" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="quantity" className="form-label text-white m-0">Quantity</label>
                                <input type="number" onChange={handleChangeValue} name='quantity' value={detail.quantity} className="form-control mb-3" min={1} id="quantity" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="firstname" className="form-label text-white m-0">First name</label>
                                <input type="text" onChange={handleChangeValue} name='first_name' value={detail.first_name} className="form-control mb-3" id="firstname" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="lastname" className="form-label text-white m-0">Last name</label>
                                <input type="text" onChange={handleChangeValue} name='last_name' value={detail.last_name} className="form-control mb-3" id="lastname" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="number" className="form-label text-white m-0">Phone number</label>
                                <input type="number" onChange={handleChangeValue} name='phone' value={detail.phone} className="form-control mb-3" id="number" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="date" className="form-label text-white m-0">Date</label>
                                <input type="date" onChange={handleChangeValue} name='event_date' value={detail.event_date} className="form-control mb-3" id="number" />
                            </div>
                        </div>
                    </LoadingOverlay>
                }
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handlClosedModal}>
                    Close
                </Button>
                {hideButton ?
                    null
                    :
                    <Button
                        variant="danger"
                        className='font_weight_600'
                        onClick={handleBookedTicket}
                    >
                        Book now
                    </Button>
                }
            </Modal.Footer>
            <ToastContainer />
        </Modal>
    )
}

export default TicketBookingModal
