import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from '../../utils/api';
import { cancelOrderEcomAPI, getOrderListEcomAPI } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { PiCaretLeftBold } from 'react-icons/pi';
import Table from 'react-bootstrap/Table';
import { getDOB } from '../../utils/jsfunctions';
import { FaRegEye } from "react-icons/fa";
import { TbShoppingCartCancel } from "react-icons/tb";
import ProductDetailModal from '../../components/shopcomponents/ProductDetailModal';
import TooltipHover from '../../components/tooltip/TooltipHover';

const MyOrder = () => {
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [product_detail_id, setProduct_detail_id] = useState<any>();
    const [productList, setProductList] = useState<any>([]);

    const getOrderList = async () => {
        setActive(true);
        const data = await getOrderListEcomAPI();
        setProductList(data);
        setActive(false);
    }

    const cancelOrder = async (cancel_id: any) => {
        setActive(true);
        const data = await cancelOrderEcomAPI(cancel_id);
        messageTxt(data);
        setActive(false);
    }

    const openProductDetail = (product_id: any) => {
        setProduct_detail_id(product_id);
        setOpenDetailModal(!openDetailModal);
    }

    useEffect(() => {
        getOrderList();
    }, [])

    // useEffect(() => {
    //     console.log('order list', productList)
    // }, [productList])


    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div
                className='mb-5 py-3 mt-5'
            >
                {openDetailModal &&
                    <ProductDetailModal handleModalBox={openProductDetail} id={product_detail_id} />
                }
                <div
                    className='d-lg-flex d-md-flex d-flex justify-content-between align-items-center order_bottom_with_color mb-lg-4 mb-md-4 mb-3 py-2 px-lg-4 px-md-4 px-2 mt-lg-5 mt-md-5 mt-0'
                >
                    <Link to={'/menu/shop'} className='text-decoration-none text-warning d-flex align-items-center'>
                        <PiCaretLeftBold className='font20 me-2' />
                        <p
                            className='m-0 font_weight_600 font16'
                        >
                            Continue Shopping
                        </p>
                    </Link>
                    <div
                        className='py-2 d-flex align-items-center'
                    >
                        <Link to={`/menu/shopping-cart`} className='ms-3 text-decoration-none text-white'
                        >
                            My Cart
                        </Link>
                    </div>
                </div>
                {Array.isArray(productList) && productList.length > 0 ?
                <Table striped bordered hover responsive variant="dark">
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Order number</th>
                            <th>Product name</th>
                            <th>Order date</th>
                            <th>Total items</th>
                            <th>Total amount</th>
                            <th>Payment status</th>
                            <th>Order status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(productList) && productList.length > 0 &&
                            productList.map((val: any, i: any) => {
                                return (
                                    <tr
                                        key={val.id}
                                        className=''
                                    >
                                        <td>{i + 1}</td>
                                        <td>{val.order_number}</td>
                                        <td>{val.item_name}</td>
                                        <td>{getDOB(val.order_date)}</td>
                                        <td>{val.total_items}</td>
                                        <td>{val.grand_total}</td>
                                        <td>{val.payment_status_name}</td>
                                        <td>{val.order_status}</td>
                                        <td
                                            className='font20 d-flex justify-content-between align-items-center'
                                        >
                                            <FaRegEye className='pointer' data-tooltip-id="view_order_detail" onClick={() => openProductDetail(val.id)} />
                                            {(val.status === 0 || val.status === 1) &&
                                                <TbShoppingCartCancel className='pointer' data-tooltip-id="cancel_order_tooltip" onClick={() => cancelOrder(val.id)} />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                :
                <div
                    className='position-relative'
                >
                    <img src='../images/menushopbanner.png' alt='shop' width={'100%'} height={'100%'} />
                    <div
                        className='position-absolute text-white font_weight_600 font18'
                        style={{
                            top: '40%',
                            left: '10%'
                        }}
                    >
                        <p
                            className='text-uppercase'
                        >
                            Items not found in your order list.
                        </p>
                        <Link
                            to={'/menu/shop'}
                            className='text-danger'
                        >
                            Continue Shopping
                        </Link>
                    </div>
                </div>
}
            </div>
            <TooltipHover text={'View Detail'} id={'view_order_detail'} place={'top'} />
            <TooltipHover text={'Cancel Order'} id={'cancel_order_tooltip'} place={'top'} />
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default MyOrder
