import React from 'react'
import { FaRegStar } from 'react-icons/fa';

const ShortMovieCard = (props: any) => {
    const { id, title, image, year, genre } = props;
    return (
        <div>
            <div
                className='position-relative pointer my-3'
                style={{
                    height: '200px',
                    width: '300px'
                }}
            >
                <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
                <div
                    className='position-absolute bg-warning px-2 d-flex align-items-baseline rounded-pill'
                    style={{
                        bottom: '-10px',
                        right: '15px'
                    }}
                >
                    <FaRegStar className='me-1' />
                    <span className='font18'>
                        {0}
                    </span>
                </div>
            </div>
            <p
                className='m-0 text-white mt-2 font16 font_weight_600'
            >
                {title}
            </p>
            <p
                className='m-0 text-white font14'
            >
                {year}/{genre} Movie
            </p>
        </div>
    )
}

export default ShortMovieCard
