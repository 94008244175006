import React, { useEffect, useState } from 'react';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import { getCategoryListAPI, getSubCategoryListAPI } from '../../utils/utils';

const SubCategory = (props: any) => {

    const { festival_data, festival_id } = props;
    const { industry_reference } = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [category_data, setCatData] = useState<any>([]);
    const [festivalSubCategory, setSubCategory] = useState<any>({ CatId: '', subCategory: [] });
    const [subCatList, setSubCatList] = useState<any>([]);

    const getCategoryList = async () => {
        const data = await getCategoryListAPI(festival_id);
        setCatData(data);
    }

    const getSubCategoryList = async () => {
        const data = await getSubCategoryListAPI(festival_id, festivalSubCategory.CatId);
        setSubCatList(data);
    }

    const handleAddFestivalSubCat = (e: any) => {
        setSubCategory({
            ...festivalSubCategory,
            subCategory: Array.isArray(festivalSubCategory.subCategory)
                ? [...festivalSubCategory.subCategory, '']
                : ['']
        });
    }

    const handleChange = (e: any, i: any) => {
        const { value } = e.target;
        const updatedcastData = [...festivalSubCategory.subCategory];

        if (typeof i === 'number' && i >= 0 && i < updatedcastData.length) {
            updatedcastData[i] = value;
            setSubCategory({ ...festivalSubCategory, subCategory: updatedcastData });
        }
    }

    const handleChangeSelect = (e: any) => {
        const { name, value } = e.target;
        setSubCategory({ ...festivalSubCategory, [name]: value });
    };

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleDeleteData = (e: any, i: any) => {
        e.preventDefault();
        const deletedData = [...festivalSubCategory.subCategory];
        deletedData.splice(i, 1);
        setSubCategory({ ...festivalSubCategory, subCategory: deletedData });
    }

    const handleDeleteList = (e: any, i: any, id:any) => {
        e.preventDefault();
        const deletedData = [...subCatList];
        deletedData[0].sub_categories.splice(i, 1);
        setSubCatList(deletedData);
        handleDeleteSubCategory(e, id);
    }

    const handleDeleteSubCategory = async (e: any, id:any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id: id,
                }
                let result = await axios(`${baseURL}/api/film_festival/sub_category/delete`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getCategoryList();
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleCategoryUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    film_festival_id: festival_id,
                    category_id: festivalSubCategory.CatId,
                    sub_categories: JSON.stringify(festivalSubCategory.subCategory)
                }
                let result = await axios(`${baseURL}/api/film_festival/sub_category/create`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setIsDisabled(!isDisabled);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getCategoryList();
        getSubCategoryList();
    }, [])

    useEffect(() => {
        if (festival_data.categories && Array.isArray(festival_data.categories)) {
            setSubCatList(festival_data.categories);
        }
    }, [festival_data])

    // useEffect(() => {
    //     console.log('ref', subCatList);
    // }, [subCatList])

    return (
        <>
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="subCategory">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesubCategory" aria-expanded="false" aria-controls="flush-collapsesubCategory">
                                Sub category
                            </button>
                        </h2>
                        <div id="flush-collapsesubCategory" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#subCategory">
                            <div className="accordion-body border theme_color">
                                <div className='d-flex justify-content-between align-items-center mb-4'>
                                    <span className='m-0 text-white fw-bold font18'>Sub category list</span>
                                    {/* <button
                                        className='py-2 px-2 border-0'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleAddFestivalSubCat}
                                    >
                                        +Add More
                                    </button> */}
                                </div>
                                {Array.isArray(subCatList) &&
                                    subCatList.map((val: any, i: any) => {
                                        return (

                                            <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form border mb-4" key={val.id}>
                                                <div className='col-lg-12 col-md-12 col-12'>
                                                    <label htmlFor="category" className="form-label text-white m-0">Selected main category</label>
                                                    <input type="text" name='CatId' value={val.name} className="form-control mb-3" disabled />
                                                </div>
                                                {val?.sub_categories.length > 0 &&
                                                    val?.sub_categories?.map((val: any, i: any) => {
                                                        return (

                                                            <div className='col-lg-6 col-md-6 col-12' key={i}>
                                                                <div
                                                                    className='position-relative'
                                                                >
                                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Sub category</label>
                                                                    <input type="text" name='subCategory' value={val.name} onChange={(e) => handleChange(e, i)} className="form-control mb-3" disabled={isDisabled} />
                                                                    <div
                                                                        className='position-absolute'
                                                                        style={{
                                                                            right: '5px',
                                                                            bottom: '5px'
                                                                        }}
                                                                    >
                                                                        {!isDisabled ?
                                                                            <button
                                                                                className='py-2 px-2 common_button rounded'
                                                                                style={{
                                                                                    backgroundColor: 'red',
                                                                                }}
                                                                                onClick={(e) => handleDeleteList(e, i, val.id)}
                                                                            >
                                                                                <ImCross className='text-white' />
                                                                            </button>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }

                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <button
                                        className='py-2 px-2 btn btn-outline-danger'
                                        style={{
                                            width: '200px'
                                        }}
                                        onClick={handleAddFestivalSubCat}
                                    >
                                        +Add More Sub Category
                                    </button>
                                </div>

                                <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form border mb-4">
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <label htmlFor="category" className="form-label text-white m-0">Select main category</label>
                                        <select className="form-select form-select-lg mb-3" onChange={handleChangeSelect} value={festivalSubCategory.CatId} name='CatId' aria-label="Large select example1" disabled={isDisabled}>
                                            <option selected>Select category...</option>
                                            {
                                                category_data?.map((val: any) => {
                                                    return (
                                                        <option key={val.id} value={val.id}>{val.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        festivalSubCategory.subCategory?.map((val: any, i: any) => {
                                            return (

                                                <div className='col-lg-6 col-md-6 col-12' key={val.id || i}>
                                                    <div
                                                        className='position-relative'
                                                    >
                                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Sub category</label>
                                                        <input type="text" name='subCategory' value={val} onChange={(e) => handleChange(e, i)} className="form-control mb-3" disabled={isDisabled} />
                                                        <div
                                                            className='position-absolute'
                                                            style={{
                                                                right: '5px',
                                                                bottom: '5px'
                                                            }}
                                                        >
                                                            {!isDisabled ?
                                                                <button
                                                                    className='py-2 px-2 common_button rounded'
                                                                    style={{
                                                                        backgroundColor: 'red',
                                                                    }}
                                                                    onClick={(e) => handleDeleteData(e, i)}
                                                                >
                                                                    <ImCross className='text-white' />
                                                                </button>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>

                                <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        {isDisabled ? 'Edit' : 'Cancel'}
                                    </button>
                                    {!isDisabled ?
                                        <button
                                            className='py-2 px-2 common_button rounded fw-bold'
                                            style={{
                                                width: '150px'
                                            }}
                                            onClick={handleCategoryUpdate}
                                        >
                                            Save
                                        </button>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default SubCategory
