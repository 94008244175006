import React from 'react'
import './filmographycss.css';
import Slider from 'react-slick';
import FilmographyCard from './FilmographyCard';

const FilmographySlider = (props: any) => {

    const { data } = props;

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    infinite: data?.length > 7,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,
                    infinite: data?.length > 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    infinite: data?.length > 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    infinite: data?.length > 4,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    infinite: data?.length > 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    infinite: data?.length > 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    infinite: data?.length > 1,
                },
            },
        ],
    };

    return (
        <Slider {...settings} className='filmography_card_slider'>
            {data?.map((val: any) => {
                return (
                    <FilmographyCard key={val.id} id={val.id} title={val.movie_title} cover={val.movie_poster} year={val.release_date} />
                )
            })}
        </Slider>
    )
}

export default FilmographySlider
