import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ProjectMediaReviewModal = (props: any) => {
    const { handleModalBox, data, dataId, project_id, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [getId, setId] = useState<any>('');
    const [mediaReview, setMediaReview] = useState<any>({
        article: '',
        article_link: ''
    });

    const handleChangeMediaVal = (e: any) => {
        const { name, value } = e.target;
        setMediaReview({ ...mediaReview, [name]: value });
    }

    const handleAddUpdateMediaReview = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { article, article_link } = mediaReview;

        if (article === '' || article_link === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id,
                    media_review_id: getId,
                    article,
                    article_link
                }
                const res = await axios(`${baseURL}/api/project/add-update/media_review`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                messageTxt(res.data.message);
                // setId('');
                setMediaReview({
                    article: '',
                    article_link: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const takehMovieRefValuesForUpdate = () => {
        setActive(true);
        if (dataId) {
            setId(dataId);
            setMediaReview({
                ...mediaReview,
                article: data.article,
                article_link: data.article_link
            });
        }
        setActive(false);
    }

    useEffect(() => {
        takehMovieRefValuesForUpdate();
    }, [data, getId])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center cast_modal">
                        <div className="row p-0 d-flex justify-content-between align-items-center">
                            <div className='col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Article link</label>
                                <input type="text" onChange={handleChangeMediaVal} value={mediaReview.article_link} name='article_link' className="form-control mb-3" />
                            </div>
                            <div className=" col-12 mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Article</label>
                                <textarea name='article' onChange={handleChangeMediaVal} value={mediaReview.article} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1"></textarea>
                            </div>
                        </div>

                        <div className='d-flex justify-content-end'>
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleAddUpdateMediaReview}
                            >
                                ADD
                            </button>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProjectMediaReviewModal
