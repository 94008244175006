import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonSlider from '../../components/commonslider/CommonSlider';
import { getLegendsDetails } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from '../../utils/api';

const LegendOfTheIndustryDetail = () => {

    LoadingOverlay.propTypes = undefined;
    const params = useParams();
    const [isActive, setActive] = useState(false);
    const [legendDetail, setLegend] = useState<any>([]);
    const [sliderImage, setSlider] = useState<any>([]);
    const { id } = params;

    // const sliderImage = ['../images/pr1.png', '../images/pr2.png', '../images/pr1.png', '../images/pr2.png', '../images/pr1.png', '../images/pr2.png', '../images/pr1.png', '../images/pr2.png'];

    const getLegendDetails = async () => {
        setActive(true);
        const data = await getLegendsDetails(id);
        setLegend(data);
        setActive(false);
    }

    useEffect(() => {
        getLegendDetails();
    }, [])

    useEffect(() => {
        if (legendDetail?.featured_images && Array.isArray(legendDetail?.featured_images)) {
            const images = legendDetail?.featured_images.map((val: any) => {
                return val.cover;
            })
            setSlider(images);
        }
        // console.log('params', sliderImage)
    }, [legendDetail]);

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <div className='my-5'>

                    <p className='m-0 text-white fw-bold fs-3 text-center text-uppercase'>{legendDetail?.name}</p>
                    {legendDetail?.nickname ?
                        <p className='m-0 text-warning fw-bold fs-3 text-center text-uppercase'>🌟🌟    {legendDetail?.nickname}    🌟🌟 </p>
                        : null
                    }

                    <div className='mt-3'>
                        <CommonSlider data={sliderImage} onlyImage={true} />
                    </div>

                    <div className='mt-5'>
                        <div className='fraternity_search_accordian_section'>
                            <div className="accordion accordion-flush" id="accordionFlushExample">

                                <div className="accordion-item rounded mb-3">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed rounded accordian_border_with_color text-warning" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                            Childhood
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body theme_color">
                                            <p className='text-white m-0 px-4'>
                                                {legendDetail?.about_childhood}
                                            </p>
                                            <div className='row mt-3 m-0 p-0 px-2'>
                                                {Array.isArray(legendDetail?.child_hood_images) &&
                                                    legendDetail.child_hood_images.map((val: any) => {
                                                        return (
                                                            <div className='col-lg-3 col-md-3 col-12 d-flex justify-content-center mb-lg-0 mb-md-0 mb-3' key={val.id}>
                                                                <div
                                                                    style={{
                                                                        height: '325px',
                                                                        width: '255px'
                                                                    }}
                                                                >
                                                                    <img src={`${baseURL}/${val.path}`} alt={val.path} width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item rounded mb-3">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed rounded accordian_border_with_color text-warning" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="true" aria-controls="flush-collapseTwo">
                                            Acting Career
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body theme_color">
                                            <p className='text-white m-0 px-4'>
                                                {legendDetail?.about_acting_career}
                                            </p>
                                            <div className='row mt-3 m-0 p-0 px-2'>
                                                {Array.isArray(legendDetail?.acting_carreer_images) &&
                                                    legendDetail.acting_carreer_images.map((val: any) => {
                                                        return (
                                                            <div className='col-lg-3 col-md-3 col-12 d-flex justify-content-center mb-lg-0 mb-md-0 mb-3' key={val.id}>
                                                                <div
                                                                    style={{
                                                                        height: '325px',
                                                                        width: '255px'
                                                                    }}
                                                                >
                                                                    <img src={`${baseURL}/${val.path}`} alt={val.path} width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item rounded mb-3">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed rounded accordian_border_with_color text-warning" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="true" aria-controls="flush-collapseThree">
                                            Awards & Honers
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body theme_color">
                                            <p className='text-white m-0 px-4'>
                                                {legendDetail?.about_awards_and_honers}
                                            </p>
                                            <div className='row mt-3 m-0 p-0 px-2'>
                                                {Array.isArray(legendDetail?.honer_images) &&
                                                    legendDetail.honer_images.map((val: any) => {
                                                        return (
                                                            <div className='col-lg-3 col-md-3 col-12 d-flex justify-content-center mb-lg-0 mb-md-0 mb-3' key={val.id}>
                                                                <div
                                                                    style={{
                                                                        height: '325px',
                                                                        width: '255px'
                                                                    }}
                                                                >
                                                                    <img src={`${baseURL}/${val.path}`} alt={val.path} width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item rounded mb-3">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed rounded accordian_border_with_color text-warning" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="true" aria-controls="flush-collapseFour">
                                            Other Details
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body theme_color">
                                            <p className='text-white m-0 px-4'>
                                                {legendDetail?.other_details}
                                            </p>
                                            <div className='row mt-3 m-0 p-0 px-2'>
                                                {Array.isArray(legendDetail?.other_images) &&
                                                    legendDetail.other_images.map((val: any) => {
                                                        return (
                                                            <div className='col-lg-3 col-md-3 col-12 d-flex justify-content-center mb-lg-0 mb-md-0 mb-3' key={val.id}>
                                                                <div
                                                                    style={{
                                                                        height: '325px',
                                                                        width: '255px'
                                                                    }}
                                                                >
                                                                    <img src={`${baseURL}/${val.path}`} alt={val.path} width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {legendDetail?.type === 'late' ?
                                    <div className="accordion-item rounded">
                                        <h2 className="accordion-header" id="flush-headingFive">
                                            <button className="accordion-button collapsed rounded accordian_border_with_color text-warning" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="true" aria-controls="flush-collapseFive">
                                                Death
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body theme_color">
                                                <p className='text-white m-0 px-4'>
                                                    {legendDetail?.about_death}
                                                </p>
                                                <div className='row mt-3 m-0 p-0 px-2'>
                                                    {Array.isArray(legendDetail?.death_images) &&
                                                        legendDetail.death_images.map((val: any) => {
                                                            return (
                                                                <div className='col-lg-3 col-md-3 col-12 d-flex justify-content-center mb-lg-0 mb-md-0 mb-3' key={val.id}>
                                                                    <div
                                                                        style={{
                                                                            height: '325px',
                                                                            width: '255px'
                                                                        }}
                                                                    >
                                                                        <img src={`${baseURL}/${val.path}`} alt={val.path} width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </LoadingOverlay>
        </>
    )
}

export default LegendOfTheIndustryDetail
