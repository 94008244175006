import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineCalendar } from "react-icons/ai";
import { CiLocationOn, CiStreamOn } from "react-icons/ci";
import { LuClock8 } from "react-icons/lu";
import { LiaLanguageSolid } from "react-icons/lia";
import { MdOutlinePersonOutline } from "react-icons/md";
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from '../../utils/api';
import { formatDate, getEventdetail } from '../../utils/utils';
import TicketBookingModal from '../../components/eventticketbookingmodal/TicketBookingModal';
import SignInModal from '../../components/signinmodal/SignInModal';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { BiLike } from 'react-icons/bi';

const EventTicketDetail = () => {

    const params = useParams();
    const auth_code = localStorage.getItem('auth_code');
    const location = useLocation();
    const [dateTime, setDateTime] = useState<any>([]);
    const { id } = params;
    const [isActive, setActive] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [eventDetails, setEventDetail] = useState<any>([]);
    const [openBookModal, setOpenBookModal] = useState(false);

    const getEventDetails = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const data = await getEventdetail(urlId);
        setEventDetail(data);
        setActive(false);
    }

    const handleTicketBookModal = (val: any) => {
        if (!auth_code) {
            setOpenLoginModal(true);
        } else {
            val ? setOpenBookModal(true) : setOpenBookModal(false);
        }
    }

    const handleCloseModal = (val: any) => {
        val ? setOpenLoginModal(false) : setOpenLoginModal(false);
    }

    useEffect(() => {
        getEventDetails();
    }, [])

    useEffect(() => {
        if (eventDetails?.start_date) {
            const data = new Date(`${eventDetails?.start_date}T${eventDetails.start_time}`);
            setDateTime(formatDate(data));
        }
        // console.log(eventDetails)
    }, [eventDetails])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            {openBookModal &&
                <TicketBookingModal handleModalBox={handleTicketBookModal} eventTtime={eventDetails?.start_time} id={eventDetails?.id} />
            }
            {openLoginModal &&
                <SignInModal handleModalBox={handleCloseModal} redirectPath={location.pathname} />
            }
            <div className='mt-5'>
                <div className='row m-0 p-0 mt-4'>
                    <div className='col-12 m-0 p-0'>
                        <div>
                            <img src={`${baseURL}/${eventDetails?.banner_image}`} className='object-fit-contain' alt='auditionbanner' width={'100%'} height={'100%'} />
                        </div>
                    </div>
                </div>

                <div className='mt-5 d-lg-flex d-md-block d-block justify-content-between align-items-center'>
                    <div className='left_div d-lg-flex d-md-flex d-block align-items-baseline'>
                        <button className='py-1 px-3 rounded border-0 text-dark font_weight_600 bg-warning text-capitalize me-lg-0 me-md-0 me-5' style={{ width: '150px' }}>
                            {eventDetails?.genre}
                        </button>
                        <button className='py-1 mx-lg-3 mx-md-3 mx-0 mt-lg-0 mt-md-0 mt-3 px-3 rounded border-0 text-dark font_weight_600 bg-warning text-capitalize' style={{ width: '150px' }}>
                            {eventDetails?.event_mode}
                        </button>
                        <div className='mt-lg-0 mt-md-0 mt-4'>
                            <p className='text-white d-flex align-items-center m-0 mb-3'>
                                <AiOutlineCalendar className='me-2' />{dateTime}
                            </p>
                        </div>
                    </div>
                    <div className='right_div mt-lg-0 mt-md-4 mt-4'>
                        <button className='py-1 px-3 rounded border-0 text-white font_weight_600' onClick={handleTicketBookModal} style={{ backgroundColor: 'red', width: '150px' }}>
                            Buy the Ticket
                        </button>
                    </div>
                </div>

                <div className='mt-3 d-flex justify-content-between align-items-center'>
                    <div
                        className='d-flex'
                    >
                        {eventDetails?.duration ?
                            <p className='text-white d-flex align-items-center m-0'>
                                <LuClock8 className='me-2' /><span className='text-warning'>{eventDetails?.duration}</span>
                            </p>
                            : null
                        }
                        {eventDetails?.language ?
                            <p className='ps-3 text-white d-flex align-items-center m-0'>
                                <LiaLanguageSolid className='me-2' /><span className='text-warning'>{eventDetails?.language}</span>
                            </p>
                            : null
                        }
                        {eventDetails?.minimum_age ?
                            <p className='ps-3 text-white d-flex align-items-center m-0'>
                                <MdOutlinePersonOutline className='me-2' /><span className='text-warning'>{eventDetails?.minimum_age}yer+</span>
                            </p>
                            : null
                        }
                        {eventDetails?.online_streaming ?
                            <p className='ps-3 text-white d-flex align-items-center m-0'>
                                <CiStreamOn className='me-2' />Online Streaming
                            </p>
                            : null
                        }
                        {eventDetails?.event_mode ?
                            <p className='ps-3 text-white d-flex align-items-center m-0'>
                                <CiLocationOn className='me-2' /><span className='text-warning'>{eventDetails?.event_mode}</span>
                            </p>
                            : null
                        }
                    </div>
                    <div>
                        <p
                            className='m-0 text-white font30'
                        >
                            &#8377; {eventDetails?.ticket_price}
                        </p>
                    </div>
                </div>

                <div
                    className='d-lg-flex d-md-block d-block justify-content-between border-bottom pb-4'
                >
                    <div>
                        <p className='m-0 text-white d-flex align-items-center m-0 font_weight_600'>
                            Venue: &nbsp; <span className='text-warning fw-normal'>{eventDetails?.address}</span>
                        </p>
                        <p className='m-0 text-white d-flex align-items-center m-0 font_weight_600'>
                            Location map: &nbsp; <Link to={eventDetails?.location_link} target='_blank' className='text-decoration-none text-warning fw-normal'>{eventDetails?.location_link}</Link>
                        </p>
                    </div>
                    <button
                        className='bg-warning text-dark font_weight_600 mt-4 rounded py-1 px-3'
                    >
                        <span className='fw-normal font12'><BiLike className='font18' /> 232.3k</span> Interested?
                    </button>
                </div>

                <div className='mt-4 border-bottom pb-4 ps-4'>
                    <p className='m-0 mb-2 text-white fw-bold font18'>About:</p>
                    <div className='text-white d-flex align-items-center m-0 mb-3'>
                        <p
                            className='m-0 text-white font16'
                        >
                            {eventDetails.about}
                        </p>
                        {/* {Array.isArray(eventDetails?.artist) &&
                            eventDetails?.artist.map((val: any) => {
                                return (
                                    <div
                                        key={val.id}
                                        style={{ height: '100px', width: '100px' }}
                                    >
                                        <img src={`${baseURL}/${val?.artist_image}`} className='rounded-circle' alt={val.artist_name} width={'100%'} height={'100%'} />
                                    </div>
                                )
                            })
                        } */}
                    </div>
                </div>

                {eventDetails?.terms_and_conditions ?
                    <div className='mt-4 ps-4'>
                        <p className='m-0 mb-2 text-white fw-bold font18'>Terms & Conditions</p>
                        <div className='ps-3 text-white d-flex align-items-center m-0 mb-3'>
                            <div className='mb-3 text-white font16' dangerouslySetInnerHTML={{ __html: eventDetails?.terms_and_conditions }} />
                        </div>
                    </div>
                    : null
                }
            </div>
        </LoadingOverlay>
    )
}

export default EventTicketDetail
