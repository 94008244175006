import React from 'react';
import './ottcss.css';
import { Link } from 'react-router-dom';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const GenreCard = (props: any) => {

    const { id, title, movies, logo } = props;

    return (
        <div
            className='parent_genre_card_div rounded d-flex align-items-center'
        >
            <div
                className='icon_box rounded mx-3'
            >
                <span className='text-warning font32'>
                    {logo}
                </span>
            </div>
            <div>
                <p
                    className='m-0 text-white font30'
                >
                    {title}
                </p>
                <p
                    className='m-0 text-white font14 mb-2'
                >
                    {movies}+ Movies
                </p>
                <Link to={`/menu/ott-category/${removeDotReplaceSpaceToHyphen(title)}-${id}`} className='text-warning font14'>
                    View More
                </Link>
            </div>
        </div>
    )
}

export default GenreCard
