import React, { useState } from 'react';
import ShopSidePannel from '../../components/shopcomponents/ShopSidePannel';
import DisplayProduct from '../../components/shopcomponents/DisplayProduct';
import ShopMobileMenu from '../../components/shopcomponents/ShopMobileMenu';

const Shop = () => {

  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleProductSelect = (productId: string) => {
    setSelectedProduct(productId);
  };

  const showMobileMenu = () => {
    setShowMenu(!showMenu);
  }

  return (
    <>
      <div
        className='d-block d-sm-none'
      >
        <ShopMobileMenu onProductSelect={handleProductSelect} onShowModal={showMobileMenu} showVal={showMenu} />
      </div>
      <div className="row">
        <div
          className='col-lg-3 col-md-3 d-none d-md-block d-lg-none d-none d-lg-block d-xl-block'
        >
          <ShopSidePannel onProductSelect={handleProductSelect} />
        </div>
        <div
          className='col-lg-9 col-md-9 col-12'
        >
          <DisplayProduct selectedProduct={selectedProduct} handleSideMenu={showMobileMenu} />
        </div>
      </div>

      {/* <div className="shop_container">
        <ShopSidePannel onProductSelect={handleProductSelect} />
        <DisplayProduct selectedProduct={selectedProduct} />
      </div> */}
    </>
  )
}

export default Shop
