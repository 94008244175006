import React, { useEffect, useState } from 'react';
import { GoogleLogin } from '@leecheuk/react-google-login';

const GoogleLoginAuth = () => {

  const client_id = '452595311897-bqjhtq3ma6kupoe83301po82a97lrsdi.apps.googleusercontent.com'

  const responseGoogle = (response: any) => {
    console.log(response);
  }

  return (
    <GoogleLogin
        clientId={client_id}
        buttonText="Sign in with Google"
        className='google_icons_style'
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
  )
}

export default GoogleLoginAuth
