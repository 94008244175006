import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { ImCross } from 'react-icons/im';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import { getCrewListAPI, getProjectDetails } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import ProjectCrewModal from '../myprofilemodals/ProjectCrewModal';
import { Table } from 'react-bootstrap';
import { IoTrash } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';

const CrewForm = (props: any) => {

    const { project_data, project_id } = props;
    const messageTxt = (message: any) => toast(message);
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false);
    const crewRef = useRef<HTMLDivElement | null>(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [getId, setId] = useState<any>('');
    const [crewList, setCrewList] = useState<any>([]);
    const [crewData, setcrewData] = useState({ name: '', role: '', image: '' });

    const getList = async () => {
        setActive(true);
        const data = await getCrewListAPI(project_id);
        setCrewList(data);
        setActive(false);
    }

    // const handleAddCrew = (e: any) => {
    //     e.preventDefault();
    //     setcrewData([...crewData, { name: '', role: '', photoFile: '' }]);
    // }

    // const handleChange = (e: any, i: any) => {
    //     const { name, value } = e.target;
    //     const updatedcrewData = [...crewData];
    //     const image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    //     if (typeof i === 'number' && i >= 0 && i < updatedcrewData.length) {
    //         updatedcrewData[i] = { ...updatedcrewData[i], [name]: value, image };
    //         setcrewData(updatedcrewData);
    //     } else {
    //         console.error('Invalid index provided for array update.');
    //     }
    // };

    const handleDeleteData = async (e: any, id: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id
                }
                let result = await axios(`${baseURL}/api/project/destroy-crew`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getList();
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleAddCrewUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    crew: crewData
                }
                let result = await axios(`${baseURL}/api/project/add-crew`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const takeCrewValuesForUpdate = (val: any) => {
        const data = val;
        setcrewData({
            ...crewData,
            name: data.name,
            role: data.role,
            image: data.image
        });
        setId(data.id);
        setOpenFormModal(!openFormModal);
    }

    const handleModalForm = () => {
        getList();
        setOpenFormModal(!openFormModal);
    }

    const handleAddCrew = () => {
        setOpenAdd(!openAdd);
        getList();
    }

    useEffect(() => {
        const element = crewRef.current;

        const handleShow = () => {
            getList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    useEffect(() => {
        // console.log('cast data', project_data.cast);
    }, [crewData, project_data])

    return (
        <div className="accordion accordion-flush mt-4" id="crewDetails">
            <div className="accordion-item rounded">
                <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsecrewDetails" aria-expanded="false" aria-controls="flush-collapsecrewDetails">
                        CREW Details
                    </button>
                </h2>
                <div id="flush-collapsecrewDetails" ref={crewRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#crewDetails">
                    <div className="accordion-body cast_form_detail accordian_border_with_color border-top-0 mb-5 theme_color">

                        <LoadingOverlay
                            active={isActive}
                            spinner
                            text='Loading your content...'
                        >
                            {openFormModal &&
                                <ProjectCrewModal handleModalBox={handleModalForm} dataId={getId} data={crewData} headingText={'Update'} />
                            }
                            {openAdd &&
                                <ProjectCrewModal handleModalBox={handleAddCrew} project_id={project_id} headingText={'Add New'} />
                            }

                            <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                <button
                                    className='py-2 border-0 rounded fw-bold'
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        width: '100px'
                                    }}
                                    onClick={handleAddCrew}
                                >
                                    + Add
                                </button>
                            </div>

                            {Array.isArray(crewList) && crewList.length > 0 ?
                                <Table striped bordered hover responsive variant="dark">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Image</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(crewList) && crewList.length > 0 &&
                                            crewList.map((val: any, i: any) => {
                                                return (
                                                    <tr
                                                        key={val.id}
                                                    >
                                                        <td>{i + 1}</td>
                                                        <td>{val.name}</td>
                                                        <td>
                                                            {val.img_path ?
                                                                <img src={`${baseURL}/${val.img_path}`} alt={val.name} className='object-fit-contain' height={'50'} width={'50'} />
                                                                :
                                                                <img src={`../images/noimage.png`} alt={val.name} className='object-fit-contain' height={'50'} width={'50'} />
                                                            }
                                                        </td>
                                                        <td>{val.role}</td>
                                                        <td>
                                                            <div
                                                                className='font20'
                                                            >
                                                                <FiEdit className='pointer' onClick={(e) => takeCrewValuesForUpdate(val)} />
                                                                <IoTrash className='pointer  ms-4' onClick={(e) => handleDeleteData(e, val.id)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                :
                                <p
                                    className="m-0 text-white text-center font_weight_600"
                                >
                                    No data found.
                                </p>
                            }
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrewForm
