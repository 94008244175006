import React, { useEffect, useState } from 'react'
import ContinueWatchingSlider from '../../components/ottcomponents/ContinueWatchingSlider'
import GenreCard from '../../components/ottcomponents/GenreCard';
import { ImFilm } from "react-icons/im";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { FaTheaterMasks, FaHeartBroken, FaGhost } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { PiHandEyeDuotone } from "react-icons/pi";
import TopMovieSlider from '../../components/ottcomponents/TopMovieSlider';
import RecommendedSlider from '../../components/ottcomponents/RecommendedSlider';
import FilmProRecSlider from '../../components/ottcomponents/FilmProRecSlider';
import ShortMovieCard from '../../components/ottcomponents/ShortMovieCard';
import NewUploadSlider from '../../components/ottcomponents/NewUploadSlider';
import { baseURL } from '../../utils/api';
import { getBannerAPI } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import TrendingSlider from '../../components/ottcomponents/TrendingSlider';
import OttBanner from '../../components/ottcomponents/OttBanner';

const Ott = () => {

  const [isActive, setActive] = useState(false);
  const [banner, setBanner] = useState<any>([]);

  const sponsorBanner = async () => {
    setActive(true);
    const data = await getBannerAPI();
    setBanner(data);
    setActive(false);
  }

  const continue_watching_data = [
    { id: '1', title: 'ottc1', image: '../images/ottc1.png' },
    { id: '2', title: 'ottc2', image: '../images/ottc2.png' },
    { id: '3', title: 'ottc3', image: '../images/ottc3.png' },
    { id: '4', title: 'ottc4', image: '../images/ottc4.png' },
    { id: '5', title: 'ottc5', image: '../images/ottc1.png' },
    { id: '6', title: 'ottc6', image: '../images/ottc2.png' },
    { id: '7', title: 'ottc7', image: '../images/ottc3.png' },
    { id: '8', title: 'ottc8', image: '../images/ottc4.png' },
  ];

  const genre_data = [
    { id: '1', title: 'Action', movies: '1,300', logo: <ImFilm /> },
    { id: '2', title: 'Fantasy', movies: '800', logo: <FaWandMagicSparkles /> },
    { id: '3', title: 'Comedy', movies: '1,000', logo: <FaTheaterMasks /> },
    { id: '4', title: 'Drama', movies: '1,000', logo: <HiUserGroup /> },
    { id: '5', title: 'Mystery', movies: '500', logo: <BsFillQuestionCircleFill /> },
    { id: '6', title: 'Romance', movies: '900', logo: <FaHeartBroken /> },
    { id: '7', title: 'Horror', movies: '750', logo: <FaGhost /> },
    { id: '8', title: 'Thriller', movies: '500', logo: <PiHandEyeDuotone /> },
  ];

  const top10movies = [
    { id: '1', title: 'A', image: '../images/otttop1.png' },
    { id: '2', title: 'B', image: '../images/otttop2.png' },
    { id: '3', title: 'C', image: '../images/otttop3.png' },
    { id: '4', title: 'D', image: '../images/otttop4.png' },
    { id: '5', title: 'E', image: '../images/otttop5.png' },
    { id: '6', title: 'F', image: '../images/otttop3.png' },
    { id: '7', title: 'G', image: '../images/otttop2.png' },
  ];

  const recommendedData = [
    { id: '1', title: 'Gandhadagudi', duration: '1hr:24mins', image: '../images/ottrec1.png' },
    { id: '2', title: 'Weapons of Vedha', duration: '1hr:24mins', image: '../images/ottrec2.png' },
    { id: '3', title: 'Aayushmanbhava', duration: '1hr:24mins', image: '../images/ottrec3.png' },
    { id: '4', title: 'Gandhadagudi', duration: '1hr:24mins', image: '../images/ottrec1.png' },
    { id: '5', title: 'Weapons of Vedha', duration: '1hr:24mins', image: '../images/ottrec2.png' },
  ];

  const fprecData = [
    { id: '1', title: 'Gandhadagudi', image: '../images/fprec1.png' },
    { id: '2', title: 'Weapons of Vedha', image: '../images/fprec2.png' },
    { id: '3', title: 'Aayushmanbhava', image: '../images/fprec3.png' },
    { id: '4', title: 'Gandhadagudi', image: '../images/fprec1.png' },
    { id: '5', title: 'Weapons of Vedha', image: '../images/fprec2.png' },
  ];

  const shortMovieData = [
    { id: '1', title: 'Gamer Girl', image: '../images/ottshort1.png', year: '2022', genre: 'Horror' },
    { id: '2', title: 'Gamer Girl', image: '../images/ottshort2.png', year: '2022', genre: 'Horror' },
    { id: '3', title: 'Gamer Girl', image: '../images/ottshort3.png', year: '2022', genre: 'Horror' },
    { id: '4', title: 'Gamer Girl', image: '../images/ottshort4.png', year: '2022', genre: 'Horror' },
    { id: '5', title: 'Gamer Girl', image: '../images/ottshort1.png', year: '2022', genre: 'Horror' },
    { id: '6', title: 'Gamer Girl', image: '../images/ottshort2.png', year: '2022', genre: 'Horror' },
    { id: '7', title: 'Gamer Girl', image: '../images/ottshort3.png', year: '2022', genre: 'Horror' },
    { id: '8', title: 'Gamer Girl', image: '../images/ottshort4.png', year: '2022', genre: 'Horror' },
  ];

  const newUpload = [
    { id: '1', title: 'Gandhadagudi', image: '../images/ottnew1.png' },
    { id: '2', title: 'Weapons of Vedha', image: '../images/ottnew2.png' },
    { id: '3', title: 'Aayushmanbhava', image: '../images/ottnew3.png' },
    { id: '4', title: 'Gandhadagudi', image: '../images/ottnew4.png' },
    { id: '5', title: 'Weapons of Vedha', image: '../images/ottnew1.png' },
    { id: '6', title: 'Weapons of Vedha', image: '../images/ottnew2.png' },
    { id: '7', title: 'Aayushmanbhava', image: '../images/ottnew3.png' },
    { id: '8', title: 'Gandhadagudi', image: '../images/ottnew4.png' },
  ];

  const latest_trending_data = [
    { id: '1', title: 'Gandhadagudi', image: '../images/ottnew1.png' },
    { id: '2', title: 'Weapons of Vedha', image: '../images/ottnew2.png' },
    { id: '3', title: 'Aayushmanbhava', image: '../images/ottnew3.png' },
    { id: '4', title: 'Gandhadagudi', image: '../images/ottnew4.png' },
    { id: '5', title: 'Weapons of Vedha', image: '../images/ottnew1.png' },
    { id: '6', title: 'Weapons of Vedha', image: '../images/ottnew2.png' },
    { id: '7', title: 'Aayushmanbhava', image: '../images/ottnew3.png' },
    { id: '8', title: 'Gandhadagudi', image: '../images/ottnew4.png' },
  ];

  useEffect(() => {
    sponsorBanner();
  }, [])

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Loading your content...'
    >
      <div
        className='mb-5'
      >
        <OttBanner />
      </div>
      <div
        className='continue_watching_section'
      >
        <p
          className='m-0 text-warning font16 font_weight_600'
        >
          Continue Watching
        </p>
        <ContinueWatchingSlider data={continue_watching_data} />
      </div>

      {/* genre section */}

      <div
        className='genre_section'
      >
        <p
          className='m-0 text-warning font16 font_weight_600'
        >
          Genre Based Movie Collection
        </p>
        <div
          className='row theme_color pt-lg-4 pt-md-4 pt-3 mt-3 mx-0 px-lg-5 px-md-5 px-0'
        >
          {
            genre_data.map((val: any) => {
              return (
                <div className='col-lg-3 col-md-6 col-12 mb-lg-4 mb-md-4 mb-3 d-flex justify-content-center align-items-center' key={val.id}>
                  <GenreCard id={val.id} movies={val.movies} title={val.title} logo={val.logo} />
                </div>
              )
            })
          }
        </div>
      </div>

      {/* top 10 movies section */}

      <div
        className='top10_movie_section mt-4'
      >
        <p
          className='m-0 text-warning font16 font_weight_600'
        >
          Top 10 movies to Watch
        </p>
        <TopMovieSlider data={top10movies} />
      </div>

      {/* recommended slider */}

      <div
        className='recommended_section mt-4'
      >
        <p
          className='m-0 text-warning font16 font_weight_600'
        >
          Recommended Movies
        </p>
        <RecommendedSlider data={recommendedData} />
      </div>

      {/* film producer recommended slider */}

      <div
        className='recommended_section mt-4'
      >
        <p
          className='m-0 text-warning font16 font_weight_600'
        >
          Film Producers Recommendation
        </p>
        <FilmProRecSlider data={fprecData} />
      </div>

      {/* short movie section */}

      <p
        className='m-0 text-warning font16 font_weight_600 mt-4'
      >
        Short Movies
      </p>
      <div
        className='short_movie_section'
        style={{
          backgroundColor: '#1e1e1e'
        }}
      >
        <div
          className='row mx-0 mt-3 px-lg-2 px-md-2 px-0'
        >
          {
            shortMovieData.map((val: any) => {
              return (
                <div className='col-lg-3 col-md-6 col-12 mb-3 d-flex justify-content-center align-items-center' key={val.id}>
                  <ShortMovieCard id={val.id} image={val.image} title={val.title} year={val.year} genre={val.genre} />
                </div>
              )
            })
          }
        </div>
      </div>

      {/* new upload section */}

      <div
        className='new_upload_section mt-4'
      >
        <p
          className='m-0 text-warning font16 font_weight_600'
        >
          New Uploads
        </p>
        <NewUploadSlider data={newUpload} />
      </div>

      <p
        className='m-0 text-warning font16 font_weight_600 mt-4'
      >
        Web Series
      </p>
      <div
        className='short_movie_section'
        style={{
          backgroundColor: '#1e1e1e'
        }}
      >
        <div
          className='row mx-0 mt-3 px-lg-2 px-md-2 px-0'
        >
          {
            shortMovieData.map((val: any) => {
              return (
                <div className='col-lg-3 col-md-6 col-12 mb-3 d-flex justify-content-center align-items-center' key={val.id}>
                  <ShortMovieCard id={val.id} image={val.image} title={val.title} year={val.year} genre={val.genre} />
                </div>
              )
            })
          }
        </div>
      </div>

      <div className='row my-5 mx-lg-5 mx-md-5 mx-2'>
        <div className='imageBox'>
          <p className='text-white font14 m-0 mb-1'>
            Advertisement
          </p>
          <img src={`${baseURL}/${banner}`} alt='sponsor' />
        </div>
      </div>

      {/* Latest and Trending Trailers section */}

      <div
        className='new_upload_section mt-4'
      >
        <p
          className='m-0 text-warning font16 font_weight_600'
        >
          Latest and Trending Trailers
        </p>
        <TrendingSlider data={latest_trending_data} />
      </div>

    </LoadingOverlay>
  )
}

export default Ott
