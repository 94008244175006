import React, { useEffect, useState, useRef } from 'react';
import './style.profile.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ToastContainer, toast } from 'react-toastify';
import { getAllEventsCategory, getAllLanguage } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';

const CreateEvents = () => {

  LoadingOverlay.propTypes = undefined;
  const messageTxt = (message: any) => toast(message);
  const [isActive, setActive] = useState(false);
  const inputRef = useRef<any>(null);
  const [languages, setLanguages] = useState<any>([]);
  const [generes, setGeneres] = useState<any>([]);

  const [createEvent, setCreateEvent] = useState<any>({
    title: '',
    event_genre: '',
    event_mode: '',
    start_date: '',
    end_date: '',
    start_time: '',
    about: '',
    language: '',
    address: '',
    duration: '',
    latitude: '',
    longitude: '',
    location_link: '',
    online_event_link: '',
    minimum_age: '',
    terms_and_conditions: '',
    online_streaming: '',
    watch_on: '',
    contact_us: '',
    city: '',
    banner_image: '',
    cover_image: '',
    artist_name: '',
    artist_image: ''
  });

  const getLanguage = async () => {
    setActive(true);
    const data = await getAllLanguage();
    setLanguages(data);
    setActive(false);
  }

  const getGeneres = async () => {
    setActive(true);
    const data = await getAllEventsCategory();
    setGeneres(data);
    setActive(false);
  }

  const handleChangeValue = (e: any) => {
    const { name, value } = e.target;
    if (name === 'banner_image' || name === 'cover_image' || name === 'artist_image') {
      const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
      if (name === 'banner_image') {
        setCreateEvent({
          ...createEvent,
          banner_image: file,
        });
      } else if (name === 'cover_image') {
        setCreateEvent({
          ...createEvent,
          cover_image: file,
        });
      } else if (name === 'artist_image') {
        setCreateEvent({
          ...createEvent,
          artist_image: file,
        });
      }
    } else {
      setCreateEvent({ ...createEvent, [name]: value });
    }
  }

  const handleChangeMapValue = () => {
    const places = inputRef.current?.getPlaces();
    if (places && places.length > 0) {
      // console.log(places[0].formatted_address);
      // console.log(places[0].geometry.location?.lat());
      // console.log(places[0].geometry.location?.lng());
      setCreateEvent({
        ...createEvent,
        address: places[0].formatted_address,
        latitude: places[0].geometry.location?.lat(),
        longitude: places[0].geometry.location?.lng()

      });
    }
  };

  const handleSubmitEvent = async (e: any) => {
    e.preventDefault();
    setActive(true);
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    const { title, event_genre, event_mode, start_date, end_date, start_time, about, language, address, duration, location_link,
      online_event_link, minimum_age, terms_and_conditions, online_streaming, watch_on, contact_us, city, banner_image, cover_image, artist_name, artist_image, latitude, longitude } = createEvent;

    if (connection_id && auth_code) {
      if (title === "" || start_date === "" || event_genre === "" || event_mode === "" || start_time === "" || online_streaming === "" || banner_image === "" || cover_image === "") {
        messageTxt('Please Fill Mandatory Fields...');
        setActive(false);
      } else {
        try {
          let body = {
            connection_id, auth_code, title, event_genre, event_mode, start_date, end_date, start_time, about, language, address, duration, location_link, online_event_link, minimum_age, terms_and_conditions, online_streaming, watch_on, contact_us,
            city, banner_image, cover_image, artist_name, artist_image, latitude, longitude
          }
          let result = await axios(`${baseURL}/api/event/registraion`, {
            method: 'POST',
            data: body,
            headers: { "Content-Type": "multipart/form-data" }
          });
          messageTxt(result.data.message);
          // console.log(result.data.data.project_id);
          setActive(false);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  const handleLoad = (searchBox: any) => {
    if (searchBox) {
      inputRef.current = searchBox;
    }
  };

  useEffect(() => {
    getLanguage();
    getGeneres();
  }, [])

  // useEffect(() => {
  //   console.log(createEvent);
  // }, [createEvent])

  return (
    <>
      <LoadingOverlay
        active={isActive}
        spinner
        text='Loading your content...'
      >
        <div className='theme_color py-3 px-5'>
          <form className='create_event_form' onSubmit={handleSubmitEvent}>
            <div className="row p-0 d-flex justify-content-between align-items-center">
              <p className='m-0 text-white mb-4 fs-4'>Create Event</p>

              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="title" className="form-label text-white m-0">Event Title</label>
                <input type="text" onChange={handleChangeValue} name='title' value={createEvent.title} className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="eventmode" className="form-label text-white m-0">Event Mode</label>
                <input type="text" onChange={handleChangeValue} name='event_mode' value={createEvent.event_mode} className="form-control mb-3" placeholder='Online/Offline' />
              </div>
              <div className='col-lg-3 col-md-3 col-12'>
                <label htmlFor="startdate" className="form-label text-white m-0">Start Date</label>
                <input type="date" onChange={handleChangeValue} value={createEvent.start_date} name='start_date' className="form-control mb-3 cal_icon" />
              </div>
              <div className='col-lg-3 col-md-3 col-12'>
                <label htmlFor="enddate" className="form-label text-white m-0">End Date</label>
                <input type="date" onChange={handleChangeValue} value={createEvent.end_date} name='end_date' className="form-control mb-3 cal_icon" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="starttime" className="form-label text-white m-0">Start Time</label>
                <input type="time" onChange={handleChangeValue} value={createEvent.start_time} name='start_time' className="form-control mb-3 cal_icon" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="movieTitle" className="form-label text-white m-0">Address</label>
                {/* <input type="text" onChange={handleChangeValue} value={createEvent.address} name='address' className="form-control mb-3" /> */}
                <LoadScript
                  googleMapsApiKey='AIzaSyBIrgOgx7pn2NOxWFk-7SvhMi5fKOwaK-o'
                  libraries={['places']}
                >
                  <StandaloneSearchBox
                    onLoad={handleLoad}
                    onPlacesChanged={handleChangeMapValue}
                  >
                    <input type="text" className="form-control mb-3" placeholder='Enter Location...' />
                    {/* <input
          type="text"
          placeholder="Search for a place..."
        /> */}
                  </StandaloneSearchBox>
                </LoadScript>
                {/* <label htmlFor="latitude" className="form-label text-white m-0">Latitude</label>
              <input type="text" onChange={handleChangeValue} value={createEvent.latitude} name='latitude' className="form-control mb-3" /> */}
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="about" className="form-label text-white m-0">About Event</label>
                <textarea onChange={handleChangeValue} value={createEvent.about} name='about' className="form-control mb-3"></textarea>
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="locationlink" className="form-label text-white m-0">Location Link</label>
                <input type="text" onChange={handleChangeValue} value={createEvent.location_link} name='location_link' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="movieTitle" className="form-label text-white m-0">Minnimum Age</label>
                <input type="number" onChange={handleChangeValue} value={createEvent.minimum_age} name='minimum_age' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="onlineeventlink" className="form-label text-white m-0">Online Event Link</label>
                <input type="text" onChange={handleChangeValue} value={createEvent.online_event_link} name='online_event_link' className="form-control mb-3" />
              </div>
              <div className='col-lg-3 col-md-3 col-12'>
                <label htmlFor="duration" className="form-label text-white m-0">Duration</label>
                <input type="text" onChange={handleChangeValue} value={createEvent.duration} name='duration' className="form-control mb-3" />
              </div>
              <div className='col-lg-3 col-md-3 col-12'>
                <label htmlFor="language" className="form-label text-white m-0">Language</label>
                <select className="form-select mb-3 text-white" onChange={handleChangeValue} value={createEvent.language} name='language' aria-label="Large select example2">
                  <option selected>Select</option>
                  {
                    languages.map((val: any) => {
                      return (
                        <option value={val.id} key={val.id}>{val.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="movieTitle" className="form-label text-white m-0">Genre</label>
                <select className="form-select mb-3" onChange={handleChangeValue} value={createEvent.event_genre} name='event_genre' aria-label="Large select example1">
                  <option selected>Select</option>
                  {
                    generes.map((val: any) => {
                      return (
                        <option value={val.id} key={val.id}>{val.title}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="movieTitle" className="form-label text-white m-0">Terms & Condition</label>
                <textarea onChange={handleChangeValue} value={createEvent.terms_and_conditions} name='terms_and_conditions' className="form-control mb-3"></textarea>
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="watchon" className="form-label text-white m-0">Watch on</label>
                <input type="text" onChange={handleChangeValue} value={createEvent.watch_on} name='watch_on' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="movieTitle" className="form-label text-white m-0">City</label>
                <input type="text" onChange={handleChangeValue} value={createEvent.city} name='city' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="movieTitle" className="form-label text-white m-0">Cover Image</label>
                <input type="file" onChange={handleChangeValue} name='cover_image' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="artistimage" className="form-label text-white m-0">Artist Image</label>
                <input type="file" onChange={handleChangeValue} name='artist_image' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="movieTitle" className="form-label text-white m-0">Online Streaming</label>
                <select className="form-select mb-3" onChange={handleChangeValue} value={createEvent.online_streaming} name='online_streaming' aria-label="Large select example">
                  <option selected>Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="contactus" className="form-label text-white m-0">Contact Us</label>
                <input type="text" onChange={handleChangeValue} name='contact_us' value={createEvent.contact_us} className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="bannerImage" className="form-label text-white m-0">Banner Image</label>
                <input type="file" onChange={handleChangeValue} name='banner_image' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <label htmlFor="artistname" className="form-label text-white m-0">Artist Name</label>
                <input type="text" onChange={handleChangeValue} value={createEvent.artist_name} name='artist_name' className="form-control mb-3" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                {/* <label htmlFor="longitude" className="form-label text-white m-0">Longitude</label>
              <input type="text" onChange={handleChangeValue} value={createEvent.longitude} name='longitude' className="form-control mb-3" /> */}
              </div>

              <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                <button
                  className='py-2 px-2 border rounded font_weight_600 me-3'
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    width: '150px'
                  }}
                  type='submit'
                >
                  Submit the Post
                </button>
                <button
                  className='py-2 px-2 border-0 rounded font_weight_600'
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    width: '150px'
                  }}
                >
                  Promote
                </button>

              </div>
            </div>
          </form>
        </div>
      </LoadingOverlay>
      <ToastContainer />
    </>
  )
}

export default CreateEvents
