import React, { useEffect, useState } from 'react';
import VideoPlayer from '../../components/videoplayer/VideoPlayer';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { addToWatchListAPI, formatDate, getAllFilmRights, getMovieDetails, giveRatingAPI, likeAndViewAPI } from '../../utils/utils';
import { SlArrowDown } from "react-icons/sl";
import SynopsisSlider from '../../components/singlemoviedetails/SynopsisSlider';
import { BsBookmarkPlusFill, BsFillBookmarkPlusFill } from 'react-icons/bs';
import { IoMdShare, IoMdStar } from "react-icons/io";
import { FaCaretSquareDown, FaRegStar } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { BiLike } from 'react-icons/bi';
import SignInModal from '../../components/signinmodal/SignInModal';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { baseURL } from '../../utils/api';
import MoviePlayerModal from '../../components/movieplayermodal/MoviePlayerModal';

const SingleVideoPlayer = () => {

    LoadingOverlay.propTypes = undefined;
    const params = useParams();
    const { id } = params;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(true);
    const [data, setData] = useState<any>([]);
    const [dateTime, setDateTime] = useState<any>([]);
    const [film_right, setFilmRight] = useState<any>([]);
    const [selectedRights, setSelectedRight] = useState<any>([]);
    const auth_code = localStorage.getItem("auth_code");
    const [rate, setRate] = useState<any>();
    const [showLogin, setShowLogin] = useState(false);
    const [toggle, setToggle] = useState<any>();
    const [addToWatch, setWatchList] = useState<any>();
    const [movieLink, setMovieLink] = useState<any>();
    const [openMovieModal, setOpenMovieModal] = useState(false);

    const getFilmRight = async () => {
        const data = await getAllFilmRights();
        setFilmRight(data);
    }

    const getData = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const result = await getMovieDetails(urlId);
        setData(result);
        setRate(result.rating);
        setToggle(result.like);
        setWatchList(result.watch);
        getFilmRight();
        setActive(false);
    }

    const giveRatingToMovie = async (e: any, givenRating: any) => {
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const urlId = extractIdFromUrl(id);
            const msg = await giveRatingAPI(urlId, givenRating);
            setRate(givenRating);
            console.log(msg);
        }
    }

    const giveLike = async (e: any, action: any, id: any) => {
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const urlId = extractIdFromUrl(id);
            const msg = await likeAndViewAPI(action, urlId);
            console.log(msg);
            messageTxt(msg);
            setToggle(!toggle);
        }
    }

    const addWatchList = async (e: any, id: any) => {
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const urlId = extractIdFromUrl(id);
            const msg = await addToWatchListAPI(urlId);
            console.log(msg)
            setWatchList(!addToWatch)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            getData();
        }, 3000);
    }, [id])

    const getSelectedRights = () => {
        const selectedNames = data?.project_rights.map((id: any) => {
            const matchingObject = film_right.find((val: any) => val.id === id.id);
            return matchingObject ? matchingObject.title : null;
        });
        setSelectedRight(selectedNames);
    };

    const copyToClipboard = () => {
        const currentUrl = window.location.href;

        navigator.clipboard.writeText(currentUrl)
            .then(() => {
                messageTxt('URL copied to clipboard !');
                // console.log('URL copied to clipboard!');
            })
            .catch((err) => {
                messageTxt('Error copying to clipboard !');
                // console.error('Error copying to clipboard:', err);
            });
    };

    const handleLoginModalBox = (val: any) => {
        val ? setShowLogin(false) : setShowLogin(false);
    }

    const handleOpenMovieModal = (val:any) => {
        setOpenMovieModal(!openMovieModal);
        setMovieLink(val);
    }

    useEffect(() => {
        if (data?.release_date) {
            const dt = new Date(`${data?.release_date}`);
            const rdate = formatDate(dt);
            const parsedDate = new Date(rdate);
            const dateWithoutTime = parsedDate.toLocaleDateString(undefined, {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
            setDateTime(dateWithoutTime);
            getSelectedRights();
        }
        // console.log('film_right',selectedRights);
        // console.log('data', data);
    }, [data, film_right, rate])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='my-5 container'>

                {openMovieModal &&
                <MoviePlayerModal handleModalBox={handleOpenMovieModal} link={movieLink} />
                }

                {showLogin ?
                    <SignInModal handleModalBox={handleLoginModalBox} />
                    : null
                }

                {(Array.isArray(data?.trailer_links) && data?.trailer_links[0]?.link) ?
                    <VideoPlayer
                        videoUrl={data?.trailer_links[0]?.link}
                        cover={data?.movie_poster}
                        width={'100%'}
                        height={'100vh'}
                    />
                    :
                    <div>
                        <img src={`${baseURL}/${data?.movie_banner}`} alt={data?.movie_title} width={'100%'} height={'100%'} />
                    </div>
                }

                {/* movie details */}

                <div
                    className='row m-0 my-lg-5 my-md-5 my-3'
                >
                    {/* detail side */}
                    <div
                        className='col-lg-12 col-md-12 col-12'
                    >
                        <div
                            className='row m-0'
                        >
                            <div
                                className='col-lg-6 col-md-6 col-12'
                            >
                                <div
                                    className='mt-2 mb-lg-4 mb-md-4 mb-2'
                                >
                                    {data?.colored_movie_link ?
                                        <button
                                            className='bg-transparent'
                                            onClick={() => handleOpenMovieModal(data?.colored_movie_link)}
                                        >
                                            <span
                                                className='font18 text-warning text-uppercase me-3'
                                            >
                                                WATCH NOW
                                            </span>
                                            <span
                                                className='font20 carret_bg_color'
                                            >
                                                <FaCaretSquareDown />
                                            </span>
                                        </button>
                                        :
                                        <button
                                            className='bg-transparent'
                                        >
                                            <span
                                                className='font18 text-secondary text-uppercase me-3'
                                            >
                                                WATCH NOW
                                            </span>
                                            <span
                                                className='font20 carret_bg_color'
                                            >
                                                <FaCaretSquareDown />
                                            </span>
                                        </button>
                                    }

                                </div>
                                <div
                                    className='my-2'
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        DURATION:
                                    </span>
                                    <span
                                        className='font18 text-white'
                                    >
                                        {data?.duration ? data?.duration : 'N/A'}
                                    </span>
                                </div>
                                <div
                                    className=''
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        POSSIBLE RELEASE DATE:
                                    </span>
                                    <span
                                        className='font18 text-white'
                                    >
                                        {dateTime ? dateTime : 'N/A'}
                                    </span>
                                </div>
                                <div
                                    className='my-2'
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        GENRE:
                                    </span>
                                    <span
                                        className='font18 text-white'
                                    >
                                        {data?.genre ? data?.genre : 'N/A'}
                                    </span>
                                </div>
                                <div
                                    className=''
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        STATUS:
                                    </span>
                                    <span
                                        className='font18 text-white'
                                    >
                                        {data?.status_of_project ? data?.status_of_project : 'N/A'}
                                    </span>
                                </div>
                                <div
                                    className='mt-2'
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        LANGUAGE:
                                    </span>
                                    <span
                                        className='font18 text-white'
                                    >
                                        {data?.language ? data?.language : 'N/A'}
                                    </span>
                                </div>
                                <div
                                    className='my-2'
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        CAST:
                                    </span>
                                    {Array.isArray(data?.cast) && data?.cast?.length > 0 ?
                                        data?.cast?.map((val: any, i: number) => {
                                            return (
                                                <span
                                                    className='font18 text-white'
                                                    key={val.id}
                                                >
                                                    {val.name}{i !== data?.cast?.length - 1 && ','}
                                                </span>
                                            )
                                        })
                                        :
                                        <span className='text-white font16'>N/A</span>
                                    }
                                </div>
                                <div
                                    className=''
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        CREW:
                                    </span>
                                    {Array.isArray(data?.crew) && data?.crew?.length > 0 ?
                                        data?.crew?.map((val: any, i: number) => {
                                            return (
                                                <span
                                                    className='font18 text-white'
                                                    key={val.id}
                                                >
                                                    {val.name}{i !== data?.crew?.length - 1 && ','}
                                                </span>
                                            )
                                        })
                                        :
                                        <span className='text-white font16'>N/A</span>
                                    }
                                </div>
                                <div
                                    className='my-2'
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        PRODUCER:
                                    </span>
                                    <span
                                        className='font18 text-white me-1'
                                    >
                                        {data?.producer_name ? data?.producer_name : 'N/A'}
                                    </span>
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        ; DIRECTOR:
                                    </span>
                                    <span
                                        className='font18 text-white me-1'
                                    >
                                        {data?.director_name ? data?.director_name : 'N/A'}
                                    </span>
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        ; MUSIC:
                                    </span>
                                    <span
                                        className='font18 text-white me-1'
                                    >
                                        {data?.music_by ? data?.music_by : 'N/A'}
                                    </span>
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        ; STORY:
                                    </span>
                                    <span
                                        className='font18 text-white me-1'
                                    >
                                        {data?.story_writer ? data?.story_writer : 'N/A'}
                                    </span>
                                </div>
                            </div>
                            <div
                                className='col-lg-6 col-md-6 col-12 text-lg-end text-md-end text-lg-end text-md-end text-start'
                            >
                                <div
                                    className='mt-2 d-flex justify-content-lg-end justify-content-sm-start'
                                >
                                    <span
                                        className={`font28 pointer`}
                                        style={{
                                            color: `${addToWatch ? '#ffc107' : '#4c4c4c'}`,
                                            zIndex: '2'
                                        }}
                                        onClick={(e: any) => addWatchList(e, id)}
                                    >
                                        <BsFillBookmarkPlusFill />
                                    </span>
                                    <h1
                                        className='text-warning'
                                    >
                                        {data?.movie_title ? data?.movie_title : 'N/A'}
                                    </h1>
                                </div>
                                <p
                                    className='m-0 text-white font30'
                                >
                                    {data?.movie_title_native_language ? data?.movie_title_native_language : 'N/A'}
                                </p>
                                <div
                                    className='d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-start'
                                >
                                    <span
                                        className='font18 text-white text-uppercase me-1'
                                    >
                                        CDBS RATING
                                    </span>
                                    {
                                        [...Array(5)].map((star, index) => {
                                            const givenRating = index + 1;
                                            return (
                                                <IoMdStar
                                                    className='pointer font24'
                                                    key={index}
                                                    style={{
                                                        color: (givenRating <= data?.cdbs_rating || givenRating === data?.cdbs_rating) ? 'yellow' : '#D9D9D9'
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <div
                                    className='my-2'
                                >
                                    <span
                                        className='font18 text-warning text-uppercase me-1'
                                    >
                                        Rate now
                                    </span>
                                    <span
                                        className='font24 text-white mx-2'
                                    >
                                        {
                                            [...Array(5)].map((star, index) => {
                                                const givenRating = index + 1;
                                                return (
                                                    <FaRegStar
                                                        className='me-1 pointer'
                                                        onClick={(e) => {
                                                            giveRatingToMovie(e, givenRating)
                                                        }}
                                                        key={id}
                                                        style={{
                                                            color: givenRating < rate || givenRating === rate ? '#FFC107' : '#D9D9D9'
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </span>
                                    <span
                                        className='font24 text-white me-2'
                                    >
                                        <BiLike
                                            className={`pointer ${toggle ? 'text-primary' : ''}`}
                                            onClick={(e: any) => giveLike(e, 'like', id)}
                                        />
                                    </span>
                                    <span
                                        className='font24 text-white me-2 pointer'
                                        onClick={copyToClipboard}
                                    >
                                        <IoMdShare />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            className='row m-0 mt-2'
                        >
                            <p
                                className='m-0 text-white font18'
                            >
                                <span
                                    className='text-warning text-uppercase fw-bold font18 me-2'
                                >
                                    Synopsys:
                                </span>{data?.movie_synopsis ? data?.movie_synopsis : 'N/A'}
                            </p>
                        </div>

                        <div
                            className='row m-0 mt-4'
                        >
                            {Array.isArray(data?.cast) &&
                                <SynopsisSlider data={data?.cast} />
                            }
                        </div>
                    </div>

                </div>

                <div
                    className='bg_add_box_block d-flex justify-content-center align-items-center mt-lg-0 mt-md-3 mt-3 mb-3'
                    style={{
                        height: '300px'
                    }}
                >
                    <h1
                        className='text-white fw-bod'
                    >
                        Ad
                    </h1>
                </div>

                {/* fiml rights */}

                {(Array.isArray(selectedRights) && selectedRights.length > 0) ?
                    <div className="accordion-item mb-3 rounded">
                        <h2 className="accordion-header" id="flush-filmRights">
                            <button className="accordion-button collapsed rounded-4 border px-4 py-2 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFilmRights" aria-expanded="true" aria-controls="flush-collapseFilmRights">
                                <div
                                    className='w-100 d-flex justify-content-between align-items-center'
                                >
                                    <span>Film Rights</span>
                                    <div
                                        className='d-flex justify-content-between align-items-center'
                                    >
                                        <button
                                            className='bg-transparent border border-warning rounded text-warning me-2'
                                        >
                                            Message me
                                        </button>
                                        <SlArrowDown className='fs-5' />
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div id="flush-collapseFilmRights" className="accordion-collapse collapse show" aria-labelledby="flush-filmRights" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body theme_color py-2 px-3 rounded-4 border border-top-0">
                                <div
                                    className='row m-0'
                                >
                                    {Array.isArray(selectedRights) &&
                                        selectedRights?.map((val: any, i: any) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`col-lg-6 col-md-6 col-12 ${i % 2 !== 0 ? 'text-lg-end' : ''}`}
                                                >
                                                    <div
                                                        className={`${i % 2 !== 0 ? 'd-flex justify-content-lg-end' : ''}`}
                                                    >
                                                        <div className="form-check ms-3 text-white">
                                                            <input className="form-check-input bg-transparent border"
                                                                checked={true}
                                                                type="checkbox"
                                                                id="flexCheckDefault"
                                                            />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                {val}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }

                {/* Other details */}

                <div className="accordion-item mb-3 rounded">
                    <h2 className="accordion-header" id="flush-otherdetails">
                        <button className="accordion-button collapsed rounded-4 border px-4 py-2 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseotherdetails" aria-expanded="true" aria-controls="flush-collapseotherdetails">
                            <div
                                className='w-100 d-flex justify-content-between align-items-center'
                            >
                                <span>Other Details</span>
                                <div
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <button
                                        className='bg-transparent border border-warning rounded text-warning me-2'
                                    >
                                        Message me
                                    </button>
                                    <SlArrowDown className='fs-5' />
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseotherdetails" className="accordion-collapse collapse show" aria-labelledby="flush-otherdetails" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body theme_color py-2 px-3 rounded-4 border border-top-0">
                            <div
                                className='row m-0'
                            >
                                <div
                                    className='col-lg-6 col-md-6 col-12'
                                >
                                    <div
                                        className='my-2'
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            Production Company Name:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.production_house ? data?.production_house : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className=''
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            Email- id:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.production_house_email ? data?.production_house_email : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className='my-2'
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            ADDRESS:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.production_house_address ? data?.production_house_address : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className=''
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            SHOOTING LOCATIONS:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.filming_locations ? data?.filming_locations : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className='my-2'
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            distributor NAME:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.distributor_name ? data?.distributor_name : 'N/A'}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className='col-lg-6 col-md-6 col-12 text-lg-end text-md-end text-start'
                                >
                                    <div
                                        className='my-2'
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            cbfc cERTIFICATE:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.cbfc_certificate ? data?.cbfc_certificate : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className=''
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            tYPE:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.type ? data?.type : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className='my-2'
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            BUDGET:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.budget ? data?.budget : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className=''
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            GROSS:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.gross_earning ? data?.gross_earning : 'N/A'}
                                        </span>
                                    </div>
                                    <div
                                        className='my-2'
                                    >
                                        <span
                                            className='font18 text-warning text-uppercase me-1'
                                        >
                                            net:
                                        </span>
                                        <span
                                            className='font18 text-white'
                                        >
                                            {data?.net_earning ? data?.net_earning : 'N/A'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default SingleVideoPlayer
