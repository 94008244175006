import React, { useRef, useEffect, useState } from 'react';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import MovieCard from '../moviecard/MovieCard';
import ImageCard from '../imagecard/ImageCard';
import Slider from "react-slick";
import CategoryCard from './CategoryCard';
import ComingSoonModal from '../comingsoonmodal/ComingSoonModal';
import { getAllIndustryTypeAPI } from '../../utils/utils';

const CategorySSlider = (props: any) => {

    // const { heading, leftIndicator, horizontalImage, data } = props;
    const [openComingSoonModal, setOpenComingSoonModal] = useState(false);
    const [data, setData] = useState<any>();

    const getIndustryData = async () => {
        const data = await getAllIndustryTypeAPI();
        setData(data);
    }

    const handleModalBox = (val: any) => {
        val ? setOpenComingSoonModal(false) : setOpenComingSoonModal(false);
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 12,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 10,
                    infinite: data?.length > 7,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 10,
                    infinite: data?.length > 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 10,
                    infinite: data?.length > 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 10,
                    infinite: data?.length > 4,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 7,
                    infinite: data?.length > 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 6,
                    infinite: data?.length > 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 3,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    infinite: data?.length > 1,
                },
            },
        ],
    };

    useEffect(() => {
        getIndustryData()
    }, []);

    return (
        <>
            {openComingSoonModal ?
                <ComingSoonModal handleModalBox={handleModalBox} />
                : null}
            <div className='my-3 py-3'>
                <Slider {...settings}>
                    {
                        data?.map((e: any, i: any) => {
                            return (
                                <CategoryCard key={e.id} id={e.id} image={e.cover} title={e.title} bgColor={i % 2 === 0 ? 'bg-warning' : 'bg-white'} />
                            );
                        })
                    }
                </Slider>
            </div>
        </>
    )
}

export default CategorySSlider