import React, { useEffect } from 'react';
import './style.fileupdate.css';
import { Outlet, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";

const FilmIndustry = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const tabs = ['Comming Soon Movies', 'New Movies', 'Trailers', 'Songs']
    const routPath = location.pathname.split('/').pop();

    const movieNews = !!location.pathname.includes('/filmupdate/movienews');

    useEffect(() => {
        if (location.pathname == '/filmupdate' ||
            location.pathname == '/filmupdate/'
        ) {
            navigate("/");
        }
    }, []);

    return (
        <div>
            {/* <div className='tab_section container text-white mt-4'>
                <p className='m-0 font22 text-warning'>{movieNews ? 'Movie News' : 'Film Updates'}</p>
                {movieNews ? null :
                    <div className='tabs_link d-flex mt-3'>
                        {
                            tabs.map((tab) => {
                                return (
                                    <NavLink to={tab.replace(/\s/g, '').toLowerCase()}
                                        className={`nav_link ${tab.replace(/\s/g, '').toLowerCase() == routPath ? 'text-danger border-danger' : ''}`}
                                        key={tab}
                                    >
                                        <Typography
                                            className="navtext"
                                            variant="overline"
                                            gutterBottom
                                            component="div"
                                        >
                                            <b className='text-capitalize'>{tab}</b>
                                        </Typography>
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                }
            </div> */}
            <Outlet />
        </div>
    )
}

export default FilmIndustry
