import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ShowreelModal = (props: any) => {
    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [showrell, setShowrell] = useState({
        title: '',
        link: '',
        video: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChangeValShowreel = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const video = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setShowrell((prevVal: any) => ({ ...prevVal, video: video }));
        } else {
            setShowrell({ ...showrell, [name]: value });
        }
    }

    const handlSubmitValShowreel = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        const { title, link } = showrell;

        if (title === '' || link === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    title,
                    link
                }
                const res = await axios(`${baseURL}/api/subscriber/showreels/add`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(res.data.message);
                setShowrell({
                    title: '',
                    link: '',
                    video: ''
                });
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }

    }


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center show_reels_section">
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="title" className="form-label text-white m-0">Title</label>
                            <input type="text" onChange={handleChangeValShowreel} value={showrell.title} name='title' placeholder='Enter video name' className="form-control mb-3" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="video_link" className="form-label text-white m-0">Video link</label>
                            <input type="text" onChange={handleChangeValShowreel} value={showrell.link} name='link' placeholder='URL link' className="form-control mb-3" />
                        </div>
                    </div>

                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                        <button
                            className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                width: '150px'
                            }}
                            onClick={handlSubmitValShowreel}
                        >
                            Save
                        </button>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ShowreelModal
