import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ProjectAwardModal = (props: any) => {
    const { handleModalBox, data, project_id, dataId, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [getId, setId] = useState<any>('');
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [award, setAward] = useState({ award_name: '', award_category: '', result: '' });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setAward({ ...award, [name]: value })
        // const updatedaward = [...award];
        // if (typeof i === 'number' && i >= 0 && i < updatedaward.length) {
        //     updatedaward[i] = { ...updatedaward[i], [name]: value };
        //     setAward(updatedaward);
        // } else {
        //     console.error('Invalid index provided for array update.');
        // }
    };

    const handleAwardAdd = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id: connection_id,
                    auth_code: auth_code,
                    project_id: project_id,
                    awards: JSON.stringify([award])
                }
                let result = await axios(`${baseURL}/api/project/add-awards`, {
                    method: 'POST',
                    data: body
                });
                setAward({ award_name: '', award_category: '', result: '' });
                setActive(false);
                messageTxt(result.data.message);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handleAwardUpdate = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id: connection_id,
                    auth_code: auth_code,
                    project_award_id: dataId,
                    award_name: award.award_name,
                    award_category: award.award_category,
                    result: award.result
                }
                let result = await axios(`${baseURL}/api/project/update-award`, {
                    method: 'POST',
                    data: body
                });
                setAward({ award_name: '', award_category: '', result: '' });
                setActive(false);
                messageTxt(result.data.message);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const takeAwardValuesForUpdate = () => {
        setActive(true);
        if (dataId) {
            setEnableUpdate(true);
            setAward({
                ...award,
                award_name: data.award_name,
                award_category: data.award_category,
                result: data.result
            });
        }
        setActive(false);
    }

    useEffect(() => {
        takeAwardValuesForUpdate();
    }, [data, getId])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center">
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Award Name</label>
                            <input type='text' className="form-select mb-3" value={award.award_name} name='award_name' onChange={(e) => handleChange(e)} aria-label="Default select example" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Award Category</label>
                            <input type='text' className="form-select mb-3" value={award.award_category} name='award_category' onChange={(e) => handleChange(e)} aria-label="Default select example" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Award Result</label>
                            <input type='text' className="form-select mb-3" value={award.result} name='result' onChange={(e) => handleChange(e)} aria-label="Default select example" />
                        </div>
                    </div>

                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                        {enableUpdate ?
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleAwardUpdate}
                            >
                                Update
                            </button>
                            :
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleAwardAdd}
                            >
                                ADD
                            </button>
                        }
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProjectAwardModal
