import React, { useEffect, useRef, useState } from 'react';
import './profileform.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getDiscographyListAPI } from '../../utils/utils';
import { Table } from 'react-bootstrap';
import { IoTrash } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';
import DiscographyModal from '../myprofilemodals/DiscographyModal';

const DiscographyForm = () => {

    const { user } = useSelector((state: any) => state.user);
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [discography_data, setDiscographyData] = useState<any>([]);
    const [discograpgy, setDiscography] = useState<any>({
        song_name: '',
        film: '',
        year: '',
        composer: '',
        notes: '',
        id: ''
    });

    const getDiscographyList = async () => {
        setActive(true);
        const data = await getDiscographyListAPI();
        setDiscographyData(data);
        setActive(false);
    }

    // const handleChange = (e: any) => {
    //     const { name, value } = e.target;
    //     setDiscography({ ...discograpgy, [name]: value });
    // }

    // const handleEditDiscography = (e: any) => {
    //     e.preventDefault();
    //     if (isDisabled) {
    //         messageTxt('You can edit your profile...');
    //     } else {
    //         messageTxt("You can't edit your profile...");
    //     }
    //     setIsDisabled(!isDisabled);
    // }

    // const handleDiscographySubmit = async (e: any) => {
    //     e.preventDefault();
    //     setActive(true);
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     const { song_name, film, year, composer, notes, id } = discograpgy;
    //     if (song_name === '') {
    //         messageTxt('Please fill song name...');
    //         setActive(false);
    //     } else if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 song_name,
    //                 film,
    //                 year,
    //                 composer,
    //                 notes,
    //                 discography_id: id
    //             }
    //             let result = await axios(`${baseURL}/api/subscriber/discography/addUpdate`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //             getDiscographyList();
    //             setActive(false);
    //             // console.log(result);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const deleteDiscography = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'discography',
                    id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getDiscographyList();
                setActive(false);
                // console.log(result);
            } catch (error) {
                console.log(error);
            }
        }
    }

    // const takeValueForUpdate = (e: any, val: any) => {
    //     const data = val;
    //     setDiscography(data);
    // }

    const handleModalForm = (val: any) => {
        setDiscography(val);
        setOpenFormModal(!openFormModal);
        getDiscographyList();
    }

    const handleAddmoreAwardModal = () => {
        getDiscographyList();
        setOpenAdd(!openAdd);
        setOpenFormModal(false);
    }

    // checking accordion open or close then api call
    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            getDiscographyList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    useEffect(() => {
        // console.log('discography', discograpgy);
    }, [discograpgy])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >

            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="userDiscography">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseuserDiscography" aria-expanded="false" aria-controls="flush-collapseuserDiscography">
                                Discography
                            </button>
                        </h2>
                        <div id="flush-collapseuserDiscography" ref={myElementRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#userDiscography">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openFormModal &&
                                    <DiscographyModal handleModalBox={handleModalForm} data={discograpgy} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <DiscographyModal handleModalBox={handleAddmoreAwardModal} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(discography_data) && discography_data.length > 0 &&
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Song name</th>
                                                <th>Film name</th>
                                                <th>Year</th>
                                                <th>Composor</th>
                                                <th>Co-Singer</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(discography_data) && discography_data.length > 0 &&
                                                discography_data.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.song_name}</td>
                                                            <td>{val.film}</td>
                                                            <td>{val.year}</td>
                                                            <td>{val.composer}</td>
                                                            <td>{val.co_singer}</td>
                                                            <td>{val.notes}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} />
                                                                    <IoTrash className='pointer' onClick={(e) => deleteDiscography(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                }

                                {/* {Array.isArray(discography_data) && discography_data.length > 0 ?
                                    <>
                                        <p className='m-0 text-white mb-3'>
                                            Discography list
                                        </p>
                                        {discography_data.length > 0 && Array.isArray(discography_data) ?
                                            discography_data.map((val: any) => {
                                                return (
                                                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center accordian_border_with_color mb-4" key={val.id}>
                                                        <div
                                                            className='d-flex justify-content-end mt-2'
                                                        >
                                                            {!isDisabled ?
                                                                <>
                                                                    <button
                                                                        className='py-2 px-2 common_button ms-2 fw-bold rounded'
                                                                        onClick={(e) => takeValueForUpdate(e, val)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        className='py-2 px-2 common_button ms-2 fw-bold rounded'
                                                                        onClick={(e) => deleteDiscography(e, val.id)}
                                                                    >
                                                                        <ImCross className='text-white' />
                                                                    </button>
                                                                </>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Remarks ( 100 words )</label>
                                                            <textarea value={val.notes} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" placeholder='Notes'></textarea>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Song name</label>
                                                            <input type="text" value={val.song_name} className="form-control mb-3" />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Film name</label>
                                                            <input type="text" value={val.film} className="form-control mb-3" />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Year</label>
                                                            <input type="text" value={val.year} className="form-control mb-3" />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Composer</label>
                                                            <input type="text" value={val.composer} className="form-control mb-3" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                    </>
                                    :
                                    null
                                } */}


                                {/* <form className='px-5 personal_info_form'>
                                    <p className='m-0 text-white font18'>Add/Update</p>
                                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Remarks ( 100 words )</label>
                                            <textarea name='notes' onChange={handleChange} value={discograpgy.notes} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" disabled={isDisabled}></textarea>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Song name</label>
                                            <input type="text" onChange={handleChange} value={discograpgy.song_name} name='song_name' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Film name</label>
                                            <input type="text" onChange={handleChange} value={discograpgy.film} name='film' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Year</label>
                                            <input type="number" onChange={handleChange} value={discograpgy.year} name='year' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Composer</label>
                                            <input type="text" onChange={handleChange} value={discograpgy.composer} name='composer' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                    </div>

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        <button
                                            className='py-2 px-2 border me-3 rounded fw-bold'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleEditDiscography}
                                        >
                                            Edit
                                        </button>
                                        {!isDisabled ?
                                            <button
                                                className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                                onClick={handleDiscographySubmit}
                                            >
                                                Save
                                            </button>
                                            : null
                                        }
                                    </div>
                                </form> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <ToastContainer />
        </LoadingOverlay>
    )
}

export default DiscographyForm
