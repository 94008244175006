import React from 'react';
import { FaCrown, FaRegStar } from "react-icons/fa6";

const FilmProRecCard = (props: any) => {
    const { id, title, image } = props;
    return (
        <div
            className='hover_zoom position-relative pointer my-3'
            style={{
                height: '250px',
                width: '350px'
            }}
        >
            <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
            <div
                className='position-absolute bg-warning font20 rounded-pill d-flex justify-content-center align-items-center'
                style={{
                    width: '30px',
                    height: '30px',
                    top: '5px',
                    right: '5px'
                }}
            >
                <FaCrown />
            </div>
            <div
                className='position-absolute bg-warning px-2 d-flex align-items-baseline rounded-pill'
                style={{
                    bottom: '-10px',
                    right: '15px'
                }}
            >
                <FaRegStar className='me-1' />
                <span className='font18'>
                    {0}
                </span>
            </div>
        </div>
    )
}

export default FilmProRecCard
