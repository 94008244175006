import React, { useState, useEffect } from 'react';
import './auditionstyle.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllIndustryTypeAPI, getAllLanguage, getAllMoviesAPI, getFilmType } from '../../utils/utils';
import Select from 'react-dropdown-select';
import { useSelector } from 'react-redux';

const AuditionCallFormModal = (props: any) => {

    const { handleModalBox } = props;
    const profile_data = useSelector((state: any) => state.user.user);
    const [selectIndustry, setSelectIndustry] = useState<any>(profile_data.industries);
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [show, setShow] = useState(true);
    const [thanksMessage, setThanksMessage] = useState(false);
    const [hideButton, setHideButton] = useState(false);
    const [filmTypeList, setfilmTypeList] = useState<any>([]);
    const [languages, setLanguages] = useState<any>([]);
    const [projectList, setProjectList] = useState<any>([]);
    const [industryList, setIndustryList] = useState<any>([]);
    const [detail, setDetail] = useState({
        project_id: '',
        title: '',
        description: '',
        audition_category: '',
        movie_type: '',
        gender: '',
        from_age: '',
        to_age: '',
        start_date: '',
        end_date: '',
        agency_email: '',
        phone: '',
        other_details: '',
        tags: '',
        language_id: '',
        industry_id: '',
        cover_image: '',
        banner_image: '',
        location: '',
        duration: '',
        audition_script: ''
    });

    const maxMobileNumber = 10;

    const getProjectList = async () => {
        setActive(true);
        const res = await getAllMoviesAPI();
        const data = res.filter((val: any) => {
            return (val.status_of_project === 'Pre-Production' || val.status_of_project === 'In Production' || val.status_of_project === 'Post-Production')
        })
        setProjectList(data);
        setActive(false);
    }

    const getFilmTypeList = async () => {
        setActive(true);
        const data = await getFilmType();
        setfilmTypeList(data);
        setActive(false);
    }

    const getLanguage = async () => {
        setActive(true);
        const data = await getAllLanguage();
        setLanguages(data);
        setActive(false);
    }

    const getIndustryList = async () => {
        setActive(true);
        const response = await getAllIndustryTypeAPI();
        const data = response.filter((val: any) => {
            return selectIndustry.includes(val.id);
        })
        setIndustryList(data);
        setActive(false);
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
        setThanksMessage(false);
        setHideButton(false);
    }

    const handleAuditionChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'cover_image') {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setDetail({ ...detail, cover_image: file });
        } else if (name === 'banner_image') {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setDetail({ ...detail, banner_image: file });
        } else if (name === 'phone') {
            if (value.length <= maxMobileNumber) {
                setDetail((prevVal: any) => ({ ...prevVal, phone: value }));
            }
        } else {
            setDetail({ ...detail, [name]: value });
        }
    }

    const handleSaveAuditionCall = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const {
            title, description, audition_category, movie_type, gender, from_age, to_age, start_date, end_date, agency_email, phone, other_details, tags, language_id,
            industry_id, cover_image, banner_image, location, project_id, duration, audition_script
        } = detail;

        if (title === '' || description === '' || audition_category === '' || movie_type === '' || gender === '' || from_age === '' || to_age === '' || start_date === '' || end_date === '' ||
            agency_email === '' || phone === '' || other_details === '' || tags === '' || language_id === '' || cover_image === '' || banner_image === '' ||
            location === '' || industry_id === '' || project_id === '' || duration === '' || audition_script === ''
        ) {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id, title, description, audition_category, movie_type, gender, from_age, to_age, start_date, end_date, agency_email, phone, other_details, tags, language_id: JSON.stringify(language_id),
                    industry_id, cover_image, banner_image, location, duration, audition_script
                }
                const res = await axios(`${baseURL}/api/audition_calls/create`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setThanksMessage(true);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }

    }

    const handleFormModalBack = () => {
        setThanksMessage(!thanksMessage);
    }

    useEffect(() => {
        getFilmTypeList();
        getLanguage();
        getIndustryList();
        getProjectList();
    }, [])

    // useEffect(() => {
    //     console.log(detail)
    // }, [detail])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75 modal_style`}
            // fullscreen={true}
            size={`lg`}
        >
            <Modal.Header className='d-flex justify-content-between align-items-center'>
                <Modal.Title
                    className='text-white'
                >
                    {thanksMessage ? '' : 'Create new audition call'}
                </Modal.Title>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </Modal.Header>

            <Modal.Body>
                {thanksMessage ?
                    <div
                        className='my-5'
                    >
                        <div
                            className='text-center mb-3'
                        >
                            <img src='../../images/confirmmodal.png' alt='confirmmodal' width={'100'} height={'100'} />
                        </div>
                        <h5
                            className='m-0 text-warning font26 fw-bold text-center'
                        >
                            Audition category is created successfully
                        </h5>
                        <div
                            className='text-center mt-4'
                        >
                            <button
                                className='border font_weight_600 rounded bg-transparent px-3'
                                onClick={handleFormModalBack}
                            >
                                Create the new Audition Category
                            </button>
                        </div>
                    </div>
                    :
                    <LoadingOverlay
                        active={isActive}
                        spinner
                        text='Loading your content...'
                    >
                        <div className="row p-0 d-flex justify-content-between align-items-center create_crew_form audition_form_modal">
                            <div className='col-12'>
                                <label htmlFor="audition_script" className="form-label text-white m-0">Audition script</label>
                                <textarea name="audition_script" onChange={handleAuditionChangeValue} value={detail.audition_script} className="form-control mb-3" id="audition_script" ></textarea>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="project_id" className="form-label text-white m-0">Select project</label>
                                <select className="form-select mb-3 bg-transparent text-white" name='project_id' value={detail.project_id} onChange={handleAuditionChangeValue} aria-label="Large select example">
                                    <option selected>Select project</option>
                                    {Array.isArray(projectList) &&
                                        projectList?.map((val: any) => {
                                            return (
                                                <option key={val.id} value={val.project_id}>{val.movie_title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="title" className="form-label text-white m-0">Title</label>
                                <input type="text" name='title' onChange={handleAuditionChangeValue} value={detail.title} className="form-control mb-3" id="title" placeholder='Eg: Audition for lead child artist' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="description" className="form-label text-white m-0">Description</label>
                                <textarea name="description" onChange={handleAuditionChangeValue} value={detail.description} className="form-control mb-3" id="description" placeholder="Description"></textarea>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="duration" className="form-label text-white m-0">Duration</label>
                                <input type="text" name='duration' onChange={handleAuditionChangeValue} value={detail.duration} className="form-control mb-3" id="duration" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="audition_category" className="form-label text-white m-0">Audition category</label>
                                <input type="text" onChange={handleAuditionChangeValue} name='audition_category' value={detail.audition_category} className="form-control mb-3" id="audition_category" placeholder='Eg: Child artist lead' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="movie_type" className="form-label text-white m-0">Movie type</label>
                                <select className="form-select form-select-lg mb-3 bg-transparent text-white" name='movie_type' value={detail.movie_type} onChange={handleAuditionChangeValue} aria-label="Large select example">
                                    <option selected>Select movie type</option>
                                    {Array.isArray(filmTypeList) &&
                                        filmTypeList?.map((val: any) => {
                                            return (
                                                <option key={val.id} value={val.title}>{val.title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="gender" className="form-label text-white m-0">Gender</label>
                                <select className="form-select form-select-lg mb-3 bg-transparent text-white" name='gender' value={detail.gender} onChange={handleAuditionChangeValue} aria-label="Large select example">
                                    <option selected className='gray_color'>Select gender</option>
                                    <option value='male'>Male</option>
                                    <option value='female'>Female</option>
                                    <option value='other'>Other</option>
                                </select>
                            </div>
                            <div className='col-lg-3 col-md-3 col-12'>
                                <label htmlFor="from_age" className="form-label text-white m-0">Age range from</label>
                                <input type="number" onChange={handleAuditionChangeValue} name='from_age' value={detail.from_age} className="form-control mb-3" id="from_age" />
                            </div>
                            <div className='col-lg-3 col-md-3 col-12'>
                                <label htmlFor="to_age" className="form-label text-white m-0">Age range to</label>
                                <input type="number" onChange={handleAuditionChangeValue} name='to_age' value={detail.to_age} className="form-control mb-3" id="to_age" />
                            </div>
                            <div className='col-lg-3 col-md-3 col-12'>
                                <label htmlFor="start_date" className="form-label text-white m-0">Start date</label>
                                <input type="date" onChange={handleAuditionChangeValue} name='start_date' value={detail.start_date} className="form-control mb-3 cal_icon" id="start_date" />
                            </div>
                            <div className='col-lg-3 col-md-3 col-12'>
                                <label htmlFor="end_date" className="form-label text-white m-0">End date</label>
                                <input type="date" onChange={handleAuditionChangeValue} name='end_date' value={detail.end_date} className="form-control mb-3 cal_icon" id="end_date" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="agency_email" className="form-label text-white m-0">Agency email</label>
                                <input type="email" onChange={handleAuditionChangeValue} name='agency_email' value={detail.agency_email} className="form-control mb-3" id="agency_email" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="phone" className="form-label text-white m-0">Phone number</label>
                                <input type="number" onChange={handleAuditionChangeValue} name='phone' value={detail.phone} className="form-control mb-3" id="phone" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="other_details" className="form-label text-white m-0">Other detail</label>
                                <input type="text" onChange={handleAuditionChangeValue} name='other_details' value={detail.other_details} className="form-control mb-3" id="other_details" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="tags" className="form-label text-white m-0">Tags</label>
                                <input type="text" onChange={handleAuditionChangeValue} name='tags' value={detail.tags} className="form-control mb-3" id="tags" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="location" className="form-label text-white m-0">Location</label>
                                <input type="text" onChange={handleAuditionChangeValue} name='location' value={detail.location} className="form-control mb-3" id="location" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="language_id" className="form-label text-white m-0">Language</label>
                                <Select
                                    name='selected'
                                    className='mb-3 text-white'
                                    options={languages}
                                    labelField='name'
                                    valueField='id'
                                    multi
                                    placeholder='Select multiple languages...'
                                    onChange={(values: any) => {
                                        const selectedIds = values.map((option: any) => option.id);
                                        setDetail({ ...detail, language_id: selectedIds })
                                    }}
                                    values={[]}
                                    style={{
                                        height: '50px',
                                        borderRadius: '5px',
                                        border: '1px solid white'
                                    }}
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="industry_id" className="form-label text-white m-0">Industry type</label>
                                <select className="form-select form-select-lg mb-3 bg-transparent text-white" name='industry_id' value={detail.industry_id} onChange={handleAuditionChangeValue} aria-label="Large select example">
                                    <option selected>Select industry</option>
                                    {Array.isArray(industryList) &&
                                        industryList?.map((val: any) => {
                                            return (
                                                <option key={val.id} value={val.id}>{val.title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="cover_image" className="form-label text-white m-0">Cover image</label>
                                <input type="file" onChange={handleAuditionChangeValue} name='cover_image' className="form-control mb-3" id="cover_image" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="banner_image" className="form-label text-white m-0">Banner image</label>
                                <input type="file" onChange={handleAuditionChangeValue} name='banner_image' className="form-control mb-3" id="banner_image" />
                            </div>
                        </div>
                    </LoadingOverlay>
                }
            </Modal.Body>
            {!thanksMessage ?
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className='font_weight_600'
                        onClick={handleSaveAuditionCall}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
                : null
            }
            <ToastContainer />
        </Modal>
    )
}

export default AuditionCallFormModal
