import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const KycModal = (props: any) => {
    const { handleModalBox, data, dataId, kyc_document_list, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [kyc, setKyc] = useState({
        document_id: '',
        document_image: '',
        type: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'document_image') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setKyc((prevKyc: any) => ({ ...prevKyc, document_image: document_image }));
        } else {
            setKyc((prevKyc) => ({ ...prevKyc, [name]: value }));
        }
    };

    const handleKYC = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (kyc.document_id === '' || kyc.document_image === '' || kyc.type === '') {
            messageTxt('Please fill all fields !');
            setActive(false);
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    document_id: kyc.document_id,
                    document_image: kyc.document_image,
                    type: kyc.type
                }
                let result = await axios(`${baseURL}/api/subscriber/update-kyc`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setKyc({
                    document_id: '',
                    document_image: '',
                    type: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <form className='px-4 kyc_form'>
                        <div className="row p-0 d-flex justify-content-between align-items-center mb-3">
                            <div className='col-12 mb-3'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Select document type</label>
                                <select onChange={handleChangeValue} name='type' value={kyc.type} className="form-select" aria-label="Default select example">
                                    <option selected>Select document type</option>
                                    <option value="address_proof">Address proof</option>
                                    <option value="id_proof">Id proof</option>
                                    <option value="film_association_card">Film association card proof</option>
                                </select>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Select id type</label>
                                <select onChange={handleChangeValue} name='document_id' value={kyc.document_id} className="form-select" aria-label="Default select example">
                                    <option selected>Select ID type</option>
                                    {
                                        kyc_document_list?.map((val: any, i: any) => {
                                            return (
                                                <option value={val.id} key={val.id}>{val.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload your photo id proof</label>
                                <input type="file" onChange={handleChangeValue} name='document_image' className="form-control" id="exampleFormControlInput1" accept="image/*" />
                            </div>
                        </div>

                        {/* <div className='row m-0 p-0 mb-5'>
                                        <div className='col-lg-6 col-md-6 col-12 p-0 pe-2'>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 p-0 pe-2'>
                                        </div>
                                    </div> */}

                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                            <button
                                className='py-2 px-2 border-0 common_button rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleKYC}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default KycModal
