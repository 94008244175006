import { differenceInYears } from 'date-fns';
import validator from 'validator';

export const isValidEmail = (email) => {
    return validator.isEmail(email);
};

export const getGeneratedTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in seconds
    const timeDifferenceInSeconds = Math.floor((currentDate - dateTime) / 1000);

    // Calculate months, days, hours, minutes, and remaining seconds
    const months = Math.floor(timeDifferenceInSeconds / (30 * 24 * 60 * 60)); // Assuming 30 days in a month
    const days = Math.floor((timeDifferenceInSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
    const hours = Math.floor((timeDifferenceInSeconds % (24 * 60 * 60)) / 3600);
    const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
    const seconds = timeDifferenceInSeconds % 60;

    // Set conditions for displaying the most significant unit first
    if (months > 0) {
        return `${months} months ago`;
    } else if (days > 0) {
        return `${days} days ago`;
    } else if (hours > 0) {
        return `${hours} hours ago`;
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `${seconds} seconds ago`;
    }
}

// get only year from date formate 2018-05-02 // 2018

export const getYearFromDate = (val) => {
    const dateObject = new Date(val);
    // Extract the year from the date object
    const year = dateObject.getFullYear();
    return year;
}

// get DOB in format 6 March 2020
export const getDOB = (inputDate) => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    if (inputDate) {
        const [year, month, day] = inputDate.split('-');
        const formattedDate = `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${year}`;
        return formattedDate;
    }
}

// fri, feb 10
export const getEventDateFormate = (inputDate) => {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const daysOfWeek = [
        "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
    ];
    if (inputDate) {
        const [year, month, day] = inputDate.split('-');
        const formattedDate = `${daysOfWeek[new Date(inputDate).getDay()]}, ${months[parseInt(month, 10) - 1]} ${parseInt(day, 10)}`;
        return formattedDate;
    }
}

// convert cm to feet inches
export const cmToFeetInches = (val) => {
    const cm = parseInt(val, 10);
    const meter = cm / 100;
    const feetValue = Math.floor(cm * 0.0328084);
    const inchesValue = Math.round((cm * 0.0328084 - feetValue) * 12);

    const result = {
        meter: meter,
        feet: feetValue,
        inches: inchesValue,
    };

    return result;
}

// convert weight to pounds
export const convertWeightToPounds = (val) => {
    const data = val * 2.204;
    const result = parseFloat(data.toFixed(2))
    return result;
}

// calculate death age
export const calculateDeathAge = (birthDate, deathDate) => {
    const parseDate = (dateString) => {
        if (!dateString) {
            return null;
        }

        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    };

    const birthDateObj = parseDate(birthDate);

    if (!birthDateObj || isNaN(birthDateObj.getTime())) {
        console.error('Invalid birth date:', birthDate);
        return null;
    }

    const today = new Date();
    const endDate = deathDate ? parseDate(deathDate) : today;

    if (endDate && isNaN(endDate.getTime())) {
        console.error('Invalid death date:', deathDate);
        return null;
    }

    return differenceInYears(endDate, birthDateObj);
};

// remove '.:;,%' ' and replace ' ' with '-'
export const removeDotReplaceSpaceToHyphen = (str) => {
    if (str) {
        const modifiedString = str.replace(/[.:;,%']/g, '').replace(/\s+/g, '-').toLowerCase();
        return modifiedString;
    }
}

// extract number or ID from url
export const extractIdFromUrl = (str) => {
    // const matchResult = str.match(/\d+/);
    // const extractedNumber = matchResult ? matchResult[0] : null;
    const regex = /-(\d+)$/;
    const match = str.match(regex);

    // Extracted number
    const extractedNumber = match ? match[1] : null;
    return extractedNumber;
}

// film festival get yeaar from 2018 to next from current
export const getYearList = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    const endYear = currentYear + 1;

    const yearList = [];

    for (let year = startYear; year <= endYear; year++) {
        yearList.push(year);
    }

    return yearList;
};