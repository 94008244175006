import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import { getAllProjects } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from '../../utils/api';

const SubmitMovieFilmfestival = (props: any) => {

    const { handlerModalBox } = props;
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false);
    const [all_movies, setAllMovies] = useState<any>([]);
    const [show, setShow] = useState(true);
    const [movieId, setMovieId] = useState<any>([]);

    const handleChangeVal = (e: any) => {
        const { value, checked } = e.target;
        if (checked === true) {
            if (!movieId.includes(parseInt(value))) {
                setMovieId([...movieId, parseInt(value)]);
            }
        } else {
            setMovieId(movieId.filter((id:any) => id !== parseInt(value)));
        }
    }

    const handlClosedModal = () => {
        handlerModalBox(false);
        setShow(false);
    }

    const getAllMovies = async () => {
        setActive(true);
        const data = await getAllProjects();
        setAllMovies(data);
        setActive(false);
    }


    useEffect(() => {
        getAllMovies();
    }, [])

    useEffect(() => {
        console.log(movieId);
    }, [movieId])

    return (
        <>
            <Modal
                show={show}
                onHide={handlClosedModal}
                backdrop="static"
                keyboard={false}
                centered={true}
                className='login_modal'
                // fullscreen={true}
                size='xl'
            >
                <div className='theme_color py-4'>
                    <button
                        className='ms-5 text-white'
                        onClick={handlClosedModal}
                        style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                        <ImCross />
                    </button>
                </div>

                <div className='bg-dark pt-4 px-lg-5 px-md-5 px-2'>
                    <LoadingOverlay
                        active={isActive}
                        spinner
                        text='Loading your content...'
                    >
                        <p
                            className='text-white fw-bold font18'
                        >
                            My User Profile listed Videos
                        </p>
                        <div className='row mt-lg-4 mt-md-4 mt-3 p-0'>
                            {Array.isArray(all_movies) && (
                                all_movies.map((val: any, idx: any) => {
                                    return (
                                        <>
                                            {val.status_of_project === "Released" || val.status_of_project === "Awards Season" ?
                                                <div
                                                    key={val.id}
                                                    className='col-lg-4 col-md-4 col-12 mb-lg-0 mb-md-0 mb-3 d-grid justify-content-center align-items-center'
                                                >
                                                    <div
                                                        className='d-grid justify-content-center align-items-center'
                                                    >
                                                        <div
                                                            style={{
                                                                height: '170px',
                                                                width: '300px'
                                                            }}
                                                        >
                                                            <img src={`${baseURL}/${val.movie_poster}`} alt={val.movie_title} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                        </div>
                                                        <p className='m-0 text-white mt-2 font16 text-center'>
                                                            {val.movie_title}
                                                        </p>
                                                    </div>
                                                    <div className="form-check mx-auto my-lg-5 my-md-5 my-2 text-white">
                                                        <input className="form-check-input"
                                                            name='addMovie'
                                                            style={{
                                                                width: '30px',
                                                                height: '30px',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            onChange={handleChangeVal}
                                                            value={val.id}
                                                            type="checkbox"
                                                            id="flexCheckDefault" />
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </>
                                    )
                                })
                            )}
                        </div>
                    </LoadingOverlay>

                    <div
                        className='d-flex justify-content-center mb-lg-5 mb-md-5 mb-4'
                    >
                        <button
                            className='py-1 fw-bold text-white py-3 mt-3'
                            style={{ width: '150px', backgroundColor: 'red', border: 'none' }}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SubmitMovieFilmfestival
