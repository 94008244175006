import React, { useEffect, useState } from 'react';
import { HiMenuAlt2 } from "react-icons/hi";
import VideoSlider from '../../components/videoslider/VideoSlider';
import { yutubeLink } from '../../utils/constaints';
import LoadingOverlay from 'react-loading-overlay-ts';
import { viewWatchListAPI } from '../../utils/utils';
import MovieCardSlider from '../../components/movieslider/MovieCardSlider';
import CommonSlider from '../../components/commonslider/CommonSlider';

const WatchList = () => {

    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false);
    const [watchListData, setWatchlistData] = useState([]);

    const getWatchListData = async () => {
        setActive(true);
        const data = await viewWatchListAPI();
        setWatchlistData(data);
        setActive(false);
    }

    useEffect(() => {
        getWatchListData();
    }, [])

    useEffect(()=> {
        console.log('watchList',watchListData);
    },[watchListData])

    return (
        <>
            <div className='row m-0 p-0'>
                <p className='m-0 text-warning font20 text-capitalize'>your Watchlist</p>
                <div className='mt-5 d-flex justify-content-between align-items-center'>
                    <div className='text-white text-capitalize'>
                        <b>watchlist</b> <span className='font14 fw-normal'>{watchListData?.length} movies</span>
                    </div>
                    <div className='text-white'>
                        <ul className='m-0 list-inline d-lg-flex d-md-block d-sm-block'>
                            <li
                                className='px-2 mx-2 text-capitalize font14 mb-lg-0 mb-md-2 mb-2 theme_color pointer'
                            >
                                last week release
                            </li>
                            <li
                                className='px-2 mx-2 text-capitalize font14 mb-lg-0 mb-md-2 mb-2 theme_color pointer'
                            >
                                this week release
                            </li>
                            <li
                                className='px-2 mx-2 text-capitalize font14 mb-lg-0 mb-md-2 mb-2 theme_color pointer'
                            >
                                this month release
                            </li>
                            <li
                                className='px-2 mx-2 text-capitalize font14 pointer'
                            >
                                <HiMenuAlt2 /> Sort
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <p className='text-white m-0 mt-4'>Now plan how you want to be entertained using this entertainment kitty box. There are freebies in the kitty. Create your Kitty entertainment for the weeks to come.</p>
                    <p className='text-white m-0 mt-2'>Buy tickets and be the part of solving social problems by contributing your part while buying your favourite movie tickets.</p>
                </div>
            </div>

            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >

                {/* <div className='row mt-5'>
                    <MovieCardSlider heading={'Newly Added Movies'} data={watchListData} />
                </div> */}
                <div className='row mt-5'>
                    <CommonSlider data={watchListData} /> 
                </div>
            </LoadingOverlay>
        </>
    )
}

export default WatchList
