import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BsPlayCircle, BsFillShareFill } from "react-icons/bs";
import { AiOutlineHeart, AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { LuMessageSquare } from "react-icons/lu";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getUiMasterClassDetailAPI, masterClassActionAPI, subscribeMasterClassAPI } from '../../utils/utils';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { baseURL } from '../../utils/api';
import VideoPlayerModal from '../../components/videoplayermodal/VideoPlayerModal';
import { ToastContainer, toast } from 'react-toastify';
import SignInModal from '../../components/signinmodal/SignInModal';

const MasterClassDetails = () => {

  LoadingOverlay.propTypes = undefined;
  const params = useParams();
  const auth_code = localStorage.getItem('auth_code');
  const messageTxt = (message: any) => toast(message);
  const [isActive, setActive] = useState(false);
  const { id } = params;
  const [classDetail, setClassDetail] = useState<any>([])
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isSubscribe, setIsSubscribe] = useState<any>(0);
  const [isLike, setIsLike] = useState<any>(0);
  const [isLogin, setIsLogin] = useState(false);

  const masterClassDetail = async () => {
    setActive(true);
    const urlId = extractIdFromUrl(id);
    const data = await getUiMasterClassDetailAPI(urlId);
    setClassDetail(data);
    setIsSubscribe(data.is_subscribed);
    setActive(false);
  }

  const handleSubscribes = async (e: any) => {
    e.preventDefault();
    setActive(true);
    const urlId = extractIdFromUrl(id);
    const data = await subscribeMasterClassAPI(urlId);
    setIsSubscribe(!isSubscribe);
    messageTxt(data);
    masterClassDetail();
    setActive(false);
  }

  const handleVideoModal = (val: any) => {
    if (!auth_code) {
      setIsLogin(true);
    } else {
      masterClassDetail();
      setVideoUrl(val);
      setOpenVideoModal(!openVideoModal);
    }
  }

  const giveVideoLike = async (val: any) => {
    setActive(true);
    const data = await masterClassActionAPI(val.id, 'like');
    masterClassDetail();
    messageTxt(data);
    setActive(false);
  }

  const handleLogin = () => {
    setIsLogin(false);
  }

  useEffect(() => {
    masterClassDetail();
  }, [])

  // useEffect(() => {
  //   // console.log(classDetail)
  // }, [classDetail])

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Loading your content...'
    >
      {openVideoModal &&
        <VideoPlayerModal handleModalBox={handleVideoModal} data={videoUrl} />
      }
      {isLogin &&
        <SignInModal handleModalBox={handleLogin} />
      }
      <div className='my-5'>
        <p className='m-0 text-warning fw-bold fs-5'>
          {classDetail?.master_class_name}
        </p>

        <div>
          <div>
            <img src={`${baseURL}/${classDetail.banner}`} alt='mclassdetail' width={'100%'} height={'100%'} />
          </div>
          <button
            className='py-2 px-3 rounded border-0 text-white w-100 mt-4 font_weight_600'
            style={{ backgroundColor: 'red' }}
            onClick={handleSubscribes}
          >
            {isSubscribe ? 'Unsubscribe' : 'Subscribe'}
          </button>
        </div>

        <div className='mt-5'>
          <p className='m-0 text-white ps-lg-4 ps-md-4 ps-0'>Description</p>
          <p className='m-0 text-white font14 ps-lg-5 ps-md-5 ps-0 mt-3'>
            {classDetail.description}
          </p>
        </div>

        <div className='mt-5'>
          <p className='m-0 text-white ps-lg-4 ps-md-4 ps-0'>Artist</p>
          <div className='ps-lg-5 ps-md-5 ps-0 mt-3' style={{ width: '140px', height: '100px' }}>
            <img src={`${baseURL}/${classDetail.creator?.avatar}`} alt='artist' className='rounded-circle' width={'100%'} height={'100%'} />
          </div>
        </div>

        <div className='mt-5'>
          <p className='m-0 text-white ps-lg-4 ps-md-4 ps-0'>Classes</p>
          {Array.isArray(classDetail.class_videos) && classDetail.class_videos.length > 0 ?
            classDetail?.class_videos.map((val: any, i: number) => {
              return (
                <div className='px-lg-5 px-md-5 px-0 py-2 mt-3 theme_color d-flex justify-content-between align-items-center mx-lg-5 mx-md-5 mx-2' key={val.id}>
                  <div className='left_acting_video d-flex align-items-center'>
                    {classDetail?.is_subscribed === 0 && i === 0 ?
                      <span className='text-white fs-1 me-3 pointer' onClick={(e) => handleVideoModal(val)}>
                        <BsPlayCircle />
                      </span>
                      :
                      null
                    }
                    {classDetail?.is_subscribed === 1 ?
                      <span className='text-white fs-1 me-3 pointer' onClick={(e) => handleVideoModal(val)}>
                        <BsPlayCircle />
                      </span>
                      : i !== 0 ?
                        <span className='text-white fs-1 me-3 pointer'>
                          <BsPlayCircle />
                        </span>
                        : null
                    }
                    <p className='text-white m-0 font14'>
                      {val.title}
                    </p>
                  </div>
                  <div className='right_acting_video d-flex justify-content-center align-items-center'>
                    {/* <span className='text-white fs-2 me-3'>
                      <AiOutlineHeart />
                    </span> */}
                    {/* <span className={`${val.like ? 'text-primary' : 'text-white'} fs-2 me-3 pointer`} onClick={() => giveVideoLike(val)}>
                      <AiOutlineLike />
                    </span> */}
                    {/* <span className='text-white fs-2 me-3'>
                      <AiOutlineDislike />
                    </span> */}
                    {/* <span className='text-white fs-2 me-3'>
                      <BsFillShareFill />
                    </span> */}
                    {/* <span className='text-white fs-2 me-3'>
                      <LuMessageSquare />
                    </span> */}
                    <div className='text-white m-0 font14'>
                      <p className='m-0 font_weight_600'>Views</p>
                      <p className='m-0 text-center font_weight_600'>{val.views}</p>
                    </div>
                  </div>
                </div>
              )
            })
            : null
          }
        </div>
      </div>
      <ToastContainer />
    </LoadingOverlay>
  )
}

export default MasterClassDetails
