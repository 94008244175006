import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const WhoIntroduceModal = (props: any) => {
    const { handleModalBox, data, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [whoIntro, setWhoIntro] = useState<any>({ name: '', relation: '' });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setWhoIntro({ ...whoIntro, [name]: value })
        // const updatedcastData = [...whoIntro];
        // if (typeof i === 'number' && i >= 0 && i < updatedcastData.length) {
        //     updatedcastData[i] = { ...updatedcastData[i], [name]: value };
        //     setWhoIntro(updatedcastData);
        // } else {
        //     console.error('Invalid index provided for array update.');
        // }
    };

    const handleWhoIntroUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    introduced_by_to_industry: JSON.stringify(whoIntro)
                }
                let result = await axios(`${baseURL}/api/subscriber/update-biography`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form">
                        <div className='col-lg-5 col-md-5 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                            <input type="text" name='name' value={whoIntro.name} onChange={handleChange} placeholder='Name' className="form-control mb-3" />
                        </div>
                        <div className='col-lg-5 col-md-5 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Relation</label>
                            <input type="text" name='relation' value={whoIntro.relation} onChange={handleChange} placeholder='Name' className="form-control mb-3" />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button
                            className='py-2 px-2 rounded bg-danger font_weight_600'
                            style={{
                                width: '150px'
                            }}
                            onClick={handleWhoIntroUpdate}
                        >
                            Save
                        </button>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default WhoIntroduceModal
