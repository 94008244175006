import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ProjectMovieRefModal = (props: any) => {
    const { handleModalBox, data, project_id, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [movieReferenceLink, setmovieReferenceLink] = useState<any>([]);

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChange = (e: any) => {
        const { value } = e.target;
        setmovieReferenceLink(value)
    };

    const handleAddMovieRefrenceUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    links: JSON.stringify([movieReferenceLink])
                }
                let result = await axios(`${baseURL}/api/project/add-movieReferenceLinks`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center cast_modal">
                        <div className="row p-0 d-flex justify-content-between align-items-center">
                            <div className='col-lg-12 col-md-12 col-12'>
                                <label htmlFor="movieTitle" className="form-label text-white m-0">Movie Reference links(Digital Newspaper/Social media/Youtube)</label>
                                <input type="text" value={movieReferenceLink} onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" placeholder='URL Link' />
                            </div>
                        </div>

                        <div className='d-flex justify-content-end'>
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleAddMovieRefrenceUpdate}
                            >
                                ADD
                            </button>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProjectMovieRefModal
