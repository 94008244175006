import React from 'react';
import './ottcss.css';
import { FaPlay } from "react-icons/fa";

const TrendingCard = (props: any) => {
    const { id, title, image } = props;
    return (
        <div
            className='hover_zoom position-relative pointer my-3'
            style={{
                height: '300px',
                width: '200px'
            }}
        >
            <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
            <div
                className='position-absolute d-flex justify-content-center align-items-center image_play_button'
                style={{
                    height: '50px',
                    width: '50px',
                    left: '40%',
                    top: '40%',
                }}
            >
                <FaPlay className='text-white font28' />
            </div>
        </div>
    )
}

export default TrendingCard
