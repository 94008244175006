import React, { useState, useEffect } from 'react';
import { PiCaretDownBold } from "react-icons/pi";
import { BsGrid } from "react-icons/bs";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { FaListUl } from "react-icons/fa6";
import { AiOutlineHeart } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { getFestivals } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import { months } from '../../utils/constaints';
import SubmitMovieFilmfestival from '../../components/submitmoviemodal/SubmitMovieFilmfestival';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const AddMovieToFilmFestival = () => {

  LoadingOverlay.propTypes = undefined;
  const auth_code = localStorage.getItem('auth_code');
  const [isActive, setActive] = useState(false);
  const [festivalData, setFestivalData] = useState<any>([]);
  const curYear = new Date().getFullYear();
  const [grid, setGrid] = useState(true);
  const [multiGrid, setmultiGrid] = useState(false);
  const [list, setList] = useState(false);
  const [openSubmitMovieModal, setOpenSubmitMovieModal] = useState(false);
  const [filterOpt, setFilterOpt] = useState({
    month: '',
    year: ''
  });

  const handleChangeFilter = (e:any) => {
    const {name, value} = e.target;
    setFilterOpt({...filterOpt, [name]: value});
  }

  const handleGrid = (val: any) => {
    if (val === 'boxgrid') {
      setGrid(true);
      setmultiGrid(false);
      setList(false);
    } else if (val === 'multigrid') {
      setmultiGrid(true);
      setGrid(false);
      setList(false);
    } else if (val === 'list') {
      setList(true);
      setmultiGrid(false);
      setGrid(false);
    }
  }

  const getFestivalData = async (filterOpt: any) => {
    setActive(true);
    const data = await getFestivals(filterOpt);
    setFestivalData(data);
    setActive(false);
  }

  useEffect(() => {
      getFestivalData(filterOpt);
  }, [filterOpt])

  const openMovieSubmitModal = (val: any) => {
    setOpenSubmitMovieModal(val);
  }

  // useEffect(() => {
  //   console.log('festival', filterData.year)
  // }, [filterData])

  return (
    <>
      <LoadingOverlay
        active={isActive}
        spinner
        text='Loading your content...'
      >

        {openSubmitMovieModal ?
          <SubmitMovieFilmfestival handlerModalBox={openMovieSubmitModal} />
          : null
        }
        <div className='row m-0 p-0'>
          <p className='m-0 text-warning fs-4 text-capitalize'>Film Festivals</p>
          <div className='mt-4 border-bottom pb-4 d-flex justify-content-between align-items-center'>

            <div className='text-white text-capitalize d-lg-flex d-md-block d-block align-items-center festival_filter'>
              <b className='text-warning'>Filters :</b>
              <div className='pointer mx-lg-3 mx-md-0 mx-0'>
              <select className="form-select" aria-label="Default select example" onChange={handleChangeFilter} value={filterOpt.year} name='year'>
                  <option className='theme_color' value={''} selected>Year</option>
                  <option className='theme_color' value={curYear}>{curYear}</option>
                  <option className='theme_color' value={curYear + 1}>{curYear + 1}</option>
                </select>
              </div>
              <div className='pointer'>
                <select className="form-select" aria-label="Default select example" onChange={handleChangeFilter} value={filterOpt.month} name='month'>
                  <option className='theme_color' value={''} selected>Month</option>
                  {
                    months.map((val) => {
                      return (
                        <option 
                          value={val.id}
                          className='theme_color'
                        >
                          {val.name}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div className='text-white'>
              <ul className='m-0 list-inline d-lg-flex d-md-block d-sm-block'>
                <li
                  className='px-2 mx-2 text-capitalize mb-lg-0 mb-md-2 mb-2 pointer'
                >
                  <span className='pe-2 font14 text-white'>Call For Entries</span>
                  <PiCaretDownBold />
                </li>
                <li
                  className='px-2 mx-2 text-capitalize mb-lg-0 mb-md-2 mb-2 pointer'
                >
                  <span className='pe-2 font14 text-white'>Event Type</span>
                  <PiCaretDownBold />
                </li>
                <li
                  className='px-2 mx-2 text-capitalize mb-lg-0 mb-md-2 mb-2 pointer'
                >
                  <span className='pe-2 font14 text-white'>Categories</span>
                  <PiCaretDownBold />
                </li>
              </ul>
            </div>
          </div>

          <div
            className='add_film_festival_section d-lg-flex d-md-block d-block justify-content-end align-items-center ps-lg-5 ps-md-0 ps-0 my-3'>
            <span
              className='pointer'
              onClick={() => handleGrid('boxgrid')}>
              <BsGrid className={`${grid ? 'active' : 'text-white'} fw-bold font26 mx-lg-3 mx-md-0 mx-0`} />
            </span>
            <span
              className='pointer'
              onClick={() => handleGrid('multigrid')}
            >
              <TfiLayoutGrid4Alt className={`${multiGrid ? 'active' : 'text-white'} fw-bold font26 mx-lg-3 mx-md-0 mx-0`} />
            </span>
            <span
              className='pointer'
              onClick={() => handleGrid('list')}
            >
              <FaListUl className={`${list ? 'active' : 'text-white'} fw-bold font26 mx-lg-3 mx-md-0 mx-0`} />
            </span>
            {auth_code ?
              <Link to="/menu/createfilmfestival">
                <button className='py-1 px-4 rounded-1 mt-lg-0 mt-md-2 mt-3 mx-lg-3 mx-md-0 mx-0'>
                  Add a Film Festival
                </button>
              </Link>
              : null
            }
          </div>

          {grid ?
            <div className='row m-0 p-0'>
              {Array.isArray(festivalData) &&
                festivalData.map((val: any, idx) => {
                  return (
                    <div className='col-lg-4 col-12 mb-4 pointer d-flex justify-content-center' key={idx}>
                      <Link to={`/menu/film-festival-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.id}`}>
                        <div
                          style={{
                            height: '236px',
                            width: '370px'
                          }}
                        >
                          <img src={`${baseURL}/${val.banner_image.path}`} alt={val.name} className='object-fit-contain' width={'100%'} height={'236'} />
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
            : null
          }

          {
            multiGrid ?
              <div className='row m-0 p-0'>
                {Array.isArray(festivalData) &&
                  festivalData.map((val: any, idx) => {
                    return (
                      <div className='col-lg-3 col-md-3 col-12 mb-5 d-flex justify-content-center align-items-center pointer' key={idx}>
                        <Link to={`/menu/film-festival-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.id}`}>
                          <div
                            style={{
                              height: '200px',
                              width: '300px'
                            }}
                          >
                            <img src={`${baseURL}/${val.banner_image.path}`} className='object-fit-contain' alt={val.name} width={'100%'} height={'236'} />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              : null
          }

          {list ?
            <div className='row m-0 p-0'>
              {Array.isArray(festivalData) &&
                festivalData.map((val: any, idx) => {
                  return (
                    <div
                      className='d-lg-flex d-md-flex d-block'
                      key={idx}
                    >
                      <div className='col-lg-4 col-12 d-flex justify-content-center align-items-center p-3 theme_color mb-4'>
                        <div
                          style={{
                            height: '200px',
                          }}
                        >
                          <img src={`${baseURL}/${val.banner_image.path}`} alt={val.name} className='object-fit-contain' width={'100%'} height={'100%'} />
                        </div>
                      </div>

                      <div className='col-lg-5 col-12 p-0 pt-3 theme_color mb-4'>
                        <p className='m-0 text-white font14 mt-2'>
                          {val.name}
                        </p>
                        <div className='text-white font14 my-3'>
                          <p className='font14'>
                            <b className='text-warning'>Venue :</b> {val.address}
                          </p>
                          <p className='font14'>
                            <b className='text-warning'>Last date to apply :</b> {val.end_date}
                          </p>
                        </div>
                      </div>

                      <div className='col-lg-3 col-12 d-flex justify-content-center align-items-center theme_color mb-4 pe-lg-3 p-0'>
                        <div className='w-100'>
                          <div className='text-white font26 text-end'>
                            <AiOutlineHeart />
                          </div>
                          <div>
                            <Link to={`/menu/film-festival-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.id}`}>
                              <button
                                className='py-1 fw-bold text-white py-3 mt-1 bg-dark'
                                style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                              >
                                View Details
                              </button>
                            </Link>
                            <button
                              className='py-1 fw-bold text-white py-3 mt-3'
                              style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                              onClick={() => setOpenSubmitMovieModal(!openSubmitMovieModal)}
                            >
                              Submit Movie
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            : null
          }
        </div >
      </LoadingOverlay>
    </>
  )
}

export default AddMovieToFilmFestival
