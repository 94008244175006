import React from 'react'
import { baseURL } from '../../utils/api'

const ShowReelsCard = (props: any) => {

    const { image } = props;

    return (
        <>
            <div
                style={{
                    height: '100px',
                    width: '150px'
                }}
            >
                <img src={`${baseURL}/${image}`} className="object-fit-contain" alt={image} width={'100%'} height={'100%'} />
            </div>
        </>
    )
}

export default ShowReelsCard
