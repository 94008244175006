import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { ImCross } from 'react-icons/im';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getTTLinkListAPI } from '../../utils/utils';
import { Table } from 'react-bootstrap';
import ProjectTeaserTrailerModal from '../myprofilemodals/ProjectTeaserTrailerModal';
import { IoTrash } from 'react-icons/io5';

const TeaserTrailerLinks = (props: any) => {

    const { project_data, project_id } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false)
    const ttRef = useRef<HTMLDivElement | null>(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [ttList, setTtList] = useState<any>([]);
    const [teaserLinks, setTeaserLinks] = useState<any>(['']);
    const [trailerLinks, settrailerLinks] = useState<any>(['']);

    const getList = async () => {
        setActive(true);
        const data = await getTTLinkListAPI(project_id);
        setTtList(data);
        setActive(false);
    }

    // Handle All Teaser Field
    // const handleAddTeaserField = (e: any) => {
    //     e.preventDefault();
    //     const addMore = [...teaserLinks, '']
    //     setTeaserLinks(addMore);
    // }

    const handleChangeTeaser = (e: any, i: any) => {
        const { value } = e.target;
        const inputData = [...teaserLinks];
        inputData[i] = value;
        setTeaserLinks(inputData);
    };

    const handleAddTeaserUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    teaser: JSON.stringify(teaserLinks)
                }
                let result = await axios(`${baseURL}/api/project/add-movie_links`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleDeleteTeaser = async (e: any, id: any) => {
        e.preventDefault()
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id
                }
                let result = await axios(`${baseURL}/api/project/destroy-links`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getList();
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    // Handle All Trailer Field
    // const handleAddTrailerField = (e: any) => {
    //     e.preventDefault();
    //     const addMore = [...trailerLinks, '']
    //     settrailerLinks(addMore);
    // }

    const handleChangeTrailer = (e: any, i: any) => {
        const { value } = e.target;
        const inputData = [...trailerLinks];
        inputData[i] = value;
        settrailerLinks(inputData);
    };

    const handleAddTrailerUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    trailer: JSON.stringify(trailerLinks)
                }
                let result = await axios(`${baseURL}/api/project/add-movie_links`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleDeleteTrailer = async (e: any, id: any) => {
        e.preventDefault()
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id
                }
                let result = await axios(`${baseURL}/api/project/destroy-links`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getList();
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleAddTeaserTrailer = () => {
        setOpenAdd(!openAdd);
        getList();
    }

    useEffect(() => {
        const element = ttRef.current;

        const handleShow = () => {
            getList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('effect', ttList);
    // }, [ttList])

    return (
        <>
            <div className="accordion accordion-flush mt-4" id="teasertrailer">
                <div className="accordion-item rounded">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseteasertrailer" aria-expanded="false" aria-controls="flush-collapseteasertrailer">
                            Teaser And Trailer Links
                        </button>
                    </h2>
                    <div id="flush-collapseteasertrailer" ref={ttRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#teasertrailer">
                        <div className="accordion-body accordian_border_with_color border-top-0 movie_link_form theme_color">

                            <LoadingOverlay
                                active={isActive}
                                spinner
                                text='Loading your content...'
                            >

                                {openAdd &&
                                    <ProjectTeaserTrailerModal handleModalBox={handleAddTeaserTrailer} project_id={project_id} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddTeaserTrailer}
                                    >
                                        + Add
                                    </button>
                                </div>

                                {Array.isArray(ttList) && ttList.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Type</th>
                                                <th>Link</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(ttList) && ttList.length > 0 &&
                                                ttList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td className='text-capitalize'>{val.type}</td>
                                                            <td>{val.link}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    {/* <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} /> */}
                                                                    <IoTrash className='pointer' onClick={(e) => handleDeleteTeaser(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white text-center font_weight_600"
                                    >
                                        No data found.
                                    </p>
                                }
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default TeaserTrailerLinks
