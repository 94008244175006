import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PiCaretRightBold } from 'react-icons/pi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllMasterClassesAPI } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import MasterClassFormModal from '../myprofilemodals/MasterClassFormModal';
import { Table } from 'react-bootstrap';

const MasterClassList = () => {

    const navigate = useNavigate();
    LoadingOverlay.propTypes = undefined;
    const myMasterClassListRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [allClass, setAllClass] = useState<any>([]);

    const fetchAllClasses = async () => {
        setActive(true);
        const data = await getAllMasterClassesAPI();
        setAllClass(data);
        setActive(false);
    };

    const handleAddmoreAwardModal = () => {
        fetchAllClasses();
        setOpenAdd(!openAdd);
    }

    useEffect(() => {
        const element = myMasterClassListRef.current;

        const handleShow = () => {
            fetchAllClasses();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='personal_project_form_accordian_section theme_color mt-3'>

                <div className="accordion accordion-flush" id={`masterClassList`}>
                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id={`flush-headingmasterClassList`}>
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapsemasterClassList`} aria-expanded="false" aria-controls={`flush-collapsemasterClassList`}>
                                Master class
                            </button>
                        </h2>
                        <div id={`flush-collapsemasterClassList`} ref={myMasterClassListRef} className="accordion-collapse collapse" aria-labelledby={`flush-headingmasterClassList`} data-bs-parent={`#masterClassList`}>
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openAdd &&
                                    <MasterClassFormModal handleModalBox={handleAddmoreAwardModal} headingText={'Add New Master Class'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(allClass) && allClass.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>
                                                    SL
                                                </th>
                                                <th>
                                                    ID
                                                </th>
                                                <th>
                                                    Class name
                                                </th>
                                                <th>
                                                    Cover image
                                                </th>
                                                <th>
                                                    Banner image
                                                </th>
                                                <th>
                                                    Description
                                                </th>
                                                <th>
                                                    Total videos
                                                </th>
                                                <th className='text-center'>
                                                    View
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(allClass) && allClass.length > 0 &&
                                                allClass.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.id}</td>
                                                            <td>{val.master_class_name}</td>
                                                            <td>
                                                                <div
                                                                    style={{
                                                                        height: '100px',
                                                                        width: '200px'
                                                                    }}
                                                                >
                                                                    <img src={`${baseURL}/${val.cover}`} alt={val.master_class_name} height={'100%'} width={'100%'} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div
                                                                    style={{
                                                                        height: '100px',
                                                                        width: '200px'
                                                                    }}
                                                                >
                                                                    <img src={`${baseURL}/${val.banner}`} alt={val.master_class_name} height={'100%'} width={'100%'} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {val.description}
                                                            </td>
                                                            <td>
                                                                {val.class_videos?.length}
                                                            </td>
                                                            <td className='text-center'>
                                                                <Link to={`/profile/master-class-detail/${removeDotReplaceSpaceToHyphen(val.master_class_name)}-${val.id}`} className='text-decoration-none'>
                                                                    <PiCaretRightBold className='text-white font22' />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white text-center font_weight_600"
                                    >
                                        No data found.
                                    </p>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </LoadingOverlay>
    )
}

export default MasterClassList
