import React, { useState } from 'react';
import './newscss.css';
import { IoMdStar } from 'react-icons/io';
import MovieReviewModal from './MovieReviewModal';

const MovieReviewCard = (props: any) => {

    const { id, title, image, genre, reviewCount, text } = props;

    const [openReviewModal, setOpenReviewModal] = useState(false);

    const openReviewModalBox = () => {
        setOpenReviewModal(!openReviewModal);
    }

    return (
        <div
            className='movie_review_card theme_color'
        >
            {openReviewModal &&
                <MovieReviewModal id={id} handleModalBox={openReviewModalBox} />
            }
            <div
                className='row pe-2'
            >
                <div
                    className='col-lg-5 col-md-5 col-12'
                >
                    <div
                        style={{
                            height: 'auto',
                            width: '130px'
                        }}
                    >
                        <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
                    </div>
                </div>
                <div
                    className='col-lg-7 col-md-7 col-12 p-0'
                >
                    <p
                        className='m-0 text-white text-decoration-underline font18 font_weight_600'
                    >
                        {title}
                    </p>
                    <p
                        className='m-0 text-white font14'
                    >
                        {genre}
                    </p>
                    <div
                        className='mt-lg-2 mt-md-2 mt-2'
                    >
                        <span
                            className='font20 text-warning'
                        >
                            {
                                [...Array(5)].map((star, index) => {
                                    const givenRating = index + 1;
                                    return (
                                        <IoMdStar
                                            className='pointer'
                                            key={index}
                                            style={{
                                                color: (givenRating <= reviewCount || givenRating === reviewCount) ? 'yellow' : '#D9D9D9'
                                            }}
                                        />
                                    )
                                })
                            }
                        </span>
                        <span className='text-white font14 ms-2'>{reviewCount}</span>
                        <p
                            className='m-0 text-white font14'
                        >
                            {text}
                        </p>
                    </div>
                    <button
                        className='btn btn-outline-warning mt-3'
                        onClick={openReviewModalBox}
                    >
                        Write review
                    </button>
                </div>
            </div>

        </div>
    )
}

export default MovieReviewCard
