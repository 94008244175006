import React, { useEffect, useState } from 'react';
import CategoryCard from '../categoryslider/CategoryCard';
import { ImCross } from 'react-icons/im';
import { Modal } from 'react-bootstrap';
import ComingSoonModal from '../comingsoonmodal/ComingSoonModal';
import { getAllIndustryTypeAPI, getConnectionId } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';

const MultiIndustryModal = (props: any) => {

    const { handleModalBox, crossButton } = props;
    const connection_id = localStorage.getItem('connection_id');
    LoadingOverlay.propTypes = undefined;
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(true)
    const [openComingSoonModal, setOpenComingSoonModal] = useState(false);
    const [all_industry, setAll_industry] = useState<any>();

    const getIndustryData = async () => {
        setActive(true);
        const data = await getAllIndustryTypeAPI();
        setAll_industry(data);
        setActive(false);
    }

    const handlClosedModal = (id: any) => {
        handleModalBox(false);
        setShow(false);
    }

    const handleComingSoon = (val: any) => {
        val ? setOpenComingSoonModal(false) : setOpenComingSoonModal(false);
    }

    useEffect(() => {
        setTimeout(() => {
            getIndustryData();
        }, 3000);
    }, [])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setOpenComingSoonModal(false)
    //     }, 3000);
    // })

    // useEffect(() => {
    //     console.log('industry type',industry_id);
    // },[industry_id])

    return (
        <Modal
            show={show}
            onHide={() => handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`lg`}
        >
            <div
                className='text-end pe-2'
            >
                {crossButton ?
                    <button
                        className=''
                        onClick={handlClosedModal}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <ImCross />
                    </button>
                    :
                    null
                }
            </div>
            <Modal.Title className='text-white px-3 pt-3'>Select your favorite film Industry...</Modal.Title>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <div className={`py-3 mt-3`}>
                    {openComingSoonModal ?
                        <p
                            className='m-0 text-white font20'
                        >
                            <ComingSoonModal handleModalBox={handleComingSoon} />
                        </p>
                        : null
                    }
                    <div className='row'>
                        {(Array.isArray(all_industry) && all_industry.length > 0) &&
                            all_industry?.map((e: any, i: any) => {
                                return (
                                    <div key={e.id} className='col-lg-2 col-md-3 col-6 mb-3'>
                                        <CategoryCard id={e.id} image={e.cover} title={e.title} bgColor={i % 2 === 0 ? 'bg-warning' : 'bg-white'} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </LoadingOverlay>
        </Modal>
    )
}

export default MultiIndustryModal