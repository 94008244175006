import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import Form from 'react-bootstrap/Form';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';

const SubscriberIndustryModal = (props: any) => {

    const { handleModalBox, industryList, dataId, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const profile_data = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    // const [industryList, setIndustryList] = useState<any>();
    const [selectIndustry, setSelectIndustry] = useState<any>(profile_data.industries);

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChangeValue = (e: any) => {
        const { value, checked } = e.target;
        const parsedValue = parseInt(value);

        if (checked) {
            setSelectIndustry([...selectIndustry, parsedValue]);
        } else {
            setSelectIndustry(selectIndustry.filter((prof: any) => prof !== parsedValue));
        }
    }

    const handleSubmitIndustry = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem("connection_id");
        const auth_code = localStorage.getItem('auth_code');
        const body = {
            connection_id,
            auth_code,
            industries: JSON.stringify(selectIndustry)
        };
        if (!selectIndustry || selectIndustry.length === 0) {
            messageTxt('Please select your Industry !')
            setActive(false);
        } else {
            try {
                const res = await axios.post(`${baseURL}/api/subscriber/industry/add`, body);
                messageTxt(res.data.message);
                setActive(false);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div
                        className='row'
                    >
                        {Array.isArray(industryList) && industryList.length > 0 && industryList.map((val, idx) => (
                            <div key={val.id} className="col-lg-2 col-md-6 col-12 mb-3 text-white font16">
                                <Form.Check
                                    type='checkbox'
                                    id={String(val.id)}
                                    label={String(val.title)}
                                    value={val.id}
                                    onChange={handleChangeValue}
                                    checked={selectIndustry.includes(val.id)}
                                />
                            </div>
                        ))}
                    </div>

                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                        <button
                            className='py-2 px-2 border-0 common_button rounded font_weight_600'
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                width: '150px'
                            }}
                            onClick={handleSubmitIndustry}
                        >
                            Save
                        </button>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default SubscriberIndustryModal
