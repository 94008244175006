import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { extractIdFromUrl, removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import { shop_product_detail } from '../../utils/constaints';
import { PiCaretLeftBold } from "react-icons/pi";
import { LuStar } from "react-icons/lu";
import UserAddressModal from '../../components/shopcomponents/UserAddressModal';
import { deleteEcomItemAPI, getCartListAPI, updateEcomQuantityAPI } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import { RxCross2 } from "react-icons/rx";
import ShopPaymentModalBox from '../../components/shopcomponents/ShopPaymentModalBox';

const ShoppingCart = () => {

    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [productData, setProductData] = useState<any>();

    const getCartList = async () => {
        setActive(true);
        const data = await getCartListAPI();
        setProductData(data);
        setActive(false);
    }

    const incrementQty = async (inventory_id: any, productId: any, qty: any) => {
        await updateEcomQuantityAPI(inventory_id, productId, qty + 1);
        getCartList();
    }

    const decrementQty = async (inventory_id: any, productId: any, qty: any) => {
        if (qty <= 1) {
            await updateEcomQuantityAPI(inventory_id, productId, 1);
            getCartList();
        } else if (qty !== 1) {
            await updateEcomQuantityAPI(inventory_id, productId, qty - 1);
            getCartList();
        }
    }

    const handleDeleteItem = async (inventory_id: any, productId: any) => {
        setActive(true);
        const data = await deleteEcomItemAPI(inventory_id, productId);
        messageTxt(data);
        getCartList();
        setActive(false);
    }

    const handlepaymentModal = () => {
        setPaymentModal(!paymentModal)
    }

    useEffect(() => {
        getCartList();
    }, [])

    // useEffect(() => {
    //     console.log('aaa', productData);
    // }, [productData])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            {paymentModal &&
                <ShopPaymentModalBox handleModalBox={handlepaymentModal} cartId={productData?.id} items={productData?.total_items} total={productData?.grand_total} />
            }
            <div
                className='shop_header_section d-flex justify-content-between align-items-center theme_color py-2 px-lg-4 px-md-4 px-2 mt-lg-5 mt-md-5 mt-2'
            >
                <div
                    className='text-warning'
                >
                    <Link to={'/menu/shop'} className='text-decoration-none text-warning d-flex align-items-center'>
                        <PiCaretLeftBold className='font20 me-2' />
                        <p
                            className='m-0 font_weight_600 font16'
                        >
                            Continue Shopping
                        </p>
                    </Link>
                </div>
                <div
                    className='py-2 d-flex align-items-center'
                >
                    <Link to={`/menu/my-order`} className='me-3 text-decoration-none text-white'
                    >
                        My Order
                    </Link>
                    <p
                        className='m-0 text-white font_weight_600 font16'
                    >
                        {productData?.total_items ? productData?.total_items : 0} items
                    </p>
                </div>
            </div>

            {Array.isArray(productData?.inventories) && productData.inventories.length > 0 ?

                <div
                    className='row mt-5'
                >
                    <div
                        className='col-lg-8 col-md-8 col-12 overflow-y-auto mb-lg-0 mb-md-0 mb-4 scroll_bar border_with_color'
                        style={{
                            maxHeight: '700px'
                        }}
                    >
                        {Array.isArray(productData?.inventories) && productData.inventories.length > 0 &&
                            productData?.inventories.map((val: any, i: any) => {
                                return (
                                    <div
                                        key={val.id}
                                        className='row border_bottom_with_color_shop pb-3'
                                    >
                                        <div
                                            className='col-lg-3 col-md-3 col-12'
                                        >
                                            <div>
                                                <img src={`${baseURL}/${val?.images[0]?.path}`} alt={val?.name} width={'100%'} height={'100%'} style={{ aspectRatio: 1 / 1 }} />
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-9 col-md-9 col-12'
                                        >
                                            <div
                                                className='d-flex justify-content-between align-items-center'
                                            >
                                                <p
                                                    className='m-0 text-warning font20 font_weight_600'
                                                >
                                                    {val?.name}
                                                </p>
                                                <RxCross2 onClick={() => handleDeleteItem(val?.id, val?.pivot?.cart_id)}
                                                    className='pointer text-white'
                                                    style={{
                                                        backgroundColor: '#3a3a3c',
                                                        width: '20px',
                                                        height: '20px',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            </div>
                                            <p
                                                className='m-0 text-white font14 mt-3'
                                            >
                                                Sold by: <span className='text-warning'>CDBS</span>
                                            </p>

                                            <div>
                                                <span
                                                    className='text-white font14'
                                                >
                                                    Color:
                                                </span>&nbsp;
                                                <span
                                                    className='font14 text-warning my-2'
                                                >
                                                    {val?.product?.color}
                                                </span>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    Size: <span
                                                        className='font14 text-warning'
                                                    >
                                                        {val?.attribute_value?.name}
                                                    </span>
                                                </p>
                                            </div>

                                            <div
                                                className='d-flex justify-content-between align-items-center my-3'
                                            >
                                                <div
                                                    className='d-flex justify-content-between align-items-center'
                                                >
                                                    <p
                                                        className='m-0 text-white text-uppercase me-3 font16'
                                                    >
                                                        &#x20B9; {val?.sale_price} X
                                                    </p>
                                                    <div
                                                        className='theme_color px-2'
                                                    >
                                                        <span
                                                            className='qty_button font24 pointer'
                                                            onClick={() => decrementQty(val?.id, val?.pivot?.cart_id, val?.pivot?.quantity)}
                                                        >
                                                            -
                                                        </span>
                                                        <span className='text-white mx-4'>{val?.pivot?.quantity}</span>
                                                        <span
                                                            className='qty_button font24 pointer'
                                                            onClick={() => incrementQty(val?.id, val?.pivot?.cart_id, val?.pivot?.quantity)}
                                                        >
                                                            +
                                                        </span>
                                                    </div>
                                                </div>
                                                <p
                                                    className='m-0 text-warning font16 mx-3'
                                                >
                                                    &#x20B9; {val?.total}
                                                </p>
                                                {/* <span
                                                className='text-warning font20 pointer'
                                            >
                                                <IoTrash onClick={() => handleDeleteItem(val?.id, val?.pivot?.cart_id)} />
                                            </span> */}
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div
                        className='col-lg-4 col-md-4 col-12'
                    >
                        <div
                            className='border_with_color p-lg-3 p-md-1 p-1'
                        >
                            <div
                                className='d-flex'
                            >
                                <div
                                    className='me-3'
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                    }}
                                >
                                    <img src='../../images/shipbus.png' alt='ship' width={'100%'} height={'100%'} />
                                </div>
                                <p
                                    className='m-0 text-white font16'
                                >
                                    Your order qualifies for Free Domestic Shipping!
                                </p>
                            </div>

                            <div
                                className='d-flex justify-content-between align-items-center mt-4 border_bottom_with_color_shop pb-lg-3 pb-md-3 pb-2'
                            >
                                <p
                                    className='m-0 text-white font_weight_600 font16'
                                >
                                    SUBTOTAL ({productData?.total_items ? productData?.total_items : 0} ITEMS)
                                </p>
                                <p
                                    className='m-0 text-white font_weight_600 font16'
                                >
                                    &#x20B9; {productData?.grand_total ? productData?.grand_total : 0}
                                </p>
                            </div>

                            <div
                                className='d-flex justify-content-between align-items-center mt-2 mb-3'
                            >
                                <p
                                    className='m-0 text-warning font_weight_600 font22'
                                >
                                    TOTAL
                                </p>
                                <p
                                    className='m-0 text-warning font_weight_600 font22'
                                >
                                    &#x20B9; {productData?.grand_total ? productData?.grand_total : 0}
                                </p>
                            </div>
                            <p
                                className='text-white m-0 font16'
                            >
                                <i>Tax included. Shipping calculated at checkout.</i>
                            </p>

                            <button
                                className='cart_buy_now font_weight_600 rounded font_weight_600 py-3 mt-3'
                                onClick={handlepaymentModal}
                            >
                                Buy Now
                            </button>

                            {/* <div
                            className='mt-lg-5 mt-md-5 mt-3'
                        >
                            <p
                                className='text-white m-0 font16'
                            >
                                -Add a Note
                            </p>
                            <textarea className="form-control cart_text_area"></textarea>
                        </div>

                        <div
                            className='mt-4 cart_inputs'
                        >
                            <p
                                className='text-white m-0 font16'
                            >
                                -Estimate Shipping
                            </p>
                            <div
                                className='mx-lg-3 mx-md-0 mx-0 mt-3'
                            >
                                <label htmlFor="Province" className="form-label text-white m-0">Province</label>
                                <input type="text" className="form-control mb-3" id="title" placeholder='Andaman and Nicobar' />
                                <label htmlFor="country" className="form-label text-white m-0">Country</label>
                                <input type="text" className="form-control mb-3" id="title" placeholder='eg: India' />
                                <label htmlFor="zip code" className="form-label text-white m-0">Zip/Postal Code</label>
                                <input type="number" className="form-control mb-3" id="title" placeholder='Zip/Postal Code' />
                            </div>

                            <div
                                className='text-center'
                            >
                                <button
                                    className='mt-5 calculate_shipping_button px-lg-4 px-md-3 px-3'
                                >
                                    Calculate Shipping
                                </button>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                :
                <div
                    className='position-relative'
                >
                    <img src='../images/menushopbanner.png' alt='shop' width={'100%'} height={'100%'} />
                    <div
                        className='position-absolute text-white font_weight_600 font18'
                        style={{
                            top: '40%',
                            left: '10%'
                        }}
                    >
                        <p
                            className='text-uppercase'
                        >
                            Items not found in your cart.
                        </p>
                        <Link
                            to={'/menu/shop'}
                            className='text-danger'
                        >
                            Continue Shopping
                        </Link>
                    </div>
                </div>
            }
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default ShoppingCart
