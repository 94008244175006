import React, { useEffect, useState } from 'react';
import './hometoplabel.css';
import { homeHighLightsAPI } from '../utils/utils';

const HomeTopLable = () => {

    const [texts, setText] = useState<any>([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const getText = async () => {
        const res = await homeHighLightsAPI();
        const data = res?.map((val: any) => val.title);
        setText(data);
    };

    useEffect(() => {
        setTimeout(() => {
            getText();
        }, 3000)
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => {
                return texts?.length > 0 ? (prevIndex + 1) % texts?.length : 0;
            });
        }, 5000);
        return () => clearInterval(intervalId);
    }, [texts]);

    // useEffect(() => {
    //     console.log(currentIndex)
    // }, [currentIndex]);

    return (
        <div
            className='row m-0 text-white my-3 rounded px-3'
            style={{ backgroundColor: '#3a3a3c', paddingTop: '12px', paddingBottom: '12px' }}
        >
            <div className='col-lg-2 col-md-2 col-12 w-auto d-flex align-items-center'>
                <span
                    className='text-warning font18 fw-bold'
                >
                    CinemaDBS Updates:
                </span>
            </div>
            <div className='col-lg-10 col-md-10 col-12'>
                <div className="text-slider py-1">
                    {(Array.isArray(texts) && texts.length > 0) &&
                        <div className="text-container" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                            {Object.values(texts).map((val: any, index: any) => (
                                <div key={index} className="text">{val}</div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default HomeTopLable
