import React, { useEffect, useState } from 'react';
import MovieVideo from './MovieVideo';
import Synopsis from './Synopsis';
import Adds from './Adds';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getMovieDetails } from '../../utils/utils';
import { baseURL } from '../../utils/api';

const MovieDetails = (props: any) => {
    const { id } = props;
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(true);
    const [data, setData] = useState<any>();
    const getData = async () => {
        setActive(true);
        const result = await getMovieDetails(id);
        setData(result);
        setActive(false);
    }

    useEffect(() => {
        setTimeout(() => {
            getData();
        }, 1000);
    }, [id])

    useEffect(() => {
        // console.log('home movie detail',id)
    }, [data]);

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='row p-0'>
                <div className='accordian_border_with_color col-lg-4 col-md-12 col-12 d-flex justify-content-center p-0'>
                    {/* <MovieVideo id={data?.id} cover={data?.movie_banner} title={data?.movie_title} /> */}
                    {(Array.isArray(data?.trailer_links) && data?.trailer_links[0]?.link) ?
                        <MovieVideo id={data?.id} cover={data?.movie_banner} title={data?.movie_title} trailer_links={data?.trailer_links[0]?.link} />
                        :
                        <div>
                            <img src={`${baseURL}/${data?.movie_banner}`} alt={data?.movie_title} width={'100%'} height={'100%'} />
                        </div>
                    }
                </div>
                <div className='col-lg-6 col-md-12 col-12 my-lg-0 my-md-4 my-5'>
                    <Synopsis data={data} />
                </div>
                <div className='col-lg-2 col-md-12 col-12'>
                    <Adds />
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default MovieDetails
