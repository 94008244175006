import React, { useEffect, useState } from 'react';
import { BsGrid } from "react-icons/bs";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { FaListUl } from "react-icons/fa6";
import { getLegends, totalPagesCalc } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { pageNumber } from '../../states/reducers';
import { baseURL } from '../../utils/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Link } from 'react-router-dom';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const LivingLegends = () => {

    const { pNumber } = useSelector((state: any) => state.pNumber);
    const {industryType} = useSelector((state: any) => state.industryType);
    LoadingOverlay.propTypes = undefined;
    const dispatch = useDispatch();
    const [limit, setLimit] = useState<any>([]);
    const [isActive, setActive] = useState(false);
    const [legend, setLegend] = useState<any>([]);
    const [activePage, setActivePage] = useState(pNumber);
    const [totalLegend, setTotallegend] = useState(0);
    const [grid, setGrid] = useState(true);
    const [multiGrid, setmultiGrid] = useState(false);

    const getLivingLegends = async () => {
        setActive(true);
        const type = 'living';
        const result = await getLegends(type, activePage);
        setLegend(result.legendList);
        setTotallegend(result.total)
        setLimit(result.limit)
        setActive(false);
    }

    const handleGrid = (val: any) => {
        if (val === 'boxgrid') {
            setGrid(true);
            setmultiGrid(false);
        } else if (val === 'multigrid') {
            setmultiGrid(true);
            setGrid(false);
        }
    }

    useEffect(() => {
        getLivingLegends();
        window.scroll(0, 0);
        dispatch(pageNumber(activePage))
    }, [activePage])

    // useEffect(() => {
    //     console.log('as', legend);
    // }, [legend])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='mt-5'>
                <div className='row'>
                    <div
                        className='col-lg-10 col-md-10 col-12'
                    >
                        <h5 className='m-0 text-warning font26 fw-bold'>Living Legends Of The {industryType[0]?.title} Industry</h5>
                    </div>
                    <div
                        className='col-lg-2 col-md-2 col-12 d-flex justify-content-center align-items-center'
                    >
                        <span
                            className='pointer'
                            onClick={() => handleGrid('boxgrid')}>
                            <BsGrid className={`${grid ? 'active' : 'text-white'} fw-bold font26 mx-lg-3 mx-md-0 mx-0`} />
                        </span>
                        <span
                            className='pointer'
                            onClick={() => handleGrid('multigrid')}
                        >
                            <TfiLayoutGrid4Alt className={`${multiGrid ? 'active' : 'text-white'} fw-bold font26 mx-lg-3 mx-md-3 mx-3`} />
                        </span>
                    </div>
                </div>

                {grid ?
                    <div className='row g-lg-3 g-md-4 m-0 p-0 mt-4'>
                        {Array.isArray(legend) &&
                            legend?.map((val: any, id: any) => {
                                return (
                                    <div className='col-lg-2 col-md-4 col-12 mb-3 pointer d-flex justify-content-center' key={val?.id}>
                                        <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.subscriber_id}`} >
                                            <div>
                                                <img src={`${baseURL}/${val?.profile_image}`} className='legend_images_home' alt={val.name} width={'100%'} height={'100%'} />
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null
                }

                {multiGrid ?
                    <div className='row g-lg-3 g-md-4 m-0 p-0 mt-4'>
                        {Array.isArray(legend) &&
                            legend?.map((val: any, id: any) => {
                                return (
                                    <div className='col-lg-1 col-md-2 col-12 mb-3 pointer d-flex justify-content-center' key={val?.id}>
                                        <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.subscriber_id}`} >
                                            <div>
                                                <img src={`${baseURL}/${val?.profile_image}`} alt={val.name} className='legend_images_home_multi' width={'100%'} height={'100%'} />
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null
                }

                <div className='d-flex justify-content-center align-items-center py-5 my-lg-5 my-md-5'>
                    <ul className='text-white d-flex justify-content-center align-items-center gap-lg-3 gap-md-3 gap-2 p-0 px-2 paginationUl'>
                        {
                            activePage !== 1 && <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage - 1)}>Previous</li>
                        }
                        {
                            totalPagesCalc(totalLegend, limit).slice(Math.max(0, activePage - 3), Math.min(totalLegend, activePage + 4)).map((val) => {
                                return <li className={`pageList py-1 px-2 ${val === activePage ? 'activePageList' : ''}`} onClick={() => setActivePage(val)} key={val}>{val}</li>
                            })
                        }
                        {activePage !== Math.ceil(totalLegend / limit) ? <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage + 1)}>Next</li> : ''}
                    </ul>
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default LivingLegends
