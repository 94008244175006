import React, { useState, useEffect } from 'react';
import './menu.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BsPlayCircle, BsFillShareFill } from "react-icons/bs";
import { AiOutlineHeart, AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { LuMessageSquare } from "react-icons/lu";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getInstituteDetailAPI } from '../../utils/utils';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { baseURL } from '../../utils/api';
import VideoPlayerModal from '../../components/videoplayermodal/VideoPlayerModal';
import { ToastContainer, toast } from 'react-toastify';
import SignInModal from '../../components/signinmodal/SignInModal';

const FilmInstituteDetail = () => {

    LoadingOverlay.propTypes = undefined;
    const params = useParams();
    const auth_code = localStorage.getItem('auth_code');
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const { id } = params;
    const [InstituteDetail, setInstituteDetail] = useState<any>([])
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [isSubscribe, setIsSubscribe] = useState<any>(0);
    const [isLike, setIsLike] = useState<any>(0);
    const [isLogin, setIsLogin] = useState(false);

    const filmInstituteDetail = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const data = await getInstituteDetailAPI(urlId);
        setInstituteDetail(data);
        setIsSubscribe(data.is_subscribed);
        setActive(false);
    }

    const rowData = [
        {
            course_name: 'XXXXion 1',
            course_duration: '6 months',
            venue: 'Bangalore',
            fees: '10,000',
            about_course: 'Lorem ipsum dolor sit amet consectetur. Sit nulla gravida vitae rutrum. Enim libero arcu pretium pellentesque arcu. Viverra ac nunc ipsum nisi amet metus odio magna. Dignissim facilisi ultrices at proin ullamcorper enim mauris. Interdum est id in turpis montes a ut. Sit urna donec hendrerit donec praesent et purus sed mauris. Fusce amet enim scelerisque massa tellus sollicitudin viverra accumsan. Convallis sit velit senectus morbi augue feugiat volutpat lectus scelerisque.Lorem ipsum dolor sit amet consectetur. Sit nulla gravida vitae rutrum. Enim libero arcu pretium pellentesque arcu. Viverra ac nunc ipsum nisi amet metus odio magna. Dignissim facilisi ultrices at proin ullamcorper enim mauris. Interdum est id in turpis montes a ut. Sit urna donec hendrerit donec praesent et purus sed mauris. Fusce amet enim scelerisque massa tellus sollicitudin viverra accumsan. Convallis sit velit senectus morbi augue feugiat volutpat lectus scelerisque.'
        },
        {
            course_name: 'XXXXion 1',
            course_duration: '6 months',
            venue: 'Bangalore',
            fees: '10,000',
            about_course: 'Lorem ipsum dolor sit amet consectetur. Sit nulla gravida vitae rutrum. Enim libero arcu pretium pellentesque arcu. Viverra ac nunc ipsum nisi amet metus odio magna. Dignissim facilisi ultrices at proin ullamcorper enim mauris. Interdum est id in turpis montes a ut. Sit urna donec hendrerit donec praesent et purus sed mauris. Fusce amet enim scelerisque massa tellus sollicitudin viverra accumsan. Convallis sit velit senectus morbi augue feugiat volutpat lectus scelerisque.Lorem ipsum dolor sit amet consectetur. Sit nulla gravida vitae rutrum. Enim libero arcu pretium pellentesque arcu. Viverra ac nunc ipsum nisi amet metus odio magna. Dignissim facilisi ultrices at proin ullamcorper enim mauris. Interdum est id in turpis montes a ut. Sit urna donec hendrerit donec praesent et purus sed mauris. Fusce amet enim scelerisque massa tellus sollicitudin viverra accumsan. Convallis sit velit senectus morbi augue feugiat volutpat lectus scelerisque.'
        }

    ];

    useEffect(() => {
        filmInstituteDetail();
    }, [])

    useEffect(() => {
        console.log(InstituteDetail)
    }, [InstituteDetail])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='my-5'>
                <p className='m-0 text-warning font22 fw-bold'>Film Institute - {InstituteDetail?.institute_name}, {InstituteDetail?.city}</p>

                <div className='row m-0 p-0 mt-4'>
                    <div className='col-12 m-0 p-0'>
                        <div>
                            <img src={`${baseURL}/${InstituteDetail?.banner}`} alt={InstituteDetail?.institute_name} width={'100%'} height={'100%'} />
                        </div>
                    </div>
                </div>

                <div className='mt-4 d-lg-flex d-md-block d-block justify-content-between align-items-center'>
                    <p className='m-0 text-warning font22 fw-bold'>About {InstituteDetail?.institute_name}</p>
                    <p className='m-0 text-white font16 fw-bold'>Address: {InstituteDetail?.address}, {InstituteDetail?.city}, Pin: {InstituteDetail?.pin_code}</p>
                </div>

                <p className='m-0 mt-2 text-white font16 fw-bold'>
                    {InstituteDetail?.about}
                </p>

                <div className='row m-0 p-0 mt-4'>
                    {(Array.isArray(InstituteDetail?.courses) && InstituteDetail?.courses.length > 0) &&
                        InstituteDetail?.courses.map((val: any, i: number) => {
                            return (
                                <>
                                    <div
                                        className='col-lg-9 col-md-12 col-12 p-0 py-3 theme_color mb-lg-4 mb-md-0 mb-0 px-lg-4 px-md-4 px-2'
                                        key={`${val.title}${i}`}
                                        style={{ backgroundColor: '#58585A' }}
                                    >
                                        <p className='m-0 text-warning font_weight_600 font26 text-capitalize'>{val.name}</p>
                                        <div className='m-0 text-white font14 mt-2 d-lg-flex d-md-grid d-grid justify-content-lg-start justify-content-md-start justify-content-start align-items-lg-center align-items-md-center'>
                                            <span
                                                className='font16'
                                            >
                                                <span className='text-warning font_weight_600'>Course Duration :</span> {val.duration} |
                                            </span>
                                            <span
                                                className='font16'
                                            >
                                                <span className='text-warning font_weight_600'>Address :</span> {InstituteDetail?.city} |
                                            </span>
                                            <span
                                                className='font16'
                                            >
                                                <span className='text-warning font_weight_600'>Fees :</span> {val.fees}
                                            </span>
                                        </div>
                                        <div className='text-white font14 my-3'>
                                            <p className='font16'>
                                                <b className='text-warning'>Course Details</b>
                                            </p>
                                            <p className='font14'>
                                                {val.description}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-3 col-12 py-3 d-flex justify-content-center align-items-center theme_color mb-4' key={val.venue}
                                        style={{ backgroundColor: '#58585A' }}
                                    >
                                        <div className='w-100'>
                                            <div>
                                                <button
                                                    className='py-1 fw-bold text-white rounded py-3 my-3'
                                                    style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                                >
                                                    I'm interested
                                                </button>
                                                <button
                                                    className='py-1 fw-bold text-white rounded py-3 mt-1'
                                                    style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                                    type="submit"
                                                >
                                                    Apply from CDBS Profile
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>

                {/* <p className='m-0 text-warning font24 fw-bold'>
                    EVENTS organized by {InstituteDetail?.institute_name}
                </p> */}

                <div className='row m-0 p-0 mt-4'>
                    {/* {
                        InstituteDetail.map((val: any, i: number) => {
                            return (
                                <>
                                    <div
                                        className='col-lg-3 col-md-12 col-12 mb-lg-4 mb-md-0 mb-0 theme_color py-3'
                                        key={`${val.title}${i + 1}`}
                                    >
                                        <div>
                                            <img src='../images/instituteeventimage.png' alt='placeholder' width={'100%'} height={'100%'} />
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 colmd-12 col-12 mb-lg-4 mb-md-0 mb-0 theme_color'
                                    >
                                        <div className='text-white mt-2 d-flex align-items-center d-flex justify-content-between align-items-lg-center'>
                                            <span
                                                className='font18'
                                            >
                                                <span className='text-warning font_weight_600'>Event Name :</span> {val.course_name}
                                            </span>
                                            <span
                                                className='font18'
                                            >
                                                <span className='text-warning font_weight_600'>Entry fees :</span> {val.fees}
                                            </span>
                                        </div>
                                        <p className='m-0 my-2 font14'>
                                            <b className='text-white font16'>About The Event</b>
                                        </p>
                                        <p
                                            className='text-white m-0 w-100 font14 event_organize_detail_section'
                                        >
                                            {val.about_course}
                                        </p>
                                        <p
                                            className='m-0 font16 text-white mt-3'
                                        >
                                            <span className='text-warning font_weight_600'>Venue address :</span> Lorem facilisis  anteAc nisi molestie Bangalore.
                                        </p>
                                        <div
                                            className='row mt-2 text-white'
                                        >
                                            <div
                                                className='col-lg-6 col-md-6 col-12'
                                            >
                                                <p
                                                    className='m-0 font16'
                                                >
                                                    <span className='text-warning font_weight_600'>City :</span> {val.course_name}
                                                </p>
                                                <p
                                                    className='m-0 font16'
                                                >
                                                    <span className='text-warning font_weight_600'>Start Date :</span> {val.fees}
                                                </p>
                                                <p
                                                    className='m-0 font16'
                                                >
                                                    <span className='text-warning font_weight_600'>Start Time :</span> {val.fees}
                                                </p>
                                            </div>
                                            <div
                                                className='col-lg-6 col-md-6 col-12'
                                            >
                                                <p
                                                    className='m-0 font16'
                                                >
                                                    <span className='text-warning font_weight_600'>Mobile No :</span> {val.course_name}
                                                </p>
                                                <p
                                                    className='m-0 font16'
                                                >
                                                    <span className='text-warning font_weight_600'>End Date :</span> {val.fees}
                                                </p>
                                                <p
                                                    className='m-0 font16'
                                                >
                                                    <span className='text-warning font_weight_600'>End Time :</span> {val.fees}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-3 col-12 py-3 d-flex justify-content-center align-items-center theme_color mb-lg-4 mb-md-4 mb-3' key={`${val.title}${i + 2}`}
                                    >
                                        <div className='w-100'>
                                            <div>
                                                <button
                                                    className='py-1 fw-bold text-white rounded py-3 mt-1'
                                                    style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                                    type="submit"
                                                >
                                                    I'm interested
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    } */}
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default FilmInstituteDetail
