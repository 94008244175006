import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineCamera } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAuditionCallDetailAPI } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import UploadAuditionVideoModal from '../../components/auditionCallForm/UploadAuditionVideoModal';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { PiCaretLeftBold } from 'react-icons/pi';
import AuditionCallFormModal from '../../components/auditionCallForm/AuditionCallFormModal';

const AuditionCallDetail = () => {

    LoadingOverlay.propTypes = undefined;
    const params = useParams();
    const [isActive, setActive] = useState(false);
    const { id } = params;
    const [auditionDetail, setAuditionDetail] = useState<any>([]);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [hideField, setHideField] = useState(0);
    const [openForm, setOpenForm] = useState(false);

    const auditionCallDetail = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const data = await getAuditionCallDetailAPI(urlId)
        setAuditionDetail(data)
        setActive(false);
    }

    const openUploadVideoModal = (val: any) => {
        setHideField(val);
        setOpenUploadModal(!openUploadModal);
    }

    const handleFormModal = () => {
        setOpenForm(!openForm);
    }

    useEffect(() => {
        auditionCallDetail();
    }, [])

    // useEffect(() => {
    //     console.log('Audition', auditionDetail);
    // }, [auditionDetail])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            {openUploadModal &&
                <UploadAuditionVideoModal handleModalBox={openUploadVideoModal} modalNumber={hideField} id={id} />
            }
            {openForm &&
                <AuditionCallFormModal handleModalBox={handleFormModal} />
            }
            <div className='my-5'>
                <div
                    className='d-flex justify-content-between align-items center'
                >
                    <h5 className='m-0 text-warning font26 fw-bold'>Audition Call</h5>
                    <Link to='/menu/auditioncall' className='text-decoration-none font26 text-white'><PiCaretLeftBold /> Back</Link>
                </div>
                <div className='row m-0 p-0 mt-4'>
                    <div className='col-12 m-0 p-0'>
                        <div>
                            <img src={`${baseURL}/${auditionDetail?.banner}`} alt={auditionDetail?.title} width={'100%'} height={'100%'} />
                        </div>
                    </div>
                </div>

                <div
                    className='d-flex justify-content-center align-items-center my-4'
                >
                    <button
                        className='bg-warning text-capitalize text-dark font_weight_600 rounded px-3'
                        onClick={handleFormModal}
                    >
                        create the new audition category
                    </button>
                </div>

                <div className='row m-0 p-0 order_bottom_with_color'>
                    <div className='col-lg-9 col-md-9 col-12 p-0 py-3 theme_color px-lg-4 px-md-4 px-2'>
                        <p
                            className='m-0 text-warning font26 font_weight_600'
                        >
                            {auditionDetail?.audition_category}
                        </p>
                        <div className='m-0 text-white font14 font_weight_600 mt-2 d-flex justify-content-between align-items-center'>
                            <span>
                                <span className='text-warning font14'>Title</span> : {auditionDetail?.title} |
                            </span>
                            <span>
                                <span className='text-warning font14'>Role</span> : {auditionDetail?.audition_category} |
                            </span>
                            <span>
                                <span className='text-warning font14'>Age</span> : {auditionDetail?.from_age}-{auditionDetail?.to_age} |
                            </span>
                            <span>
                                <span className='text-warning font14'>Venue</span> : {auditionDetail?.location} |
                            </span>
                            <span>
                                <span className='text-warning font14'>Type</span> : {auditionDetail?.movie_type} |
                            </span>
                            <span>
                                <span className='text-warning font14'>Duration</span> : {auditionDetail?.duration}
                            </span>
                        </div>
                        <div className='text-white font14 my-3'>
                            <p className='font16'>
                                <b className='text-warning'>Description</b>
                            </p>
                            <p className='font14'>
                                {auditionDetail?.description}
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-12 py-3 d-flex justify-content-center align-items-center theme_color'>
                        <div className='w-100'>
                            <div className='text-white font16 text-end'>
                                <p className='m-0 d-flex justify-content-center align-items-center mb-2'>
                                    <AiOutlineCamera className='me-2' />
                                    Camera
                                </p>
                                <p className='m-0 d-flex justify-content-center align-items-center'>

                                    <TfiGallery className='text-white me-2' />
                                    Gallery
                                </p>
                            </div>
                            <div>
                                <button
                                    className='rounded fw-bold text-white my-3'
                                    style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                    onClick={() => openUploadVideoModal(2)}
                                >
                                    Upload Audition Video
                                </button>
                                <button
                                    className='rounded fw-bold text-white mt-1'
                                    style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                    onClick={() => openUploadVideoModal(1)}
                                >
                                    Apply from CDBS Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row theme_color m-0 py-3'>
                    <p
                        className='m-0 text-warning font_weight_600 font16'
                    >
                        Audition Script / Show-reel Script
                    </p>
                    {auditionDetail?.audition_script ?
                        <p
                            className='m-0 text-white font_weight_600 font14 mt-4'
                        >
                            {auditionDetail?.audition_script}
                        </p>
                        :
                        <p
                            className='m-0 text-white font_weight_600 font14 mt-4'
                        >
                            Not available
                        </p>
                    }
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default AuditionCallDetail
