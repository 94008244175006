import React, { useEffect, useState, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ImCross } from 'react-icons/im';
import { getMediaReviewListAPI } from '../../utils/utils';
import { IoTrash } from 'react-icons/io5';
import { Table } from 'react-bootstrap';
import ProjectMediaReviewModal from '../myprofilemodals/ProjectMediaReviewModal';
import { FiEdit } from 'react-icons/fi';

const MediaReviewForm = (props: any) => {

    const { project_id } = props;
    const messageTxt = (message: any) => toast(message);
    const mediaReviewRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [reviewList, setReviewList] = useState<any>([]);
    const [getId, setId] = useState<any>('');
    const [mediaReview, setMediaReview] = useState<any>({
        article: '',
        article_link: ''
    });

    const getReviewList = async () => {
        setActive(true);
        const reviewList = await getMediaReviewListAPI(project_id);
        setReviewList(reviewList);
        setActive(false);
    }

    const handleEditMediaReview = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleChangeMediaVal = (e: any) => {
        const { name, value } = e.target;
        setMediaReview({ ...mediaReview, [name]: value });
    }

    const handleAddUpdateMediaReview = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { article, article_link } = mediaReview;

        if (article === '' || article_link === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id,
                    media_review_id: getId,
                    article,
                    article_link
                }
                const res = await axios(`${baseURL}/api/project/add-update/media_review`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                messageTxt(res.data.message);
                getReviewList();
                setId('');
                setMediaReview({
                    article: '',
                    article_link: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const deleteMediaReview = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id: id
                }
                let result = await axios(`${baseURL}/api/project/destroy-media_review`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getReviewList();
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const takehReviewForUpdate = (val: any) => {
        const data = val;
        setMediaReview({
            ...mediaReview,
            article: data.article,
            article_link: data.article_link
        });
        setId(data.id);
        setOpenFormModal(!openFormModal);
    }

    const handleUpdateModalForm = () => {
        getReviewList();
        setOpenFormModal(!openFormModal);
    }

    const handleAddMediaReview = () => {
        setOpenAdd(!openAdd);
        getReviewList();
    }

    useEffect(() => {
        const element = mediaReviewRef.current;

        const handleShow = () => {
            getReviewList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log(reviewList);
    // },[reviewList])

    return (
        <div className='happy_movement_form mt-3'>
            <div className="accordion accordion-flush" id="mediaReviewAddUpdate">

                <div className="accordion-item rounded">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsemediaReviewAddUpdate" aria-expanded="false" aria-controls="flush-collapsemediaReviewAddUpdate">
                            Media review
                        </button>
                    </h2>
                    <div id="flush-collapsemediaReviewAddUpdate" ref={mediaReviewRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#mediaReviewAddUpdate">
                        <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                            <LoadingOverlay
                                active={isActive}
                                spinner
                                text='Loading your content...'
                            >
                                {openFormModal &&
                                    <ProjectMediaReviewModal handleModalBox={handleUpdateModalForm} dataId={getId} data={mediaReview} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <ProjectMediaReviewModal handleModalBox={handleAddMediaReview} project_id={project_id} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddMediaReview}
                                    >
                                        + Add
                                    </button>
                                </div>

                                {Array.isArray(reviewList) && reviewList.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Article link</th>
                                                <th>Article</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(reviewList) && reviewList.length > 0 &&
                                                reviewList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.article_link}</td>
                                                            <td>{val.article}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-start align-items-center'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => takehReviewForUpdate(val)} />
                                                                    <IoTrash className='pointer ms-4' onClick={(e) => deleteMediaReview(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white text-center font_weight_600"
                                    >
                                        No data found.
                                    </p>
                                }
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MediaReviewForm
