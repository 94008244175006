import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ProjectTeaserTrailerModal = (props: any) => {
    const { handleModalBox, data, project_id, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [teaserLinks, setTeaserLinks] = useState<any>({ type: '', link: '' });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setTeaserLinks({ ...teaserLinks, [name]: value })
    };

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleAddTeaserTrailerUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        setActive(true);
        const { type, link } = teaserLinks;
        if (connection_id && auth_code) {
            if (type === 'trailer') {
                try {
                    let body = {
                        connection_id,
                        auth_code,
                        project_id: project_id,
                        trailer: JSON.stringify([link])
                    }
                    let result = await axios(`${baseURL}/api/project/add-movie_links`, {
                        method: 'POST',
                        data: body,
                        headers: { "Content-Type": "multipart/form-data" }
                    });
                    messageTxt(result.data.message);
                    setActive(false);
                    // props.handleId(result.data.data.project_id);
                    // console.log(result.data.data.project_id);
                } catch (error) {
                    console.log(error);
                    setActive(false);
                }
            } else if (type === 'teaser') {
                try {
                    let body = {
                        connection_id,
                        auth_code,
                        project_id: project_id,
                        teaser: JSON.stringify([link])
                    }
                    let result = await axios(`${baseURL}/api/project/add-movie_links`, {
                        method: 'POST',
                        data: body,
                        headers: { "Content-Type": "multipart/form-data" }
                    });
                    messageTxt(result.data.message);
                    setActive(false);
                    // props.handleId(result.data.data.project_id);
                    // console.log(result.data.data.project_id);
                } catch (error) {
                    console.log(error);
                    setActive(false);
                }
            }
        }
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center cast_modal">

                        <div className='col-lg-3 col-md-3 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Select type</label>
                            <select className="form-select mb-3 bg-transparent text-white" onChange={handleChange} name='type' value={teaserLinks.type} aria-label="Default select example">
                                <option selected>Select teaser/trailer</option>
                                <option value="teaser">Teaser</option>
                                <option value="trailer">Trailer</option>
                            </select>
                        </div>
                        <div className='col-lg-9 col-md-9 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Movie Reference links</label>
                            <input type="text" value={teaserLinks.link} name='link' onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" placeholder='URL Link' />
                        </div>

                        <div className='d-flex justify-content-end'>
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleAddTeaserTrailerUpdate}
                            >
                                ADD
                            </button>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProjectTeaserTrailerModal
