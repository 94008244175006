import React, { useEffect, useState } from 'react';
import './style.sponsored.css.css';
import axios from 'axios';
import { baseURL } from '../../utils/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Link } from 'react-router-dom';
import { getAllIndustryTypeAPI } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { industryType } from '../../states/reducers';

const Sponsored = () => {
    const industry_type = useSelector((state: any) => state.industryType.industryType);
    const numbersOfLines = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    const multiIndustryVal = localStorage.getItem('multiindustry');
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(false);
    const [banner, setBanner] = useState<any>([]);
    const [industry_name, setIndustryName] = useState<any>();

    const sponsorBanner = async () => {
        setActive(true);
        const connection_id = localStorage.getItem("connection_id");

        const body = {
            connection_id,
            banner_type_id: 4
        };

        try {
            const response = await axios.post(`${baseURL}/api/banner_list`, body);
            if (response?.data && response?.data?.data) {
                setBanner(Object.values(response?.data?.data));
            } else {
                console.error('Invalid response data:', response.data);
                // Handle the case where response data is not as expected.
            }
            setActive(false);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            sponsorBanner();
        }, 4000)
    }, [])

    // useEffect(() => {
    //     // console.log('ss', industry_name[0].title)
    // }, [industry_name])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-12 d-flex justify-content-center align-items-lg-center mb-lg-0 mb-md-2 mb-2 overflow-hidden'>
                        <div className='d-flex justify-content-center align-items-baseline w-100'>
                            {
                                numbersOfLines.map((val) => {
                                    return (
                                        <span className='sponsoredLines mx-2' key={val}></span>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-12 col-12'>
                        <div className='text-warning font26 text-center fw-bold'>
                            {industry_type?.title ?
                                <>
                                    Legacy of the {industry_type[0]?.title}
                                </>
                                : null
                            }
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-12 col-12 d-flex justify-content-center align-items-lg-center mt-lg-0 mt-md-2 mt-2 overflow-hidden'>
                        <div className='d-flex justify-content-center align-items-baseline w-100'>
                            {
                                numbersOfLines.map((val) => {
                                    return (
                                        <span className='sponsoredLines mx-2' key={val}></span>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <p
                        className='m-0 mt-1 text-white font16 text-center fw-bold'
                    >
                        {(Array.isArray(industry_type) && industry_type.length > 0) ?
                            <>
                                {industry_type[0]?.title_native_language}
                            </>
                            : null
                        }

                    </p>
                    <div className='d-flex justify-content-center align-items-center mb-2 mt-lg-4 mt-md-4 mt-4'>
                        <span className='font16 text-center sponsore_border text-white pb-lg-2 pb-md-2 pb-4'>
                            {(Array.isArray(industry_type) && industry_type.length > 0) && industry_type[0]?.short_description ?
                                <>
                                    {industry_type[0]?.short_description} &gt;&gt; <Link to={`/${industry_type[0].linked_page_url}`} className='text-white text-decoration-none'>Read more</Link>
                                </>
                                : null
                            }
                        </span>
                    </div>
                </div>

                <div className='row'>
                    {/* <div className='col-12'> */}
                    <div className='imageBox'>
                        <p className='text-warning font16 m-0'>
                            Sponsored
                        </p>
                        <img src={`${baseURL}/${banner}`} alt='sponsor' />
                    </div>
                    {/* </div> */}
                </div>
            </div>
            {/* <div className='mb-5 mt-5'>
                    <div className='d-flex justify-content-center align-items-center'>
                        {
                            numbersOfLines.map((val) => {
                                return (
                                    <span className='sponsoredLines mx-2' key={val}></span>
                                )
                            })
                        }
                        <div className='text-warning font26 mx-4'>
                            Legacy of the Sandalwood
                        </div>
                        {
                            numbersOfLines.map((val) => {
                                return (
                                    <div className='sponsoredLines mx-2' key={val}></div>
                                )
                            })
                        }
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <span>
                            Legacy of the Sandalwood
                        </span>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-2'>
                        <span className='font12 w-50 text-center sponsore_border text-white pb-2'>
                            Kannada cinema, also known as Sandalwood, or Chandanavana, is the
                            segment of the Indian cinema dedicated to the production of motion pictures 
                            in the kannada languagewidely spoken in the state of karnataka. '&gt &gt' Read more
                        </span>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className='sponsorBox'>
                            <p className='text-warning font16 m-0'>
                                Sponsored
                            </p>
                            <img src={'./images/sponsor.png'} className='' alt='sponsor' height={'360'} />
                        </div>
                    </div>
                </div> */}
        </LoadingOverlay>
    )
}

export default Sponsored
