import React, { useState, useEffect } from 'react';
import './profileform.css';
import axios from 'axios';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import { getUserDetailsAPI, calculateAge, getFaceShapAPI } from '../../utils/utils';
import { FaEdit } from "react-icons/fa";
import { PiCaretDownBold } from "react-icons/pi";
import { isValidEmail } from '../../utils/jsfunctions';

const FilmFraternityPersonalForm = () => {

    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [profile_data, setProfile_data] = useState<any>({});
    const [age, setAge] = useState<any>();
    const [activeSave, setActiveSave] = useState(false);
    const [face_profile_shape, setFaceShape] = useState<any>();

    const [profile, setProfile] = useState({
        first_name: '',
        last_name: '',
        name_in_industry_native_language: '',
        name_given_by_industry: '',
        cause_of_death: '',
        autograph_picture: '',
        habits: '',
        payment_preferred: '',
        zodiac_sign: '',
        height: '',
        current_weight: '',
        hip_size: '',
        chest_size: '',
        face_profile: '',
        eye_color: '',
        hair_color: '',
        other_physical_measurements: '',
        preffered_dressware: '',
        gender: '',
        birth_date: '',
        birth_place: '',
        nationality: '',
        religion: '',
        marital_status: '',
        home_town: '',
        address: '',
        email: '',
        phone: '',
        whatsapp_number: '',
        death_date: '',
        avatar: '',
        nickname: '',
        shoulder_size: '',
        foot_size: '',
        thigh_size: '',
        skin_color: '',
        name: '',
    });

    const maxMobileNumber = 10;

    const getUserDetails = async () => {
        const data = await getUserDetailsAPI();
        setProfile_data(data);
    }

    const getFaceShape = async () => {
        const data = await getFaceShapAPI();
        setFaceShape(data);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'avatar' || name === 'autograph_picture') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            if (name === 'avatar') {
                setProfile((prevVal: any) => ({ ...prevVal, avatar: document_image }));
            } else if (name === 'autograph_picture') {
                setProfile((prevVal: any) => ({ ...prevVal, autograph_picture: document_image }));
            }
        } else if (name === 'phone') {
            if (value.length <= maxMobileNumber) {
                setProfile((prevVal: any) => ({ ...prevVal, phone: value }));
            }
        } else if (name === 'whatsapp_number') {
            if (value.length <= maxMobileNumber) {
                setProfile((prevVal: any) => ({ ...prevVal, whatsapp_number: value }));
            }
        } else {
            setProfile({ ...profile, [name]: value });
        }
    }

    const handleEditFraternityPersonal = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleUpdatePersonalInfo = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { name, first_name, last_name, name_in_industry_native_language, name_given_by_industry, cause_of_death, autograph_picture, habits, payment_preferred, zodiac_sign, height, current_weight, hip_size, chest_size, face_profile, eye_color, hair_color, other_physical_measurements, preffered_dressware, gender, birth_date, birth_place, nationality, religion, marital_status, home_town,
            address, email, phone, whatsapp_number, death_date, avatar, nickname, shoulder_size, foot_size, thigh_size, skin_color } = profile;
        if (phone === '' || whatsapp_number === '') {
            messageTxt('Please enter phone number and whatsapp number');
        } else if (phone.length > 10 || whatsapp_number.length > 10) {
            messageTxt('Please enter 10 digit phone or whatsapp number');
        } else if (!isValidEmail(email)) {
            messageTxt('Please enter valid email');
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    name, first_name, last_name, name_in_industry_native_language, name_given_by_industry, cause_of_death, autograph_picture, habits, payment_preferred, zodiac_sign, height, current_weight, hip_size, chest_size, face_profile, eye_color, hair_color, other_physical_measurements, preffered_dressware, gender, birth_date, birth_place, nationality, religion, marital_status, home_town,
                    address, email, phone, whatsapp_number, death_date, avatar, nickname, shoulder_size, foot_size, thigh_size, skin_color
                }
                setActiveSave(true);
                let result = await axios(`${baseURL}/api/subscriber/update-film_fertinity/personal_information`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                const calAge = calculateAge(profile.birth_date);
                setAge(calAge);
                messageTxt(result.data.message);
                setIsDisabled(!isDisabled);
                setActiveSave(false);
                // console.log(result);
            } catch (error) {
                console.log(error);
                setActiveSave(false);
            }
        }
    }

    const calculateUserAge = () => {
        if (profile_data?.death_date) {
            const calAge = calculateAge(profile_data.birth_date, profile_data.death_date);
            setAge(calAge);
        } else {
            const birthDate = profile_data.birth_date;
            const calAge = calculateAge(birthDate);
            setAge(calAge);
        }

        // const birthDate = profile_data?.birth_date;
        // const calAge = calculateAge(birthDate);
        // setAge(calAge);
    }

    useEffect(() => {
        getUserDetails();
        getFaceShape();
    }, []);

    useEffect(() => {
        // if (profile_data.death_date) {
        //     const calAge = calculateAge(profile_data.death_date);
        //     setAge(calAge);
        // } else {
        //     const birthDate = profile_data.birth_date;
        //     const calAge = calculateAge(birthDate);
        //     setAge(calAge);
        // }

        // const birthDate = profile_data.birth_date;
        // const calAge = calculateAge(birthDate);
        // setAge(calAge);

        calculateUserAge();

        setProfile({
            ...profile,
            ...profile_data,
        });
    }, [profile_data]);

    useEffect(() => {
        // console.log(profile_data);
    }, [profile_data]);

    return (
        <>
            <div className='personal_info_form_accordian_section mt-5'>
                <div className="accordion accordion-flush" id="accordionFlushExample">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                aria-expanded="true"
                                aria-controls="flush-collapseOne"
                                className="accordion-button collapsed rounded accordian_border_with_color hide_accordion_caret"
                            >
                                Personal information
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse show collapse mb-1" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body accordian_border_with_color border-top-0" style={{ backgroundColor: '#242424' }}>
                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded font_weight_600'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleEditFraternityPersonal}
                                    >
                                        Edit
                                    </button>
                                </div>
                                <form className='px-5 personal_info_form'>
                                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center check_disabled">
                                        <div>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Display name</label>
                                            <input type="text" onChange={handleChange} value={profile.name} name='name' className="form-control mb-3 myElement" placeholder='User/Display name' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">First name on the records ( aadhar card )</label>
                                            <input type="text" onChange={handleChange} value={profile.first_name} name='first_name' className="form-control mb-3" placeholder='First Name' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Last Name on the records ( aadhar card )</label>
                                            <input type="text" onChange={handleChange} value={profile.last_name} name='last_name' placeholder='Last Name' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Name written in Native Language (Aadhaar Card)</label>
                                            <input type="text" onChange={handleChange} value={profile.name_in_industry_native_language} name='name_in_industry_native_language' placeholder='Enter full name' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload your passport picture</label>
                                            {isDisabled && profile_data.avatar ?
                                                <div
                                                    style={{ height: '66px', width: '50px' }}
                                                >
                                                    <img src={`${baseURL}/${profile_data.avatar}`} alt={profile_data.name} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                </div>
                                                :
                                                <input type="file" onChange={handleChange} name='avatar' className="form-control mb-3" disabled={isDisabled} />
                                            }
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Title given by the Industry</label>
                                            <input type="text" onChange={handleChange} value={profile.name_given_by_industry} name='name_given_by_industry' placeholder="Power star" className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Any other name (nick name)</label>
                                            <input type="text" onChange={handleChange} value={profile.nickname} name='nickname' className="form-control mb-3" placeholder='eg: any other name xyz' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Date of birth</label>
                                            <input type="date" onChange={handleChange} value={profile.birth_date} name='birth_date' className="form-control mb-3 cal_icon" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Birth place</label>
                                            <input type="text" onChange={handleChange} value={profile.birth_place} name='birth_place' placeholder='eg: Bangalore' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Nationality</label>
                                            <input type="text" onChange={handleChange} value={profile.nationality} name='nationality' placeholder='eg: Indian' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Religion</label>
                                            <input type="text" onChange={handleChange} value={profile.religion} name='religion' placeholder='eg: Hindu, Muslim, Sikh etc.' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Marital status</label>
                                            <select className="form-select mb-3" onChange={handleChange} value={profile.marital_status} name='marital_status' aria-label="Default select example" disabled={isDisabled}>
                                                <option selected>Select your marital status...</option>
                                                <option value="1">Bachelor</option>
                                                <option value="2">Married</option>
                                                <option value="3">Single</option>
                                                <option value="4">Divorced</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Home town</label>
                                            <input type="text" onChange={handleChange} value={profile.home_town} name='home_town' placeholder='eg: Bangalore' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Email id</label>
                                            <input type="email" onChange={handleChange} value={profile.email} name='email' className="form-control mb-3" placeholder='Enter your email' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Contact number</label>
                                            <input type="number" onChange={handleChange} value={profile.phone} name='phone' className="form-control mb-3" placeholder='Phone number' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Whatsapp number</label>
                                            <input type="number" onChange={handleChange} value={profile.whatsapp_number} name='whatsapp_number' placeholder='enter your whatsapp number' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Gender</label>
                                            <select className="form-select mb-3" onChange={handleChange} value={profile.gender} name='gender' aria-label="Default select example" disabled={isDisabled}>
                                                <option selected>Select your gender</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="3">Other</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Full address(permanent address)</label>
                                            <input type="text" onChange={handleChange} value={profile.address} name='address' className="form-control mb-3" placeholder='Enter your Full Address with Pincode ' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Age</label>
                                            <input type="text" value={age} className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Habits</label>
                                            <input type="text" onChange={handleChange} value={profile.habits} name='habits' className="form-control mb-3" placeholder='acting,dancing,singing etc...' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Payment preferred</label>
                                            <select className="form-select mb-3" onChange={handleChange} value={profile.payment_preferred} name='payment_preferred' aria-label="Default select example" disabled={isDisabled}>
                                                <option selected>Select payment preferred</option>
                                                <option value="Daily">Daily</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Weekly">Monthly</option>
                                                <option value="Project Lumpsum">Project Lumpsum</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Zodiac sign</label>
                                            <input type="text" onChange={handleChange} value={profile.zodiac_sign} name='zodiac_sign' placeholder='eg: Libra, Scorpion etc.' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Signature/Autograph picture</label>
                                            {isDisabled && profile_data.autograph_picture ?
                                                <div
                                                    style={{ height: '66px', width: '50px' }}
                                                >
                                                    <img src={`${baseURL}/${profile_data.autograph_picture}`} alt={profile_data.name} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                </div>
                                                :
                                                <input type="file" onChange={handleChange} name='autograph_picture' className="form-control mb-3" disabled={isDisabled} />
                                            }
                                        </div>
                                    </div>

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        {activeSave ?
                                            <button
                                                className='py-2 px-2 border-0 personal_save_button rounded font16 font_weight_600'
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                            >
                                                Updating...
                                            </button>
                                            :
                                            <>
                                                {!isDisabled ?
                                                    <button
                                                        className='py-2 px-2 border-0 personal_save_button rounded font16 font_weight_600'
                                                        style={{
                                                            backgroundColor: 'red',
                                                            color: 'white',
                                                            width: '150px'
                                                        }}
                                                        onClick={handleUpdatePersonalInfo}
                                                    >
                                                        Save
                                                    </button>
                                                    : null
                                                }
                                            </>
                                        }
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div >
            </div >
            <ToastContainer />
        </>
    )
}

export default FilmFraternityPersonalForm
