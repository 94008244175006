import React, { useState, useEffect } from 'react';
import { getQandAAPI } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { FaPlus, FaMinus } from "react-icons/fa6";

const QuestionAnswers = () => {

    LoadingOverlay.propTypes = undefined;
    const [openAccordion, setOpenAccordion] = useState<string | null>(null);
    const [isActive, setActive] = useState(false);
    const [qaData, setData] = useState<any>([]);

    const handleAccordionClick = (index: string) => {
        setOpenAccordion((prevOpenAccordion) => (prevOpenAccordion === index ? null : index));
    };

    const getAQ = async () => {
        setActive(true);
        const data = await getQandAAPI();
        setData(data);
        setActive(false);
    }

    useEffect(() => {
        getAQ();
    }, [])

    // useEffect(() => {
    //     console.log('qa', qaData);
    // }, [qaData])

    return (
        <>
            <div
                className='container'
            >
                <p
                    className='fs-4 fw-bold text-white mt-5'
                >
                    <span className='text-warning'>Frequently</span> Asked Question
                </p>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div
                        className='row my-5 p-0'
                    >
                        <div className='col-lg-4 col-md-4 col-12 p-0'>
                            <div>
                                <img src='./images/qaimage.png' alt='qaimage' width={'100%'} height={'100%'} />
                            </div>
                        </div>

                        <div className='col-lg-8 col-md-8 col-12 p-0'>
                            <div className='theme_color py-5 px-4 rounded-4' style={{height: '100%'}}>
                                {qaData && Array.isArray(qaData) &&
                                    qaData?.map((val: any, index: number) => {
                                        const collapseId = `collapse-${index}`;
                                        const isOpen = openAccordion === collapseId;
                                        return (
                                            <div className="accordion-item rounded" style={{ backgroundColor: `${isOpen ? 'white' : '#D9D5D5'}` }} key={val.id}>
                                                <h2 className="accordion-header" id={`heading-${index}`}>
                                                    <button className="accordion-button position-relative collapsed rounded text-dark p-3 fw-bold"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#${collapseId}`}
                                                        aria-expanded="false"
                                                        aria-controls={collapseId}
                                                        onClick={() => handleAccordionClick(collapseId)}
                                                    >
                                                        {val.question}
                                                        <div className='position-absolute' style={{ right: '10px' }}>
                                                            {isOpen  ? <FaMinus className='font22' /> : <FaPlus className='font22' />}
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id={collapseId} className="accordion-collapse collapse mb-1" aria-labelledby={`heading-${index}`} data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body text-dark px-3 font14 pb-5">
                                                        {val.answer}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        </>
    )
}

export default QuestionAnswers
