import React, { useState, useEffect } from 'react';
import './auditionstyle.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllIndustryTypeAPI, getAllLanguage, getAllMoviesAPI, getFilmType, getShowReelListAPI } from '../../utils/utils';
import Select from 'react-dropdown-select';
import { useSelector } from 'react-redux';

const UploadAuditionVideoModal = (props: any) => {

    const { handleModalBox, modalNumber, id } = props;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [show, setShow] = useState(true);
    const [thanksMessage, setThanksMessage] = useState(false);
    const [hideButton, setHideButton] = useState(false);
    const [hideField, setHideField] = useState(modalNumber);
    const [videoList, setVideoList] = useState<any>([]);
    const [videoLink, setVideoLink] = useState({
        video_link: '',
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
        setThanksMessage(false);
        setHideButton(false);
    }

    const handleVideoChangeValue = (e: any) => {
        const { name, value } = e.target;
        setVideoLink({ ...videoLink, [name]: value })
    }

    const getShowreelsVideo = async () => {
        setActive(true);
        const data = await getShowReelListAPI();
        setVideoList(data);
        setActive(false);
    }

    const handleUploadVideo = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        setHideButton(true);
        const { video_link } = videoLink;
        if (video_link === '') {
            messageTxt('Please select or fill field.');
            setActive(false);
            setHideButton(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    audition_call_id: id,
                    video_link
                }
                const res = await axios(`${baseURL}/api/audition_calls/audition_video/upload`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setThanksMessage(true);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
                setHideButton(false);
            }
        }
    }

    useEffect(() => {
        getShowreelsVideo();
    }, [])

    // useEffect(() => {
    //     console.log(videoLink);
    // }, [videoLink])


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div className='text-end'>
                <button
                    className='me-3 text-white'
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent', color: 'black' }}
                >
                    <ImCross />
                </button>
            </div>

            <div className='bg-dark pt-4 px-lg-5 px-md-5 px-2'>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    {thanksMessage ?
                        <div
                            className='my-4'
                        >
                            <div
                                className='text-center mb-3'
                            >
                                <img src='../../images/confirmmodal.png' alt='confirmmodal' width={'100'} height={'100'} />
                            </div>
                            <h5
                                className='m-0 text-white font26 fw-bold text-center'
                            >
                                Submission is Successful 
                            </h5>
                        </div>
                        :
                        <div className='row mt-lg-4 mt-md-4 mt-3 p-0 upload_video_modal'>
                            {hideField === 1 ?
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <label htmlFor="video_link" className="form-label text-white m-0">Select from your showreels</label>
                                    <select className="form-select mb-3 bg-transparent text-white" name='video_link' value={videoLink.video_link} onChange={handleVideoChangeValue} aria-label="select example">
                                        <option selected>Select project</option>
                                        {Array.isArray(videoList) &&
                                            videoList?.map((val: any) => {
                                                return (
                                                    <option key={val.id} value={val.link}>{val.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                : null
                            }
                            {hideField === 2 ?
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <label htmlFor="video_link" className="form-label text-white m-0">Other video link</label>
                                    <input type="text" name='video_link' onChange={handleVideoChangeValue} value={videoLink.video_link} className="form-control mb-3" id="video_link" />
                                </div>
                                : null
                            }
                        </div>
                    }
                </LoadingOverlay>

                <div
                    className='d-flex justify-content-center mb-lg-5 mb-md-5 mb-4'
                >
                    {hideButton ?
                        null
                        :
                        <button
                            className='py-1 fw-bold rounded text-white py-3 mt-3'
                            style={{ width: '150px', backgroundColor: 'red', border: 'none' }}
                            onClick={handleUploadVideo}
                        >
                            Upload
                        </button>
                    }
                </div>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default UploadAuditionVideoModal
