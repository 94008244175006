import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineCalendar } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";

const FilmFestivalTicket = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state || {};

    const photos = ['aff1', 'aff2', 'aff3', 'aff4', 'aff5', 'aff6', 'aff7', 'aff8', 'aff9'];
    const bestFilm = ['bestfilm1', 'bestfilm2', 'bestfilm3', 'bestfilm4'];
    const winnersMovie = ['bestfilm5', 'bestfilm3', 'bestfilm1'];

    useEffect(() => {
        if (data !== 1 || data === undefined) {
            navigate('/profile/addmovietofilmfestival');
        }
    }, [data])

    return (
        <div className='mt-5'>
            <div className='row m-0 p-0 mt-4'>
                <div className='col-12 m-0 p-0'>
                    <div>
                        <img src='../images/filmfestivalgetticketposter.png' alt='film festival' width={'100%'} height={'100%'} />
                    </div>
                </div>
            </div>

            <div className='mt-3 d-flex justify-content-between align-items-center theme_color'>
                <div className='left_div'>
                    <button className='py-3 px-2 rounded border-0 text-white theme_color' style={{ width: '150px' }}>
                        Tickets
                    </button>
                    <button className='py-3 mx-lg-1 mx-md-1 mx-0 mt-lg-0 mt-md-0 mt-3 px-2 rounded border-0 text-white theme_color' style={{ width: '150px' }}>
                        About
                    </button>
                    <button className='py-3 mx-lg-1 mx-md-1 mx-0 mt-lg-0 mt-md-0 mt-3 px-2 rounded border-0 text-white theme_color' style={{ width: '150px' }}>
                        Photos
                    </button>
                    <button className='py-3 mx-lg-1 mx-md-1 mx-0 mt-lg-0 mt-md-0 mt-3 px-2 rounded border-0 text-white theme_color' style={{ width: '150px' }}>
                        Public Voting
                    </button>
                </div>
            </div>

            <p className='m-0 text-warning font22 fw-bold my-4'>
                Bengaluru International Film Festival - 14th Edition
            </p>

            <div className='mb-4'>
                <p className='text-white d-flex align-items-center m-0 mb-3'>
                    <AiOutlineCalendar className='me-2' />Fri 10 Feb 2023 at 10:00 pm
                    <CiLocationOn className='mx-3' />Bangalore
                </p>
            </div>

            <div className='row m-0 p-0'>
                <div className='col-lg-4 col-12 p-3 theme_color mb-4'>
                    <div
                        style={{
                            height: '200px',
                            width: '370px'
                        }}
                    >
                        <img src={`../images/filmfestivalgetticketposter.png`} alt={'addfestival1'} width={'100%'} height={'100%'} />
                    </div>
                </div>

                <div className='col-lg-5 col-12 p-0 pt-3 theme_color mb-4'>
                    <p className='m-0 text-white font14 mt-2'>
                        Bengaluru International Film Festival
                    </p>
                    <div className='text-white font14 my-3'>
                        <p className='font14'>
                            <b className='text-warning'>Venue :</b> Orion Mall, Yashwant Pura, Bengaluru,  Karnataka, India.
                        </p>
                        <p className='font14'>
                            <b className='text-warning'>Price :</b> <span className='ms-4'>Rs 600</span>
                        </p>
                        <div className='d-flex justify-content-start align-items-center'>
                            <p className='text-white m-0'>Qty</p>
                            <select className="form-select ms-4 w-25 bg-transparent text-white text-center" aria-label="Default select example">
                                <option className='text-dark' selected>2</option>
                                <option className='text-dark' value="1">3</option>
                                <option className='text-dark' value="2">4</option>
                                <option className='text-dark' value="3">5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className='col-lg-3 col-12 d-flex justify-content-center align-items-center theme_color mb-4'>
                    <div className='w-100'>
                        <div>
                            <button
                                className='py-1 fw-bold text-white py-3 mt-1'
                                style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                type="submit"
                            >
                                Buy Tickets
                            </button>
                            <button
                                className='py-1 fw-bold text-white py-3 mt-1'
                                style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                type="submit"
                            >
                                Submit the Movie
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <p className='m-0 text-white'>About</p>
                <p className='m-0 text-white font14 ps-lg-4 ps-md-4 ps-0 mt-2'>
                    BIFFes - Bengaluru International Film Festival - the flagship cultural congregation of the capital city of Bengaluru - Riding on the stupendous success of the past 13 years, the 14 Edition of BIFFES, with FlAPF Accreditation, will be held from March 23 - 30, 2023. The film festival, which took its genesis, in the winter of 2006, has risen in size, scope and stature, carving a special niche for itself as a must seen festival, BIFFes brings with it a bounteous bouquet promising enriching experience and engagement with cinema, in its 14th Edition. The film festival is organised by the Karnataka Chalanachitra Academy for the Karnataka Government and is overseen by the Karnataka Information and Public Relations Department. The 14th BIFFes will be held from March 23 to March 30, 2023. Bengaluru, is an important centre of international film culture in keeping with Karnataka's glorious heritage and the state's achievements in the fields of literature, fine arts, and cinema. We would like to recall here that our 13th edition of BIFFes was a resounding success with the screening of 200 films across 13 screens from more than 45 countries, witnessed by more than 8000 delegates who enjoyed and appreciated the quality of cinema presented
                </p>
            </div>

            <p className='m-0 text-white my-4'>Photos</p>
            <div className='row m-0 p-0 px-4'>
                {
                    photos.map((photo) => {
                        return (
                            <div className='col-lg-4 col-md-6 col-12 mb-4'>
                                <div>
                                    <img src={`../images/${photo}.png`} alt={photo} width={'100%'} height={'100%'} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <p className='m-0 text-white my-4'>Public Voting</p>

            <div className="accordion accordion-flush" id="accordionFlushExample">

                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed rounded border mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                            Best Film
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse mb-1 show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <div className='row m-0 p-0'>
                                {
                                    bestFilm.map((film) => {
                                        return (
                                            <div className='col-lg-3 col-md-4 col-12'>
                                                <div>
                                                    <img src={`../images/${film}.png`} alt={film} width={'100%'} height={'100%'} />
                                                </div>
                                                <button
                                                    className='border-0 text-white w-100 py-2 mt-2'
                                                    style={{ backgroundColor: 'red' }}
                                                >
                                                    Vote
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <p className='m-0 text-white my-4'>Competition</p>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed rounded border mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="true" aria-controls="flush-collapseTwo">
                            Asian Competition
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse mb-1 show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <div className='row m-0 p-0 px-4'>
                                <p className='text-warning m-0 mt-2 mb-3'>Winners</p>
                                {
                                    winnersMovie.map((film) => {
                                        return (
                                            <div className='col-lg-4 col-md-4 col-12'>
                                                <div>
                                                    <img src={`../images/${film}.png`} alt={film} width={'100%'} height={'100%'} />
                                                </div>
                                                <p className='m-0 mt-3 text-white font14'>
                                                    Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='row m-0 p-0 px-4'>
                                <p className='text-warning m-0 mt-3 mb-2'>Nominees</p>
                                {
                                    bestFilm.map((film) => {
                                        return (
                                            <div className='col-lg-3 col-md-4 col-12'>
                                                <div>
                                                    <img src={`../images/${film}.png`} alt={film} width={'100%'} height={'100%'} />
                                                </div>
                                                <p className='m-0 mt-3 text-white font14'>
                                                    Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed rounded border mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Indian Cinema Competition
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse mb-1" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                        <button className="accordion-button collapsed rounded border mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Kannada Cinema Competition
                        </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse mb-1" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                        <button className="accordion-button collapsed rounded border mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Curated Events
                        </button>
                    </h2>
                    <div id="flush-collapseFive" className="accordion-collapse collapse mb-1" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default FilmFestivalTicket
