import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { extractIdFromUrl } from '../../utils/jsfunctions';

const MasterClassVideoModal = (props: any) => {

    const { handleModalBox, data, dataId, getId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [uploadVideos, setUploadVideos] = useState({
        title: '',
        video_link: '',
        description: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleClassVideoChangeValue = (e: any) => {
        const { name, value } = e.target;
        setUploadVideos({ ...uploadVideos, [name]: value });
    };

    const handleUploadVideo = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const urlId = extractIdFromUrl(dataId);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { title, video_link, description } = uploadVideos;

        if (title === '' || video_link === '') {
            messageTxt('Please fill video title and video link field.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    title,
                    video_link,
                    description,
                    master_class_id: urlId,
                    id: getId
                }
                const res = await axios(`${baseURL}/api/master_class/add_video`, {
                    method: 'POST',
                    data: body,
                    headers: { 'Content-Type': "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setUploadVideos({
                    title: '',
                    video_link: '',
                    description: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    useEffect(() => {
        if (getId) {
            setUploadVideos({
                ...uploadVideos,
                title: data.title,
                video_link: data.video_link,
                description: data.description
            });
        }
    }, [data])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center mt-4 mb-3">
                        <div className='col-lg-6 col-md-6 col-12 mb-3'>
                            <label htmlFor="title" className="form-label text-white m-0">Title</label>
                            <input type="text" onChange={handleClassVideoChangeValue} name='title' value={uploadVideos.title} className="form-control" id="exampleFormControlInput1" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12 mb-3'>
                            <label htmlFor="video_link" className="form-label text-white m-0">Video link</label>
                            <input type="text" onChange={handleClassVideoChangeValue} name='video_link' value={uploadVideos.video_link} className="form-control" id="exampleFormControlInput1" />
                        </div>
                        <div
                            className='text-end'
                        >
                            <button
                                className='py-2 px-2 border-0 common_button rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleUploadVideo}
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default MasterClassVideoModal
