import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { deleteProject, getProjectDetails } from '../../utils/utils';
import AboutProject from '../../components/myprofileforms/AboutProject';
import AwardForm from '../../components/myprofileforms/AwardForm';
import MovieSnippets from '../../components/myprofileforms/MovieSnippets';
import SongsForm from '../../components/myprofileforms/SongsForm';
import TeaserTrailerLinks from '../../components/myprofileforms/TeaserTrailerLinks';
import CastForm from '../../components/myprofileforms/CastForm';
import CrewForm from '../../components/myprofileforms/CrewForm';
import MovieRefrenceLink from '../../components/myprofileforms/MovieRefrenceLink';
import { ToastContainer, toast } from 'react-toastify';
import { BsFillTrashFill } from 'react-icons/bs';
import MediaReviewForm from '../../components/myprofileforms/MediaReviewForm';

const ProjectDetail = () => {

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);

    const location = useLocation();
    const navigate = useNavigate();
    const [project_data, setProject_data] = useState<any>();
    const { project_id } = location.state;

    const getProjectDetail = async () => {
        const data = await getProjectDetails(project_id);
        setProject_data(data)
    }

    const handleDelete = async () => {
        const res = await deleteProject(project_id);
        messageTxt(res);
        navigate('/profile/myprofile')
    }

    useEffect(() => {
        getProjectDetail();
    }, [])

    useEffect(() => {
        if (!location.state && !location.state.project_id) {
            navigate('/profile/myprofile')
        }
    }, [])

    return (
        <>
            <div className='mt-5 d-flex justify-content-between align-items-center px-4'>
                <div className='text-white'>
                    <Link className='text-decoration-none text-white' to='/'>Home</Link>
                    <span className='mx-1'>/</span>
                    <Link className='text-decoration-none text-white' to='/profile/myprofile'>MyProfile</Link>
                    <span className='mx-1'>/</span>
                    <span className='pointer'>Project Detail</span>
                </div>
                <button
                    className='border-0 fs-4 bg-dark'
                    style={{
                        right: '10px',
                        top: '5px'
                    }}
                    onClick={handleDelete}
                >
                    <BsFillTrashFill className='text-white' />
                </button>
            </div>
            <AboutProject project_data={project_data} project_id={project_id} />
            <AwardForm project_data={project_data} project_id={project_id} />
            <MovieSnippets project_data={project_data} project_id={project_id} />
            <SongsForm project_data={project_data} project_id={project_id} />
            <TeaserTrailerLinks project_data={project_data} project_id={project_id} />
            <CastForm project_data={project_data} project_id={project_id} />
            <CrewForm project_data={project_data} project_id={project_id} />
            <MovieRefrenceLink project_data={project_data} project_id={project_id} />
            <MediaReviewForm project_data={project_data} project_id={project_id} />
        </>
    )
}

export default ProjectDetail
