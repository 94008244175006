import React, { useState, useEffect } from 'react';
import { BsGrid } from "react-icons/bs";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { FaListUl, FaLocationCrosshairs } from "react-icons/fa6";
import { BiSearchAlt } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';

import { getInstituteListAPI, totalPagesCalc } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import InstituteModal from '../../components/myprofilemodals/InstituteModal';

const FilmInstitute = () => {

  const navigate = useNavigate();
  const { pNumber } = useSelector((state: any) => state.pNumber);
  const [isActive, setActive] = useState(false);
  const [limit, setLimit] = useState<any>();
  const [activePage, setActivePage] = useState(pNumber);
  const [openAdd, setOpenAdd] = useState(false);
  const [totalLegend, setTotallegend] = useState(0);
  const [days_filter, setDays_filter] = useState('this_month');
  const [grid, setGrid] = useState(true);
  const [multiGrid, setmultiGrid] = useState(false);
  const [instituteList, setInstituteList] = useState<any>([]);

  const handleGrid = (val: any) => {
    if (val === 'boxgrid') {
      setGrid(true);
      setmultiGrid(false);
    } else if (val === 'multigrid') {
      setmultiGrid(true);
      setGrid(false);
    }
  }

  // get all institute list
  const getAllList = async () => {
    setActive(true);
    const result = await getInstituteListAPI(limit, activePage);
    setInstituteList(result.institute_list);
    setTotallegend(result.total)
    setLimit(result.limit)
    setActive(false);
  }

  const handleAddInstituteModal = () => {
    setOpenAdd(!openAdd);
  }

  useEffect(() => {
    getAllList();
  }, [])

  // useEffect(() => {
  //   console.log(instituteList);
  // }, [instituteList])

  return (
    <div className='my-5'>
      <LoadingOverlay
        active={isActive}
        spinner
        text='Loading your content...'
      >

        {openAdd &&
          <InstituteModal handleModalBox={handleAddInstituteModal} headingText={'Add New'} />
        }

        <div
          className='audition_call_filter_section border-bottom pb-4 d-lg-flex d-md-block d-block justify-content-between align-items-center ps-lg-5 ps-md-0 ps-0 mt-5'>
          <h5 className='m-0 text-warning fw-bold font26'>Film Training Institute</h5>
          <div className='d-lg-flex d-md-flex d-flex justify-content-lg-center justify-content-md-start justify-content-md-start align-items-center mt-lg-0 mt-md-3 mt-3'>
            {/* <div className='input_search_box'>
              <input type='text' placeholder='search' />
              <BiSearchAlt className='searchIcon' />
            </div>
            <div className='bg-white location p-1 d-flex align-items-center'>
              <FaLocationCrosshairs />
            </div> */}
          </div>
        </div>

        <div
          className='d-lg-flex d-md-flex d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-center my-4'
        >
          <div
            className=''
          >
            <span
              className='pointer'
              onClick={() => handleGrid('boxgrid')}
            >
              <BsGrid className={`${grid ? 'active' : 'text-white'} fw-bold font26`} />
            </span>
            <span
              className='ms-3 pointer'
              onClick={() => handleGrid('multigrid')}
            >
              <TfiLayoutGrid4Alt className={`${multiGrid ? 'active' : 'text-white'} fw-bold font26`} />
            </span>
          </div>
          
          <div className='ms-4'>
            <button className='font_weight_600 px-4 rounded-1 mt-lg-0 mt-md-2 mt-3' style={{ backgroundColor: 'red' }} onClick={handleAddInstituteModal}>
              Create Training Institute
            </button>
          </div>
        </div>

        {grid ?
          <div className='row'>
            {
              instituteList.map((val: any, i: number) => {
                return (
                  <div className='col-lg-4 col-md-6 col-12 mb-5 pointer' key={`${val.institute_name}${i}`}>
                    <div
                      className='audition_card_hover_effect p-3'
                    >
                      <Link to={`/menu/film-institute-details/${removeDotReplaceSpaceToHyphen(val.institute_name)}-${val.id}`} className='text-decoration-none'>
                        <div
                          style={{
                            height: '560px'
                          }}
                        >
                          <img src={`${baseURL}/${val?.image}`} alt={val.institute_name} className='object-fit-contain' width={'100%'} height={'100%'} />
                        </div>
                        <p
                          className='m-0 text-white mt-2 font_weight_600'
                        >
                          {val.institute_name}, {val.city}
                        </p>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
          : null
        }

        {multiGrid ?
          <div className='row'>
            {
              instituteList.map((val: any, i: number) => {
                return (
                  <div className='col-lg-3 col-md-4 col-12 mb-5 pointer' key={`${val.institute_name}${i}`}>
                    <div
                      className='audition_card_hover_effect p-3'
                    >
                      <Link to={`/menu/film-institute-details/${removeDotReplaceSpaceToHyphen(val.institute_name)}-${val.id}`} className='text-decoration-none'>
                        <div
                          style={{
                            height: '360px'
                          }}
                        >
                          <img src={`${baseURL}/${val?.image}`} alt={val.institute_name} className='object-fit-contain' width={'100%'} height={'100%'} />
                        </div>
                        <p
                          className='m-0 text-white mt-2 font_weight_600'
                        >
                          {val.institute_name}, {val.city}
                        </p>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
          : null
        }

        <div className='d-flex justify-content-center align-items-center py-5 my-lg-5 my-md-5'>
          <ul className='text-white d-flex justify-content-center align-items-center gap-lg-3 gap-md-3 gap-2 p-0 px-2 paginationUl'>
            {
              activePage !== 1 && <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage - 1)}>Previous</li>
            }
            {
              totalPagesCalc(totalLegend, limit).slice(Math.max(0, activePage - 3), Math.min(totalLegend, activePage + 4)).map((val) => {
                return <li className={`pageList py-1 px-2 ${val === activePage ? 'activePageList' : ''}`} onClick={() => setActivePage(val)} key={val}>{val}</li>
              })
            }
            {activePage !== Math.ceil(totalLegend / limit) ? <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage + 1)}>Next</li> : ''}
          </ul>
        </div>
      </LoadingOverlay>
    </div>
  )
}

export default FilmInstitute
