import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { ImCross } from 'react-icons/im';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import { getMovieRefListAPI, getProjectDetails } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Table } from 'react-bootstrap';
import { IoTrash } from 'react-icons/io5';
import ProjectMovieRefModal from '../myprofilemodals/ProjectMovieRefModal';

const MovieRefrenceLink = (props: any) => {

    const { project_data, project_id } = props;
    const messageTxt = (message: any) => toast(message);
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false)
    const movieRef = useRef<HTMLDivElement | null>(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [refList, setRefList] = useState<any>([]);
    const [movieReferenceLink, setmovieReferenceLink] = useState<any>(['']);

    const getList = async () => {
        setActive(true);
        const data = await getMovieRefListAPI(project_id);
        setRefList(data);
        setActive(false);
    }

    // const handleAdd = (e: any) => {
    //     e.preventDefault();
    //     const addMore = [...movieReferenceLink, '']
    //     setmovieReferenceLink(addMore);
    // }

    const handleChange = (e: any, i: any) => {
        const { value } = e.target;
        const inputData = [...movieReferenceLink];
        inputData[i] = value;
        setmovieReferenceLink(inputData);
    };

    const handleDelete = async (e: any, id: any) => {
        e.preventDefault()
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id
                }
                let result = await axios(`${baseURL}/api/project/destroy-links`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getList();
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleAddMovieRefrenceUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    links: JSON.stringify(movieReferenceLink)
                }
                let result = await axios(`${baseURL}/api/project/add-movieReferenceLinks`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleAddMovieLink = () => {
        setOpenAdd(!openAdd);
        getList();
    }

    useEffect(() => {
        const element = movieRef.current;

        const handleShow = () => {
            getList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log(refList);
    // }, [refList])

    return (
        <div className="accordion accordion-flush mt-4" id="movieRefference">
            <div className="accordion-item rounded">
                <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsemovieRefference" aria-expanded="false" aria-controls="flush-collapsemovieRefference">
                        Movie Reference links
                    </button>
                </h2>
                <div id="flush-collapsemovieRefference" ref={movieRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#movieRefference">
                    <div className="accordion-body accordian_border_with_color border-top-0 cast_form_detail theme_color">

                        <LoadingOverlay
                            active={isActive}
                            spinner
                            text='Loading your content...'
                        >

                            {openAdd &&
                                <ProjectMovieRefModal handleModalBox={handleAddMovieLink} project_id={project_id} headingText={'Add New'} />
                            }

                            <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                <button
                                    className='py-2 border-0 rounded fw-bold'
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        width: '100px'
                                    }}
                                    onClick={handleAddMovieLink}
                                >
                                    + Add
                                </button>
                            </div>

                            {Array.isArray(refList) && refList.length > 0 ?
                                <Table striped bordered hover responsive variant="dark">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Movie reference link</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(refList) && refList.length > 0 &&
                                            refList.map((val: any, i: any) => {
                                                return (
                                                    <tr
                                                        key={val.id}
                                                    >
                                                        <td>{i + 1}</td>
                                                        <td>{val.link}</td>
                                                        <td>
                                                            <div
                                                                className='font20 d-flex justify-content-between align-items-center'
                                                            >
                                                                {/* <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} /> */}
                                                                <IoTrash className='pointer' onClick={(e) => handleDelete(e, val.id)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                :
                                <p
                                    className="m-0 text-white text-center font_weight_600"
                                >
                                    No data found.
                                </p>
                            }
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MovieRefrenceLink
