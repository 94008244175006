import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllGenres } from '../../utils/utils';

const WrittenScriptModal = (props: any) => {

  const { handleModalBox, data, dataId, headingText } = props;

  LoadingOverlay.propTypes = undefined;
  const messageTxt = (message: any) => toast(message);
  const [show, setShow] = useState(true);
  const [isActive, setActive] = useState(false);
  const [scriptId, setScriptId] = useState<any>();
  const [genres, setGenres] = useState<any>();
  const [writtenScriptVal, setWrittenScriptVal] = useState({
    ip_registered: '',
    sharable: '',
    log_line: '',
    budget: '',
    id: '',
    title: '',
    genre_id: ''
  })

  const handlClosedModal = () => {
    handleModalBox();
    setShow(false);
  }

  const getGeneres = async () => {
    setActive(true);
    const data = await getAllGenres();
    setGenres(data);
    setActive(false);
  }

  const handleChangeWrittenScriptVal = (e: any) => {
    const { name, value } = e.target;
    setWrittenScriptVal({ ...writtenScriptVal, [name]: value });
  }

  const handleSubmitWrittenScript = async (e: any) => {
    e.preventDefault();
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');
    const { ip_registered, sharable, log_line, budget, genre_id, id, title } = writtenScriptVal;
    if (log_line === '' || title === '') {
      messageTxt('Please enter a log line and title !');
    } else if (connection_id && auth_code) {
      try {
        let body = {
          connection_id,
          auth_code,
          ip_registered,
          sharable,
          log_line,
          title,
          budget,
          genre_id,
          script_id: scriptId
        }
        let result = await axios(`${baseURL}/api/subscriber/scripts/addUpdate`, {
          method: 'POST',
          data: body,
          headers: { "Content-Type": "multipart/form-data" }
        });
        messageTxt(result.data.message);
        setActive(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getData = () => {
    setActive(true);
    if (data) {
      setScriptId(data.id);
      setWrittenScriptVal(data);
    }
    setActive(false);
  }

  useEffect(() => {
    getGeneres();
    getData();
  }, [data])

  return (
    <Modal
      show={show}
      onHide={handlClosedModal}
      backdrop="static"
      keyboard={false}
      centered={true}
      className={`opacity-75`}
      // fullscreen={true}
      size={`xl`}
    >
      <div
        className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
      >
        <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
        <button
          className=''
          onClick={handlClosedModal}
          style={{ backgroundColor: 'transparent' }}
        >
          <ImCross />
        </button>
      </div>
      <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
        <LoadingOverlay
          active={isActive}
          spinner
          text='Loading your content...'
        >
          <div className="row p-0 d-flex justify-content-between align-items-center modal_form_add_update">
            <div className='col-lg-6 col-md-6 col-12'>
              <label htmlFor="movieTitle" className="form-label text-white m-0">Title</label>
              <input type="text" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.title} name="title" placeholder='Enter movie name' className="form-control mb-3" />
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Log line ( 100 words )</label>
              <textarea
                className="form-control mb-3"
                style={{ border: '1px solid gray' }}
                id="exampleFormControlTextarea1"
                placeholder='Describe about movie in short term (100 words)'
                onChange={handleChangeWrittenScriptVal}
                name="log_line"
                value={writtenScriptVal.log_line}
              >
              </textarea>
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label htmlFor="movieTitle" className="form-label text-white m-0">Budget</label>
              <input type="text" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.budget} name="budget" className="form-control mb-3" placeholder="100 cr" />
            </div>
            <div className='col-lg-6 col-md-6 col-12 written_script_multi_select'>
              <label htmlFor="movieTitle" className="form-label text-white m-0">Genre</label>
              <select className="form-select mb-3" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.genre_id} name="genre_id" aria-label="Default select example">
                <option selected>Select genre...</option>
                {(Array.isArray(genres) && genres.length > 0) &&
                  genres.map((val: any) => {
                    return (
                      <option key={val.id} value={val.id}>{val.title}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label htmlFor="movieTitle" className="form-label text-white m-0">IP registered</label>
              <select className="form-select mb-3" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.ip_registered} name="ip_registered" aria-label="Default select example">
                <option selected>Select...</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <label htmlFor="movieTitle" className="form-label text-white m-0">Sharable</label>
              <select className="form-select mb-3" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.sharable} name="sharable" aria-label="Default select example">
                <option selected>Select...</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
              <button
                className='py-2 px-2 bg-danger border-0 rounded fw-bold'
                style={{
                  color: 'white',
                  width: '150px'
                }}
                onClick={handleSubmitWrittenScript}
              >
                Save
              </button>
            </div>
          </div>
        </LoadingOverlay>
      </div>
      <ToastContainer />
    </Modal>
  )
}

export default WrittenScriptModal
