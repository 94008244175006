import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { useSelector } from 'react-redux';
import { getBannerAPI, totalPagesCalc } from '../../utils/utils';
import RecommendedMovieCard from '../../components/ottcomponents/RecommendedMovieCard';
import { baseURL } from '../../utils/api';

const OttGenreMovies = () => {

    LoadingOverlay.propTypes = undefined;
    const params = useParams();
    const { id } = params;
    const { pNumber } = useSelector((state: any) => state.pNumber);
    const [isActive, setActive] = useState(false);
    const [activePage, setActivePage] = useState(pNumber);
    const [totalLegend, setTotallegend] = useState(0);
    const [limit, setLimit] = useState<any>([]);
    const [banner, setBanner] = useState<any>([]);

    const getMovies = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        setActive(false);
    }

    const sponsorBanner = async () => {
        setActive(true);
        const data = await getBannerAPI();
        setBanner(data);
        setActive(false);
    }

    const recommendedData = [
        { id: '1', title: 'Gandhadagudi', duration: '1hr:24mins', image: '../../images/ottrec1.png' },
        { id: '2', title: 'Weapons of Vedha', duration: '1hr:24mins', image: '../../images/ottrec2.png' },
        { id: '3', title: 'Aayushmanbhava', duration: '1hr:24mins', image: '../../images/ottrec3.png' },
        { id: '4', title: 'Gandhadagudi', duration: '1hr:24mins', image: '../../images/ottrec1.png' },
        { id: '5', title: 'Weapons of Vedha', duration: '1hr:24mins', image: '../../images/ottrec2.png' },
        { id: '6', title: 'Gandhadagudi', duration: '1hr:24mins', image: '../../images/ottrec1.png' },
        { id: '7', title: 'Weapons of Vedha', duration: '1hr:24mins', image: '../../images/ottrec2.png' },
        { id: '8', title: 'Aayushmanbhava', duration: '1hr:24mins', image: '../../images/ottrec3.png' },
        { id: '9', title: 'Gandhadagudi', duration: '1hr:24mins', image: '../../images/ottrec1.png' },
        { id: '10', title: 'Weapons of Vedha', duration: '1hr:24mins', image: '../../images/ottrec2.png' },
        { id: '11', title: 'Gandhadagudi', duration: '1hr:24mins', image: '../../images/ottrec1.png' },
        { id: '12', title: 'Weapons of Vedha', duration: '1hr:24mins', image: '../../images/ottrec2.png' },
    ];

    useEffect(() => {
        sponsorBanner();
    }, [])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <p
                className='m-0 text-warning font24 font_weight_600'
            >
                Action Movies
            </p>

            <div
                className='row'
            >
                {
                    recommendedData.map((val: any) => {
                        return (
                            <div
                                key={val.id}
                                className='col-lg-4 col-md-6 col-12 d-flex justify-content-center align-items-center'
                            >
                                <RecommendedMovieCard id={val?.id} title={val?.title} duration={val?.duration} image={val?.image} />
                            </div>
                        )
                    })
                }
            </div>

            <div className='d-flex justify-content-center align-items-center py-5 my-lg-5 my-md-5'>
                <ul className='text-white d-flex justify-content-center align-items-center gap-lg-3 gap-md-3 gap-2 p-0 px-2 paginationUl'>
                    {
                        activePage !== 1 && <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage - 1)}>Previous</li>
                    }
                    {
                        totalPagesCalc(totalLegend, limit).slice(Math.max(0, activePage - 3), Math.min(totalLegend, activePage + 4)).map((val) => {
                            return <li className={`pageList py-1 px-2 ${val === activePage ? 'activePageList' : ''}`} onClick={() => setActivePage(val)} key={val}>{val}</li>
                        })
                    }
                    {activePage !== Math.ceil(totalLegend / limit) ? <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage + 1)}>Next</li> : ''}
                </ul>
            </div>

            <div className='row my-5 mx-lg-5 mx-md-5 mx-2'>
                <div className='imageBox'>
                    <p className='text-white font14 m-0 mb-1'>
                        Advertisement
                    </p>
                    <img src={`${baseURL}/${banner}`} alt='sponsor' />
                </div>
            </div>

        </LoadingOverlay>
    )
}

export default OttGenreMovies
