import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import { getFaceShapAPI, getUserDetailsAPI } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';

const PhysicalStats = () => {

    const messageTxt = (message: any) => toast(message);
    const physicalStatsRef = useRef<HTMLDivElement | null>(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [activeSave, setActiveSave] = useState(false);
    const [isActive, setActive] = useState(false)
    const [face_profile_shape, setFaceShape] = useState<any>();
    const [profile_data, setProfile_data] = useState<any>({});
    const [physicalStats, setPhysicalStats] = useState({
        current_weight: '',
        height: '',
        shoulder_size: '',
        chest_size: '',
        hip_size: '',
        preffered_dressware: '',
        face_profile: '',
        foot_size: '',
        eye_color: '',
        hair_color: '',
        thigh_size: '',
        skin_color: '',
    });

    const getUserDetails = async () => {
        setActive(true);
        const data = await getUserDetailsAPI();
        setProfile_data(data);
        setActive(false);
    }

    const getFaceShape = async () => {
        setActive(true);
        const data = await getFaceShapAPI();
        setFaceShape(data);
        setActive(false);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setPhysicalStats({ ...physicalStats, [name]: value });
    }

    const handleEditPhysicalStats = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleUpdatePhysicalStats = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { current_weight, height, shoulder_size, chest_size, hip_size, preffered_dressware, face_profile, foot_size, eye_color, hair_color, thigh_size, skin_color } = physicalStats;
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    current_weight, height, shoulder_size, chest_size, hip_size, preffered_dressware, face_profile, foot_size, eye_color, hair_color, thigh_size, skin_color
                }
                setActiveSave(true);
                let result = await axios(`${baseURL}/api/subscriber/update-film_fertinity/personal_information`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt('Physical stats updated successfuly');
                setIsDisabled(!isDisabled);
                setActiveSave(false);
                setActive(false)
                // console.log(result);
            } catch (error) {
                console.log(error);
                setActiveSave(false);
                setActive(false);
            }
        }
    }

    useEffect(() => {
        setPhysicalStats({
            ...physicalStats,
            ...profile_data,
        });
    }, [profile_data]);

    // useEffect(()=>{
    //     console.log('physical',profile_data);
    // },[profile_data])

    useEffect(() => {
        const element = physicalStatsRef.current;

        const handleShow = () => {
            getUserDetails();
            getFaceShape();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    return (
        <>
            <div className='physical_stats_form mt-3'>
                <div className="accordion accordion-flush" id="physicalStats">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsephysicalStats" aria-expanded="false" aria-controls="flush-collapsephysicalStats">
                                Physical Stats
                            </button>
                        </h2>
                        <div id="flush-collapsephysicalStats" ref={physicalStatsRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#physicalStats">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded font_weight_600'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleEditPhysicalStats}
                                    >
                                        Edit
                                    </button>
                                </div>
                                <LoadingOverlay
                                    active={isActive}
                                    spinner
                                    text='Loading your content...'
                                >
                                    <form className='px-5 personal_info_form'>
                                        <div className="row p-0 mb-5 d-flex justify-content-between align-items-center">
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Height (in cm)</label>
                                                <input type="number" onChange={handleChange} value={physicalStats.height} name='height' className="form-control mb-3" placeholder='Enter your height' disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Current weight (in kg)</label>
                                                <input type="number" onChange={handleChange} value={physicalStats.current_weight} name='current_weight' className="form-control mb-3" placeholder='Enter your current weight' disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Shoulder size (in inches)</label>
                                                <input type="number" onChange={handleChange} value={physicalStats.shoulder_size} name='shoulder_size' className="form-control mb-3" placeholder='Enter your Shoulder size' disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Chest size (in inches)</label>
                                                <input type="number" onChange={handleChange} value={physicalStats.chest_size} name='chest_size' className="form-control mb-3" placeholder='Enter size' disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0"> Hip size (in inches)</label>
                                                <input type="number" onChange={handleChange} value={physicalStats.hip_size} name='hip_size' className="form-control mb-3" placeholder='Enter size' disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Preferred dressware</label>
                                                <select className="form-select mb-3" onChange={handleChange} value={physicalStats.preffered_dressware} name='preffered_dressware' aria-label="Default select example" disabled={isDisabled}>
                                                    <option selected>Select preferred dressware</option>
                                                    <option value="Traditional">Traditional</option>
                                                    <option value="Modern">Modern</option>
                                                    <option value="Model">Model</option>
                                                    <option value="Fashionable">Fashionable</option>
                                                    <option value="Glamour">Glamour</option>
                                                    <option value="Bold Dress">Bold Dress</option>
                                                    <option value="Bikni">Bikni</option>
                                                </select>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Face profile</label>
                                                <select className="form-select mb-3" onChange={handleChange} value={physicalStats.face_profile} name='face_profile' aria-label="Default select example" disabled={isDisabled}>
                                                    <option selected>Select face profile</option>
                                                    {Array.isArray(face_profile_shape) &&
                                                        face_profile_shape?.map((shape) => {
                                                            return (
                                                                <option value={shape.type}>{shape.type}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Foot size</label>
                                                <input type="text" onChange={handleChange} value={physicalStats.foot_size} name='foot_size' className="form-control mb-3" placeholder='Indo Western, Indo Western' disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Eye colour</label>
                                                <input type="text" onChange={handleChange} value={physicalStats.eye_color} name='eye_color' className="form-control mb-3" disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Hair colour</label>
                                                <input type="text" onChange={handleChange} value={physicalStats.hair_color} name='hair_color' className="form-control mb-3" disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Thigh Size (in inches)</label>
                                                <input type="number" onChange={handleChange} value={physicalStats.thigh_size} name='thigh_size' className="form-control mb-3" disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Skin colour</label>
                                                <input type="text" onChange={handleChange} value={physicalStats.skin_color} name='skin_color' className="form-control mb-3" disabled={isDisabled} />
                                            </div>
                                        </div>

                                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                            {activeSave ?
                                                <button
                                                    className='py-2 px-2 border-0 personal_save_button rounded font16 font_weight_600'
                                                    style={{
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        width: '150px'
                                                    }}
                                                >
                                                    Updating...
                                                </button>
                                                :
                                                <>
                                                    {!isDisabled ?
                                                        <button
                                                            className='py-2 px-2 border-0 personal_save_button rounded font16 font_weight_600'
                                                            style={{
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                                width: '150px'
                                                            }}
                                                            onClick={handleUpdatePhysicalStats}
                                                        >
                                                            Save
                                                        </button>
                                                        : null
                                                    }
                                                </>
                                            }
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>

                </div >
            </div >
            <ToastContainer />
        </>
    )
}

export default PhysicalStats
