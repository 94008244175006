import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllIndustryTypeAPI } from '../../utils/utils';
import { useSelector } from 'react-redux';

const MasterClassFormModal = (props: any) => {

    const { handleModalBox, data, dataId, kyc_document_list, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const profile_data = useSelector((state: any) => state.user.user);
    const [selectIndustry, setSelectIndustry] = useState<any>(profile_data.industries);
    const [industryList, setIndustryList] = useState<any>([]);
    const [idForVideo, setIdForVideo] = useState<any>('');
    const [masterClass, setMasterClass] = useState({
        master_class_name: '',
        class_type: '',
        description: '',
        industry_id: '',
        banner: '',
        cover: ''
    });
    const [upload_video, setUpload_video] = useState({
        title: '',
        video_link: '',
        description: ''
    });

    const getIndustryList = async () => {
        setActive(true);
        const response = await getAllIndustryTypeAPI();
        const data = response?.filter((val: any) => {
            return selectIndustry?.includes(val.id);
        })
        setIndustryList(data);
        setActive(false);
    }

    const handleMasterClassChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'banner') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setMasterClass((prevKyc: any) => ({ ...prevKyc, banner: document_image }));
        } else if (name === 'cover') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setMasterClass((prevKyc: any) => ({ ...prevKyc, cover: document_image }));
        } else {
            setMasterClass({ ...masterClass, [name]: value });
        }
    };

    const handleMasterClassVideoChangeValue = (e: any) => {
        const { name, value } = e.target;
        setUpload_video({ ...upload_video, [name]: value });
    };

    const handleEditMasterClass = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit.');
            setIdForVideo('');
            setMasterClass({
                master_class_name: '',
                class_type: '',
                description: '',
                industry_id: '',
                banner: '',
                cover: ''
            });
            setUpload_video({
                title: '',
                video_link: '',
                description: ''
            });
        } else {
            messageTxt("You can't edit.");
        }
        setIsDisabled(!isDisabled);
    }

    const handleSaveMasterClass = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { master_class_name, class_type, description, industry_id, banner, cover } = masterClass;

        if (master_class_name === '' || class_type === '' || description === '' || industry_id === '' || banner === '' || cover === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    master_class_name,
                    class_type,
                    description,
                    industry_id,
                    banner,
                    cover
                }
                const res = await axios(`${baseURL}/api/master_class/create`, {
                    method: 'POST',
                    data: body,
                    headers: { 'Content-Type': "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setIdForVideo(res.data.data.id);
                setIsDisabled(true);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handleSaveVideo = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { title, video_link, description } = upload_video;

        if (title === '' || video_link === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    title,
                    video_link,
                    description,
                    master_class_id: idForVideo
                }
                const res = await axios(`${baseURL}/api/master_class/add_video`, {
                    method: 'POST',
                    data: body,
                    headers: { 'Content-Type': "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setUpload_video({
                    title: '',
                    video_link: '',
                    description: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    useEffect(() => {
        getIndustryList();
    }, [])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <form className='px-4 master_class_form'>
                        <div className="row p-0 d-flex justify-content-between align-items-center mb-3">
                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                <label htmlFor="className" className="form-label text-white m-0">Class name</label>
                                <input type="text" onChange={handleMasterClassChangeValue} name='master_class_name' value={masterClass.master_class_name} className="form-control" id="exampleFormControlInput1" disabled={isDisabled} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                <label htmlFor="className" className="form-label text-white m-0">Class description</label>
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    disabled={isDisabled}
                                    onChange={handleMasterClassChangeValue}
                                    name='description'
                                    value={masterClass.description}
                                >
                                </textarea>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Select class type</label>
                                <select onChange={handleMasterClassChangeValue} name='class_type' value={masterClass.class_type} className="form-select" aria-label="Default select example" disabled={isDisabled}>
                                    <option selected>Select class type</option>
                                    <option value="1">Free</option>
                                    <option value="2">Paid</option>
                                </select>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Industry</label>
                                <select onChange={handleMasterClassChangeValue} name='industry_id' value={masterClass.industry_id} className="form-select" aria-label="Default select example" disabled={isDisabled}>
                                    <option selected>Select industry</option>
                                    {Array.isArray(industryList) &&
                                        industryList?.map((val: any) => {
                                            return (
                                                <option key={val.id} value={val.id}>{val.title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload banner image</label>
                                <input type="file" onChange={handleMasterClassChangeValue} name='banner' className="form-control" id="exampleFormControlInput1" accept="image/*" disabled={isDisabled} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload cover image</label>
                                <input type="file" onChange={handleMasterClassChangeValue} name='cover' className="form-control" id="exampleFormControlInput1" accept="image/*" disabled={isDisabled} />
                            </div>
                        </div>

                        {idForVideo && idForVideo !== '' ?
                            <div className="row p-0 d-flex justify-content-between align-items-center mt-4 mb-3">
                                <p
                                    className='m-0 font16 text-white mb-3'
                                >
                                    Upload video here.
                                </p>
                                <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                    <label htmlFor="title" className="form-label text-white m-0">Title</label>
                                    <input type="text" onChange={handleMasterClassVideoChangeValue} name='title' value={upload_video.title} className="form-control" id="exampleFormControlInput1" />
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                    <label htmlFor="video_link" className="form-label text-white m-0">Video link</label>
                                    <input type="text" onChange={handleMasterClassVideoChangeValue} name='video_link' value={upload_video.video_link} className="form-control" id="exampleFormControlInput1" />
                                </div>
                                <div>
                                    <button
                                        className='py-2 px-2 border-0 common_button rounded font_weight_600'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleSaveVideo}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                            :
                            null
                        }

                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                            <button
                                className='py-2 px-2 border me-3 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleEditMasterClass}
                            >
                                Edit
                            </button>

                            {!isDisabled ?
                                <button
                                    className='py-2 px-2 border-0 common_button rounded font_weight_600'
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        width: '150px'
                                    }}
                                    onClick={handleSaveMasterClass}
                                >
                                    Save
                                </button>
                                : null
                            }
                        </div>
                    </form>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default MasterClassFormModal
