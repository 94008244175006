import React from 'react';
import { Link } from 'react-router-dom';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import { baseURL } from '../../utils/api';

const ShopCard = (props: any) => {
    const { id, image, title, type, discountPrice, colors, price } = props;
    console.log('colors',colors);
    return (
        <Link to={`/menu/shop-product-details/${removeDotReplaceSpaceToHyphen(title)}-${id}`} className='text-white text-decoration-none'>
            <div>
                <img src={`${baseURL}/${image}`} alt={title} width={'100%'} height={'100%'} />
            </div>
            <p
                className='m-0 font16 tet-white mt-2 font_weight_600'
            >
                {title}
            </p>
            <div
                className='d-flex justify-content-between align-items-center font_weight_600'
            >
                <span
                    className='font16'
                >
                    {type}
                </span>
                <span
                    className='font20 text-warning'
                >
                    &#x20B9; {discountPrice}
                </span>
            </div>
            <div
                className='d-flex justify-content-between align-items-center font_weight_600'
            >
                <div
                    className='color_div_section d-flex'
                >
                    <div
                        className={`color_div`}
                        style={{ backgroundColor: `${colors}` }}
                    >
                    </div>
                </div>
                <span
                    className='font16 text-secondary text-decoration-line-through'
                >
                    &#x20B9; {price}
                </span>
            </div>
        </Link>
    )
}

export default ShopCard
