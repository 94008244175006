import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { ImCross } from 'react-icons/im';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { FiEdit } from 'react-icons/fi';
import { IoTrash } from 'react-icons/io5';
import { Table } from 'react-bootstrap';
import ProjectAwardModal from '../myprofilemodals/ProjectAwardModal';
import { getAwardListAPI } from '../../utils/utils';

const AwardForm = (props: any) => {

    const { project_data, project_id } = props;
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false)
    const messageTxt = (message: any) => toast(message);
    const awardRef = useRef<HTMLDivElement | null>(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [getId, setId] = useState<any>('');
    const [awardList, setAwardList] = useState<any>([]);
    const [award, setAward] = useState({ award_name: '', award_category: '', result: '' });

    const getList = async () => {
        setActive(true);
        const reviewList = await getAwardListAPI(project_id);
        setAwardList(reviewList);
        setActive(false);
    }

    // const handleAddCrew = (e: any) => {
    //     e.preventDefault();
    //     setAward([...award, { award_name: '', award_category: '', award_result: '' }]);
    // }

    // const handleChange = (e: any, i: any) => {
    //     const { name, value } = e.target;
    //     const updatedaward = [...award];
    //     if (typeof i === 'number' && i >= 0 && i < updatedaward.length) {
    //         updatedaward[i] = { ...updatedaward[i], [name]: value };
    //         setAward(updatedaward);
    //     } else {
    //         console.error('Invalid index provided for array update.');
    //     }
    // };

    const handleDeleteData = async (e: any, id: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id
                }
                let result = await axios(`${baseURL}/api/project/destroy-award`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getList();
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    // const handleAwardAdd = async (e: any) => {
    //     e.preventDefault();
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id: connection_id,
    //                 auth_code: auth_code,
    //                 project_id: project_id,
    //                 awards: JSON.stringify(award)
    //             }
    //             let result = await axios(`${baseURL}/api/project/add-awards`, {
    //                 method: 'POST',
    //                 data: body
    //             });
    //             messageTxt(result.data.message);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const takeAwardValuesForUpdate = (val: any) => {
        const data = val;
        setAward({
            ...award,
            award_name: data.award_name,
            award_category: data.award_category,
            result: data.result
        });
        setId(data.id);
        setOpenFormModal(!openFormModal);
    }

    const handleModalForm = () => {
        getList();
        setOpenFormModal(!openFormModal);
    }

    const handleAddAwardModal = () => {
        setOpenAdd(!openAdd);
        getList();
    }

    useEffect(() => {
        const element = awardRef.current;

        const handleShow = () => {
            getList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('asif', award);
    // }, [project_data, award])

    return (
        <>
            <div className="accordion accordion-flush mt-4" id="awardForm">
                <div className="accordion-item rounded">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseawardForm" aria-expanded="false" aria-controls="flush-collapseawardForm">
                            Awards
                        </button>
                    </h2>
                    <div id="flush-collapseawardForm" ref={awardRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#awardForm">
                        <div className="accordion-body accordian_border_with_color border-top-0 mb-5 awards_form theme_color">
                            <LoadingOverlay
                                active={isActive}
                                spinner
                                text='Loading your content...'
                            >

                                {openFormModal &&
                                    <ProjectAwardModal handleModalBox={handleModalForm} dataId={getId} data={award} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <ProjectAwardModal handleModalBox={handleAddAwardModal} project_id={project_id} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(awardList) && awardList.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Award name</th>
                                                <th>Award category</th>
                                                <th>Award result</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(awardList) && awardList.length > 0 &&
                                                awardList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.award_name}</td>
                                                            <td>{val.award_category}</td>
                                                            <td>{val.result}</td>
                                                            <td>
                                                                <div
                                                                    className='font20'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => takeAwardValuesForUpdate(val)} />
                                                                    <IoTrash className='pointer ms-4' onClick={(e) => handleDeleteData(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white text-center font_weight_600"
                                    >
                                        No data found.
                                    </p>
                                }

                                {/* {
                                    project_data?.award_details?.map((val: any, i: any) => {
                                        return (
                                            <div className="row mt-4 p-0 d-flex justify-content-between align-items-center" key={val.id}>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Award Name</label>
                                                    <input type='text' className="form-select mb-3" value={val.award_name} name='award_name' onChange={(e) => handleChange(e, i)} aria-label="Default select example" disabled />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Award Category</label>
                                                    <input type='text' className="form-select mb-3" value={val.award_category} name='award_category' onChange={(e) => handleChange(e, i)} aria-label="Default select example" disabled />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Award Result</label>
                                                    <input type='text' className="form-select mb-3" value={val.result} name='result' onChange={(e) => handleChange(e, i)} aria-label="Default select example" disabled />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <button
                                                        className='py-2 px-2 border-0'
                                                        style={{
                                                            backgroundColor: 'red',
                                                        }}
                                                        onClick={(e) => handleDeleteData(e, val.id)}
                                                    >
                                                        <ImCross className='text-white' />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                } */}

                            </LoadingOverlay>
                        </div>

                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default AwardForm
