import React, { useState, useEffect } from 'react';
import './shopcss.css';
import { Modal } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ImCross } from 'react-icons/im';
import { BiSolidDiscount } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import { addShippingAddressEcomAPI, getAllUserAddressEcomAPI } from '../../utils/utils';

const UserAddressModal = (props: any) => {

    const { handleModalBox, items, total } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [all_address, setAllAddress] = useState<any>([]);
    const [exist_address, setExist_address] = useState('');
    const [userAddress, setUserAddress] = useState({
        first_name: '',
        last_name: '',
        email: '',
        landmark: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        street_address: ''
    });

    const getAddress = async () => {
        setActive(true);
        const data = await getAllUserAddressEcomAPI();
        setAllAddress(data);
        setActive(false);
    }

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'exist_address') {
            setExist_address(value)
        } else {
            setUserAddress({ ...userAddress, [name]: value });
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const { first_name, last_name, email, landmark, address, city, state, zip_code, street_address } = userAddress;
        if (first_name === '' || last_name === '' || landmark === '' || address === '' || city === '' || state === '' || zip_code === '' || street_address === '') {
            messageTxt('Please fill all mandatory fields.');
            setActive(false);
        } else {
            try {
                const data = await addShippingAddressEcomAPI(userAddress);
                messageTxt(data);
                setUserAddress({
                    first_name: '',
                    last_name: '',
                    email: '',
                    landmark: '',
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    street_address: ''
                });
                handleModalBox(exist_address);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }

    }

    useEffect(() => {
        getAddress();
    }, [])

    // useEffect(() => {
    //     console.log(all_address)
    // }, [all_address])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div
                className='bg-dark pb-lg-5 pb-md-5 pb-3 p-lg-4 p-md-4 p-1'
            >
                <div
                    className='d-flex justify-content-between align-items-center'
                >
                    <p
                        className='m-0 text-warning font_weight_600 font20 pointer'
                        onClick={()=>handleModalBox(exist_address)}
                    >
                        Back
                    </p>
                    <div
                        style={{
                            height: '50px',
                            width: '200px',
                        }}
                    >
                        <img src='../../images/logo.png' alt='logo' width={'100%'} height={'100%'} />
                    </div>
                    <div>
                    </div>
                </div>
                <p
                    className='m-0 text-white font16 text-center mt-4'
                >
                    10% Off On Order Above 1000/-
                </p>
                <div
                    className='text-warning d-flex justify-content-between align-items-center mt-5 pb-lg-3 pb-md-3 pb-2'
                >
                    <p
                        className='m-0 font_weight_600 font16'
                    >
                        Order ({items} ITEMS)
                    </p>
                    <p
                        className='m-0 font_weight_600 font16'
                    >
                        &#x20B9; {total}
                    </p>
                </div>
                <select className="form-select my-4 bg-transparent text-white" aria-label="Large select example">
                    <option selected>Add coupon</option>
                    {/* {Array.isArray(filmTypeList) &&
                        filmTypeList?.map((val: any) => {
                            return (
                                <option key={val.id} value={val.title}>{val.title}</option>
                            )
                        })
                    } */}
                </select>
                <p
                    className='m-0 text-warning font_weight_600 font18 mt-5 mb-2'
                >
                    Select existance address
                </p>
                <select className="form-select mb-5 bg-transparent text-white" name='exist_address' onChange={handleChangeValue} aria-label="Large select example">
                    <option selected>Select shipping address</option>
                    {Array.isArray(all_address) &&
                        all_address?.map((val: any) => {
                            return (
                                <option key={val.id} value={val.id}>{val.address}, {val.street}, {val.landmark}, {val.city}, {val.zip_code}, {val.state}</option>
                            )
                        })
                    }
                </select>
                <p
                    className='m-0 text-warning font_weight_600 font18 mb-3'
                >
                    Add new shipping address
                </p>
                <div
                    className='userAddressModal_input'
                >
                    <div className='mb-3'>
                        <label htmlFor="pincode" className="form-label text-white m-0">* Pincode</label>
                        <input type="number" name='zip_code' onChange={handleChangeValue} value={userAddress.zip_code} className="form-control mb-3" id="pincode" />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="First name" className="form-label text-white m-0">* First name</label>
                        <input type="text" name='first_name' onChange={handleChangeValue} value={userAddress.first_name} className="form-control mb-3" id="First name" />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="Last name" className="form-label text-white m-0">* Last name</label>
                        <input type="text" name='last_name' onChange={handleChangeValue} value={userAddress.last_name} className="form-control mb-3" id="Last name" />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor=" Flat, house number, floor, building" className="form-label text-white m-0">* Flat, house number, floor, building</label>
                        <input type="text" name='address' onChange={handleChangeValue} value={userAddress.address} className="form-control mb-3" id=" Flat, house number, floor, building" />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="Area, street, sector, village" className="form-label text-white m-0">* Area, street, sector, village</label>
                        <input type="text" name='street_address' onChange={handleChangeValue} value={userAddress.street_address} className="form-control mb-3" id="Area, street, sector, village" />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="Landmark" className="form-label text-white m-0">* Landmark</label>
                        <input type="text" name='landmark' onChange={handleChangeValue} value={userAddress.landmark} className="form-control mb-3" id="Landmark" />
                    </div>
                    <div
                        className='row'
                    >
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="City" className="form-label text-white m-0">* City</label>
                            <input type="text" name='city' onChange={handleChangeValue} value={userAddress.city} className="form-control mb-3" id="City" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="State" className="form-label text-white m-0">* State</label>
                            <input type="text" name='state' onChange={handleChangeValue} value={userAddress.state} className="form-control mb-3" id="State" />
                        </div>
                    </div>
                    <div className=''>
                        <label htmlFor="email" className="form-label text-white m-0">E-mail (Optional)</label>
                        <input type="email" name='email' onChange={handleChangeValue} value={userAddress.email} className="form-control mb-2" id="email" />
                    </div>
                    <p
                        className='m-0 text-white font_weight_600 font14 mb-3'
                    >
                        Order delivery details will be sent here
                    </p>
                </div>
                <div
                    className='address_modal_box_button mt-5'
                >
                    <button
                        className='add_address font_weight_600 py-3 me-lg-5 me-md-5 me-0 w-100'
                        onClick={handleSubmit}
                    >
                        Add address
                    </button>
                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default UserAddressModal
