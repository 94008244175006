import React, { useEffect, useState, useRef } from 'react';
import { LuSendHorizonal } from "react-icons/lu";
import { BsGrid } from "react-icons/bs";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { FaListUl } from "react-icons/fa6";
import { baseURL } from '../../utils/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getBirthdays, sendBirthdayWishes } from '../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import WishModal from '../../components/myprofilemodals/WishModal';

const Birthdays = () => {

  LoadingOverlay.propTypes = undefined;
  const messageTxt = (message: any) => toast(message);
  const [isActive, setActive] = useState(false);
  const message = useRef<HTMLInputElement>(null);
  const [grid, setGrid] = useState(true);
  const [multiGrid, setmultiGrid] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [wish_data, setWish_data] = useState<any>();
  const [starBirthdays, setBirthdays] = useState<any>([]);

  const getAllBirthdays = async () => {
    setActive(true);
    const result = await getBirthdays();
    setBirthdays(result);
    setActive(false);
  }

  const sendMessage = async (val: any) => {
    setWish_data(val);
    setOpenAdd(!openAdd);
  }

  const handleGrid = (val: any) => {
    if (val === 'boxgrid') {
      setGrid(true);
      setmultiGrid(false);
    } else if (val === 'multigrid') {
      setmultiGrid(true);
      setGrid(false);
    }
  }

  const openWishModal = () => {
    setOpenAdd(!openAdd);
  }

  useEffect(() => {
    getAllBirthdays();
  }, [])

  // useEffect(() => {
  //   console.log('bi', starBirthdays);
  // }, [starBirthdays])

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Loading your content...'
    >
      <div className='my-5'>
        {openAdd &&
          <WishModal handleModalBox={openWishModal} data={wish_data} headingText={`Send Wishes to`} />
        }
        <div className='row'>
          <div
            className='col-lg-10 col-md-10 col-12'
          >
            <h5 className='m-0 text-warning font26 fw-bold'>Birthdays</h5>
          </div>
          {/* <div
            className='col-lg-2 col-md-2 col-12 d-flex justify-content-center align-items-center'
          >
            <span
              className='pointer'
              onClick={() => handleGrid('boxgrid')}
            >
              <BsGrid className={`${grid ? 'active' : 'text-white'} fw-bold font26`} />
            </span>
            <span
              className='pointer'
              onClick={() => handleGrid('multigrid')}
            >
              <TfiLayoutGrid4Alt className={`${multiGrid ? 'active' : 'text-white'} fw-bold font26 ms-3`} />
            </span>
          </div> */}
        </div>

        {Array.isArray(starBirthdays?.yesterday) && starBirthdays?.yesterday.length > 0 ?
          <p className='text-white fw-bold font18 ps-2 mt-3'>Yesterday</p>
          : null
        }
        {grid ?
          <div className='row m-0 p-0'>
            {Array.isArray(starBirthdays?.yesterday) &&
              starBirthdays?.yesterday.map((val: any) => {
                return (
                  <div className='col-lg-2 col-md-3 col-12 birthday_section' key={val.id}>
                    <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.id}`} className='text-decoration-none'>
                      <div className='position-relative'>
                        <div
                          style={{
                            maxWidth: '100%',
                            height: '100%'
                          }}
                        >
                          {val.avatar ?
                            <img src={`${baseURL}/${val.avatar}`} alt={val.name} width={'100%'} height={'100%'} />
                            :
                            <img src={`../images/noimagefound.png`} alt={val.name} width={'100%'} height={'100%'} />
                          }
                          {/* <LuSendHorizonal
                            className='position-absolute text-dark'
                            style={{ bottom: '10%', right: '10px' }}
                            onClick={() => sendMessage(val.id)}
                          /> */}
                        </div>
                      </div>
                    </Link>
                    <button
                      className='rounded bg-transparent border my-3'
                      onClick={() => sendMessage(val)}
                    >
                      Send wishes <LuSendHorizonal className='text-white' />
                    </button>
                  </div>
                )
              })
            }
          </div>
          : null
        }

        {Array.isArray(starBirthdays?.today) && starBirthdays?.today.length > 0 ?
          <p className='text-white fw-bold font18 ps-2 mt-4'>Today</p>
          : null
        }
        <div className='row m-0 p-0'>
          {Array.isArray(starBirthdays?.today) &&
            starBirthdays?.today.map((val: any) => {
              return (
                <div className='col-lg-2 col-md-2 col-12 birthday_section' key={val.id}>
                  <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.id}`} className='text-decoration-none'>
                    <div className='position-relative'>
                      <div
                        style={{
                          maxWidth: '100%',
                          height: '100%'
                        }}
                      >
                        {val.avatar ?
                          <img src={`${baseURL}/${val.avatar}`} alt={val.name} width={'100%'} height={'100%'} />
                          :
                          <img src={`../images/noimagefound.png`} alt={val.name} width={'100%'} height={'100%'} />
                        }
                        {/* <LuSendHorizonal
                          className='position-absolute text-white'
                          style={{ bottom: '10%', right: '10px' }}
                          onClick={() => sendMessage(val.id)}
                        /> */}
                      </div>
                    </div>
                  </Link>
                  <button
                    className='rounded bg-transparent border my-3'
                    onClick={() => sendMessage(val.id)}
                  >
                    Send wishes <LuSendHorizonal className='text-white' />
                  </button>
                  {/* <div className='my-0 mb-2'>
                    <input type='text' ref={message} placeholder='Send your wishes' />
                  </div> */}
                </div>
              )
            })
          }
        </div>

        {Array.isArray(starBirthdays?.this_week) && starBirthdays?.this_week.length > 0 ?
          <p className='text-white fw-bold font18 ps-2 mt-4'>This Week</p>
          : null
        }
        <div className='row m-0 p-0'>
          {Array.isArray(starBirthdays?.this_week) &&
            starBirthdays?.this_week.map((val: any) => {
              return (
                <div className='col-lg-2 col-md-2 col-12 mb-3 birthday_section' key={val.id}>
                  <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.id}`} className='text-decoration-none'>
                    <div
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%'
                      }}
                    >
                      {val.avatar ?
                        <img src={`${baseURL}/${val.avatar}`} alt={val.name} width={'100%'} height={'100%'} />
                        :
                        <img src={`../images/noimagefound.png`} alt={val.name} width={'100%'} height={'100%'} />
                      }
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </div>

        {Array.isArray(starBirthdays?.this_week) && starBirthdays?.this_week.length > 0 ?
          <p className='text-white fw-bold font18 ps-2 mt-4'>This Month</p>
          : null
        }
        <div className='row m-0 p-0'>
          {Array.isArray(starBirthdays?.this_month) &&
            starBirthdays?.this_month.map((val: any) => {
              return (
                <div className='col-lg-2 col-md-2 col-12 mb-3 birthday_section' key={val.id}>
                  <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.name)}-${val.id}`} className='text-decoration-none'>
                    <div
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%'
                      }}
                    >
                      {val.avatar ?
                        <img src={`${baseURL}/${val.avatar}`} alt={val.name} width={'100%'} height={'100%'} />
                        :
                        <img src={`../images/noimagefound.png`} alt={val.name} width={'100%'} height={'100%'} />
                      }
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </div>

      </div>
      <ToastContainer />
    </LoadingOverlay>
  )
}

export default Birthdays
