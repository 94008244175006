import React, { useEffect, useRef } from 'react';
import Slider from "react-slick";
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import HomeSynopsysCard from './HomeSynopsysCard';

const HomeSynopsisSlider = (props: any) => {
    const { data } = props;

    let sliderRef = useRef<any>(null);
    const next = () => {
        sliderRef.current?.slickNext();
    };
    const previous = () => {
        sliderRef.current?.slickPrev();
    };

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 6,
                    infinite: data.length > 7,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 6,
                    infinite: data.length > 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 6,
                    infinite: data.length > 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    infinite: data.length > 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    infinite: data.length > 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    infinite: data.length > 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    infinite: data.length > 1,
                },
            },
        ],
    };

    return (
        <div className='row'>
            <div
                className='col-lg-10 col-md-10 col-12'
            >
                <Slider {...settings} ref={sliderRef} className='home_synopsis_slider_style'>
                    {
                        data?.map((e: any, i: any) => {
                            return (
                                <div key={e?.id} className='d-flex justify-content-center'>
                                    <HomeSynopsysCard cover={e.img_path} title={e.name} />
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
            <div
                className='col-lg-2 col-md-2 col-12 mt-lg-0 mt-md-0 mt-3 d-flex justify-content-center'
            >
                {data.length !== 0 ?
                    <div className='text-white d-flex justify-content-center align-items-center mt-2'>
                        <div className='border rounded-pill p-1 d-flex align-items-center justify-content-center' onClick={previous}>
                            <PiCaretLeftBold />
                        </div>
                        <div className='border mx-4 rounded-pill p-1 d-flex align-items-center justify-content-center' onClick={next}>
                            <PiCaretRightBold />
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default HomeSynopsisSlider
