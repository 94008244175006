import React from 'react'
import './homesynopsiscss.css';
import { baseURL } from '../../utils/api'

const HomeSynopsysCard = (props: any) => {
    const { cover, title } = props;
    return (
        <div className='home_s_img_box position-relative'>
            <img src={`${baseURL}/${cover}`} className='object-fit-contain' alt={title} />
            <div 
                className='font12 text-white position-absolute w-100 ps-1'
                style={{
                    bottom: '0px',
                    left: '0px',
                    backgroundColor: 'rgba(0, 0, 0,0.3)'
                }}
            >
                <p className='m-0'>{title}</p>
            </div>
        </div>
    )
}

export default HomeSynopsysCard
