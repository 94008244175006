import React from 'react';
import './profilesliderstyle.css';
import Slider from "react-slick";
import OnlyImages from '../moviecard/OnlyImages';
import ProfileImageCard from './ProfileImageCard';
import ShowReelsCard from './ShowReelsCard';

const ProfileSlider = (props: any) => {

    const { data, showReel } = props;
    // console.log(data);

    const calculateSlidesToShow = () => {
        if (data) {
            const length = data.length;
            if (length > 1 && length <= 3) return length;
            if (length > 3) return 3;  // Change this to 2 or 3 based on your preference
            return 1;
        }
        return 1; // Default value
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: calculateSlidesToShow(),
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    infinite: data?.length > 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    infinite: data?.length > 1,
                },
            },
        ],
    };

    const renderProfileImageCard = (val: any, idx: any) => (
        <div key={idx} className='d-flex justify-content-center'>
            <ProfileImageCard image={val.path} />
        </div>
    );

    const renderShowReelsCard = (val: any, idx: any) => (
        <div key={idx} className='d-flex justify-content-center'>
            <ShowReelsCard image={val.path} />
        </div>
    );

    return (
        <>
            {
                showReel ?
                    <Slider {...settings}>
                        {data?.map(renderShowReelsCard)}
                    </Slider>
                    :
                    <Slider {...settings} className='profile_card_slider'>
                        {data?.map(renderProfileImageCard)}
                    </Slider>
            }
        </>
    )
}

export default ProfileSlider
