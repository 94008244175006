import React, { useState, useEffect, useRef } from 'react';
import '../signinmodal/Signinmodal.css';
import './newscss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { IoMdStar } from 'react-icons/io';
import { FaRegStar } from 'react-icons/fa';
import { BiLike } from 'react-icons/bi';

const MovieReviewModal = (props: any) => {

    const { id, handleModalBox } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const myMasterClassRef = useRef<HTMLTextAreaElement | null>(null);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [rate, setRate] = useState<any>();
    const [toggle, setToggle] = useState<any>();

    const reviewData = { id: 1, title: 'Kho Gaye Hum Kahan', image: '../images/mnews1.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' };

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`lg`}
        >
            <div
                className='row p-2'
            >
                <div
                    className='col-lg-5 col-md-12 col-12'
                >
                    <div
                        className='d-flex d-lg-flex d-xl-none justify-content-between align-items-center'
                    >
                        <p
                            className='m-0 text-white text-decoration-underline font18 font_weight_600'
                        >
                            {reviewData.title}
                        </p>
                        <button
                            onClick={handlClosedModal}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <ImCross />
                        </button>
                    </div>
                    <div>
                        <img src={`${reviewData.image}`} alt={reviewData.title} width={'100%'} height={'100%'} />
                    </div>
                    <p
                        className='m-0 text-white font14 text-capitalize mt-2'
                    >
                        {reviewData.genre}
                    </p>
                    <p
                        className='m-0 text-white font14 text-capitalize'
                    >
                        Release Date: 05/01/2024
                    </p>
                </div>
                <div
                    className='col-lg-7 col-md-12 col-12 p-0 pe-lg-3 px-lg-0 px-md-3 px-3'
                >
                    <div
                        className='d-none d-lg-block d-xl-flex justify-content-between align-items-center'
                    >
                        <p
                            className='m-0 text-white text-decoration-underline font18 font_weight_600'
                        >
                            {reviewData.title}
                        </p>
                        <button
                            onClick={handlClosedModal}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <ImCross />
                        </button>
                    </div>
                    <div
                        className=''
                    >
                        <span
                            className={`font14 text-warning text-uppercase me-1`}
                        >
                            CDBS RATING
                        </span>
                        <span
                            className='font20 text-warning'
                        >
                            {
                                [...Array(5)].map((star, index) => {
                                    const givenRating = index + 1;
                                    return (
                                        <IoMdStar
                                            className='pointer'
                                            key={index}
                                            style={{
                                                color: (givenRating <= reviewData.reviewCount || givenRating === reviewData.reviewCount) ? 'yellow' : '#D9D9D9'
                                            }}
                                        />
                                    )
                                })
                            }
                        </span>
                        <span className='text-white font14 ms-2'>{reviewData.reviewCount}/5</span>
                    </div>
                    <p
                        className='m-0 text-warning font14 font_weight_600 mt-2'
                    >
                        Write your review here
                    </p>
                    <textarea className='review_textarea' ref={myMasterClassRef} ></textarea>
                    <div
                        className='d-flex justify-content-between align-items-center'
                    >
                        <div
                            className='lh-1'
                        >
                            <span
                                className={`font14 font_weight_600 text-warning text-uppercase me-1`}
                            >
                                Rate now
                            </span>
                            <span
                                className='font20 text-white ms-2'
                            >
                                {
                                    [...Array(5)].map((star, index) => {
                                        const givenRating = index + 1;
                                        return (
                                            <FaRegStar
                                                className='me-1 pointer'
                                                // onClick={(e) => {
                                                //     giveRatingToMovie(e, givenRating)
                                                // }}
                                                key={id}
                                                style={{
                                                    color: givenRating < rate || givenRating === rate ? '#FFC107' : '#D9D9D9'
                                                }}
                                            />
                                        )
                                    })
                                }
                            </span>
                        </div>
                        <div>
                            <span
                                className='text-warning font14 font_weight_600 me-2'
                            >
                                LIKE
                            </span>
                            <span
                                className='font24 text-white'
                            >
                                <BiLike
                                    className={`pointer ${toggle ? 'text-primary' : ''}`}
                                // onClick={(e: any) => giveLike(e, 'like', id)}
                                />
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    className='text-end pe-3 my-2'
                >
                    <button
                        className='font_weight_600 rounded px-3 edit_review_button me-3'
                    >
                        Edit Review
                    </button>
                    <button
                        className='font_weight_600 rounded submit_review_button'
                    >
                        Submit Review
                    </button>
                </div>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default MovieReviewModal
