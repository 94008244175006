import React, { useEffect, useState } from 'react';
import { getAllRecentlyAddedAPI, totalPagesCalc } from '../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { pageNumber } from '../states/reducers';
import { baseURL } from '../utils/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Link } from 'react-router-dom';
import { removeDotReplaceSpaceToHyphen } from '../utils/jsfunctions';

const AllMovies = () => {
    const { pNumber } = useSelector((state: any) => state.pNumber);
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false);
    const dispatch = useDispatch();
    const [limit, setLimit] = useState<any>([]);
    const [activePage, setActivePage] = useState(pNumber);
    const [totalMovies, setTotalmovies] = useState(0);
    const [movies, setMovies] = useState<any>([]);

    const getLivingLegends = async () => {
        setActive(true);
        const result = await getAllRecentlyAddedAPI(activePage);
        setMovies(result.movie_list);
        setTotalmovies(result.totalMovies)
        setLimit(result.limit)
        setActive(false);
    }

    useEffect(() => {
        getLivingLegends();
        window.scroll(0, 0);
        dispatch(pageNumber(activePage))
    }, [activePage])

    useEffect(()=>{
        console.log('all',movies);
    },[movies])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='mt-5 px-lg-5 px-md-5 px-2 mx-lg-3 mx-md-3 mx-0'>
                <div className='legends_header_section d-flex justify-content-between align-items-center'>
                    <p className='m-0 text-warning font22 fw-bold ps-lg-5 ps-md-5 ps-2'>Newly Added Movies</p>
                </div>

                <div className='row g-lg-3 g-md-4 m-0 p-0 mt-4'>
                    {Array.isArray(movies) &&
                        movies?.map((val: any, id: any) => {
                            return (
                                <div className='col-lg-2 col-md-2 col-12 mb-3 pointer d-flex justify-content-center' key={val?.id}>
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' >
                                        <div
                                            style={{
                                                height: '360px',
                                                width: '240px'
                                            }}
                                        >
                                            <img src={`${baseURL}/${val?.cover}`} alt={val.title} className='object-fit-contain rounded-4' width={'100%'} height={'100%'} />
                                        </div>
                                        <p
                                            className='m-0 text-white mt-2 font14'
                                        >
                                            {val?.genre}
                                        </p>
                                        <p
                                            className='m-0 text-white mt-2 font16 font_weight_600'
                                        >
                                            {val?.title}
                                        </p>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='d-flex justify-content-center align-items-center py-5 my-lg-5 my-md-5'>
                    <ul className='text-white d-flex justify-content-center align-items-center gap-lg-3 gap-md-3 gap-2 p-0 px-2 paginationUl'>
                        {
                            activePage !== 1 && <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage - 1)}>Previous</li>
                        }
                        {
                            totalPagesCalc(totalMovies, limit).slice(Math.max(0, activePage - 3), Math.min(totalMovies, activePage + 4)).map((val) => {
                                return <li className={`pageList py-1 px-2 ${val === activePage ? 'activePageList' : ''}`} onClick={() => setActivePage(val)} key={val}>{val}</li>
                            })
                        }
                        {activePage !== Math.ceil(totalMovies / limit) ? <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage + 1)}>Next</li> : ''}
                    </ul>
                </div>

            </div>
        </LoadingOverlay>
    )
}

export default AllMovies
