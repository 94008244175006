import React from 'react';
import { baseURL } from '../../utils/api';
import { Link } from 'react-router-dom';
import VideoPlayer from '../videoplayer/VideoPlayer';

const MovieVideo = (props: any) => {
    const { id, cover, title, trailer_links } = props;
    return (
        <>

            {trailer_links ?
                <VideoPlayer videoUrl={trailer_links} height={'400px'} />
                :
                <div
                    className=''
                >
                    <img src='../images/youtubenotavailable.png' alt={`youtubenotavailable`} width={'100%'} height={'100%'} />
                </div>
            }


            {/* // <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${id}`}>
        //     <div
        //         className='position-relative'
        //         style={{
        //             height: '100%',
        //             width: '100%'
        //         }}
        //     >
        //         <img src={`${baseURL}/${cover}`} alt={`${title}`} width={'100%'} height={'100%'} />
        //         <div 
        //             className='position-absolute'
        //             style={{
        //                 top: '0',
        //                 left: '0',
        //                 width: '100%',
        //                 height: '100%',
        //                 background: 'rgba(0, 0, 0, 0.5)',
        //                 display: 'flex',
        //                 justifyContent: 'center',
        //                 alignItems: 'center'
        //             }}
        //         >
        //         </div>
        //         <div
        //             className='position-absolute py-2 d-flex justify-content-center align-items-center'
        //             style={{
        //                 backgroundColor: 'rgba(255, 255, 255, 0.5)',
        //                 zIndex: '999',
        //                 top: '40%',
        //                 left: '50%',
        //             }}
        //         >
        //             <img src='../images/playbutton.png' alt={`playbutton`} width={'50%'} height={'50%'} />
        //         </div>
        //     </div>
        // </Link> */}
        </>
    )
}

export default MovieVideo
