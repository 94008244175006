import React, { useRef } from 'react'
import Slider from 'react-slick';
import NewsNewlyAddedCard from './NewsNewlyAddedCard';

const NewsNewlyAddedSlider = (props: any) => {
    const { data } = props;
    let sliderRef = useRef<any>(null);

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,

                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,

                },
            },
        ],
    };

    return (
        <Slider {...settings} ref={sliderRef} className='movie_card_slider'>
            {
                data?.map((e: any, i: number) => {
                    return (
                        <div key={i} className='d-grid justify-content-center'>
                            <NewsNewlyAddedCard
                                id={e?.id}
                                cover={e?.cover}
                                title={e?.title}
                                genres={e?.genre}
                                dob={e?.date}
                                watch={e?.watch}
                                like={e?.like}
                                cdbs_rating={e?.cdbs_rating}
                                rating={e?.rating}
                            />
                        </div>
                    );
                })
            }
        </Slider>
    )
}

export default NewsNewlyAddedSlider
