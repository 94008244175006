import React, { useEffect, useRef, useState } from 'react';
import './menu.css';
import { BsGrid } from "react-icons/bs";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { FaListUl, FaLocationCrosshairs } from "react-icons/fa6";
import { BiSearchAlt } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { getAuditionCallListAPI, totalPagesCalc } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';
import { baseURL } from '../../utils/api';
import AuditionCallFormModal from '../../components/auditionCallForm/AuditionCallFormModal';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const AuditionCall = () => {

  const { pNumber } = useSelector((state: any) => state.pNumber);
  const [isActive, setActive] = useState(false);
  const [limit, setLimit] = useState<any>();
  const [activePage, setActivePage] = useState(pNumber);
  const [totalLegend, setTotallegend] = useState(0);
  const [days_filter, setDays_filter] = useState('this_month');
  const [grid, setGrid] = useState(true);
  const [multiGrid, setmultiGrid] = useState(false);
  const [auditionCall, setAuditionCall] = useState<any>([]);
  const [openForm, setOpenForm] = useState(false);
  const [searchByLocation, setSearchByLocation] = useState<any>({
    textSearch: ''
  });

  const handleSearchText = (e: any) => {
    const { name, value } = e.target;
    setSearchByLocation({ ...searchByLocation, [name]: value });
  }

  const handleGrid = (val: any) => {
    if (val === 'boxgrid') {
      setGrid(true);
      setmultiGrid(false);
    } else if (val === 'multigrid') {
      setmultiGrid(true);
      setGrid(false);
    }
  }

  const handleChange = (e: any) => {
    const value = e.target.value;
    setDays_filter(value)
  }

  const getAllAuditionCall = async () => {
    setActive(true);
    const result = await getAuditionCallListAPI(limit, activePage, days_filter, searchByLocation.textSearch);
    setAuditionCall(result.audition_list);
    setTotallegend(result.total)
    setLimit(result.limit)
    setActive(false);
  }

  const handleOpenForm = () => {
    setOpenForm(!openForm);
  }

  useEffect(() => {
    getAllAuditionCall();
  }, [days_filter, searchByLocation])

  // useEffect(() => {
  //   console.log(auditionCall);
  // },[auditionCall])

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Loading your content...'
    >
      {openForm &&
        <AuditionCallFormModal handleModalBox={handleOpenForm} />
      }
      <div className='mt-5'>
        <div className='audition_call_header_section d-flex justify-content-between align-items-center border-bottom'>
          <h5 className='m-0 text-warning font26 fw-bold pb-4'>Audition Call</h5>
          {/* <button className='rounded font_weight_600' onClick={handleOpenForm}>
            Create New Audition Call
          </button> */}
        </div>
        <div
          className='audition_call_filter_section d-lg-flex d-md-block d-block justify-content-between align-items-center ps-lg-5 ps-md-0 ps-0 my-4'>
          <div className='d-flex justify-content-center align-items-center'>
            <div className='input_search_box'>
              <input type='text' placeholder='search' name='textSearch' onChange={handleSearchText} value={searchByLocation.textSearch} />
              <BiSearchAlt className='searchIcon' />
            </div>
            <div className='bg-white location p-1 d-flex align-items-center'>
              <FaLocationCrosshairs />
            </div>
            <div
              className='ms-4 festival_filter'
            >
            <select className="form-select" style={{border: '1px solid gray'}} aria-label="Default select example" onChange={handleChange} name='year'>
              <option className='theme_color' value={''} selected>All</option>
              <option className='theme_color' value={'this_month'} selected>This month</option>
              <option className='theme_color' value={'this_week'} selected>This week</option>
              <option className='theme_color' value={'last_week'} selected>Last week</option>
            </select>
            </div>
          </div>
          {/* <div
            className='mt-lg-0 mt-md-4 mt-3'
          >
            <button className={`py-1 px-4 rounded-1 ${days_filter === 'last_week' ? 'bg-danger' : ''}`} value={'last_week'} onClick={handleClick}>
              Last Week Audition
            </button>
            <button className={`py-1 px-4 rounded-1 ${days_filter === 'this_week' ? 'bg-danger' : ''} mx-lg-5 mx-md-5 mx-0`} value={'this_week'} onClick={handleClick}>
              This week Auditions
            </button>
            <button className={`py-1 px-4 rounded-1 ${days_filter === 'this_month' ? 'bg-danger' : ''}`} value={'this_month'} onClick={handleClick}>
              This month Auditions
            </button>
          </div> */}
          <div
            className='mt-lg-0 mt-md-3 mt-3'
          >
            <span
              className='pointer'
              onClick={() => handleGrid('boxgrid')}
            >
              <BsGrid className={`${grid ? 'active' : 'text-white'} fw-bold font26`} />
            </span>
            <span
              className='ms-3 pointer'
              onClick={() => handleGrid('multigrid')}
            >
              <TfiLayoutGrid4Alt className={`${multiGrid ? 'active' : 'text-white'} fw-bold font26`} />
            </span>
            <button className='rounded bg-danger ms-4 font_weight_600' onClick={handleOpenForm}>
              Create New Audition Call
            </button>
          </div>
        </div>

        {grid ?
          <div className='row'>
            {
              auditionCall.map((val: any) => {
                return (
                  <div className='col-lg-4 col-md-3 col-12 mb-5 pointer' key={val?.id}>
                    <div
                      className='audition_card_hover_effect p-3'
                    >
                      <Link to={`/menu/audition-call-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none '>
                        <div
                          style={{
                            height: '560px'
                          }}
                        >
                          <img src={`${baseURL}/${val?.cover}`} alt={val?.title} className='object-fit-contain' width={'100%'} height={'100%'} />
                        </div>
                        <p
                          className='m-0 text-white mt-2 font_weight_600'
                        >
                          <span>{val.title}</span>, <span>{val.location}</span>
                        </p>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
          : null
        }

        {multiGrid ?
          <div className='row'>
            {
              auditionCall.map((val: any) => {
                return (
                  <div className='col-lg-2 col-md-2 col-12 mb-5 pointer' key={val.id}>
                    <div
                      className='audition_card_hover_effect p-3'
                    >
                      <Link to={`/menu/audition-call-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none'>
                        <div
                          style={{
                            height: '230px'
                          }}
                        >
                          <img src={`${baseURL}/${val?.cover}`} alt={val?.title} className='object-fit-contain' width={'100%'} height={'100%'} />
                        </div>
                        <p
                          className='m-0 text-white mt-2'
                        >
                          {val.title}
                        </p>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
          : null
        }
      </div>

      <div className='d-flex justify-content-center align-items-center py-5 my-lg-5 my-md-5'>
        <ul className='text-white d-flex justify-content-center align-items-center gap-lg-3 gap-md-3 gap-2 p-0 px-2 paginationUl'>
          {
            activePage !== 1 && <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage - 1)}>Previous</li>
          }
          {
            totalPagesCalc(totalLegend, limit).slice(Math.max(0, activePage - 3), Math.min(totalLegend, activePage + 4)).map((val) => {
              return <li className={`pageList py-1 px-2 ${val === activePage ? 'activePageList' : ''}`} onClick={() => setActivePage(val)} key={val}>{val}</li>
            })
          }
          {activePage !== Math.ceil(totalLegend / limit) ? <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage + 1)}>Next</li> : ''}
        </ul>
      </div>

    </LoadingOverlay>
  )
}

export default AuditionCall
