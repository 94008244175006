import React, { useEffect, useState } from 'react';
import './profileform.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import { getAllLanguage } from '../../utils/utils';

const BiographyForm = () => {

    const biography_data = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [activeSave, setActiveSave] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [language, setLanguages] = useState<any>();
    const [biographyData, setBiographydata] = useState<any>({
        about: '',
        personnel_showreel: '',
        portfolio_pictures: '',
        profession_in_industry: '',
        industry_type: '',
        wanted_to_be: '',
        became_an: '',
        debut_performance: '',
        debut_performance_title: '',
        debut_performance_screen: '',
        debut_performance_year: '',
        debut_performance_charater_name: '',
        total_films_made: '',
        total_years_worked_in_industry: '',
        occupation_for_leaving: '',
        want_to_build_in_my_carrier: '',
        what_else_want_to_be: '',
        latest_qualification: '',
        tenth_pass_from: '',
        pollitically_involvement: '',
        graduated_from: '',
        master_graduated_from: '',
        latest_art_training_institute_name: '',
        trained_in: '',
        art_training_start_date: '',
        art_training_end_date: '',
        attach_in_industry: '',
        favourite_food: '',
        favourite_actor: '',
        favourite_actress: '',
        favourite_film: '',
        favourite_musician: '',
        favourite_drink: '',
        favourite_destination: '',
        favourite_practices: '',
        favourite_sport: '',
        first_car_purchased: '',
        first_home_purchased: '',
        other_skill_sets: '',
        super_hit_movies_are: '',
        tv_experience: '',
        media_experience: '',
        theater_experience: '',
        art_experience: '',
        film_experience: '',
        debut_performance_language: '',
    });

    const getLanguages = async () => {
        const data = await getAllLanguage();
        setLanguages(data);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'portfolio_pictures') {
            const files = e.target.files;
            const selectedFilesArray = Array.from(files);
            setBiographydata((prevData: any) => ({
                ...prevData,
                portfolio_pictures: [...prevData.portfolio_pictures, selectedFilesArray[0]]
            }))
        } else if (name === 'about') {
            const words = value.split(/\s+/).filter((el:string)=>{return el.length !== 0}).length
            setWordCount(words);
            if (words <= 5000) {
                setBiographydata((prevData: any) => ({
                    ...prevData,
                    about: value
                }))
            }
        } else {
            setBiographydata((prevData: any) => ({
                ...prevData,
                [name]: value,
            }));
        }
    }

    const handleEditBiography = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleBiographyUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        const { about, personnel_showreel, portfolio_pictures, profession_in_industry, industry_type, wanted_to_be, became_an,
            debut_performance, debut_performance_title, debut_performance_screen, debut_performance_year, debut_performance_charater_name,
            total_films_made, total_years_worked_in_industry, occupation_for_leaving, want_to_build_in_my_carrier, what_else_want_to_be,
            latest_qualification, tenth_pass_from, pollitically_involvement, graduated_from, master_graduated_from, latest_art_training_institute_name, 
            trained_in, art_training_start_date, art_training_end_date, attach_in_industry, favourite_food,
            favourite_actor, favourite_actress, favourite_film, favourite_musician, favourite_drink, favourite_destination, favourite_practices, favourite_sport, first_car_purchased, 
            first_home_purchased, other_skill_sets, super_hit_movies_are, tv_experience, media_experience, theater_experience, art_experience, film_experience, debut_performance_language } = biographyData;

        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    about, personnel_showreel, portfolio_pictures, profession_in_industry, industry_type, wanted_to_be, became_an,
                    debut_performance, debut_performance_title, debut_performance_screen, debut_performance_year, debut_performance_charater_name,
                    total_films_made, total_years_worked_in_industry, occupation_for_leaving, want_to_build_in_my_carrier, what_else_want_to_be,
                    latest_qualification, tenth_pass_from, pollitically_involvement, graduated_from, master_graduated_from, latest_art_training_institute_name, trained_in, 
                    art_training_start_date, art_training_end_date, attach_in_industry, favourite_food,
                    favourite_actor, favourite_actress, favourite_film, favourite_musician, favourite_drink, favourite_destination, favourite_practices, favourite_sport, 
                    first_car_purchased, first_home_purchased, other_skill_sets, super_hit_movies_are, tv_experience, media_experience, theater_experience, art_experience, film_experience,
                    debut_performance_language
                }
                setActiveSave(true);
                let result = await axios(`${baseURL}/api/subscriber/update-biography`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setIsDisabled(!isDisabled);
                setActiveSave(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(()=>{
        getLanguages();
    },[])

    useEffect(() => {
        setBiographydata((prevData: any) => ({
            ...prevData,
            about: biography_data.about || '',
            personnel_showreel: biography_data.personnel_showreel || '',
            portfolio_pictures: biography_data.portfolio_pictures || '',
            profession_in_industry: biography_data.profession_in_industry || '',
            industry_type: biography_data.industry_type || '',
            wanted_to_be: biography_data.wanted_to_be || '',
            became_an: biography_data.became_an || '',
            debut_performance: biography_data.debut_performance || '',
            debut_performance_title: biography_data.debut_performance_title || '',
            debut_performance_screen: biography_data.debut_performance_screen || '',
            debut_performance_year: biography_data.debut_performance_year || '',
            debut_performance_charater_name: biography_data.debut_performance_charater_name || '',
            total_films_made: biography_data.total_films_made || '',
            total_years_worked_in_industry: biography_data.total_years_worked_in_industry || '',
            occupation_for_leaving: biography_data.occupation_for_leaving || '',
            want_to_build_in_my_carrier: biography_data.want_to_build_in_my_carrier || '',
            what_else_want_to_be: biography_data.what_else_want_to_be || '',
            latest_qualification: biography_data.latest_qualification || '',
            tenth_pass_from: biography_data.tenth_pass_from || '',
            pollitically_involvement: biography_data.pollitically_involvement || '',
            graduated_from: biography_data.graduated_from || '',
            master_graduated_from: biography_data.master_graduated_from || '',
            latest_art_training_institute_name: biography_data.latest_art_training_institute_name || '',
            trained_in: biography_data.trained_in || '',
            art_training_start_date: biography_data.art_training_start_date || '',
            art_training_end_date: biography_data.art_training_end_date || '',
            attach_in_industry: biography_data.attach_in_industry || '',
            favourite_food: biography_data?.favouriteThings?.favourite_food || '',
            favourite_actor: biography_data?.favouriteThings?.favourite_actor || '',
            favourite_actress: biography_data?.favouriteThings?.favourite_actress || '',
            favourite_film: biography_data?.favouriteThings?.favourite_film || '',
            favourite_musician: biography_data?.favouriteThings?.favourite_musician || '',
            favourite_drink: biography_data?.favouriteThings?.favourite_drink || '',
            favourite_destination: biography_data?.favouriteThings?.favourite_destination || '',
            favourite_practices: biography_data?.favouriteThings?.favourite_practices || '',
            favourite_sport: biography_data?.favouriteThings?.favourite_sport || '',
            first_car_purchased: biography_data?.favouriteThings?.first_car_purchased || '',
            first_home_purchased: biography_data?.favouriteThings?.first_home_purchased || '',
            other_skill_sets: biography_data.other_skill_sets || '',
            super_hit_movies_are: biography_data.super_hit_movies_are || '',
            tv_experience: biography_data.tv_experience || '',
            media_experience: biography_data.media_experience || '',
            theater_experience: biography_data.theater_experience || '',
            art_experience: biography_data.art_experience || '',
            film_experience: biography_data.film_experience || '',
            debut_performance_language: biography_data.debut_performance_language || '',
        }));
    }, [biography_data]);

    // useEffect(() => {
    //     console.log('aaaa', biographyData)
    // }, [biographyData]);

    return (
        <>
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="biographyForm">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{backgroundColor: '#3a3a3c'}} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsebiographyForm" aria-expanded="false" aria-controls="flush-collapsebiographyForm">
                                Biography
                            </button>
                        </h2>
                        <div id="flush-collapsebiographyForm" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#biographyForm">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                            <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded font_weight_600'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleEditBiography}
                                    >
                                        Edit
                                    </button>
                                </div>
                                <form className='px-5 biography_form'>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">About me ({wordCount}/5000 words)</label>
                                        <textarea name='about' onChange={handleChange} value={biographyData.about} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" placeholder='Eg I am Krishna' disabled={isDisabled}></textarea>
                                    </div>
                                    <div className='row p-0 mb-5 d-flex justify-content-between align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Profession in the industry</label>
                                            <input type="text" onChange={handleChange} value={biographyData.profession_in_industry} name='profession_in_industry' className="form-control mb-3" placeholder='Actor, Producer,Director' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Industry type</label>
                                            <input type="text" onChange={handleChange} value={biographyData.industry_type} name='industry_type' className="form-control mb-3" placeholder='Film Industry/TV Industry/Creative/Art/Media/Press' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Wanted to be an</label>
                                            <input type="text" onChange={handleChange} value={biographyData.wanted_to_be} name='wanted_to_be' className="form-control mb-3" placeholder='eg: Actor, director, producer, dancer etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Became an</label>
                                            <input type="text" onChange={handleChange} value={biographyData.became_an} name='became_an' className="form-control mb-3" placeholder='eg: Actor, Singer, Dancer etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Debut performance</label>
                                            <input type="text" onChange={handleChange} value={biographyData.debut_performance} name='debut_performance' className="form-control mb-3" placeholder='Name of the movie/tv serial etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Debut performance title</label>
                                            <input type="text" onChange={handleChange} value={biographyData.debut_performance_title} name='debut_performance_title' className="form-control mb-3" placeholder='eg: Hero, Villan etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Film/TV?</label>
                                            <select className="form-select mb-3" onChange={handleChange} value={biographyData.debut_performance_screen} name='debut_performance_screen' aria-label="Default select example" disabled={isDisabled}>
                                                <option selected>Select debut performance screen</option>
                                                <option value="TV">TV</option>
                                                <option value="Film">Film</option>
                                                <option value="Theatre">Theatre</option>
                                                <option value="Road Drama">Road Drama</option>
                                                <option value="Hobby Performance">Hobby Performance</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Debut performance language</label>
                                            <select className="form-select mb-3" onChange={handleChange} value={biographyData.debut_performance_language} name='debut_performance_language' aria-label="Default select example" disabled={isDisabled}>
                                                <option selected>Select debut language</option>
                                                {Array.isArray(language) && 
                                                    language?.map((lang) => {
                                                        return (
                                                            <option value={lang.name}>{lang.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Year</label>
                                            <input type="number" onChange={handleChange} value={biographyData.debut_performance_year} name='debut_performance_year' className="form-control mb-3" placeholder='Debut performance year eg: (2001)' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Character name</label>
                                            <input type="text" onChange={handleChange} value={biographyData.debut_performance_charater_name} name='debut_performance_charater_name' className="form-control mb-3" placeholder='Character name which was played in debut' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Total films made</label>
                                            <input type="number" onChange={handleChange} value={biographyData.total_films_made} name='total_films_made' className="form-control mb-3" placeholder='180' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Total number of years worked in the film industry</label>
                                            <input type="number" onChange={handleChange} value={biographyData.total_years_worked_in_industry} name='total_years_worked_in_industry' className="form-control mb-3" placeholder='50' disabled={isDisabled} />
                                        </div>
                                        {/* <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Politically involvement</label>
                                            <input type="text" onChange={handleChange} value={biographyData.pollitically_involvement} name='pollitically_involvement' className="form-control mb-3" disabled={isDisabled} />
                                        </div> */}
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Occupation for leaving</label>
                                            <input type="text" onChange={handleChange} value={biographyData.occupation_for_leaving} name='occupation_for_leaving' className="form-control mb-3" placeholder='eg: Directer, Producer etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">What I want to build in my career</label>
                                            <input type="text" onChange={handleChange} value={biographyData.want_to_build_in_my_carrier} name='want_to_build_in_my_carrier' className="form-control mb-3" placeholder='eg: Legendry hero' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">What else you want to be? </label>
                                            <input type="text" onChange={handleChange} value={biographyData.what_else_want_to_be} name='what_else_want_to_be' className="form-control mb-3" placeholder='eg: Painter, Writer etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload portfolio pictures ( max 2 )</label>
                                            <input type="file" name='portfolio_pictures' onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" placeholder="Jane Cooper" disabled={isDisabled} />
                                        </div>
                                        {/* <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Personel showreel ( from any platform add a link eg: Youtube )</label>
                                            <input type="text" name='personnel_showreel' onChange={handleChange} value={biographyData.personnel_showreel} className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                        </div> */}
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Other skill sets</label>
                                            <input type="text" onChange={handleChange} value={biographyData.other_skill_sets} name='other_skill_sets' className="form-control mb-3" placeholder='eg: Dancer, Director etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Super hit movies are</label>
                                            <input type="text" onChange={handleChange} value={biographyData.super_hit_movies_are} name='super_hit_movies_are' className="form-control mb-3" placeholder='eg: K.G.F, Bahubali etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Tv (total experience in years)</label>
                                            <input type="number" onChange={handleChange} value={biographyData.tv_experience} name='tv_experience' className="form-control mb-3" placeholder='eg: 15' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Media (total experience in years)</label>
                                            <input type="number" onChange={handleChange} value={biographyData.media_experience} name='media_experience' className="form-control mb-3" placeholder='eg: 15' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Theater (total experience in years)</label>
                                            <input type="number" onChange={handleChange} value={biographyData.theater_experience} name='theater_experience' className="form-control mb-3" placeholder='eg: 15' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Art (total experience in years)</label>
                                            <input type="number" onChange={handleChange} value={biographyData.art_experience} name='art_experience' className="form-control mb-3" placeholder='eg: 15' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Film (total experience in years)</label>
                                            <input type="number" onChange={handleChange} value={biographyData.film_experience} name='film_experience' className="form-control mb-3" placeholder='eg: 15' disabled={isDisabled} />
                                        </div>
                                    </div>

                                    <h3
                                        className='text-white my-4'
                                    >
                                        Favourite things liked
                                    </h3>

                                    <div className='row p-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite food</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_food} name='favourite_food' className="form-control mb-3" placeholder='eg: Rajma, Biryani etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite actor</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_actor} name='favourite_actor' className="form-control mb-3" placeholder='eg: Allu Arjun etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite actress</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_actress} name='favourite_actress' className="form-control mb-3" placeholder='eg: Anushka Shetty, Shruti Haasan etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite film</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_film} name='favourite_film' className="form-control mb-3" placeholder='eg: K.G.F etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite musican</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_musician} name='favourite_musician' className="form-control mb-3" placeholder='eg: A R Rahman, Shankar Mahadevan etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite drink</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_drink} name='favourite_drink' className="form-control mb-3" placeholder='eg: Soft drink' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite destination</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_destination} name='favourite_destination' className="form-control mb-3" placeholder='eg: Dubai, America etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite practices (Eg. Yoga)</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_practices} name='favourite_practices' className="form-control mb-3" placeholder='eg: Alom Vilom etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Favourite sports</label>
                                            <input type="text" onChange={handleChange} value={biographyData.favourite_sport} name='favourite_sport' className="form-control mb-3" placeholder='eg: Cricket, Hocky etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">First car purchased</label>
                                            <input type="text" onChange={handleChange} value={biographyData.first_car_purchased} name='first_car_purchased' className="form-control mb-3" placeholder='Lamborgini' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">First home purchased</label>
                                            <input type="text" onChange={handleChange} value={biographyData.first_home_purchased} name='first_home_purchased' className="form-control mb-3" placeholder='Swarg Villa' disabled={isDisabled} />
                                        </div>
                                    </div>



                                    {/* <div
                                        className='d-flex justify-content-between align-items-center'
                                    >
                                        <h3
                                            className='text-white my-4'
                                        >
                                            Who Introduced you to the film industry?
                                        </h3>
                                        <button
                                            className='btn btn-outline-danger bg-transparent'
                                            onClick={handleAddIndustry}
                                        >
                                            +Add More
                                        </button>
                                    </div>

                                    <div
                                        className='row p-0 mb-5 d-flex justify-content-between align-items-center'
                                    >
                                        {
                                            biographyData.introduced_by_to_industry.map((val: any, i: any) => {
                                                return (
                                                    <>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Name</label>
                                                            <input type="text" onChange={handleChange} value={val.name} name='name' className="form-control mb-3" disabled={isDisabled} />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Relation</label>
                                                            <input type="text" onChange={handleChange} value={val.relation} name='relation' className="form-control mb-3" disabled={isDisabled} />
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div> */}

                                    <h3
                                        className='text-white my-4'
                                    >
                                        Qualification
                                    </h3>

                                    <div className='row p-0 mb-5 d-flex justify-content-between align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Latest qualification</label>
                                            <input type="text" onChange={handleChange} value={biographyData.latest_qualification} name='latest_qualification' className="form-control mb-3" placeholder='eg: B.Tech/M.Tech' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">School from</label>
                                            <input type="text" onChange={handleChange} value={biographyData.tenth_pass_from} name='tenth_pass_from' className="form-control mb-3" placeholder='eg: Christ Church Inter Collage' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Graduation from</label>
                                            <input type="text" onChange={handleChange} value={biographyData.graduated_from} name='graduated_from' className="form-control mb-3" placeholder='eg: Delhi University' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Master Graduation from</label>
                                            <input type="text" onChange={handleChange} value={biographyData.master_graduated_from} name='master_graduated_from' className="form-control mb-3" placeholder='eg: Delhi University' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Latest art training institute name</label>
                                            <input type="text" onChange={handleChange} value={biographyData.latest_art_training_institute_name} name='latest_art_training_institute_name' className="form-control mb-3" placeholder='eg: Aadarsh Institute' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Trained in</label>
                                            <input type="text" onChange={handleChange} value={biographyData.trained_in} name='trained_in' className="form-control mb-3" placeholder='eg: Acting, Directing etc.' disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Start date</label>
                                            <input type="date" onChange={handleChange} value={biographyData.art_training_start_date} name='art_training_start_date' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">End date</label>
                                            <input type="date" onChange={handleChange} value={biographyData.art_training_end_date} name='art_training_end_date' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">From what date you started working in the industry?</label>
                                            <input type="date" onChange={handleChange} value={biographyData.attach_in_industry} name='attach_in_industry' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                    </div>

                                    {/* <h3
                                        className='text-white my-4'
                                    >
                                        Give 2 references & contacts from the Film Industry
                                    </h3>

                                    <div className='row p-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Full Name</label>
                                            <input type="text" onChange={handleChange} value={biographyData.reference_contact[0]?.full_name} name='full_name' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Contact</label>
                                            <input type="text" onChange={handleChange} value={biographyData.reference_contact[0]?.phone} name='phone' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Email Id</label>
                                            <input type="text" onChange={handleChange} value={biographyData.reference_contact[0]?.email} name='email' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                    </div>
                                    <div className='row p-0 d-flex justify-content-between align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Full Name</label>
                                            <input type="text" onChange={handleChange} value={biographyData.reference_contact[1]?.full_name} name='full_name' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Contact</label>
                                            <input type="text" onChange={handleChange} value={biographyData.reference_contact[1]?.phone} name='phone' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Email Id</label>
                                            <input type="text" onChange={handleChange} value={biographyData.reference_contact[1]?.email} name='email' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                    </div> */}

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        {activeSave ?
                                            <button
                                                className='py-2 px-2 border-0 common_button rounded font16 font_weight_600'
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                            >
                                                Updating...
                                            </button>
                                            :
                                            <>
                                                {!isDisabled ?
                                                    <button
                                                        className='py-2 px-2 common_button rounded fw-bold'
                                                        style={{
                                                            width: '150px'
                                                        }}
                                                        onClick={handleBiographyUpdate}
                                                    >
                                                        Save
                                                    </button>
                                                    : null
                                                }
                                            </>
                                        }
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default BiographyForm
