import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllIndustryTypeAPI } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import ProfessionForm from './ProfessionForm';

const IndustryForm = (props: any) => {
    const { onCloseModal } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [industryList, setIndustryList] = useState<any>();
    const [selectIndustry, setSelectIndustry] = useState<any>([]);
    const [openProfession, setOpenProfessionModal] = useState(false);

    const getIndustryList = async () => {
        setActive(true);
        const response = await getAllIndustryTypeAPI();
        setIndustryList(response);
        setActive(false);
    }

    const handleChangeValue = (e: any) => {
        const { value, checked } = e.target;
        const parsedValue = parseInt(value);

        if (checked) {
            setSelectIndustry([...selectIndustry, parsedValue]);
        } else {
            setSelectIndustry(selectIndustry.filter((prof: any) => prof !== parsedValue));
        }
    }

    const handleSubmitIndustry = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem("connection_id");
        const auth_code = localStorage.getItem('auth_code');
        const body = {
            connection_id,
            auth_code,
            industries: JSON.stringify(selectIndustry)
        };
        if (!selectIndustry || selectIndustry.length === 0) {
            messageTxt('Please select your Industry !')
            setActive(false);
        } else {
            try {
                const res = await axios.post(`${baseURL}/api/subscriber/industry/add`, body);
                messageTxt(res.data.message);
                setOpenProfessionModal(true);
                setActive(false);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    useEffect(() => {
        getIndustryList();
    }, [])

    // useEffect(() => {
    //     console.log('input', selectIndustry);
    // }, [selectIndustry])

    return (
        <>
            {openProfession ?
                <ProfessionForm onCloseModal={onCloseModal} />
                :
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div
                        className='mb-5'
                    >
                        <p
                            className='m-0 text-white font_weight_600 font28'
                        >
                            You are from which Film Industry ?
                        </p>
                        <p
                            className='m-0 text-white mt-2 font18'
                        >
                            Select the film industry
                        </p>
                    </div>
                    <div
                        className='row'
                    >
                        {Array.isArray(industryList) && industryList.length > 0 && industryList.map((val, idx) => (
                            <div key={val.id} className="col-lg-2 col-md-6 col-12 mb-3 text-white font16">
                                <Form.Check
                                    type='checkbox'
                                    id={String(val.id)}
                                    label={String(val.title)}
                                    value={val.id}
                                    onChange={handleChangeValue}
                                />
                            </div>
                        ))}
                    </div>
                    <div
                        className='text-center mt-3'
                    >
                        <button
                            className='py-3 px-lg-5 px-md-5 px-3 font_weight_600 bg-danger w-75 rounded text-white'
                            style={{ backgroundColor: 'red', border: 'none' }}
                            onClick={handleSubmitIndustry}
                        >
                            Submit
                        </button>
                    </div>
                </LoadingOverlay>
            }
        </>
    )
}

export default IndustryForm
