import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ProjectCastModal = (props: any) => {
    const { handleModalBox, data, project_id, dataId, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [isActive, setActive] = useState(false);
    const [castData, setcastData] = useState({ name: '', character_name: '', image: '', role: '' });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setcastData({ ...castData, image: image })
        } else {
            setcastData({ ...castData, [name]: value })
        }
        // const updatedcastData = [...castData];
        // const image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
        // if (typeof i === 'number' && i >= 0 && i < updatedcastData.length) {
        //     updatedcastData[i] = { ...updatedcastData[i], [name]: value, image };
        //     setcastData(updatedcastData);
        // } else {
        //     console.error('Invalid index provided for array update.');
        // }
    };

    const handleAddCast = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    cast: [castData]
                }
                let result = await axios(`${baseURL}/api/project/add-cast`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                setcastData({ name: '', character_name: '', image: '', role: '' });
                messageTxt(result.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handleUpdateCast = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    cast_id: dataId,
                    name: castData.name,
                    character_name: castData.character_name,
                    image: castData.image
                }
                let result = await axios(`${baseURL}/api/project/update-cast`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                setcastData({ name: '', character_name: '', image: '', role: '' });
                messageTxt(result.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const takeAwardValuesForUpdate = () => {
        setActive(true);
        if (dataId) {
            setEnableUpdate(true);
            setcastData({
                ...castData,
                name: data.name,
                character_name: data.character_name,
                image: data.image,
                role: data.role
            });
        }
        setActive(false);
    }

    useEffect(() => {
        takeAwardValuesForUpdate();
    }, [data, dataId])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center cast_modal">
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Real Name</label>
                            <input type="text" name='name' value={castData.name} onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Character Name in the movie</label>
                            <input type="text" name='character_name' value={castData.character_name} onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Role</label>
                            <input type="text" name='role' value={castData.role} onChange={handleChange} className="form-control mb-3" placeholder='eg: Hero' id="exampleFormControlInput1" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Photo</label>
                            <input type="file" name='image' onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" />
                        </div>

                        <div className='d-flex justify-content-end'>
                        {enableUpdate ?
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleUpdateCast}
                            >
                                Update
                            </button>
                            :
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleAddCast}
                            >
                                ADD
                            </button>
                        }
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProjectCastModal
