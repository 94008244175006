import React, { useRef } from 'react';
import Slider from 'react-slick';
import FilmProRecCard from './FilmProRecCard';

const FilmProRecSlider = (props: any) => {
    const { data } = props;

    let sliderRef = useRef<any>(null);

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,

                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,

                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,

                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,

                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <Slider {...settings} ref={sliderRef} className='movie_card_slider'>
            {
                data?.map((val: any, i: number) => {
                    return (
                        <div key={i}>
                            <FilmProRecCard id={val?.id} title={val?.title} image={val?.image} />
                        </div>
                    );
                })
            }
        </Slider>
    )
}

export default FilmProRecSlider
