export const months = [
    { id: '01', name: 'January' },
    { id: '02', name: 'February' },
    { id: '03', name: 'March' },
    { id: '04', name: 'April' },
    { id: '05', name: 'May' },
    { id: '06', name: 'June' },
    { id: '07', name: 'July' },
    { id: '08', name: 'August' },
    { id: '09', name: 'September' },
    { id: '10', name: 'October' },
    { id: '11', name: 'November' },
    { id: '12', name: 'December' }
];

export const bannerImage = [
    '../../images/banner1.png'
    // 'https://wallpapercave.com/wp/wp11581873.jpg',
    // 'https://wallpaperaccess.com/full/8714664.jpg',
    // 'https://wallpapercave.com/wp/wp11581804.jpg',
    // 'https://wallpapercave.com/wp/wp11581873.jpg'
];

export const yutubeLink = [
    '../../images/watchlistkgf.png',
    '../../images/watchlistkgf.png',
    '../../images/watchlistkgf.png',
    '../../images/watchlistkgf.png',
    '../../images/watchlistkgf.png',
    '../../images/watchlistkgf.png',
    '../../images/watchlistkgf.png',
    '../../images/watchlistkgf.png'
];

export const movieCoverImage = [
    {
        cover: '../../images/lovemocktail2.png',
        genre: 'Romantic',
        title: 'Love Mocktail 2'
    },
    {
        cover: '../../images/kgf2.png',
        genre: 'Action, Drama',
        title: 'KGF 2'
    },
    {
        cover: '../../images/kabzaa.png',
        genre: 'Action, Drama',
        title: 'Kabzaa'
    },
    {
        cover: '../../images/headbush.png',
        genre: 'Action, Drama',
        title: 'Head Bush'
    },
    {
        cover: '../../images/lovemocktail2.png',
        genre: 'Romantic',
        title: 'Love Mocktail 2'
    },
    {
        cover: '../../images/kgf2.png',
        genre: 'Action, Drama',
        title: 'KGF 2'
    },
    {
        cover: '../../images/kabzaa.png',
        genre: 'Action, Drama',
        title: 'Kabzaa'
    },
    {
        cover: '../../images/headbush.png',
        genre: 'Action, Drama',
        title: 'Head Bush'
    }
];

export const todaysBirthday = [
    {
        cover: '../../images/sivarajkumar.png',
        date: '12 July 1964',
        title: 'Siva Raj Kumar'
    },
    {
        cover: '../../images/yash.png',
        date: '12 July 1964',
        title: 'Yash'
    },
    {
        cover: '../../images/sudeep.png',
        date: '12 July 1964',
        title: 'Sudeep'
    },
    {
        cover: '../../images/upendrarao.png',
        date: '12 July 1964',
        title: 'Upendra Rao'
    },
    {
        cover: '../../images/sivarajkumar.png',
        date: '12 July 1964',
        title: 'Siva Raj Kumar'
    },
    {
        cover: '../../images/yash.png',
        date: '12 July 1964',
        title: 'Yash'
    },
    {
        cover: '../../images/sudeep.png',
        date: '12 July 1964',
        title: 'Sudeep'
    },
    {
        cover: '../../images/upendrarao.png',
        date: '12 July 1964',
        title: 'Upendra Rao'
    }
];

export const filmFestival = [
    {
        cover: '../../images/filmfestival1.png',
        date: '12 July 2023',
        title: 'Bengaluru International Film Festival'
    },
    {
        cover: '../../images/filmfestival2.png',
        date: '12 July 2023',
        title: 'Behindswood Gold Medals'
    },
    {
        cover: '../../images/filmfestival1.png',
        date: '12 July 2023',
        title: 'Bengaluru International Film Festival'
    },
    {
        cover: '../../images/filmfestival2.png',
        date: '12 July 2023',
        title: 'Behindswood Gold Medals'
    },
    {
        cover: '../../images/filmfestival1.png',
        date: '12 July 2023',
        title: 'Bengaluru International Film Festival'
    },
    {
        cover: '../../images/filmfestival2.png',
        date: '12 July 2023',
        title: 'Behindswood Gold Medals'
    }
];

export const conemaDBSOTT = [
    {
        cover: '../../images/raider.png',
        date: '12 July 1964',
        title: 'Raider'
    },
    {
        cover: '../../images/mufthi.png',
        date: '12 July 1964',
        title: 'Mufthi'
    },
    {
        cover: '../../images/rekke.png',
        date: '12 July 1964',
        title: 'Rekke'
    },
    {
        cover: '../../images/bondravi.png',
        date: '12 July 1964',
        title: 'Bond Ravi'
    },
    {
        cover: '../../images/raider.png',
        date: '12 July 1964',
        title: 'Raider'
    },
    {
        cover: '../../images/mufthi.png',
        date: '12 July 1964',
        title: 'Mufthi'
    },
    {
        cover: '../../images/rekke.png',
        date: '12 July 1964',
        title: 'Rekke'
    },
    {
        cover: '../../images/bondravi.png',
        date: '12 July 1964',
        title: 'Bond Ravi'
    }
];

export const otherFilmIndustries = [
    { cover: '../../images/coorgi.png', language: 'Coorgi' },
    { cover: '../../images/tulu.png', language: 'Tulu' },
    { cover: '../../images/telugu.png', language: 'Telugu' },
    { cover: '../../images/coorgi.png', language: 'Coorgi' },
    { cover: '../../images/tulu.png', language: 'Tulu' },
    { cover: '../../images/telugu.png', language: 'Telugu' }
];

export const languages = [
    'English'
]

export const filmIndustry = [
    'Sandalwood',
    // 'tollywood',
    // 'mollywood',
    // 'kollywood'
];

export const filmUpdate = [
    'comming soon Movies',
    'New Movies',
    'Trailers',
    'songs',
    'movie news'
];

export const menu = [
    'Legends of the Industry',
    'Living Legends',
    'Film Festival',
    // 'Award news',
    'OTT',
    // 'Free tickets',
    'Events tickets',
    'Master class',
    'Audition call',
    'Film institute',
    'Birthdays',
    'Shop',
    'Contact us'
];

export const fraternityUser = [
    'My profile',
    // 'My film Festival',
    // 'Plan your entertainment',
    'Watchlist',
    // 'Search film fraternity',
    'Create Events',
    // 'Promote your movie',
    // 'Create audition call',
    'Add movie to film Festival',
    // 'Send donations',
    // 'Offers'
];

export const cinemaFans = [
    'My profile',
    // 'Plan your entertainment',
    // 'Watchlist',
    // 'Send donations',
    // 'Offers'
];

export const footerNemu = [
    'Career',
    'Privacy_policy',
    'Terms & Conditions',
    'About_Us',
    'Q&A',
    'Disclaimer',
    'Cookie_policy',
    'Copyright',
    'Social Media Promotion-Terms & Conditions'
];

// shop dummy shop products

export const shop_products = [
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product1.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct2.png', price: '1595.00', discountPrice: '797',
        colors: ['black', 'white', 'green']
    },
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product3.png', price: '1595.00', discountPrice: '797',
        colors: ['blue', 'aqua', 'pink']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct4.png', price: '1595.00', discountPrice: '797',
        colors: ['green', 'blue', 'red']
    },
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product1.png', price: '1595.00', discountPrice: '797',
        colors: ['yellow', 'black', 'red']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct2.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red']
    },
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product3.png', price: '1595.00', discountPrice: '797',
        colors: ['black', 'green', 'red']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct4.png', price: '1595.00', discountPrice: '797',
        colors: ['red', 'black', 'red']
    },
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product1.png', price: '1595.00', discountPrice: '797',
        colors: ['green', 'black', 'red']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct2.png', price: '1595.00', discountPrice: '797',
        colors: ['yellow', 'black', 'red']
    },
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product3.png', price: '1595.00', discountPrice: '797',
        colors: ['aqua', 'black', 'red']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct4.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red']
    },
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product1.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct2.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red']
    },
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product3.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct4.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red']
    }
];

// shop product details
export const shop_product_detail = [
    {
        id: '3', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/product3.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red'],
        size: ['s', 'm', 'l', 'xl', 'xxl']
    },
    {
        id: '4', name: 'Official Animal Silence Fleece', type: 'Hoodie', image: '../images/wproduct4.png', price: '1595.00', discountPrice: '797',
        colors: ['white', 'black', 'red'],
        size: ['s', 'm', 'l', 'xl', 'xxl']
    }
];