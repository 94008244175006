import React, { useState, useEffect, useRef } from 'react';
import '../../components/myprofileforms/profileform.css';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { baseURL } from '../../utils/api';
import { ImCross } from 'react-icons/im';
import LoadingOverlay from 'react-loading-overlay-ts';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import Select from 'react-dropdown-select';
import { deleteCourseAPI, deleteMasterClassAPI, getAllIndustryTypeAPI, getAllInstituteCourseListAPI, getAllLanguage } from '../../utils/utils';
import { FiEdit } from 'react-icons/fi';
import { IoTrash } from 'react-icons/io5';
import { Table } from 'react-bootstrap';
import InstituteDetailModal from '../../components/myprofilemodals/InstituteDetailModal';

const FilmInstituteDetailCrud = () => {

  const messageTxt = (message: any) => toast(message);
  LoadingOverlay.propTypes = undefined;
  const myFilmInstituteRef = useRef<HTMLDivElement | null>(null);
  const params = useParams();
  const { id } = params;
  const [isActive, setActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [getId, setId] = useState<any>('');
  const [detailList, setDetailList] = useState<any>([]);
  const [detail, setDetail] = useState<any>({
    name: '',
    duration: '',
    location: '',
    fees: '',
    description: ''
  })

  const handleChangeVal = (e: any) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  }

  const getCourseDetails = async () => {
    setActive(true);
    const urlId = extractIdFromUrl(id);
    const data = await getAllInstituteCourseListAPI(urlId);
    setDetailList(data);
    setActive(false);
  }

  const handleEditCourse = (e: any) => {
    e.preventDefault();
    if (isDisabled) {
      messageTxt('You can edit.');
    } else {
      messageTxt("You can't edit.");
    }
    setIsDisabled(!isDisabled);
  }

  // const handleSaveUpdateCourse = async (e: any) => {
  //   e.preventDefault();
  //   setActive(true);
  //   const connection_id = localStorage.getItem('connection_id');
  //   const auth_code = localStorage.getItem('auth_code');
  //   const urlId = extractIdFromUrl(id);

  //   const { name, duration, location, fees, description } = detail;

  //   if (name === '' || duration === '' || location === '' || fees === '' || description === '') {
  //     messageTxt('Please fill all fields.');
  //     setActive(false);
  //   } else {
  //     try {
  //       let body = {
  //         connection_id,
  //         auth_code,
  //         institute_id: urlId,
  //         name,
  //         duration,
  //         location,
  //         fees,
  //         description,
  //         id: getId
  //       }
  //       const res = await axios(`${baseURL}/api/add_instituteCourses`, {
  //         method: 'POST',
  //         data: body,
  //         headers: { 'Content-Type': "multipart/form-data" }
  //       })
  //       setDetail({
  //         name: '',
  //         duration: '',
  //         location: '',
  //         fees: '',
  //         description: ''
  //       })
  //       setId('');
  //       messageTxt(res.data.message);
  //       getCourseDetails();
  //       setActive(false);
  //     } catch (error) {
  //       console.log(error);
  //       setActive(false);
  //     }
  //   }
  // }

  const getUpdateValue = (e: any, val: any) => {
    e.preventDefault();
    const data = val;
    setDetail({
      ...detail,
      name: data.name,
      duration: data.duration,
      location: data.location,
      fees: data.fees,
      description: data.description
    });
    setId(data.id);
    setOpenAdd(!openAdd);
  }

  const handleDeleteCourse = async (e: any, id: any) => {
    e.preventDefault();
    setActive(true);
    const data = await deleteCourseAPI(id);
    getCourseDetails();
    messageTxt(data);
    setActive(false)
  }

  const handleAddCourseModal = () => {
    getCourseDetails();
    setOpenAdd(!openAdd);
    setDetail({
      name: '',
      duration: '',
      location: '',
      fees: '',
      description: ''
    })
    setId('');
  }

  useEffect(() => {
    getCourseDetails();
  }, []);

  // useEffect(() => {
  //   console.log(detail);
  //   console.log('list', detailList)
  // }, [detail, detailList])


  return (
    <>
      <div className='mt-5 d-flex justify-content-between align-items-center px-4'>
        <div className='text-white'>
          <Link className='text-decoration-none text-white' to='/'>Home</Link>
          <span className='mx-1'>/</span>
          <Link className='text-decoration-none text-white' to='/profile/myprofile'>MyProfile</Link>
          <span className='mx-1'>/</span>
          <span className='pointer'>Film institute detail</span>
        </div>
        <button
          className='border-0 fs-4 bg-dark'
          style={{
            right: '10px',
            top: '5px'
          }}
        // onClick={handleDeleteClass}
        >
          <BsFillTrashFill className='text-white' />
        </button>
      </div>
      <LoadingOverlay
        active={isActive}
        spinner
        text='Loading your content...'
      >
        <div className='mt-3'>
          <div className="accordion accordion-flush" id="filmInstitute">

            <div className="accordion-item rounded">
              <h2 className="accordion-header" id="flush-headingfilmInstitute">
                <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefilmInstitute" aria-expanded="true" aria-controls="flush-collapsefilmInstitute">
                  Create/Update film institute
                </button>
              </h2>
              <div id="flush-collapsefilmInstitute" ref={myFilmInstituteRef} className="accordion-collapse show collapse" aria-labelledby="flush-headingKycForm" data-bs-parent="#KycForm">
                <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                  {openAdd &&
                    <InstituteDetailModal handleModalBox={handleAddCourseModal} data={detail} getId={getId} dataId={id} headingText={'Add New'} />
                  }

                  <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                    <button
                      className='py-2 border-0 rounded fw-bold'
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        width: '100px'
                      }}
                      onClick={handleAddCourseModal}
                    >
                      + Add
                    </button>
                  </div>

                  {Array.isArray(detailList) && detailList.length > 0 ?
                    <Table striped bordered hover responsive variant="dark">
                      <thead>
                        <tr>
                          <th>SL</th>
                          <th>Course name</th>
                          <th>Duration</th>
                          <th>Location</th>
                          <th>Fees</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(detailList) && detailList.length > 0 &&
                          detailList.map((val: any, i: any) => {
                            return (
                              <tr
                                key={val.id}
                              >
                                <td>{i + 1}</td>
                                <td>{val.name}</td>
                                <td>{val.duration}</td>
                                <td>{val.location}</td>
                                <td>{val.fees}</td>
                                <td>{val.description}</td>
                                <td>
                                  <div
                                    className='font20 d-flex justify-content-between align-items-center'
                                  >
                                    <FiEdit className='pointer' onClick={(e) => getUpdateValue(e, val)} />
                                    <IoTrash className='pointer' onClick={(e) => handleDeleteCourse(e, val.id)} />
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                    :
                    <p
                      className="m-0 text-white text-center font_weight_600"
                    >
                      No data found.
                    </p>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </LoadingOverlay>
    </>
  )
}

export default FilmInstituteDetailCrud
