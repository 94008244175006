import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllNotification, notificationReadAPI, replaceUnderscoreAndCapitalize } from '../utils/utils';
import { baseURL } from '../utils/api';
import { Link } from 'react-router-dom';
import { getGeneratedTime } from '../utils/jsfunctions';

const Notification = () => {

    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false);
    const [notifications, setNotifications] = useState<any>();

    const getNotifications = async () => {
        setActive(true);
        const data = await getAllNotification();
        setNotifications(data);
        setActive(false);
    }

    const readNotification = async (type: any) => {
        await notificationReadAPI(type);
    }

    useEffect(() => {
        getNotifications();
    }, [])

    useEffect(() => {
        console.log('notify', notifications);
    }, [notifications])

    return (
        <>
            <div
                className='container mb-5'
            >
                <div
                    className='d-flex align-items-baseline justify-content-between'
                >
                    <h3 className='mt-5 text-warning font22 fw-bold'>
                        Notifications
                    </h3>
                    <p
                        className='m-0 text-danger fw-bold pointer'
                        onClick={() => { readNotification('all') }}
                    >
                        Mark all as  read
                    </p>
                </div>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    {(notifications && Object.keys(notifications).length > 0) &&
                        <>
                            {
                                Object.keys(notifications).map((values: any, i: any) => (
                                    <>
                                        {values === 'todays_birthday' ? (
                                            <div className="accordion accordion-flush mt-4" id="notification" key={i}>
                                                <div className="accordion-item rounded">
                                                    <h2 className="accordion-header" id={`flush-heading${i}`}>
                                                        <button
                                                            className="accordion-button collapsed rounded border text-white"
                                                            style={{ backgroundColor: '#3a3a3c' }}
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#flush-collapsenotification-${i}`}
                                                            aria-expanded="false"
                                                            aria-controls={`flush-collapsenotification-${i}`}
                                                            onClick={() => { readNotification(values) }}
                                                        >
                                                            {replaceUnderscoreAndCapitalize(values)}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`flush-collapsenotification-${i}`}
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby={`flush-heading${i}`}
                                                        data-bs-parent="#notification"
                                                    >
                                                        <div className="accordion-body border mb-5 awards_form theme_color">
                                                            <div
                                                                className='d-flex justify-content-center align-items-center breadcrumb'
                                                            >
                                                                {notifications[values].map((val: any, j: any) => (
                                                                    <div key={val.id} className='text-white mb-4'>
                                                                        <Link
                                                                            to={`${val.link}`}
                                                                            target='_blank'
                                                                            className="m-0 font14 text-white text-decoration-none"
                                                                        >
                                                                            <div
                                                                                className='mt-2 mx-2 bg-dark rounded-3 align-items-center'
                                                                                style={{ width: '230px', height: '230px' }}
                                                                            >
                                                                                <div className='d-flex justify-content-center py-3'>
                                                                                    {val.cover === null ?
                                                                                        <div
                                                                                            style={{ width: '120px', height: '120px' }}
                                                                                        >
                                                                                            <img src='../images/myprofileimage.png' className='user_avatar rounded-circle' alt={'dummy'} width='100' height='100' />
                                                                                        </div>
                                                                                        :
                                                                                        <div
                                                                                            style={{ width: '120px', height: '120px' }}
                                                                                        >
                                                                                            <img src={`${baseURL}/${val.cover}`} className='user_avatar rounded-circle' alt={'dummy'} width='100%' height='100%' />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <p className='m-0 mt-1 text-center font16'>
                                                                                    {val.title !== null ? val.title : val.from}
                                                                                </p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            :
                                            (
                                                <div className="accordion accordion-flush mt-4" id="notification" key={i}>
                                                    <div className="accordion-item rounded">
                                                        <h2 className="accordion-header" id={`flush-heading${i}`}>
                                                            <button
                                                                className="accordion-button collapsed rounded border text-white"
                                                                style={{ backgroundColor: '#3a3a3c' }}
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#flush-collapsenotification-${i}`}
                                                                aria-expanded="false"
                                                                aria-controls={`flush-collapsenotification-${i}`}
                                                                onClick={() => { readNotification(values) }}
                                                            >
                                                                {replaceUnderscoreAndCapitalize(values)}
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id={`flush-collapsenotification-${i}`}
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby={`flush-heading${i}`}
                                                            data-bs-parent="#notification"
                                                        >
                                                            <div className="accordion-body border mb-5 awards_form theme_color">
                                                                <ul>
                                                                    {notifications[values].map((val: any, j: any) => (
                                                                        <>
                                                                            {val.link !== null ?
                                                                                <Link
                                                                                    to={`${val.link}`}
                                                                                    target='_blank'
                                                                                    className="m-0 font14 text-decoration-none"
                                                                                >
                                                                                    <li key={val.id} className='text-white mb-4'>
                                                                                        <div
                                                                                            className='d-flex justify-content-start align-items-center mt-2'
                                                                                        >
                                                                                            <div>
                                                                                                {val.cover === null ?
                                                                                                    <img src='../images/noimage.png' className='user_avatar rounded-5' alt={'dummy'} width='50' height='50' />
                                                                                                    :
                                                                                                    <div
                                                                                                        style={{ width: '120px', height: '120px' }}
                                                                                                    >
                                                                                                        <img src={`${baseURL}/${val.cover}`} className='user_avatar' alt={'dummy'} width='100%' height='100%' />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className='ms-3'
                                                                                            >
                                                                                                <span className='m-0 font16 fw-bold'>
                                                                                                    {val.title !== null ? val.title : val.from}
                                                                                                </span>
                                                                                                <span className='ms-3 font16'>
                                                                                                    {getGeneratedTime(val.updated_at)}
                                                                                                </span>
                                                                                                <p className="m-0 font14 my-1" style={{ color: '#9c9c9d' }}>
                                                                                                    {val.description}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </Link>
                                                                                :
                                                                                <li key={val.id} className='text-white mb-4'>
                                                                                    <div
                                                                                        className='d-flex justify-content-start align-items-center mt-2'
                                                                                    >
                                                                                        <div>
                                                                                            {val.cover === null ?
                                                                                                <img src='../images/noimage.png' className='user_avatar rounded-5' alt={'dummy'} width='50' height='50' />
                                                                                                :
                                                                                                <div
                                                                                                    style={{ width: '120px', height: '120px' }}
                                                                                                >
                                                                                                    <img src={`${baseURL}/${val.cover}`} className='user_avatar' alt={'dummy'} width='100%' height='100%' />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            className='ms-3'
                                                                                        >
                                                                                            <span className='m-0 font16 fw-bold'>
                                                                                                {val.title !== null ? val.title : val.from}
                                                                                            </span>
                                                                                            <span className='ms-3 font16 fw-bold'>
                                                                                                {getGeneratedTime(val.updated_at)}
                                                                                            </span>
                                                                                            <p className="m-0 font14 my-1" style={{ color: '#9c9c9d' }}>
                                                                                                {val.description}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>
                                ))
                            }
                        </>
                    }
                </LoadingOverlay >
            </div>
        </>
    )
}

export default Notification
