import React, { useEffect, useState } from 'react';
import HomeTopLable from '../UI/HomeTopLable';
import CarouselBanner from '../components/carousel/CarouselBanner';
import MovieCardSlider from '../components/movieslider/MovieCardSlider';
import Accordian from '../UI/Accordian';
import Sponsored from '../UI/sponsor/Sponsored';
import MovieDetails from '../components/singlemoviedetails/MovieDetails';
import LoadingOverlay from 'react-loading-overlay-ts';
import { filmFestivalSlider, getChangeIndustryTypeAPI, newlyAddedMovies, recentlyViewedAPI, sponsoredMovies, todaysBirthdaySlider } from '../utils/utils';
import ShowAddPopUp from '../UI/ShowAddPopUp';
import CategorySSlider from '../components/categoryslider/CategorySSlider';
import NewlyAddedSlider from '../components/newlyaddedmovieslider/NewlyAddedSlider';
import HomeMovieFilter from '../components/homefilter/HomeMovieFilter';
import MultiIndustryModal from '../components/multiindustrymodal/MultiIndustryModal';

const Home = () => {
    // const {user} = useSelector((state:any)=> state.user);
    const connection_id = localStorage.getItem('connection_id');
    const multiIndustryVal = localStorage.getItem('multiindustry');
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(true)
    const [Newly_added_movies, setNewlyAddedMovies] = useState<Array<any>>([]);
    const [todayBirthday, setBirthday] = useState<Array<any>>([]);
    const [filmFestival, setfilmFestival] = useState<Array<any>>([]);
    const [sponsorMovie, setSponsorMovies] = useState<Array<any>>([]);
    const [recentlyViewed, setRecentlyViewed] = useState<Array<any>>([]);
    const [movie_detail_id, setMovieDetailId] = useState<number>();
    const [newlyMovieFilter, setNewlymoviefilter] = useState<any>({});
    const [gen, setGen] = useState<any>();
    const [days, setDays] = useState<any>();
    const [openMultiIndustryModal, setMultiIndustryModal] = useState(true);

    const allSliderData = async () => {
        const data = await todaysBirthdaySlider();
        setBirthday(data);
        const festival = await filmFestivalSlider();
        setfilmFestival(festival)
        const sponsorMovies = await sponsoredMovies();
        setSponsorMovies(sponsorMovies);
        const recentlyView = await recentlyViewedAPI();
        setRecentlyViewed(recentlyView);
        setActive(false);
    }

    const newlyAddedMovie = async () => {
        setActive(true);
        const newMovie = await newlyAddedMovies(days, gen);
        setNewlyAddedMovies(newMovie);
        setActive(false);
    }

    const getIdForMoviedetail = (id: any) => {
        setMovieDetailId(id);
    }

    const filterNewlyMovie = (gen: any, days: any) => {
        setGen(gen);
        setDays(days);
    }

    const handleCloseModal = (val: any) => {
        val ? setMultiIndustryModal(false) : setMultiIndustryModal(false);
    }

    useEffect(() => {
        getChangeIndustryTypeAPI(multiIndustryVal);
        if (multiIndustryVal) {
            setMultiIndustryModal(false);
            getChangeIndustryTypeAPI(multiIndustryVal);
            setTimeout(() => {
                newlyAddedMovie();
                allSliderData();
            }, 3000);
        }
    }, [])

    useEffect(() => {
        newlyAddedMovie();
    }, [gen, days])

    // useEffect(() => {
    //     console.log('newly', Newly_added_movies);
    // }, [Newly_added_movies])

    return (
        <>
            {openMultiIndustryModal ?
                <MultiIndustryModal handleModalBox={handleCloseModal} />
                : null
            }
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <div className='px-lg-5 px-md-5 px-2 mx-lg-3 mx-md-3 mx-0'>
                    {/* <ShowAddPopUp /> */}
                    <div className=''>
                        <HomeTopLable />
                        <CategorySSlider />
                        <CarouselBanner />
                    </div>

                    <div
                        className='mt-5'
                    >
                        <HomeMovieFilter handleFilterFunction={filterNewlyMovie} />
                    </div>

                    {(Array.isArray(Newly_added_movies) && Newly_added_movies.length > 0) &&
                        <div className='my-5'>
                            {/* <MovieCardSlider heading={'Newly<br /> Added Movies'} data={Newly_added_movies} view_all_link={'/allmovies'} /> */}
                            <NewlyAddedSlider selectedId={movie_detail_id} heading={'Newly Added Movies'} data={Newly_added_movies} view_all_link={'/allmovies'} handleSendBackIdParent={getIdForMoviedetail} />
                        </div>
                    }

                    {movie_detail_id &&
                        <div className='my-5'>
                            <MovieDetails id={movie_detail_id} />
                        </div>
                    }

                    {/* <div className='my-5'>
                    <Accordian />
                </div> */}

                    {(Array.isArray(todayBirthday) && todayBirthday.length > 0) &&
                        <div className='my-5'>
                            <MovieCardSlider heading={"Birthdays"} leftIndicator={true} data={todayBirthday} view_all_link={'/menu/birthdays'} birthday={true} />
                        </div>
                    }

                    {(Array.isArray(filmFestival) && filmFestival.length > 0) &&
                        <div className='my-5'>
                            <MovieCardSlider heading={"Film Festivals"} horizontalImage={true} data={filmFestival} view_all_link={'/menu/filmfestival'} />
                        </div>
                    }

                    {/* <div className='my-5'>
                    <MovieCardSlider heading={"On Cinema DBS OTT"} leftIndicator={true} data={Newly_added_movies} />
                </div> */}

                    {/* <div className='my-5'>
                    <MovieCardSlider heading={"Other Film Industry"} horizontalImage={true} data={otherFilmIndustries} />
                </div> */}

                    {(Array.isArray(recentlyViewed) && recentlyViewed.length > 0) &&
                        <div className='my-5'>
                            <MovieCardSlider heading={"Recently Viewed"} leftIndicator={true} data={recentlyViewed} />
                        </div>
                    }

                    {(Array.isArray(sponsorMovie) && sponsorMovie.length > 0) &&
                        <div className='my-5'>
                            <MovieCardSlider heading={"Sponsored Movies"} data={sponsorMovie} view_all_link={'/allsponsoredmovies'} />
                        </div>
                    }

                    <div className='my-5'>
                        <Sponsored />
                    </div>

                    {/* <div className='my-5'>
                    <CommonSlider /> 
                </div> */}
                    {/* <div className='my-5'>
                    <VideoSlider data={yutubeLink} /> 
                </div> */}
                </div>
            </LoadingOverlay>
        </>
    )
}

export default Home
