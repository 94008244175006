import React, { useEffect, useState } from 'react';
import '../moviecard/moviecard.css';
import { baseURL } from '../../utils/api';
import { addToWatchListAPI, likeAndViewAPI } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import SignInModal from '../signinmodal/SignInModal';
import CardRating from '../ratings/cards/CardRating';
import { BsFillBookmarkPlusFill } from 'react-icons/bs';

const NewsNewlyAddedCard = (props: any) => {
    const { id, selectedId, cover, title, dob, genres, like, watch, handleSendBackIdToParent, cdbs_rating, rating } = props;
    const auth_code = localStorage.getItem("auth_code");
    const [show, setShow] = useState(false);
    const messageTxt = (message: any) => toast(message);
    const [toggle, setToggle] = useState(like);
    const [addToWatch, setWatchList] = useState(watch);

    const addRecentView = (action: any, id: any) => {
        likeAndViewAPI(action, id);
    }
    // const giveLike = async (e: any, action: any, id: any) => {
    //     e.stopPropagation();
    //     if (!auth_code) {
    //         setShow(true);
    //     } else {
    //         const msg = await likeAndViewAPI(action, id);
    //         console.log(msg);
    //         messageTxt(msg);
    //         setToggle(!toggle);
    //     }
    // }
    // const addWatchList = async (e: any, id: any) => {
    //     e.stopPropagation();
    //     if (!auth_code) {
    //         setShow(true);
    //     } else {
    //         const msg = await addToWatchListAPI(id);
    //         console.log(msg)
    //         setWatchList(!addToWatch)
    //     }
    // }

    return (
        <div className='d-flex justify-content-center pointer'  onClick={() => addRecentView('view', id)} style={{ width: 'fit-content' }}>
            <Link to={`/video-detail/${title}-${id}`} className='text-decoration-none'>
                <div className="image" key={id}>
                    <div className='position-relative movie_card_section'>
                        <div className='position-relative'>
                            <img src={`${baseURL}/${cover}`} className="rectangle rounded-4" alt={title} />
                            <div
                                className='position-absolute'
                                style={{
                                    top: '1%',
                                    right: '3%'
                                }}
                            >
                                {/* <span
                                className={`font34`}
                                style={{
                                    color: `${addToWatch ? 'rgb(232, 215, 66)' : '#4c4c4c'}`,
                                    zIndex: '2'
                                }}
                                onClick={(e: any) => addWatchList(e, id)}
                            >
                                <BsFillBookmarkPlusFill />
                            </span> */}
                            </div>
                        </div>
                        <div
                            className='position-absolute text-white d-flex align-items-center w-100 movie_card_icons ps-2 rounded-bottom-4'
                            style={{
                                bottom: '0px',
                                left: '0px',
                                zIndex: 1,
                            }}
                        >
                            <CardRating id={id} cdbs_rating={cdbs_rating} rating={rating} IsUserTating={false} />
                            {/* <span>
                                <AiOutlineLike
                                    className={`${toggle ? 'text-primary' : ''}`}
                                    onClick={(e: any) => giveLike(e, 'like', id)}
                                />
                            </span>
                            <span>
                                <AiOutlineHeart
                                    className={`${addToWatch ? 'text-danger' : ''}`}
                                    onClick={(e: any) => addWatchList(e, id)}
                                />
                            </span>
                            <span>
                                <AiOutlineShareAlt />
                            </span> */}
                        </div>
                    </div>

                    {genres ?
                        <p className={`m-0 font14 ps-1 mt-2 text-white`}>{genres}</p> : null
                    }
                    {dob ?
                        <p className='m-0 text-white font14 mt-2'>{dob}</p> : null
                    }
                    {title ?
                        <p className={`m-0 font16 font_bebas_neue ps-1 font_weight_600 text-white`}>{title}</p> : null
                    }
                </div>
            </Link>
        </div>
    )
}

export default NewsNewlyAddedCard
