import React, { useState, useEffect } from 'react';
import './style.moviedetail.css';
import { BiLike } from "react-icons/bi";
import HomeSynopsisSlider from '../homemoviedetailslider/HomeSynopsisSlider';
import { BsFillBookmarkPlusFill } from 'react-icons/bs';
import { IoMdShare, IoMdStar } from "react-icons/io";
import { FaCaretSquareDown, FaRegStar } from 'react-icons/fa';
import CardRating from '../ratings/cards/CardRating';
import { addToWatchListAPI, giveRatingAPI, likeAndViewAPI } from '../../utils/utils';
import SignInModal from '../signinmodal/SignInModal';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const Synopsis = (props: any) => {
    const { data } = props;
    const messageTxt = (message: any) => toast(message);
    const auth_code = localStorage.getItem("auth_code");
    const [showLogin, setShowLogin] = useState(false);
    const [toggle, setToggle] = useState<any>();
    const [addToWatch, setWatchList] = useState<any>();
    const [ratings, setRating] = useState<any>();

    const addBookMarkList = async (id: any) => {
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const msg = await addToWatchListAPI(id);
            messageTxt(msg);
            setWatchList(!addToWatch)
        }
    }

    const giveLike = async (e: any, action: any, id: any) => {
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const msg = await likeAndViewAPI(action, id);
            messageTxt(msg);
            setToggle(!toggle);
        }
    }

    const handleLoginModalBox = (val: any) => {
        val ? setShowLogin(false) : setShowLogin(false);
    }

    const copyToClipboard = () => {
        const currentUrl = window.location.href;

        navigator.clipboard.writeText(currentUrl + `/video-detail/${removeDotReplaceSpaceToHyphen(data?.title)}-${data?.id}`)
            .then(() => {
                messageTxt('URL copied to clipboard !');
                // console.log('URL copied to clipboard!');
            })
            .catch((err) => {
                messageTxt('Error copying to clipboard !');
                // console.error('Error copying to clipboard:', err);
            });
    };

    const giveRatingToMovie = async (e: any, givenRating: any) => {
        e.stopPropagation();
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const msg = await giveRatingAPI(data?.id, givenRating);
            setRating(givenRating);
        }
    }

    useEffect(() => {
        setToggle(data?.like);
        setWatchList(data?.watch);
        setRating(data?.rating);
        // console.log(ratings);
    }, [data])

    return (
        <div className='px-2'>
            {showLogin ?
                <SignInModal handleModalBox={handleLoginModalBox} />
                : null
            }
            <div className='movie-detail text-white'>
                <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(data?.movie_title)}-${data?.id}`} className='text-decoration-none'>
                    <p className='text-warning m-0 text-end pe-2 font26 font_weight_600'>{data?.movie_title}</p>
                    <p className='text-white m-0 text-end pe-2 mt-1 font22 font_weight_600'>{data?.movie_title_native_language}</p>
                </Link>
                <div
                    className='d-lg-flex d-md-flex d-block justify-content-lg-between justify-content-md-between justify-content-between align-items-baseline'
                >
                    <ul className='response-icon px-2 font26 d-flex align-items-center'>
                        <li>
                            <span
                                className={`font34 pointer`}
                                style={{
                                    color: `${addToWatch ? 'rgb(232, 215, 66)' : '#4c4c4c'}`,
                                    zIndex: '2'
                                }}
                                onClick={() => addBookMarkList(data?.id)}
                            >
                                <BsFillBookmarkPlusFill />
                            </span>
                        </li>
                        <li className='mx-lg-3 mx-md-3 mx-0'>
                            <BiLike
                                className={`pointer ${toggle ? 'text-primary' : ''}`}
                                onClick={(e: any) => giveLike(e, 'like', data?.id)}
                            />
                        </li>
                        <li>
                            <span
                                className='text-white me-2 pointer'
                                onClick={copyToClipboard}
                            >
                                <IoMdShare />
                            </span>
                        </li>
                    </ul>
                    {/* <CardRating id={data?.id} cdbs_rating={data?.cdbs_rating} rating={ratings} font={'font12'} /> */}
                    <div>
                        <div
                            className='lh-1'
                        >
                            <span
                                className={`font12 font_weight_600 text-warning text-uppercase me-1`}
                            >
                                Rate now
                            </span>
                            <span
                                className='font20 text-white ms-2'
                            >
                                {
                                    [...Array(5)].map((star, index) => {
                                        const givenRating = index + 1;
                                        return (
                                            <FaRegStar
                                                className='me-1 pointer'
                                                onClick={(e) => {
                                                    giveRatingToMovie(e, givenRating)
                                                }}
                                                key={givenRating}
                                                style={{
                                                    color: (givenRating <= ratings || givenRating === ratings) ? '#FFC107' : '#D9D9D9'
                                                }}
                                            />
                                        )
                                    })
                                }
                            </span>
                        </div>
                        <div
                            className=''
                        >
                            <span
                                className={`font12 font_weight_600 text-white text-uppercase me-1`}
                            >
                                CDBS RATING
                            </span>
                            <span
                                className='font24 text-warning'
                            >
                                {
                                    [...Array(5)].map((star, index) => {
                                        const givenRating = index + 1;
                                        return (
                                            <IoMdStar
                                                className='pointer'
                                                key={index}
                                                style={{
                                                    color: (givenRating <= data?.cdbs_rating || givenRating === data?.cdbs_rating) ? 'yellow' : '#D9D9D9'
                                                }}
                                            />
                                        )
                                    })
                                }
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    className='ps-2 d-flex justify-content-between align-items-center'
                >
                    <div>
                        <span
                            className='font12 font_weight_600 text-warning text-uppercase me-1'
                        >
                            LANGUAGE:
                        </span>
                        <span
                            className='font12 text-white'
                        >
                            {data?.language}
                        </span>
                    </div>
                    <div>
                        <span
                            className='font12 font_weight_600 text-warning text-uppercase me-1'
                        >
                            STATUS:
                        </span>
                        <span
                            className='font12 text-white'
                        >
                            {data?.status_of_project}
                        </span>
                    </div>
                </div>

                <div
                    className='ps-2 d-flex justify-content-between align-items-center'
                >
                    <div>
                        <span
                            className='font12 font_weight_600 text-warning text-uppercase me-1'
                        >
                            GENRE:
                        </span>
                        <span
                            className='font12 text-white'
                        >
                            {data?.genre}
                        </span>
                    </div>
                    <div>
                        <span
                            className='font12 text-warning font_weight_600 text-uppercase me-3'
                        >
                            WATCH NOW
                        </span>
                        <span
                            className='font20 carret_bg_color'
                        >
                            <FaCaretSquareDown />
                        </span>
                    </div>
                </div>

            </div>
            {data?.movie_synopsis ?
                <div className='text-white font16 my-3 px-2 truncate'>
                    <b>Synopsis</b> : {data?.movie_synopsis}
                </div>
                : null
            }
            {Array.isArray(data?.cast) &&
                <div>
                    <HomeSynopsisSlider data={data?.cast} />
                </div>
            }
            <ToastContainer />
        </div>
    )
}

export default Synopsis
