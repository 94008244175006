import React, { useEffect, useState } from 'react';
import { baseURL } from '../../utils/api';
import { AiOutlineLike, AiOutlineShareAlt, AiOutlineHeart } from 'react-icons/ai';
import { addToWatchListAPI, likeAndViewAPI } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { getYearFromDate, removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const FilmographyCard = (props: any) => {
    const { id, cover, title, year } = props;
    const auth_code = localStorage.getItem("auth_code");
    return (
        <div className='d-flex justify-content-center pointer' style={{ width: 'fit-content' }}>
            <div className="image" key={id}>
                <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(title)}-${id}`} className='text-decoration-none'>
                    <img src={`${baseURL}/${cover}`} className="rounded-4 profileImage" alt={title} />
                </Link>
                {title ?
                    <p className='m-0 text-white font18 font_weight_600 font_bebas_neue mt-2'>{title}</p> : null
                }
                {year ?
                    <p className='m-0 text-white fw-bold font_weight_600 font14 mt-1'>{getYearFromDate(year)}</p> : null
                }
            </div>
        </div>
    )
}

export default FilmographyCard
