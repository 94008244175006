import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllIndustryTypeAPI, getSelectedIndustryListAPI } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import SubscriberIndustryModal from '../myprofilemodals/SubscriberIndustryModal';
import { user } from '../../states/reducers';

const SubscriberIndustry = () => {

    LoadingOverlay.propTypes = undefined;
    const profile_data = useSelector((state: any) => state.user.user);
    const dispatch = useDispatch();
    const messageTxt = (message: any) => toast(message);
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [industryList, setIndustryList] = useState<any>();
    const [selectIndustry, setSelectIndustry] = useState<any>(profile_data.industries);

    const getIndustryList = async () => {
        setActive(true);
        const response = await getAllIndustryTypeAPI();
        setIndustryList(response);
        setActive(false);
    }

    const handleEditSubscriberIndustry = () => {
        setOpenModal(!openModal);
        getIndustryList();
    }

    const handleChangeValue = (e: any) => {
        const { value, checked } = e.target;
        const parsedValue = parseInt(value);

        if (checked) {
            setSelectIndustry([...selectIndustry, parsedValue]);
        } else {
            setSelectIndustry(selectIndustry.filter((prof: any) => prof !== parsedValue));
        }
    }

    const handleSubmitIndustry = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem("connection_id");
        const auth_code = localStorage.getItem('auth_code');
        const body = {
            connection_id,
            auth_code,
            industries: JSON.stringify(selectIndustry)
        };
        if (!selectIndustry || selectIndustry.length === 0) {
            messageTxt('Please select your Industry !')
            setActive(false);
        } else {
            try {
                const res = await axios.post(`${baseURL}/api/subscriber/industry/add`, body);
                messageTxt(res.data.message);
                setActive(false);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            getIndustryList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('input', selectIndustry);
    // }, [selectIndustry])

    return (
        <div className='subscriber_industry_accordion mt-3'>
            <div className="accordion accordion-flush" id="SubscriberIndustry">

                <div className="accordion-item rounded">
                    <h2 className="accordion-header" id="flush-headingSubscriberIndustry">
                        <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSubscriberIndustry" aria-expanded="false" aria-controls="flush-collapseSubscriberIndustry">
                            Subscriber Industry
                        </button>
                    </h2>
                    <div id="flush-collapseSubscriberIndustry" ref={myElementRef} className="accordion-collapse collapse" aria-labelledby="flush-headingSubscriberIndustry" data-bs-parent="#SubscriberIndustry">
                        <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                            {openModal &&
                                <SubscriberIndustryModal handleModalBox={handleEditSubscriberIndustry} industryList={industryList} headingText={'Film Industry'} />
                            }

                            <LoadingOverlay
                                active={isActive}
                                spinner
                                text='Loading your content...'
                            >
                                <div
                                    className='row'
                                >
                                    {Array.isArray(industryList) && industryList.length > 0 && industryList.map((val, idx) => (
                                        <div key={val.id} className="col-lg-2 col-md-6 col-12 mb-3 text-white font16">
                                            <Form.Check
                                                type='checkbox'
                                                id={String(val.id)}
                                                label={String(val.title)}
                                                value={val.id}
                                                onChange={handleChangeValue}
                                                checked={selectIndustry.includes(val.id)}
                                            />
                                        </div>
                                    ))}
                                </div>

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        <button
                                            className='py-2 px-2 border me-3 rounded font_weight_600'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleEditSubscriberIndustry}
                                        >
                                            Edit
                                        </button>

                                        {!isDisabled ?
                                            <button
                                                className='py-2 px-2 border-0 common_button rounded font_weight_600'
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                                onClick={handleSubmitIndustry}
                                            >
                                                Save
                                            </button>
                                            : null
                                        }
                                    </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SubscriberIndustry
