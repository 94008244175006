import axios from "axios";
import { app_key, baseURL } from "./api";
import { differenceInYears } from 'date-fns';

export async function getConnectionId() {
    let body = {
        app_key
    };
    await axios(`${baseURL}/api/get-connection`, {
        method: "post",
        data: body,
    })
        .then((res) => {
            localStorage.setItem("connection_id", res.data.data.connection_id);
        })
        .catch((err) => {
            console.log("Error:", err);
        });
};

export const getStringToIntArray = (arr) => {
    return arr.reduce((result, item) => {
        if (Array.isArray(item)) {
            result = result.concat(getStringToIntArray(item));
        } else if (!isNaN(item)) {
            result.push(Number(item));
        }
        return result;
    }, []);
}

// replace '_' into ' ' and capitalize string
export const replaceUnderscoreAndCapitalize = (inputString) => {
    let stringWithSpaces = inputString.replace(/_/g, ' ');
    let capitalizedString = stringWithSpaces.replace(/\b\w/g, match => match.toUpperCase());
    return capitalizedString;
}

// get banner
export const getBannerAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                banner_type_id: 4
            };
            let response = await axios.post(`${baseURL}/api/banner_list`, body);
            if (response?.data && response?.data?.data) {
                return Object.values(response?.data?.data);
            } else {
                console.error('Invalid response data:', response.data);
                // Handle the case where response data is not as expected.
            }
        } catch (error) {
            console.log(error);
        }
    }
}

// get all industry types
export const getAllIndustryTypeAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/master/industry_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// selected industry 
export const getSelectedIndustryListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
    };
    try {
        const response = await axios.post(`${baseURL}/api/subscriber/industry/list`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// change industry type
export const getChangeIndustryTypeAPI = async (industry_id) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                industry_id
            }
            let result = await axios.post(`${baseURL}/api/set/industry`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// Happy movement list
export const getHappyMovementListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
    };
    try {
        const response = await axios.post(`${baseURL}/api/subscriber/happy_moments/list`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// other achievement list
export const getOtherAchievementListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
    };
    try {
        const response = await axios.post(`${baseURL}/api/subscriber/other/achievements/list`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// show reel list 
export const getShowReelListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
    };
    try {
        const response = await axios.post(`${baseURL}/api/subscriber/showreels/list`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// political involment list 
export const getPoliticalInvolmentListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
    };
    try {
        const response = await axios.post(`${baseURL}/api/subscriber/political_involvement/list`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// media review list
export const getMediaReviewListAPI = async (project_id) => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
        project_id
    };
    try {
        const response = await axios.post(`${baseURL}/api/project/list/media_reviews`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// controversy list
export const getControversyListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
    };
    try {
        const response = await axios.post(`${baseURL}/api/subscriber/controversies/list`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// Q&A API
export const getQandAAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/faq`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get user location and return near events
export const nearEventsAPI = async (latitude, longitude) => {
    const connection_id = localStorage.getItem('connection_id');
    if (connection_id) {
        try {
            let body = {
                connection_id,
                latitude,
                longitude
            }
            let result = await axios.post(`${baseURL}/api/menu/event_list/nearby`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get master profession 
export const getMasterProfessionListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const body = {
        connection_id
    };
    try {
        const response = await axios.post(`${baseURL}/api/get-master-professions`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// get profession list
export const getProfessionListAPI = async (selectedMaster) => {
    const connection_id = localStorage.getItem("connection_id");
    const body = {
        connection_id,
        master_profession_id: JSON.stringify(selectedMaster)
    };
    try {
        const response = await axios.post(`${baseURL}/api/get-professions`, body);
        return response.data.data;

    } catch (error) {
        console.error('Error:', error);
    }
}

// get sub-profession list 
export const getSubProfessionListAPI = async (selectedProfession) => {
    const connection_id = localStorage.getItem("connection_id");
    const body = {
        connection_id,
        profession_id: JSON.stringify(selectedProfession)
    };
    try {
        const response = await axios.post(`${baseURL}/api/get-sub-professions`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// get discography list
export const getDiscographyListAPI = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem('auth_code');
    const body = {
        connection_id,
        auth_code,
    };
    try {
        const response = await axios.post(`${baseURL}/api/subscriber/discography/list`, body);
        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

// master class list
export const getAllMasterClassesAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/master_class/my_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// master class single data
export const getMasterClassDetailAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/master_class/detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// delete master class
export const deleteMasterClassAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/master_class/delete`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// delete master class video
export const deleteMasterClassVideoAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/master_class/delete_video`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// home banner api
export const getHomeBannerAPI = async (banner_type_id) => {
    const connection_id = localStorage.getItem('connection_id');
    if (connection_id) {
        try {
            let body = {
                connection_id,
                banner_type_id
            }
            let result = await axios.post(`${baseURL}/api/banner_data`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get All pages 
export const getFooterPagesAPI = async (url) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if ((connection_id && auth_code) || connection_id) {
        try {
            let body = {
                connection_id,
                auth_code,
                url
            }
            let result = await axios.post(`${baseURL}/api/page`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// notification list
export const getAllNotification = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/notification_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// notification read only one section
export const notificationReadAPI = async (type) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                type
            }
            let result = await axios.post(`${baseURL}/api/notification/read`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// get All movies
export const getAllMoviesAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/all_project_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// search movies 
export const getSearchMoviesAPI = async (movie_name) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                movie_title: movie_name
            }
            let result = await axios.post(`${baseURL}/api/search/movie`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// search profile
export const getSearchProfileAPI = async (where) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                search: where.textSearch
            }
            let result = await axios.post(`${baseURL}/api/search/${where.select_for_search}`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getUserDetailsAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/subscriber/details`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// recently added viewed all
export const getAllRecentlyAddedAPI = async (page) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id || auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                page
            }
            let result = await axios.post(`${baseURL}/api/view_all/recently_added`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// sponsored all movies
export const getAllSponsoredAddedAPI = async (page) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id || auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                page
            }
            let result = await axios.post(`${baseURL}/api/view_all/sponsored_movies`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getAllProjects = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/project/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get written script list
export const getWrittenScriptListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/subscriber/scripts/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get family members list 
export const getFamilyMemberListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/subscriber/familyMembers/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get kyc list
export const getKycListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/subscriber/kyc/List`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getProjectDetails = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/project/detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getMovieDetails = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/movie_detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getProjectStatus = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/master/project_status_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getFilmType = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/master/film_type_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getAllLanguage = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem("auth_code");

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ connection_id, auth_code })
    };

    try {
        const response = await fetch(`${baseURL}/api/master/language-list`, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.data;

    } catch (error) {
        console.error('Error:', error);
    }
}

export const getAllGenres = async () => {
    const connection_id = localStorage.getItem("connection_id");
    const auth_code = localStorage.getItem("auth_code");

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ connection_id, auth_code })
    };

    try {
        const response = await fetch(`${baseURL}/api/master/genre-list`, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.data;

    } catch (error) {
        console.error('Error:', error);
    }
}

export const getAllFilmRights = async () => {
    const connection_id = localStorage.getItem("connection_id");

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ connection_id })
    };

    try {
        const response = await fetch(`${baseURL}/api/master/film-rights`, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.data;

    } catch (error) {
        console.error('Error:', error);
    }
}

export const newlyAddedMovies = async (days, gen) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id) {
        try {
            let body = {
                connection_id,
                auth_code,
                filter: gen,
                genre: days
            }
            let result = await axios.post(`${baseURL}/api/newly_added_movies`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const todaysBirthdaySlider = async () => {
    const connection_id = localStorage.getItem("connection_id");

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ connection_id })
    };

    try {
        const response = await fetch(`${baseURL}/api/todays_birthday`, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.data;

    } catch (error) {
        console.error('Error:', error);
    }
}

export const filmFestivalSlider = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/film_festival`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const sponsoredMovies = async (days, gen) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/sponsored_movies`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const deleteProject = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/project/destroy-project`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// face shape
export const getFaceShapAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/master/face_profile-list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get awards and honors list
export const getAwardAndHonorsListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/subscriber/awards/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// assets list 
export const getAssetsListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/subscriber/assets/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// Legends API
export const getLegends = async (type, page) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                type,
                page
            }
            let result = await axios.post(`${baseURL}/api/menu/legends`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getLegendsDetails = async (id) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                id
            }
            let result = await axios.post(`${baseURL}/api/menu/legend/detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getBirthdays = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/menu/birthdays`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// Birthdays wishes
export const sendBirthdayWishes = async (id, message) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                to_id: id,
                message
            }
            let result = await axios.post(`${baseURL}/api/send_birthday_wish`, body);
            return result.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// Events API
export const getAllEventsCategory = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/eventGenres`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getAllCategorydetail = async (id) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                event_genre_id: id
            }
            let result = await axios.post(`${baseURL}/api/menu/event_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getEventdetail = async (id) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                event_id: id
            }
            let result = await axios.post(`${baseURL}/api/menu/event_detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getEventForUpcomming = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/menu/event_list/upcoming`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// Film Festival List and events
export const getAllFestivalListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/film_festival/my_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// audition call list
export const getAuditionCallListAPI = async (limit, page, time_filter, search_location) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                limit,
                page,
                time_filter,
                search_location
            }
            let result = await axios.post(`${baseURL}/api/menu/audition_call_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// audition call detail
export const getAuditionCallDetailAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");
    if (connection_id) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/menu/audition_call/detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// master class home ui list 
export const getMasterClassListAPI = async (limit, page) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                limit,
                page
            }
            let result = await axios.post(`${baseURL}/api/menu/master_class_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// master class ui details
export const getUiMasterClassDetailAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/menu/master_class_detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// master class video like and view count
export const masterClassActionAPI = async (id, action) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                action,
                master_class_video_id: id
            }
            let result = await axios.post(`${baseURL}/api/master_class/action`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}
// subscribe master class
export const subscribeMasterClassAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/master_class/subscribe`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// film institute list for crud
export const getAllInstituteListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/filmInstitutes`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// get institute course list
export const getAllInstituteCourseListAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                institute_id: id
            }
            let result = await axios.post(`${baseURL}/api/instituteCourses`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// delete institute course
export const deleteCourseAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/instituteCourse/delete`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// UI institute list
export const getInstituteListAPI = async (limit, page) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");
    if (connection_id) {
        try {
            let body = {
                connection_id,
                auth_code,
                limit,
                page
            }
            let result = await axios.post(`${baseURL}/api/menu/institute_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// UI institute detail
export const getInstituteDetailAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");
    if (connection_id) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/menu/institute_detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getSingleFestivalAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/film_festival/detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getFestivals = async (filterOpt) => {
    const { month, year } = filterOpt;
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                month: month,
                year: year
            }
            let result = await axios.post(`${baseURL}/api/menu/film_festival_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getSingleFestivals = async (id) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                id: id
            }
            let result = await axios.post(`${baseURL}/api/menu/film_festival/detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// Nomination List
export const getNominationList = async (festival_id, sub_category_id, type) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                film_festival_id: festival_id,
                event_sub_category_id: sub_category_id,
                type: type
            }
            let result = await axios.post(`${baseURL}/api/film_festival/nominations/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// delete nimination
export const deleteNominationAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/film_festival/nominations/delete`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

export const giveVoteforNominees = async (sc_id, nominee_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                event_sub_category_id: sc_id,
                nominee_id: nominee_id
            }
            let result = await axios.post(`${baseURL}/api/film_festival/give_vote`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getCategoryListAPI = async (id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                id
            }
            let result = await axios.post(`${baseURL}/api/film_festival/category/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getSubCategoryListAPI = async (festival_id, festival_cat_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                film_festival_id: festival_id,
                film_festival_category_id: festival_cat_id
            }
            let result = await axios.post(`${baseURL}/api/film_festival/sub_category/list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// create nominee
export const createNomineeAPI = async (festival_id, category_id, sub_category_id, nomination) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                event_id: festival_id,
                event_category_id: category_id,
                event_sub_category_id: sub_category_id,
                project_id: JSON.stringify(nomination)
            }
            let result = await axios.post(`${baseURL}/api/film_festival/nominations/create`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// like and view API
export const likeAndViewAPI = async (action, id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                action,
                project_id: id
            }
            let result = await axios.post(`${baseURL}/api/action`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// give rating 
export const giveRatingAPI = async (id, r_count) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                rating: r_count,
                project_id: id
            }
            let result = await axios.post(`${baseURL}/api/rate_movie`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

export const recentlyViewedAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem("auth_code");

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
            }
            let result = await axios.post(`${baseURL}/api/recently_viewed`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    } else {
        return [];
    }
}

// watchList API
export const viewWatchListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/watch_list/view`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// add to watch list
export const addToWatchListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/watch_list`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

export const homeHighLightsAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/highlights`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// pagination function
export const totalPagesCalc = (total, limit) => {
    let pages = [];
    for (let x = 1; x <= Math.ceil(total / limit); x++) {
        pages.push(x);
    }
    return pages;
}

// Date Formate 
export const formatDate = (date) => {
    const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    return date.toLocaleString('en-US', options);
}

// Calculate Age
export const calculateAge = (birthDate, deathDate) => {
    if (deathDate) {
        return differenceInYears(new Date(deathDate), new Date(birthDate));
    } else {
        const today = new Date();
        return differenceInYears(today, new Date(birthDate));
    }
};


// shop modules APIs

export const getEcomCategoriesAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/category`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getEcomProductListAPI = async (subcategory_id) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                subcategory_id
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/products_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getEcomProductDetailsAPI = async (inventory_id) => {
    const connection_id = localStorage.getItem('connection_id');

    if (connection_id) {
        try {
            let body = {
                connection_id,
                inventory_id
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/productDetails`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const addToCartProductAPI = async (inventory_id, quantity) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                inventory_id,
                quantity
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/addToCart`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getCartListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/show_cart`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// update order product quantity
export const updateEcomQuantityAPI = async (inventory_id, cart_id, quantity) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                inventory_id,
                cart_id,
                quantity
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/update_cart`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// delete order item in cart
export const deleteEcomItemAPI = async (inventory_id, cart_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                inventory_id,
                cart_id
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/remove_item`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// add adress for shipping
export const addShippingAddressEcomAPI = async (data) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');
    const { first_name, last_name, email, landmark, address, city, state, zip_code, street_address } = data;

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                first_name, last_name, email, landmark, address, city, state, zip_code, street_address
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/shipping_details`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// get user's address
export const getAllUserAddressEcomAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/shipping_address_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// place order
export const placedOrderEcomAPI = async (cart_id, shipping_id, payment_method_id, inventory_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                cart_id,
                shipping_id,
                payment_method_id,
                inventory_id
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/place_order`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// placed order list
export const getOrderListEcomAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/order_list`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// cancel order
export const cancelOrderEcomAPI = async (order_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                order_id
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/cancel_order`, body);
            return result.data.message;
        } catch (error) {
            console.log(error);
        }
    }
}

// product details ecom
export const getOrderDetailsEcomAPI = async (order_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                order_id
            }
            let result = await axios.post(`${baseURL}/api/e-commerce/order_detail`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const WhoIntroduceListAPI = async () => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code
            }
            let result = await axios.post(`${baseURL}/api/subscriber/introduced_by`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

// project list section

export const getCastListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/project/list/cast`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getCrewListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/project/list/crew`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getMovieRefListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/project/list/reference_link`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getAwardListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/project/list/awards`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getSongLinkListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/project/list/songs`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export const getTTLinkListAPI = async (project_id) => {
    const connection_id = localStorage.getItem('connection_id');
    const auth_code = localStorage.getItem('auth_code');

    if (connection_id && auth_code) {
        try {
            let body = {
                connection_id,
                auth_code,
                project_id
            }
            let result = await axios.post(`${baseURL}/api/project/list/teaser-trailers`, body);
            return result.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}