import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const FamilyModal = (props: any) => {

    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [family_id, setFamilyId] = useState<any>();
    const [family, setFamily] = useState({
        family_member: '',
        name: '',
        birth_year: '',
        death_year: '',
        relationship: '',
        image: '',
        member_profession: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChangeFamilyVal = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setFamily((prevVal: any) => ({ ...prevVal, image: image }));
        } else {
            setFamily({ ...family, [name]: value });
        }
    }

    const handleSubmitFamily = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { family_member, name, birth_year, death_year, relationship, image, member_profession } = family;
        if (name === '' || family_member === '') {
            messageTxt('Please enter a name and family member !');
            setActive(false);
        } else if (birth_year.length > 4 || birth_year.length < 4) {
            messageTxt('Please enter 4 digit year !');
            setActive(false);
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    family_member,
                    name,
                    birth_year,
                    death_year,
                    relationship,
                    image,
                    member_profession,
                    family_id: family_id
                }
                let result = await axios(`${baseURL}/api/subscriber/family/addUpdate`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setFamily({
                    family_member: '',
                    name: '',
                    birth_year: '',
                    death_year: '',
                    relationship: '',
                    image: '',
                    member_profession: ''
                })
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    };

    const getData = () => {
        setActive(true);
        if (data) {
            setFamilyId(data.id);
            setFamily({
                family_member: data.family_member,
                name: data.name,
                birth_year: data.birth_year,
                death_year: data.death_year,
                relationship: data.relationship,
                image: data.image,
                member_profession: data.member_profession,
            })
        }
        setActive(false);
    }

    useEffect(() => {
        getData();
    }, [data])


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center modal_form_add_update">
                        <div className='col-lg-6 col-md-6 col-12 written_script_multi_select'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Family member</label>
                            <select className="form-select mb-3" onChange={handleChangeFamilyVal} value={family.family_member} name="family_member" aria-label="Default select example">
                                <option selected>Select family member...</option>
                                <option value={'Father'}>Father</option>
                                <option value={'Mother'}>Mother</option>
                                <option value={'Brother'}>Brother</option>
                                <option value={'Sister'}>Sister</option>
                                <option value={'Son'}>Son</option>
                                <option value={'Daughter'}>Daughter</option>
                                <option value={'Spouse'}>Spouse</option>
                                <option value={'Relatives'}>Relatives</option>
                            </select>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                            <input type="text" onChange={handleChangeFamilyVal} value={family.name} name="name" placeholder='Enter family member name' className="form-control mb-3" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Birth year</label>
                            <input type="number" onChange={handleChangeFamilyVal} value={family.birth_year} name="birth_year" className="form-control mb-3" placeholder='eg: 1990' />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Death year</label>
                            <input type="number" onChange={handleChangeFamilyVal} value={family.death_year} name="death_year" className="form-control mb-3" placeholder='eg: 2024' />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Relationship</label>
                            <input type="text" onChange={handleChangeFamilyVal} value={family.relationship} name="relationship" className="form-control mb-3" placeholder='eg: friend' />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Family member photo</label>
                            <input type="file" onChange={handleChangeFamilyVal} name="image" className="form-control mb-3" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Family member profession</label>
                            <input type="text" onChange={handleChangeFamilyVal} value={family.member_profession} name="member_profession" className="form-control mb-3" placeholder='eg: actor, dancer, professor etc.' />
                        </div>

                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                            <button
                                className='py-2 px-2 bg-danger border-0 rounded fw-bold save_button'
                                style={{
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleSubmitFamily}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default FamilyModal
