import React, { useState } from 'react';
import { AiOutlineLike } from 'react-icons/ai';
import { BsFillBookmarkPlusFill } from 'react-icons/bs';
import { FaRegStar } from 'react-icons/fa';
import { ImShare2 } from 'react-icons/im';
import LoadingOverlay from 'react-loading-overlay-ts';

const OttBanner = () => {

    const [isActive, setActive] = useState(false);

    const banner = [
        { id: '1', image: '../images/ottbanner1.png', title: 'K.G.F: Chapter 2' },
        { id: '2', image: '../images/ottbanner1.png', title: 'K.G.F: Chapter 2' },
    ];

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div
                            className='row'
                            style={{
                                backgroundColor: '#171a1f'
                            }}
                        >
                            <div
                                className='col-lg-7 col-md-12 col-12 py-lg-3 py-md-3 py-0 ps-lg-5 ps-md-4 ps-0 px-lg-0 px-md-4 px-0'
                            >
                                <div>
                                    <img src={`${banner[0].image}`} className="d-block w-100" alt={banner[0].title} />
                                </div>
                            </div>
                            <div
                                className='col-lg-5 col-md-12 col-12 px-lg-3 px-md-4 px-4'
                            >
                                <p
                                    className='m-0 text-white font30 font_weight_600 mt-2'
                                >
                                    {banner[0].title}
                                </p>
                                <div
                                    className='d-flex align-items-baseline'
                                >
                                    <div
                                        className='bg-warning px-2 d-flex align-items-baseline rounded-pill me-3 my-3'
                                        style={{
                                            bottom: '-10px',
                                            right: '15px'
                                        }}
                                    >
                                        <FaRegStar className='me-1 text-dark' />
                                        <span className='font18 text-dark'>
                                            {0}
                                        </span>
                                    </div>
                                    <p
                                        className='text-white font16 font_weight_600'
                                    >
                                        2022 Indian Kannada-language period action film
                                    </p>
                                </div>
                                <div
                                    className='mb-4'
                                >
                                    <span className='text-white font14 font_weight_600'>Synopsis : </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Lorem ipsum dolor sit amet consectetur. Quam consectetur scelerisque neque consectetur est libero facilisi. Dui suspendisse odio turpis mauris. Ultrices aliquam elementum arcu congue egestas et purus auctor cursus. Volutpat vehicula lobortis sit rhoncus. Augue vitae tincidunt varius mattis mi pharetr dund nec donec. Pulvinar massa habitant gravida orci fames. Integer mattis aliquam eget vitae habitant fames sollicitudin. Volutpat nisl at purus posuere nunc non rhoncus
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Director: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Prashanth Neel
                                    </span>
                                </div>

                                <div
                                    className='w-75 mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Starring: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Yash, Sanjay Dutt, Raveena Tandon,  Srinidhi Shetty, Prakash Raj, Archana Jois, Achyuth Kumar, Vasishta N. Simha, Rao Ramesh, T. S. Nagabharana, Malavika Avinash
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Genre: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Action, Crime, Drama
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Subtitle: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        English U/A
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Language: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Kannada
                                    </span>
                                </div>

                                <div
                                    className='d-flex align-items-center'
                                >
                                    <button
                                        className='rounded theme_color px-5'
                                    >
                                        View All
                                    </button>
                                    <div
                                        className='d-flex align-items-center ms-3'
                                    >
                                        <span
                                            className='font24'
                                            style={{
                                                color: '#9fa0a1'
                                            }}
                                        >
                                            <BsFillBookmarkPlusFill />
                                        </span>
                                        <span
                                            className='font24 text-white mx-3'
                                        >
                                            <AiOutlineLike />
                                        </span>
                                        <span
                                            className='font24 text-white'
                                        >
                                            <ImShare2 />
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div
                            className='row'
                            style={{
                                backgroundColor: '#171a1f'
                            }}
                        >
                            <div
                                className='col-lg-7 col-md-12 col-12 py-lg-3 py-md-3 py-0 ps-lg-5 ps-md-4 ps-0 px-lg-0 px-md-4 px-0'
                            >
                                <div>
                                    <img src={`${banner[0].image}`} className="d-block w-100" alt={banner[0].title} />
                                </div>
                            </div>
                            <div
                                className='col-lg-5 col-md-12 col-12 px-lg-3 px-md-4 px-4'
                            >
                                <p
                                    className='m-0 text-white font30 font_weight_600 mt-2'
                                >
                                    {banner[0].title}
                                </p>
                                <div
                                    className='d-flex align-items-baseline'
                                >
                                    <div
                                        className='bg-warning px-2 d-flex align-items-baseline rounded-pill me-3 my-3'
                                        style={{
                                            bottom: '-10px',
                                            right: '15px'
                                        }}
                                    >
                                        <FaRegStar className='me-1 text-dark' />
                                        <span className='font18 text-dark'>
                                            {0}
                                        </span>
                                    </div>
                                    <p
                                        className='text-white font16 font_weight_600'
                                    >
                                        2022 Indian Kannada-language period action film
                                    </p>
                                </div>
                                <div
                                    className='mb-4'
                                >
                                    <span className='text-white font14 font_weight_600'>Synopsis : </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Lorem ipsum dolor sit amet consectetur. Quam consectetur scelerisque neque consectetur est libero facilisi. Dui suspendisse odio turpis mauris. Ultrices aliquam elementum arcu congue egestas et purus auctor cursus. Volutpat vehicula lobortis sit rhoncus. Augue vitae tincidunt varius mattis mi pharetr dund nec donec. Pulvinar massa habitant gravida orci fames. Integer mattis aliquam eget vitae habitant fames sollicitudin. Volutpat nisl at purus posuere nunc non rhoncus
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Director: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Prashanth Neel
                                    </span>
                                </div>

                                <div
                                    className='w-75 mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Starring: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Yash, Sanjay Dutt, Raveena Tandon,  Srinidhi Shetty, Prakash Raj, Archana Jois, Achyuth Kumar, Vasishta N. Simha, Rao Ramesh, T. S. Nagabharana, Malavika Avinash
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Genre: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Action, Crime, Drama
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Subtitle: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        English U/A
                                    </span>
                                </div>

                                <div
                                    className='mb-1'
                                >
                                    <span className='text-warning font14 font_weight_600'>Language: </span>
                                    <span
                                        className='m-0 text-white font14'
                                    >
                                        Kannada
                                    </span>
                                </div>

                                <div
                                    className='d-flex align-items-center'
                                >
                                    <button
                                        className='rounded theme_color px-5'
                                    >
                                        View All
                                    </button>
                                    <div
                                        className='d-flex align-items-center ms-3'
                                    >
                                        <span
                                            className='font24'
                                            style={{
                                                color: '#9fa0a1'
                                            }}
                                        >
                                            <BsFillBookmarkPlusFill />
                                        </span>
                                        <span
                                            className='font24 text-white mx-3'
                                        >
                                            <AiOutlineLike />
                                        </span>
                                        <span
                                            className='font24 text-white'
                                        >
                                            <ImShare2 />
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </LoadingOverlay >
    )
}

export default OttBanner
