import React, { useState, useEffect } from 'react';
import './profileform.css';
import { ImCross } from 'react-icons/im';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getProjectDetails } from '../../utils/utils';

const AboutProject = (props: any) => {

    const { project_data, project_id } = props;
    const messageTxt = (message: any) => toast(message);
    LoadingOverlay.propTypes = undefined;
    const [isActive, setActive] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true);
    const [aboutProject, setAboutProject] = useState({
        movie_title: '',
        movie_poster: '',
        movie_log_line: '',
        movie_synopsis: '',
        film_theme_based_on: '',
        how_movie_produced_filming: '',
        film_legacy: '',
        box_office_performance: '',
        available_ott: '',
        duration: '',
        distributor_name: '',
    });

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'movie_poster') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setAboutProject((prevVal: any) => ({ ...prevVal, movie_poster: document_image }));
        } else {
            setAboutProject({ ...aboutProject, [name]: value });
        }
    }

    const handleAboutUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { movie_title, movie_poster, movie_log_line, movie_synopsis, film_theme_based_on, how_movie_produced_filming, film_legacy, box_office_performance, available_ott, duration, distributor_name } = aboutProject;
        if (movie_title === '') {
            messageTxt('Please enter movie title ');
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    movie_title,
                    movie_poster,
                    distributor_name, duration,
                    available_on: available_ott,
                    performance: box_office_performance,
                    film_legacy,
                    description: how_movie_produced_filming,
                    movie_theme: film_theme_based_on,
                    movie_synopsis,
                    movie_log_line,
                }
                let result = await axios(`${baseURL}/api/project/update-project_details`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        setAboutProject({
            ...aboutProject,
            movie_title: project_data?.movie_title || '',
            movie_poster: project_data?.movie_poster || '',
            movie_log_line: project_data?.movie_log_line || '',
            movie_synopsis: project_data?.movie_synopsis || '',
            film_theme_based_on: project_data?.movie_theme || '',
            how_movie_produced_filming: project_data?.description || '',
            film_legacy: project_data?.film_legacy || '',
            box_office_performance: project_data?.performance || '',
            available_ott: project_data?.available_on || '',
            duration: project_data?.duration || '',
            distributor_name: project_data?.distributor_name || ''
        });
    }, [project_data]);

    // useEffect(() => {
    //     console.log('about data', aboutProject);
    // }, [aboutProject])

    return (
        <>

            <div className="accordion accordion-flush mt-4" id="ProjectDetail">
                <div className="accordion-item rounded">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{backgroundColor: '#3a3a3c'}} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseProjectDetail" aria-expanded="false" aria-controls="flush-collapseProjectDetail">
                            About Project
                        </button>
                    </h2>
                    <div id="flush-collapseProjectDetail" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#ProjectDetail">
                        <div className="accordion-body accordian_border_with_color border-top-0 mb-5 theme_color">
                            <LoadingOverlay
                                active={isActive}
                                spinner
                                text='Loading your content...'
                            >
                                <div className="row about_project_detail mt-3 p-0 d-flex justify-content-between align-items-center">
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Movie Title</label>
                                        <input type="text" onChange={handleChangeValue} value={aboutProject.movie_title} name='movie_title' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                        <label htmlFor="movieLogLine" className="form-label text-white m-0">Movie Log Line</label>
                                        <textarea onChange={handleChangeValue} value={aboutProject.movie_log_line} name='movie_log_line' className="form-control mb-3" id="exampleFormControlTextarea1" placeholder='Short line of the story (150 words)' disabled={isDisabled}></textarea>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Film Legacy</label>
                                        <textarea onChange={handleChangeValue} value={aboutProject.film_legacy} name='film_legacy' className="form-control mb-3" id="exampleFormControlTextarea1" disabled={isDisabled}></textarea>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Available on Which OTT platforms</label>
                                        <input type="text" onChange={handleChangeValue} value={aboutProject.available_ott} name='available_ott' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Distributor Name</label>
                                        <input type="text" onChange={handleChangeValue} value={aboutProject.distributor_name} name='distributor_name' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Movie Poster</label>
                                        <input type="file" onChange={handleChangeValue} name='movie_poster' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                        <label htmlFor="movieLogLine" className="form-label text-white m-0">Movie Synopsis</label>
                                        <textarea onChange={handleChangeValue} value={aboutProject.movie_synopsis} name='movie_synopsis' className="form-control mb-3" id="exampleFormControlTextarea1" placeholder='Movie Synopsis  ( 5000 words )' disabled={isDisabled}></textarea>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">How this movie was produced and filming was completed?</label>
                                        <textarea onChange={handleChangeValue} value={aboutProject.how_movie_produced_filming} name='how_movie_produced_filming' className="form-control mb-3" id="exampleFormControlTextarea1" disabled={isDisabled}></textarea>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">BOX OFFICE Peformance</label>
                                        <textarea onChange={handleChangeValue} value={aboutProject.box_office_performance} name='box_office_performance' className="form-control mb-3" id="exampleFormControlTextarea1" disabled={isDisabled}></textarea>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Duration</label>
                                        <input type="text" onChange={handleChangeValue} value={aboutProject.duration} name='duration' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                    </div>
                                    <div className='mb-3'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Film Theme based on</label>
                                        <textarea onChange={handleChangeValue} value={aboutProject.film_theme_based_on} name='film_theme_based_on' className="form-control mb-3" id="exampleFormControlTextarea1" disabled={isDisabled}></textarea>
                                    </div>
                                </div>

                                <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className='py-2 px-2 border-0'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleAboutUpdate}
                                    >
                                        Update
                                    </button>
                                </div>
                            </LoadingOverlay>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default AboutProject
