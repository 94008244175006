import React, { useState, useEffect } from 'react';
import '../../components/signinmodal/Signinmodal.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ImCross } from 'react-icons/im';

const BuyNowModal = (props: any) => {

    const { handleModalBox, redirectPath } = props;
    LoadingOverlay.propTypes = undefined;
    const routePath = redirectPath || '/';
    const userData = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    const [data, setData] = useState<Number>(0);
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [quantity, setQuantity] = useState<number>(1);

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const incrementQty = () => {
        setQuantity(quantity + 1);
    }

    const decrementQty = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        } else if (quantity <= 0) {
            setQuantity(1);
        }
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`lg`}
        >
            <div
                className='bg-dark pb-lg-5 pb-md-5 pb-3 p-lg-4 p-md-4 p-1'
            >
                <div
                    className='d-flex justify-content-between align-items-center'
                >
                    <p
                        className='m-0 text-warning font_weight_600 font20'
                    >
                        Cart
                    </p>
                    <button
                        className=''
                        onClick={handlClosedModal}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <ImCross />
                    </button>
                </div>

                <div
                    className='row pb-2 mt-lg-5 mt-md-5 mt-3'
                >
                    <div
                        className='col-lg-3 col-md-3 col-12 mb-3'
                    >
                        <div
                            style={{
                                aspectRatio: '1/1'
                            }}
                        >
                            <img src={'../../images/cartmodalimage.png'} width={'100%'} height={'100%'} />
                        </div>
                    </div>
                    <div
                        className='col-lg-9 col-md-9 col-12 mb-3'
                    >
                        <p
                            className='m-0 text-warning font16'
                        >
                            Official Animal Best Father Fleece
                        </p>
                        <p
                            className='m-0 text-warning font16 mt-1'
                        >
                            Hoodie
                        </p>
                        <p
                            className='m-0 text-white font14 mt-1'
                        >
                            Color: <span className='text-warning'>White</span>
                        </p>
                        <p
                            className='m-0 text-white font14 mt-1'
                        >
                            Size: <span className='text-warning'>S</span>
                        </p>

                        <div
                            className='d-flex align-items-center'
                        >
                            <p
                                className='m-0 text-white text-uppercase me-3'
                            >
                                quantity
                            </p>
                            <div
                                className='theme_color px-2'
                            >
                                <span
                                    className='qty_button font24 pointer'
                                    onClick={decrementQty}
                                >
                                    -
                                </span>
                                <span className='text-white mx-4'>{quantity}</span>
                                <span
                                    className='qty_button font24 pointer'
                                    onClick={incrementQty}
                                >
                                    +
                                </span>
                            </div>
                        </div>
                    </div>

                    <div
                        className='col-lg-3 col-md-3 col-12'
                    >
                        <div
                            style={{
                                aspectRatio: '1/1'
                            }}
                        >
                            <img src={'../../images/cartmodalimage.png'} width={'100%'} height={'100%'} />
                        </div>
                    </div>
                    <div
                        className='col-lg-9 col-md-9 col-12'
                    >
                        <p
                            className='m-0 text-warning font16'
                        >
                            Official Animal Best Father Fleece
                        </p>
                        <p
                            className='m-0 text-warning font16 mt-1'
                        >
                            Hoodie
                        </p>
                        <p
                            className='m-0 text-white font14 mt-1'
                        >
                            Color: <span className='text-warning'>White</span>
                        </p>
                        <p
                            className='m-0 text-white font14 mt-1'
                        >
                            Size: <span className='text-warning'>S</span>
                        </p>

                        <div
                            className='d-flex align-items-center'
                        >
                            <p
                                className='m-0 text-white text-uppercase me-3'
                            >
                                quantity
                            </p>
                            <div
                                className='theme_color px-2'
                            >
                                <span
                                    className='qty_button font24 pointer'
                                    onClick={decrementQty}
                                >
                                    -
                                </span>
                                <span className='text-white mx-4'>{quantity}</span>
                                <span
                                    className='qty_button font24 pointer'
                                    onClick={incrementQty}
                                >
                                    +
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='d-flex justify-content-between align-items-center text-warning mt-4'
                >
                    <p
                        className='m-0 font_weight_600 font20'
                    >
                        TOTAL
                    </p>
                    <p
                        className='m-0 font_weight_600 font20'
                    >
                        &#x20B9; 797
                    </p>
                </div>
                <p
                    className='text-white m-0 font14 mt-2'
                >
                    <i>Tax included. Shipping calculated at checkout.</i>
                </p>

                <div
                    className='mt-lg-5 mt-md-5 mt-3 d-flex justify-content-center align-items-center'
                >
                    <button
                        className='by_now_button font_weight_600 me-lg-5 me-md-5 me-3'
                    >
                        View Cart
                    </button>
                    <button
                        className='add_to_cart_button font_weight_600 me-lg-5 me-md-5 me-0'
                    >
                        Buy Now
                    </button>
                </div>

            </div>
        </Modal>
    )
}

export default BuyNowModal
