import React, { useEffect, useState, useRef } from 'react';
import './festivalform.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaTrashAlt, FaEdit } from 'react-icons/fa';
import { createNomineeAPI, deleteNominationAPI, getAllFestivalListAPI, getCategoryListAPI, getNominationList, getSearchMoviesAPI, getSubCategoryListAPI } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-dropdown-select';

const NominationForm = (props: any) => {

    const { festival_data, festival_id } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const movieTitlte = useRef<HTMLInputElement>(null);
    const [categoryList, setCatList] = useState<any>([]);
    const [subCatList, setSubCatList] = useState<any>([]);
    const [movies, setMovies] = useState<any>([]);
    const [festival_detail, setFestivalDetail] = useState<any>([]);

    const catId = useRef<HTMLSelectElement>(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number | null>(null);
    const subCatId = useRef<HTMLSelectElement>(null);
    const [nomination, setNomination] = useState<any>([]);

    const handleCategoryChange = (e: any) => {
        const { name } = e.target;
        if (name === 'catId') {
            const selectedValue = catId.current?.value;
            setSelectedCategoryId(selectedValue ? parseInt(selectedValue, 10) : null);
        } else if (name === 'subCatId') {
            const selectedValue = subCatId.current?.value;
            setSelectedSubCategoryId(selectedValue ? parseInt(selectedValue, 10) : null);
        }
    };

    const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        const updatedNomination = [...nomination];

        if (checked) {
            updatedNomination.push(value);
            setNomination(updatedNomination);
        } else {
            setNomination(updatedNomination.filter((item: any) => item !== value));
        }
    };

    const getNomineeDetails = async () => {
        const data = await getNominationList(festival_id, null, 'film_festival');
        setFestivalDetail(data);
    }

    const getCategoryList = async () => {
        const data = await getCategoryListAPI(festival_id);
        setCatList(data);
    }

    const getSubCategoryList = async () => {
        setActive(true);
        const data = await getSubCategoryListAPI(festival_id, selectedCategoryId);
        setSubCatList(data);
        setActive(false);
    }

    const getMovie = async () => {
        if (movieTitlte.current) {
            const value = movieTitlte.current.value;
            const data = await getSearchMoviesAPI(value);
            setMovies(data);
        }
    }

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const submitNominees = async () => {
        setActive(true);
        const msg = await createNomineeAPI(festival_id, selectedCategoryId, selectedSubCategoryId, nomination);
        getNomineeDetails();
        messageTxt(msg);
        setActive(false);
    }

    const deleteNominees = async (id:any) => {
        setActive(true);
        const msg = await deleteNominationAPI(id);
        getNomineeDetails();
        messageTxt(msg);
        setActive(false);
    }


    useEffect(() => {
        getCategoryList();
        getNomineeDetails();
    }, [])

    useEffect(() => {
        getSubCategoryList();
    }, [selectedCategoryId])

    // useEffect(() => {
    //     console.log('movies', festival_detail);
    // }, [festival_detail])

    return (
        <>
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="nomination">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenomination" aria-expanded="false" aria-controls="flush-collapsenomination">
                                Nomination
                            </button>
                        </h2>
                        <div id="flush-collapsenomination" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#nomination">
                            <div className="accordion-body border theme_color">

                                <LoadingOverlay
                                    active={isActive}
                                    spinner
                                    text='Loading your content...'
                                >


                                    <div className='overflow-auto'>
                                        <table className="table table-dark table-striped border">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Nominees
                                                    </th>
                                                    <th>
                                                        Sub categories
                                                    </th>
                                                    <th>
                                                        Categories
                                                    </th>
                                                    <th>
                                                        Votes
                                                    </th>
                                                    <th className='text-center'>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(festival_detail) &&
                                                    festival_detail.map((val: any, i: any) => {
                                                        return (
                                                            <>
                                                                <tr className="table-active" key={val.id}>
                                                                    <td>
                                                                        <div
                                                                            className='d-flex align-items-center'
                                                                            style={{ height: '100px' }}
                                                                        >
                                                                            {val.name}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                        className='d-flex align-items-center'
                                                                            style={{ height: '100px' }}
                                                                        >
                                                                            {val.sub_category.name}
                                                                        </div>

                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            className='d-flex align-items-center'
                                                                            style={{ height: '100px' }}
                                                                        >
                                                                            {val.category.name}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            className='d-flex align-items-center'
                                                                            style={{ height: '100px' }}
                                                                        >
                                                                            {val.votes.length}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        <div
                                                                            className='d-flex align-items-center justify-content-center'
                                                                            style={{ height: '100px' }}
                                                                        >
                                                                            {/* <button
                                                                                className='px-2 fs-4 border-0'
                                                                                style={{
                                                                                    backgroundColor: 'transparent',
                                                                                    color: 'white',
                                                                                }}
                                                                            // onClick={() => handleId(val.id)}
                                                                            >
                                                                                <FaEye />
                                                                            </button>
                                                                            <button
                                                                                className='px-2 fs-4 border-0 mx-2'
                                                                                style={{
                                                                                    backgroundColor: 'transparent',
                                                                                    color: 'white',
                                                                                }}
                                                                            // onClick={() => handleId(val.id)}
                                                                            >
                                                                                <FaEdit />
                                                                            </button> */}
                                                                            <button
                                                                                className='px-2 font22 border-0'
                                                                                style={{
                                                                                    backgroundColor: 'transparent',
                                                                                    color: 'white',
                                                                                }}
                                                                                onClick={() => deleteNominees(val.id)}
                                                                            >
                                                                                <FaTrashAlt />
                                                                            </button>
                                                                        </div>

                                                                    </td>
                                                                </tr>

                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>


                                    <div
                                        className='row p-0 nominees_form'
                                    >
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="category" className="form-label text-white m-0">Select main category</label>
                                            <select className="form-select form-select-lg mb-3" ref={catId} onChange={handleCategoryChange} name='catId' aria-label="Large select example1" disabled={isDisabled}>
                                                <option selected>Select main category...</option>
                                                {
                                                    categoryList.map((val: any) => {
                                                        return (
                                                            <option key={val.id} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="category" className="form-label text-white m-0">Select sub category</label>
                                            <select className="form-select form-select-lg mb-3" ref={subCatId} onChange={handleCategoryChange} name='subCatId' aria-label="Large select example1" disabled={isDisabled}>
                                                <option selected>Select Sub Category...</option>
                                                {Array.isArray(subCatList) &&
                                                    subCatList.map((val: any) => {
                                                        return (
                                                            <option key={val.id} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className='col-lg-10 col-md-10 col-10'>
                                            <label htmlFor="category" className="form-label text-white m-0">Search movie</label>
                                            <input type="text" ref={movieTitlte} className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        {!isDisabled ?
                                            <div className='col-lg-2 col-md-2 col-2 d-flex align-items-center'>
                                                <button
                                                    className='py-2 px-2 common_button rounded fw-bold'
                                                    style={{
                                                        width: '150px'
                                                    }}
                                                    onClick={getMovie}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                            : null
                                        }

                                    </div>

                                    {
                                        movies.map((val: any) => {
                                            return (
                                                <div key={val.project_id}>
                                                    <ul className='list-unstyled d-flex breadcrumb'>
                                                        <li style={{ minWidth: 'fit-content' }}>
                                                            <div className="form-check ms-3">
                                                                <input className="form-check-input" type="checkbox" onChange={handleCheckBox} name={val.movie_title} value={val.project_id} style={{ height: '17px' }} />
                                                                <label className="form-check-label text-white" htmlFor="flexCheckDefault">
                                                                    {val.movie_title}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        <button
                                            className='py-2 px-2 border me-3 rounded fw-bold'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleEdit}
                                        >
                                            {isDisabled ? 'Edit' : 'Cancel'}
                                        </button>
                                        {!isDisabled ?
                                            <button
                                                className='py-2 px-2 common_button rounded fw-bold'
                                                style={{
                                                    width: '150px'
                                                }}
                                                onClick={submitNominees}
                                            >
                                                Save
                                            </button>
                                            : null
                                        }
                                    </div>


                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default NominationForm
