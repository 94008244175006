import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const FilmographyModal = (props: any) => {

    const { handleModalBox, data, dataId, kyc_document_list, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [aboutProject, setAboutProject] = useState({
        movie_title: '',
        movie_title_native_language: '',
        movie_poster: '',
        movie_banner: '',
        movie_log_line: '',
        movie_synopsis: '',
        film_theme_based_on: '',
        how_movie_produced_filming: '',
        film_legacy: '',
        box_office_performance: '',
        available_ott: '',
        duration: '',
        distributor_name: '',
        description: '',
    });

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'movie_poster') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setAboutProject((prevVal: any) => ({ ...prevVal, movie_poster: document_image }));
        } else if (name === 'movie_banner') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setAboutProject((prevVal: any) => ({ ...prevVal, movie_banner: document_image }));
        } else {
            setAboutProject({ ...aboutProject, [name]: value });
        }
    }

    const handleAboutDetail = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { movie_title, movie_title_native_language, movie_poster, movie_banner, movie_log_line, movie_synopsis, film_theme_based_on, how_movie_produced_filming, film_legacy, box_office_performance, available_ott, duration, distributor_name } = aboutProject;
        if (movie_title === '') {
            messageTxt('Please enter movie title ');
            setActive(false);
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    movie_title,
                    movie_poster,
                    distributor_name, duration,
                    available_on: available_ott,
                    performance: box_office_performance,
                    film_legacy,
                    movie_banner,
                    description: how_movie_produced_filming,
                    movie_theme: film_theme_based_on,
                    movie_synopsis,
                    movie_log_line,
                    movie_title_native_language,
                }
                let result = await axios(`${baseURL}/api/project/create`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setActive(false);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const handleCropComplete = (croppedImageUrl: string) => {
        setCroppedImage(croppedImageUrl);
    };

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row about_project_detail mt-3 p-0 d-flex justify-content-between align-items-center">
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Movie title</label>
                            <input type="text" onChange={handleChangeValue} value={aboutProject.movie_title} name='movie_title' placeholder='eg: Kiese Dakuchi Kouthi Mate etc.' className="form-control mb-3" id="exampleFormControlInput1" />
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Movie in native language</label>
                            <input type="text" onChange={handleChangeValue} value={aboutProject.movie_title_native_language} name='movie_title_native_language' placeholder='eg: କିଏସେ ଡାକୁଛି କୋଉଠି ମତେ etc.' className="form-control mb-3" id="exampleFormControlInput1" />
                            <label htmlFor="movieLogLine" className="form-label text-white m-0">Movie log line</label>
                            <textarea onChange={handleChangeValue} value={aboutProject.movie_log_line} name='movie_log_line' className="form-control mb-3" id="exampleFormControlTextarea1" placeholder='Short line of the story (150 words)'></textarea>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Film legacy</label>
                            <textarea onChange={handleChangeValue} value={aboutProject.film_legacy} name='film_legacy' placeholder='Describe film legacy' className="form-control mb-3" id="exampleFormControlTextarea1"></textarea>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Available on which ott platforms</label>
                            <input type="text" onChange={handleChangeValue} value={aboutProject.available_ott} name='available_ott' placeholder='eg: Netflix, Jio Cinema etc.' className="form-control mb-3" id="exampleFormControlInput1" />
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Distributor name</label>
                            <input type="text" onChange={handleChangeValue} value={aboutProject.distributor_name} name='distributor_name' placeholder='Distributor name' className="form-control mb-3" id="exampleFormControlInput1" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Movie poster</label>
                            <input type="file" onChange={handleChangeValue} name='movie_poster' className="form-control mb-3" id="exampleFormControlInput1" />
                            <label htmlFor="moviebanner" className="form-label text-white m-0">Movie Banner</label>
                            <input type="file" onChange={handleChangeValue} name='movie_banner' className="form-control mb-3" id="exampleFormControlInput1" />
                            <label htmlFor="movieLogLine" className="form-label text-white m-0">Movie synopsis</label>
                            <textarea onChange={handleChangeValue} value={aboutProject.movie_synopsis} name='movie_synopsis' className="form-control mb-3" id="exampleFormControlTextarea1" placeholder='Movie synopsis  ( 5000 words )'></textarea>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">How this movie was produced and filming was completed?</label>
                            <textarea onChange={handleChangeValue} value={aboutProject.how_movie_produced_filming} name='how_movie_produced_filming' placeholder='Describe how movie was produce and filming was completed' className="form-control mb-3" id="exampleFormControlTextarea1"></textarea>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Box office peformance</label>
                            <textarea onChange={handleChangeValue} value={aboutProject.box_office_performance} name='box_office_performance' placeholder='Describe movie performance in the box office' className="form-control mb-3" id="exampleFormControlTextarea1"></textarea>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Duration</label>
                            <input type="text" onChange={handleChangeValue} value={aboutProject.duration} name='duration' placeholder='150 min' className="form-control mb-3" id="exampleFormControlInput1" />
                        </div>
                        <div className='mb-3'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Film theme based on</label>
                            <textarea onChange={handleChangeValue} value={aboutProject.film_theme_based_on} name='film_theme_based_on' placeholder='eg: Pollitics, Biography etc.' className="form-control mb-3" id="exampleFormControlTextarea1"></textarea>
                        </div>
                    </div>

                    <div className='mt-3 d-lg-flex d-md-block d-block justify-content-lg-end'>
                        <button
                            className='py-2 px-2 border-0 rounded'
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                width: '150px'
                            }}
                            onClick={handleAboutDetail}
                        >
                            Save
                        </button>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default FilmographyModal
