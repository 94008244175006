import React, { useState, useEffect } from 'react';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ControversyModal = (props: any) => {

    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [getId, setId] = useState<any>('');
    const [img_path, setImgPath] = useState<any>();
    const [controversy, setControversy] = useState({
        title: '',
        description: '',
        image: '',
        controversy_date: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleSaveImage = async (image: any) => {
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (image === null || image === undefined) {
            messageTxt('Please select image.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    image
                }
                const res = await axios(`${baseURL}/api/master/upload_file`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                setImgPath(res.data.data);
                setActive(false);
            } catch (error) {
                console.log(error)
                setActive(false);
            }
        }
    }

    const handleChangeVal = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const controversy_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setControversy((prevVal: any) => ({ ...prevVal, image: controversy_image }));
            handleSaveImage(controversy_image);
        } else {
            setControversy({ ...controversy, [name]: value });
        }
    }

    const handleSaveControversy = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { title, description, controversy_date } = controversy;
        if (title === '' || description === '' || controversy_date === '') {
            messageTxt('Please fill the title and description and date...');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    controversy_title: title,
                    controversy_description: description,
                    controversy_date,
                    img_path,
                    controversy_id: getId
                }
                const res = await axios(`${baseURL}/api/subscriber/controversy/addUpdate`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt('Title and description saved successfully.')
                setControversy({
                    title: '',
                    description: '',
                    image: '',
                    controversy_date: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const takehMovementValuesForUpdate = () => {
        setActive(true);
        if (dataId) {
            setId(dataId);
            setControversy({
                ...controversy,
                title: data.title,
                description: data.description
            });
        }
        setActive(false);
    }

    useEffect(() => {
        takehMovementValuesForUpdate();
    }, [data, getId])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center modal_form_add_update">

                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Title</label>
                            <input type="text" onChange={handleChangeVal} value={controversy.title} name='title' placeholder='Enter controversy name' className="form-control mb-3" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Controversy description</label>
                            <textarea name='description' onChange={handleChangeVal} value={controversy.description} className="form-control" placeholder='Describe controversy' style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1"></textarea>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Controversy date</label>
                            <input type="date" onChange={handleChangeVal} value={controversy.controversy_date} name='controversy_date' className="form-control mb-3" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Controversy image</label>
                            <input type="file" onChange={handleChangeVal} name='image' className="form-control mb-3" />
                        </div>
                    </div>

                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                        <button
                            className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                width: '150px'
                            }}
                            onClick={handleSaveControversy}
                        >
                            Save
                        </button>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ControversyModal
