import React, { useEffect, useState } from 'react';
import './carousel.css';
import { baseURL } from '../../utils/api';
import LoadingOverlay from 'react-loading-overlay-ts';
import axios from 'axios';
import { getHomeBannerAPI } from '../../utils/utils';
import { Link } from 'react-router-dom';
import BannerRating from '../ratings/banner/BannerRating';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const CarouselBanner = () => {
    const [isActive, setActive] = useState(false);
    const [banner, setBanner] = useState<any>();

    const homeBanner = async () => {
        setActive(true);
        const result = await getHomeBannerAPI(1);
        if (result && typeof result === 'object') {
            setBanner(Object.values(result));
            setActive(false);
        }
        setActive(false);
    }

    useEffect(() => {
        setTimeout(() => {
            homeBanner();
        }, 3000)
    }, [])

    // useEffect(() => {
    //     console.log(banner);
    // }, [banner])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            {Array.isArray(banner) && banner.length > 0 &&
                <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                    {Array.isArray(banner) && banner.length > 0 ?
                        <div className="carousel-indicators d-xl-inline-flex d-none d-lg-none d-md-none">
                            <img src={`${baseURL}/${banner[0]?.banner_path}`} width='50' height='50' alt='asif' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`0`} className="active carousel_indicator border border-secondary" aria-current="true" aria-label={`Slide 1`} />
                            <img src={`${baseURL}/${banner[1]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`1`} aria-label={`Slide 2`} />
                            <img src={`${baseURL}/${banner[2]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`2`} aria-label={`Slide 3`} />
                            <img src={`${baseURL}/${banner[3]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`3`} aria-label={`Slide 4`} />
                            <img src={`${baseURL}/${banner[4]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`4`} aria-label={`Slide 5`} />
                            <img src={`${baseURL}/${banner[5]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`5`} aria-label={`Slide 6`} />
                            <img src={`${baseURL}/${banner[6]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`6`} aria-label={`Slide 7`} />
                            <img src={`${baseURL}/${banner[7]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`7`} aria-label={`Slide 8`} />
                            <img src={`${baseURL}/${banner[8]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`8`} aria-label={`Slide 9`} />
                            <img src={`${baseURL}/${banner[9]?.banner_path}`} className='carousel_indicator border border-secondary' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`9`} aria-label={`Slide 10`} />
                        </div>
                        : null
                    }
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[0]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[0]?.movie_title)}-${banner[0]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[0]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[0]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[0]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[1]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[1]?.movie_title)}-${banner[1]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[1]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[1]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[1]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[2]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[2]?.movie_title)}-${banner[2]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[2]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[2]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[2]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[3]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[3]?.movie_title)}-${banner[3]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[3]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[3]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[3]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[4]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[4]?.movie_title)}-${banner[4]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[4]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[4]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[4]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[5]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[5]?.movie_title)}-${banner[5]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[5]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[5]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[5]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[6]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[6]?.movie_title)}-${banner[6]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[6]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[6]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[0]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[7]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[7]?.movie_title)}-${banner[7]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[7]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[7]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[7]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[8]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[8]?.movie_title)}-${banner[8]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[8]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[8]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[8]} />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='position-relative'
                            >
                                {banner[9]?.data_id ?
                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(banner[9]?.movie_title)}-${banner[9]?.data_id}`} className='text-decoraton-none text-white'>
                                        <img src={`${baseURL}/${banner[9]?.banner_path}`} className="d-block w-100" alt="..." />
                                    </Link>
                                    :
                                    <img src={`${baseURL}/${banner[9]?.banner_path}`} className="d-block w-100" alt="..." />
                                }
                                <div
                                    className='position-absolute'
                                    style={{
                                        right: '2%',
                                        top: '3%'
                                    }}
                                >
                                    <BannerRating data={banner[9]} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            }


            {/* <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">

                    <div className="carousel-indicators d-none d-lg-inline-flex d-md-inline-flex">
                        <img src={`${baseURL}/${banner[0]}`} width='50' height='50' alt='asif' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`0`} className="active carousel_indicator" aria-current="true" aria-label={`Slide 1`} />
                        <img src={`${baseURL}/${banner[1]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`1`} aria-label={`Slide 2`} />
                        <img src={`${baseURL}/${banner[2]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`2`} aria-label={`Slide 3`} />
                        <img src={`${baseURL}/${banner[3]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`3`} aria-label={`Slide 4`} />
                        <img src={`${baseURL}/${banner[4]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`4`} aria-label={`Slide 5`} />
                        <img src={`${baseURL}/${banner[5]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`5`} aria-label={`Slide 6`} />
                        <img src={`${baseURL}/${banner[6]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`6`} aria-label={`Slide 7`} />
                        <img src={`${baseURL}/${banner[7]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`7`} aria-label={`Slide 8`} />
                        <img src={`${baseURL}/${banner[8]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`8`} aria-label={`Slide 9`} />
                        <img src={`${baseURL}/${banner[9]}`} className='carousel_indicator' width='50' height='50' alt='...' data-bs-target="#carouselExampleInterval" data-bs-slide-to={`9`} aria-label={`Slide 10`} />
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[0]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[1]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[2]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[3]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[4]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[5]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[6]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[7]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[8]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <div
                                className='d-flex justify-content-center'
                            >
                                <img src={`${baseURL}/${banner[9]}`} className="d-block object-fit-contain" alt="..." />
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div> */}



            {/* <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators d-none d-lg-inline-flex d-md-inline-flex">

                        {
                            banner?.map((val: any, idx: any) => {
                                return (
                                    <div key={idx}>
                                        {idx === 0 ?
                                            // <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={`Slide ${idx+1}`}>
                                            <img src={`${baseURL}/${val}`} width='50' height='50' alt='asif' data-bs-target="#carouselExampleIndicators" data-bs-slide-to={`${idx}`} className="active cardImage carousel_indicator" aria-current="true" aria-label={`Slide ${idx + 1}`} />
                                            // </button>
                                            :
                                            // <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={`Slide ${idx+1}`}>
                                            <img src={`${baseURL}/${val}`} className='cardImage carousel_indicator' width='50' height='50' alt='asif' data-bs-target="#carouselExampleIndicators" data-bs-slide-to={`${idx}`} aria-label={`Slide ${idx + 1}`} />
                                            // </button>
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div className="carousel-inner">
                        {
                            banner?.map((image: any, idx: any) => {
                                return (
                                    <div className="carousel-item active" key={idx}>
                                        <img src={`${baseURL}/${image}`} className="d-block w-100" alt={image} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div> */}
        </LoadingOverlay >
    )
}

export default CarouselBanner
