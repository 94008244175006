import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import LoadingOverlay from 'react-loading-overlay-ts';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-toastify';
import { getMasterProfessionListAPI, getStringToIntArray } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { PiCaretLeftBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";

const ProfessionModal = (props: any) => {


    LoadingOverlay.propTypes = undefined;
    const { onCloseModal, data } = props;
    const navigate = useNavigate();
    const [isActive, setActive] = useState(false);
    const [show, setShow] = useState(true);
    const messageTxt = (message: any) => toast(message);
    const [masterProfessionList, setMasterProfessionList] = useState<any>([]);
    const [selectedMaster, setselectedMaster] = useState<any>([]);
    const [professionList, setProfessionList] = useState([]);
    const [selectedProfession, setSelectedProfession] = useState<any>([]);
    const [subProfessionList, setSubProfessionList] = useState<any>([]);
    const [selectedSubProfession, setSelectedSubProfession] = useState<any>([]);
    const [openTabs, setOpenTabs] = useState(false);

    const getMasterProfessionList = async () => {
        setActive(true);
        const response = await getMasterProfessionListAPI();
        setMasterProfessionList(response);
        setActive(false);
    }

    const handlClosedModal = () => {
        setShow(false);
    }

    const handleChangeMasterValue = (e: any) => {
        const { value, checked } = e.target;
        const parsedValue = parseInt(value);

        if (checked) {
            setselectedMaster([...selectedMaster, parsedValue]);
        } else {
            setselectedMaster(selectedMaster.filter((prof: any) => prof !== parsedValue));
            setSelectedProfession([]);
        }
        setProfessionList([]);
    }

    const handleGetProfession = async (e: any) => {
        e.preventDefault();
        setActive(true);
        if (!Array.isArray(selectedMaster) || selectedMaster.length === 0) {
            messageTxt('Please select your stream !');
        } else {
            setOpenTabs(true);
            setActive(false);
        }
        setActive(false);
    }

    const handleChangeProfessionValue = (e: any) => {
        const { value, checked } = e.target;
        const parsedValue = parseInt(value);

        if (checked) {
            setSelectedProfession([...selectedProfession, parsedValue]);
        } else {
            setSelectedProfession(selectedProfession.filter((prof: any) => prof !== parsedValue));
            setSelectedSubProfession([]);
        }
        setSelectedSubProfession([]);
    }

    // const handleGetSubProfession = async (e: any) => {
    //     e.preventDefault();
    //     setActive(true);
    //     const response = await getSubProfessionListAPI(selectedProfession);
    //     setSubProfessionList(response.data.data);
    //     setActive(false);
    // }

    const handleChangeSubProfessionValue = (e: any) => {
        const { value, checked } = e.target;
        const parsedValue = parseInt(value);

        if (checked) {
            setSelectedSubProfession([...selectedSubProfession, parsedValue]);
        } else {
            setSelectedSubProfession(selectedSubProfession.filter((prof: any) => prof !== parsedValue));
        }
    }

    const handleSubmitProfessions = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem("connection_id");
        const auth_code = localStorage.getItem('auth_code');
        const body = {
            connection_id,
            auth_code,
            master_profesion_id: JSON.stringify(selectedMaster),
            profession_id: JSON.stringify(selectedProfession),
            subprofession_id: JSON.stringify(selectedSubProfession)
        };
        if (!selectedProfession || selectedProfession.length === 0) {
            messageTxt('Please select your profession !')
            setActive(false);
        } else {
            try {
                await axios.post(`${baseURL}/api/subscriber/update/profession`, body);
                window.location.reload();
                onCloseModal();
                setActive(false);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    useEffect(() => {
        getMasterProfessionList();
    }, [])

    useEffect(() => {
        try {
            const newMasterArray = getStringToIntArray(data.master_professon_id);
            const newProfessionaArray = getStringToIntArray(data.profession_id);
            const newSubProfessionArray = getStringToIntArray(data.sub_profession_id);
            setselectedMaster([...newMasterArray]);
            setSelectedProfession([...newProfessionaArray]);
            setSelectedSubProfession([...newSubProfessionArray]);
        } catch (error) {
            console.error("Error converting string to integer:", error);
        }
    }, [data])

    useEffect(()=>{
        // console.log('as',selectedSubProfession);
    },[selectedSubProfession])


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='text-end'
            >
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>

            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <div
                    className='p-4'
                >
                {!openTabs ?
                    <Form className='p-4'>
                        <div className='w-100 d-flex justify-content-center'>
                            <div className='row'>
                                {masterProfessionList?.map((profession: any) => (
                                    <div key={`${profession.id}`} className="col-6 mb-3 text-white font16">
                                        <Form.Check
                                            type='checkbox'
                                            id={`${profession.id}`}
                                            label={`${profession.title}`}
                                            value={profession.id}
                                            onChange={handleChangeMasterValue}
                                            checked={selectedMaster.includes(profession.id)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button
                                className='py-3 w-100 rounded text-white bg-danger font_weight_600'
                                onClick={handleGetProfession}
                            >
                                NEXT
                            </button>
                        </div>
                    </Form>
                    :
                    <Tabs
                        defaultActiveKey={
                            (Array.isArray(masterProfessionList) && masterProfessionList.length > 0)
                            && masterProfessionList.find((val) => selectedMaster.includes(val.title))
                        }
                        id="fill-tab-example"
                        className="mb-4 register_tabs_for_professions"
                    >
                        {Array.isArray(masterProfessionList) && masterProfessionList.length > 0 && masterProfessionList.map((val, idx) => (
                            selectedMaster.includes(val.id) && (
                                <Tab eventKey={`${val.title}`} title={`${val.title}`} key={val.id}>
                                    <div className='text-white'>
                                        {val.professions.length > 0 && (
                                            <Form className='mt-5'>
                                                <div className='w-100 d-flex justify-content-center mt-5'>
                                                    <div className='row w-100'>
                                                        {val.professions.map((profession: any) => (
                                                            <div key={profession.id} className="col-lg-3 col-md-2 col-12 mb-3 text-white font16">
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id={String(profession.id)}
                                                                    label={String(profession.title)}
                                                                    value={profession.id}
                                                                    onChange={handleChangeProfessionValue}
                                                                    checked={selectedProfession.includes(profession.id)}
                                                                />
                                                                {selectedProfession.includes(profession.id) && Array.isArray(profession?.sub_professions) && profession?.sub_professions.length > 0 && (
                                                                    <div className='ms-4 font14'>
                                                                        {profession.sub_professions.map((subProfession: any) => (
                                                                            <Form.Check
                                                                                key={subProfession.id}
                                                                                type='checkbox'
                                                                                id={String(subProfession.id)}
                                                                                label={String(subProfession.title)}
                                                                                value={subProfession.id}
                                                                                onChange={handleChangeSubProfessionValue}
                                                                                checked={selectedSubProfession.includes(subProfession.id)}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div
                                                        className='font_weight_600 d-flex justify-content-center align-items-center pointer'
                                                        onClick={() => setOpenTabs(false)}
                                                    >
                                                        <PiCaretLeftBold className='font18' /> <span>Back</span>
                                                    </div>
                                                    <button
                                                        className='py-3 px-lg-5 px-md-5 px-3 font_weight_600 bg-danger rounded text-white'
                                                        style={{ backgroundColor: 'red', border: 'none' }}
                                                        onClick={handleSubmitProfessions}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </div>
                                </Tab>
                            )
                        ))}
                    </Tabs>
                }
                </div>
            </LoadingOverlay>
        </Modal>
    )
}

export default ProfessionModal
