import React from 'react'

const EventAwardCard = (props:any) => {
    const { image, title } = props;
    return (
        <div
            className='event_award_section position-relative pointer my-3'
            style={{
                height: '200px',
                width: '300px'
            }}
        >
            <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
            <div
                className='event_award_text_section w-100'
            >
                <p
                    className='m-0 text-warning font14 text-decoration-underline text-center'
                >
                    Read more
                </p>
            </div>
        </div>
    )
}

export default EventAwardCard
