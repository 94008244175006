import React, { useEffect, useRef, useState } from 'react';
import './professions.css';
import MasterForm from './MasterForm';
import { useSelector } from 'react-redux';
import { getMasterProfessionListAPI, getProfessionListAPI, getSubProfessionListAPI } from '../../utils/utils';
import ProfessionModal from './ProfessionModal';
import LoadingOverlay from 'react-loading-overlay-ts';

const ProfessionAccordian = () => {
    const profile = useSelector((state: any) => state.user.user);
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [selectedMasterProfessionList, setSelectedMasterProfessionList] = useState<any>();
    const [selectedProfessionList, setSelectedProfessionList] = useState<any>();
    const [selectedSubProfessionList, setSelectedSubProfessionList] = useState<any>();
    const [openProfessionModal, setOpenProfessionModalState] = useState(false);

    // get selected master
    const getMasterList = async () => {
        setActive(true);
        try {
            const data = await getMasterProfessionListAPI();
            const selectedMasterIds = profile?.master_professon_id || [];
            const selectedMaster = data.filter((val: any) => selectedMasterIds.includes(val.id.toString()));
            setSelectedMasterProfessionList(selectedMaster);
        } catch (error) {
            console.error('Error fetching master list:', error);
        }
        setActive(false);
    };

    // get selected profession
    const getProfessionList = async () => {
        setActive(true);
        try {
            const data = await getProfessionListAPI();
            const selectedProfessionIds = profile?.profession_id || [];
            const selectedProfession = data.filter((val: any) => selectedProfessionIds.includes(val.id.toString()));
            setSelectedProfessionList(selectedProfession);
        } catch (error) {
            console.error('Error fetching master list:', error);
        }
        setActive(false);
    };

    // get selected sub-profession
    const getSubProfessionList = async () => {
        setActive(true);
        try {
            const data = await getSubProfessionListAPI();
            const selectedSubProfessionIds = profile?.sub_profession_id || [];
            const newSelectedSubProfession = data.filter((val: any) => selectedSubProfessionIds.includes(val.id.toString()));
            setSelectedSubProfessionList(newSelectedSubProfession);
        } catch (error) {
            console.error('Error fetching master list:', error);
        }
        setActive(false);
    };

    const refreshProfessionsList = () => {
        getMasterList();
        getProfessionList();
        getSubProfessionList();
    }

    const handleCloseModal = () => {
        setOpenProfessionModalState(!openProfessionModal);
    }

    // checking accordion open or close then api call
    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            refreshProfessionsList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    useEffect(() => {
        // console.log('aaa', profile);
    }, [profile])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='mt-3'>
                {openProfessionModal ?
                    <ProfessionModal data={profile} onCloseModal={handleCloseModal} />
                    : null
                }
                <div className="accordion accordion-flush" id="professionAc">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingprofession">
                            <button className="accordion-button collapsed rounded accordian_border_with_color profession_form_button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseprofession" aria-expanded="false" aria-controls="flush-collapseprofession">
                                Profession details
                            </button>
                        </h2>
                        <div id="flush-collapseprofession" ref={myElementRef} className="accordion-collapse collapse mb-1" aria-labelledby="flush-headingprofession" data-bs-parent="#professionAc">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded font_weight_600'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={() => setOpenProfessionModalState(!openProfessionModal)}
                                    >
                                        Edit
                                    </button>
                                </div>
                                {/* <MasterForm data={profile} /> */}
                                <div>
                                    <p
                                        className='m-0 text-warning font18'
                                    >
                                        Master Profession
                                    </p>
                                    <p
                                        className='m-0 text-white font16'
                                    >
                                        {(Array.isArray(selectedMasterProfessionList) && selectedMasterProfessionList.length > 0) &&
                                            selectedMasterProfessionList.map((val: any, i: number) => {
                                                return (
                                                    <span key={val.id}>
                                                        {val.title}{i !== selectedMasterProfessionList.length - 1 && ','} &nbsp;
                                                    </span>
                                                )
                                            })
                                        }
                                    </p>
                                </div>
                                <div
                                    className='mt-4'
                                >
                                    <p
                                        className='m-0 text-warning font18'
                                    >
                                        Profession
                                    </p>
                                    <p
                                        className='m-0 text-white font16'
                                    >
                                        {(Array.isArray(selectedProfessionList) && selectedProfessionList.length > 0) &&
                                            selectedProfessionList.map((val: any, i: number) => {
                                                return (
                                                    <span key={val.id}>
                                                        {val.title}{i !== selectedProfessionList.length - 1 && ','} &nbsp;
                                                    </span>
                                                )
                                            })
                                        }
                                    </p>
                                </div>
                                <div
                                    className='mt-4'
                                >

                                    {(Array.isArray(selectedSubProfessionList) && selectedSubProfessionList.length > 0) &&
                                        <>
                                            <p
                                                className='m-0 text-warning font18'
                                            >
                                                Sub-Profession
                                            </p>
                                            <p
                                                className='m-0 text-white font16'
                                            >
                                                {selectedSubProfessionList.map((val: any, i: number) => {
                                                    return (
                                                        <span key={val.id}>
                                                            {val.title}{i !== selectedSubProfessionList.length - 1 && ','} &nbsp;
                                                        </span>
                                                    )
                                                })}
                                            </p>
                                        </>
                                    }

                                </div>
                                {/* <div
                                    className='text-end mt-3'
                                >
                                    <button
                                        className='font16 font_weight_600 rounded profession_edit_button px-4'
                                        onClick={() => setOpenProfessionModalState(!openProfessionModal)}
                                    >
                                        Edit
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default ProfessionAccordian
