import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from "../../utils/api";
import axios from "axios";
import { ImCross } from 'react-icons/im';
import { getFamilyMemberListAPI } from "../../utils/utils";
import { FiEdit } from "react-icons/fi";
import { IoTrash } from "react-icons/io5";
import FamilyModal from "../myprofilemodals/FamilyModal";
import { Table } from "react-bootstrap";

const FamilyForm = () => {

    const messageTxt = (message: any) => toast(message);
    LoadingOverlay.propTypes = undefined;
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [familyList, setFamilyList] = useState<any>();
    const [family, setFamily] = useState({
        family_member: '',
        name: '',
        birth_year: '',
        death_year: '',
        relationship: '',
        family_id: '',
        image: '',
        member_profession: ''
    })

    const getFamilyList = async () => {
        setActive(true);
        const data = await getFamilyMemberListAPI();
        setFamilyList(data);
        setActive(false);
    }

    // const handleEditFamily = (e: any) => {
    //     e.preventDefault();
    //     if (isDisabled) {
    //         messageTxt('You can edit your profile...');
    //     } else {
    //         messageTxt("You can't edit your profile...");
    //     }
    //     setIsDisabled(!isDisabled);
    // }

    // const handleChangeFamilyVal = (e: any) => {
    //     const { name, value } = e.target;
    //     if (name === 'image') {
    //         const image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    //         setFamily((prevVal: any) => ({ ...prevVal, image: image }));
    //     } else {
    //         setFamily({ ...family, [name]: value });
    //     }
    // }

    // const handleSubmitFamily = async (e: any) => {
    //     e.preventDefault();
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     const { family_member, name, birth_year, death_year, relationship, family_id, image, member_profession } = family;
    //     if (name === '' || family_member === '') {
    //         messageTxt('Please enter a name and family member !');
    //         setIsDisabled(false);
    //     } else if (birth_year.length > 4 || birth_year.length < 4 || death_year.length > 4 || death_year.length < 4) {
    //         messageTxt('Please enter 4 digit year !');
    //         setIsDisabled(false);
    //     } else if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 family_member,
    //                 name,
    //                 birth_year,
    //                 death_year,
    //                 relationship,
    //                 image,
    //                 member_profession,
    //                 family_id
    //             }
    //             let result = await axios(`${baseURL}/api/subscriber/family/addUpdate`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //             setFamily({
    //                 family_member: '',
    //                 name: '',
    //                 birth_year: '',
    //                 death_year: '',
    //                 relationship: '',
    //                 family_id: '',
    //                 image: '',
    //                 member_profession: ''
    //             })
    //             getFamilyList();
    //             setActive(false);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // };

    // const takeFamilyValuesForUpdate = (e: any, val: any) => {
    //     const data = val;
    //     setFamily(data);
    // }

    const deleteFamilyMember = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'familyMember',
                    id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getFamilyList();
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleModalForm = (val:any) => {
        setFamily(val);
        setOpenFormModal(!openFormModal);
        getFamilyList();
    }

    const handleAddmoreAwardModal = () => {
        getFamilyList();
        setOpenAdd(!openAdd);
        setOpenFormModal(false);
    }

    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            getFamilyList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('family', family);
    // }, [family])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='written_script_accordion_button mt-3'>
                <div className="accordion accordion-flush" id="familyForm">
                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed ac_button rounded accordian_border_with_color text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefamilyForm" aria-expanded="false" aria-controls="flush-collapsefamilyForm">
                                Family
                            </button>
                        </h2>
                        <div id="flush-collapsefamilyForm" ref={myElementRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#familyForm">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                            {openFormModal &&
                                    <FamilyModal handleModalBox={handleModalForm} data={family} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <FamilyModal handleModalBox={handleAddmoreAwardModal} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(familyList) && familyList.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Family member</th>
                                                <th>Image</th>
                                                <th>Birth year</th>
                                                <th>Death year</th>
                                                <th>Relationship</th>
                                                <th>Profession</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(familyList) && familyList.length > 0 &&
                                                familyList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.name}</td>
                                                            <td>{val.family_member}</td>
                                                            <td
                                                                className='p-0'
                                                            >
                                                                {val.image?.path ?
                                                                    <img src={`${baseURL}/${val.image?.path}`} alt={val.name} className='object-fit-contain' height={'50'} width={'50'} />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt={val.name} className='object-fit-contain' height={'50'} width={'50'} />
                                                                }
                                                            </td>
                                                            <td>{val.birth_year}</td>
                                                            <td>{val.death_year}</td>
                                                            <td>{val.relationship}</td>
                                                            <td>{val.member_profession}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} />
                                                                    <IoTrash className='pointer' onClick={(e) => deleteFamilyMember(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white text-center font_weight_600"
                                    >
                                        No data found.
                                    </p>
                                }

                                {/* {Array.isArray(familyList) && familyList.length > 0 ?
                                    <>
                                        <p className='m-0 text-white mb-3'>
                                            Family members list
                                        </p>
                                        {familyList.length > 0 && Array.isArray(familyList) ?
                                            familyList.map((val: any) => {
                                                return (
                                                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center accordian_border_with_color mb-4" key={val.id}>
                                                        <div
                                                            className='d-flex justify-content-end mt-2'
                                                        >
                                                            {!isDisabled ?
                                                                <>
                                                                    <button
                                                                        className='py-2 px-2 ms-2 fw-bold rounded bg-danger'
                                                                        onClick={(e) => takeFamilyValuesForUpdate(e, val)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        className='py-2 px-2 ms-2 fw-bold rounded bg-danger'
                                                                        onClick={(e) => deleteFamilyMember(e, val.id)}
                                                                    >
                                                                        <ImCross className='text-white' />
                                                                    </button>
                                                                </>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12 written_script_multi_select'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">Family member</label>
                                                            <select className="form-select mb-3" value={val.family_member} aria-label="Default select example" disabled={isDisabled}>
                                                                <option selected>Select family member...</option>
                                                                <option value={'Father'}>Father</option>
                                                                <option value={'Mother'}>Mother</option>
                                                                <option value={'Brother'}>Brother</option>
                                                                <option value={'Sister'}>Sister</option>
                                                                <option value={'Son'}>Son</option>
                                                                <option value={'Daughter'}>Daughter</option>
                                                                <option value={'Spouse'}>Spouse</option>
                                                                <option value={'Relatives'}>Relatives</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                                                            <input type="text" value={val.name} className="form-control mb-3" disabled={isDisabled} />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Birth year</label>
                                                            <input type="text" value={val.birth_year} className="form-control mb-3" disabled={isDisabled} />

                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">Death year</label>
                                                            <input type="text" value={val.death_year} className="form-control mb-3" disabled={isDisabled} />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">Relationship</label>
                                                            <input type="text" value={val.relationship} className="form-control mb-3" disabled={isDisabled} />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            {val.image ?
                                                                <div
                                                                    style={{ height: '66px', width: '50px' }}
                                                                >
                                                                    <img src={`${baseURL}/${val.image.path}`} alt={val.name} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                    </>
                                    :
                                    null
                                } */}

                                {/* <p className='m-0 mb-3 text-white font18'>Add/Update</p> */}
                                {/* <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form">
                                    <div className='col-lg-6 col-md-6 col-12 written_script_multi_select'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Family member</label>
                                        <select className="form-select mb-3" onChange={handleChangeFamilyVal} value={family.family_member} name="family_member" aria-label="Default select example" disabled={isDisabled}>
                                            <option selected>Select family member...</option>
                                            <option value={'Father'}>Father</option>
                                            <option value={'Mother'}>Mother</option>
                                            <option value={'Brother'}>Brother</option>
                                            <option value={'Sister'}>Sister</option>
                                            <option value={'Son'}>Son</option>
                                            <option value={'Daughter'}>Daughter</option>
                                            <option value={'Spouse'}>Spouse</option>
                                            <option value={'Relatives'}>Relatives</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                                        <input type="text" onChange={handleChangeFamilyVal} value={family.name} name="name" className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Birth year</label>
                                        <input type="number" onChange={handleChangeFamilyVal} value={family.birth_year} name="birth_year" className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Death year</label>
                                        <input type="number" onChange={handleChangeFamilyVal} value={family.death_year} name="death_year" className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Relationship</label>
                                        <input type="text" onChange={handleChangeFamilyVal} value={family.relationship} name="relationship" className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Family member photo</label>
                                        <input type="file" onChange={handleChangeFamilyVal} name="image" className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Family member profession</label>
                                        <input type="text" onChange={handleChangeFamilyVal} value={family.member_profession} name="member_profession" className="form-control mb-3" disabled={isDisabled} />
                                    </div>

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        <button
                                            className='py-2 px-2 border me-3 rounded fw-bold'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleEditFamily}
                                        >
                                            Edit
                                        </button>
                                        {!isDisabled ?
                                            <button
                                                className='py-2 px-2 border-0 rounded fw-bold save_button'
                                                style={{
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                                onClick={handleSubmitFamily}
                                            >
                                                Save
                                            </button>
                                            : null
                                        }
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default FamilyForm
