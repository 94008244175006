import React, { useEffect, useState } from 'react';
import { FaRegStar } from 'react-icons/fa';
import { IoMdStar } from "react-icons/io";
import { giveRatingAPI } from '../../../utils/utils';
import SignInModal from '../../signinmodal/SignInModal';

const CardRating = (props: any) => {

    const { id, cdbs_rating, rating, font, IsUserTating = true } = props;
    const auth_code = localStorage.getItem("auth_code");
    const [rate, setRate] = useState<any>(rating);
    const [showLogin, setShowLogin] = useState(false);

    const giveRatingToMovie = async (e: any, givenRating: any) => {
        e.stopPropagation();
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const msg = await giveRatingAPI(id, givenRating);
            setRate(givenRating);
            console.log(msg);
        }
    }

    const handleLoginModalBox = (val: any) => {
        val ? setShowLogin(false) : setShowLogin(false);
    }

    useEffect(() => {
        // setRate(rating);
    }, [rate])

    return (
        <div>
            {showLogin ?
                <SignInModal handleModalBox={handleLoginModalBox} />
                : null
            }
            {IsUserTating ?
                <div
                    className='lh-1'
                >
                    <span
                        className={`${font ? font : 'font10'} font_weight_600 text-warning text-uppercase me-1`}
                    >
                        Rate now
                    </span>
                    <span
                        className='font20 text-white ms-2'
                    >
                        {
                            [...Array(5)].map((star, index) => {
                                const givenRating = index + 1;
                                return (
                                    <FaRegStar
                                        className='me-1 pointer'
                                        onClick={(e) => {
                                            giveRatingToMovie(e, givenRating)
                                        }}
                                        key={id}
                                        style={{
                                            color: givenRating < rate || givenRating === rate ? '#FFC107' : '#D9D9D9'
                                        }}
                                    />
                                )
                            })
                        }
                    </span>
                </div>
                : null
            }
            <div
                className=''
            >
                <span
                    className={`${font ? font : 'font10'} font_weight_600 text-white text-uppercase me-1`}
                >
                    CDBS RATING
                </span>
                <span
                    className='font24 text-warning'
                >
                    {
                        [...Array(5)].map((star, index) => {
                            const givenRating = index + 1;
                            return (
                                <IoMdStar
                                    className='pointer'
                                    key={index}
                                    style={{
                                        color: (givenRating <= cdbs_rating || givenRating === cdbs_rating) ? 'yellow' : '#D9D9D9'
                                    }}
                                />
                            )
                        })
                    }
                </span>
            </div>
        </div>
    )
}

export default CardRating
