import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from '../../utils/api';
import { getAllCategorydetail, getAllEventsCategory, getEventForUpcomming, nearEventsAPI } from '../../utils/utils';
import { getEventDateFormate, removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import { useSelector } from 'react-redux';

const EventsTickets = () => {

  const navigate = useNavigate();
  const auth_code = localStorage.getItem('auth_code');
  const co_ordinats = useSelector((state: any) => state.userLocation.userLocation);
  const [isActive, setActive] = useState(false);
  const [activeId, setActiveId] = useState<number>(1);
  const [eventCategory, setEventCategory] = useState<any>([]);
  const [allCategoryData, setAllCategoryData] = useState<any>([]);
  const [upCommingEvent, setUpcommingEvent] = useState<any>([]);
  const [nearEvents, SetNearEvents] = useState<any>([]);

  const eventgn = [
    { cover: 'egn1', title: 'Andy', status: 'Online', date: 'Fri, Feb 10', watch: 'Watch On Zoom', rupees: 'Rs 50' },
    { cover: 'egn2', title: 'Bandy', status: 'Online', date: 'Fri, Feb 10', watch: 'Watch On Zoom', rupees: 'Rs 50' },
    { cover: 'egn3', title: 'Shandy', status: 'Online', date: 'Fri, Feb 10', watch: 'Watch On Zoom', rupees: 'Rs 50' },
    { cover: 'egn4', title: 'Nandy', status: 'Online', date: 'Fri, Feb 10', watch: 'Watch On Zoom', rupees: 'Rs 50' },
    { cover: 'egn5', title: 'Nandy', status: 'Online', date: 'Fri, Feb 10', watch: 'Watch On Zoom', rupees: 'Rs 50' }
  ];

  const getAlldata = async () => {
    setActive(true);
    const data = await getAllCategorydetail(1);
    setAllCategoryData(data);
    setActive(false);
  }

  const getUpcommingEvent = async () => {
    setActive(true);
    const data = await getEventForUpcomming();
    setUpcommingEvent(data);
    setActive(false);
  }

  const getEventCategory = async () => {
    setActive(true);
    const data = await getAllEventsCategory();
    setEventCategory(data);
    setActive(false);
  }

  const categoryContents = async (id: any) => {
    setActiveId(id);
    const data = await getAllCategorydetail(id);
    setAllCategoryData(data);
  }

  const getNearByEvents = async () => {
    setActive(true);
    const data = await nearEventsAPI(co_ordinats?.latitude, co_ordinats?.longitude);
    SetNearEvents(data);
    setActive(false);
  }

  useEffect(() => {
    getEventCategory();
    getAlldata();
    getUpcommingEvent();
  }, []);

  useEffect(() => {
    getNearByEvents();
  }, [co_ordinats]);

  // useEffect(() => {
  //   console.log(nearEvents)
  // }, [nearEvents]);

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Loading your content...'
    >
      <div className='mt-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='m-0 text-warning font26 fw-bold'>Events</h5>
          {auth_code ?
            <button
              className='px-3 rounded border-0 text-white'
              style={{ backgroundColor: 'red' }}
              onClick={() => navigate('/profile/createevents')}
            >
              Add New Event
            </button>
            : null
          }
        </div>

        <div className='row mt-4 p-0 event_ticket_category_section'>
          {
            eventCategory.map((val: any) => {
              return (
                <div className='col-lg-2 col-md-2 col-4 mb-lg-3 mb-md-3 mb-2 pointer' key={val.id}>
                  <div onClick={() => categoryContents(val.id)}>
                    <img src={`${baseURL}/${val.cover}`} alt={val.title} className={`object-fit-contain ${activeId === val.id ? 'event_category_active_border' : ''}`} width={'100%'} height={'100%'} />
                  </div>
                  <p
                    className={`m-0 ${activeId === val.id ? 'text-warning' : 'text-white'} text-center w-100 font14 font_weight_600 mt-2`}
                    style={{ bottom: '1px' }}
                  >
                    {val.title}
                  </p>
                </div>
              )
            })
          }
        </div>

        <h5 className='m-0 text-white font26 fw-bold my-4'>{allCategoryData?.category_name} - upcoming</h5>

        <div className='row p-0'>
          {Array.isArray(allCategoryData?.list) && allCategoryData?.list.length > 0 ?
            allCategoryData?.list.map((val: any) => {
              return (
                <div className='col-lg-3 col-md-6 col-12 mb-3 pointer' key={val.id}>
                  <Link to={`/menu/event-ticket-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none'>
                    <div
                      className='d-grid align-items-center justify-content-center'
                    >
                      <div className='position-relative'>
                        <div
                          style={{
                            height: '400px',
                            width: '305px'
                          }}
                        >
                          <img src={`${baseURL}/${val.cover}`} alt={val.title} className='object-fit-contain' width={'100%'} height={'100%'} />
                        </div>
                        <p
                          className='m-0 text-dark text-capitalize font_weight_600 px-2 position-absolute event_mode_bg_color px-4 rounded'
                          style={{ top: '10px', left: '20px' }}
                        >
                          {val.event_mode}
                        </p>
                        <p
                          className='m-0 text-warning position-absolute font16 font_weight_600'
                          style={{ bottom: '1px', left: '20px' }}
                        >
                          {getEventDateFormate(val.start_date)}
                        </p>
                      </div>
                      <p className='m-0 text-white font_weight_600 font16 mt-2 ps-3'>
                        {val.title}
                      </p>
                      <p className='m-0 font16 font_weight_600 text-white mt-2 ps-3'>
                        Ticket Price: <span className='text-warning'>{val.ticket_price}</span>
                      </p>
                    </div>
                  </Link>
                </div>
              )
            })
            :
            <p
              className='m-0 text-white font16 my-5 text-center'
            >
              Events are not available
            </p>
          }
        </div>

        <div className='row p-0 my-4'>
          <h5 className='m-0 text-white font26 fw-bold mb-2'>Get Set For This Weekend</h5>

          {Array.isArray(upCommingEvent) &&
            upCommingEvent.map((val: any) => {
              return (
                <div className='col-lg-3 col-md-6 col-12 d-flex justify-content-center align-items-center mb-3' key={val.id}>
                  <Link to={`/menu/event-ticket-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none'>
                    <div
                      style={{ width: '232px', height: '289px' }}
                    >
                      <img src={`${baseURL}/${val.cover}`} alt={val.title} className='object-fit-contain' width={'100%'} height={'100%'} />
                    </div>
                    <p className='m-0 text-white font_weight_600 font16 mt-2 ps-3'>
                      {val.title}
                    </p>
                    <p className='m-0 font16 font_weight_600 text-white mt-2 ps-3'>
                      Ticket Price: <span className='text-warning'>{val.ticket_price}</span>
                    </p>
                  </Link>
                </div>
              )
            })
          }
        </div>

        <div className='row p-0 my-4'>
          <h5 className='m-0 text-white font26 fw-bold mb-2'>Near By</h5>

          {
            nearEvents.map((val: any) => {
              return (
                <div key={val.id} className='col-lg-3 col-md-4 col-12 mb-4'>
                  <Link to={`/menu/event-ticket-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none'>
                    <div className=''>
                      <img src={`${baseURL}/${val.cover}`} alt={val.title} className='object-fit-contain' width={'100%'} height={'100%'} />
                    </div>
                    <p className='m-0 text-white font_weight_600 font16 mt-2 ps-3'>
                      {val.title}
                    </p>
                    <p className='m-0 font16 font_weight_600 text-white mt-2 ps-3'>
                      Ticket Price: <span className='text-warning'>{val.ticket_price}</span>
                    </p>
                  </Link>
                </div>
              )
            })
          }
        </div>

      </div>
    </LoadingOverlay>
  )
}

export default EventsTickets
