import React from 'react';
import { baseURL } from '../../utils/api';

const OnlyImages = (props: any) => {

    const { image } = props;

    return (
        <div className='d-flex justify-content-center pointer' style={{ width: 'fit-content' }}>
            <div className="image">
                <div className='position-relative movie_card_section'>
                    <img src={`${baseURL}/${image}`} className="rectangle p-1 object-fit-contain" alt={image} />
                </div>
            </div>
        </div>
    )
}

export default OnlyImages
