import React from 'react'

const Adds = () => {
    return (
        <div
            className='theme_color d-flex justify-content-center align-items-center text-white home_movieDetail_ad'
        >
            AD
        </div>
    )
}

export default Adds
