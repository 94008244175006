import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ProjectSongModal = (props: any) => {

    const { handleModalBox, data, project_id, dataId, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [songLinks, setsongLinks] = useState({ name: '', youtube_link: '', singers: '' });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setsongLinks({ ...songLinks, [name]: value });
        // const updatedsongLinksData = [...songLinks];
        // updatedsongLinksData[i] = { ...updatedsongLinksData[i], [name]: value };
        // setsongLinks(updatedsongLinksData);
    };

    const handleAddSongAdd = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    project_songs: JSON.stringify([songLinks])
                }
                let result = await axios(`${baseURL}/api/project/add-songs`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                setsongLinks({ name: '', youtube_link: '', singers: '' });
                messageTxt(result.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handleSongUpdate = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id: connection_id,
                    auth_code: auth_code,
                    song_id: dataId,
                    name: songLinks.name,
                    link: songLinks.youtube_link,
                    singers: songLinks.singers
                }
                let result = await axios(`${baseURL}/api/project/update-song`, {
                    method: 'POST',
                    data: body
                });
                setsongLinks({ name: '', youtube_link: '', singers: '' });
                setActive(false);
                messageTxt(result.data.message);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const takeSongValuesForUpdate = () => {
        setActive(true);
        if (dataId) {
            setEnableUpdate(true);
            setsongLinks({
                ...songLinks,
                name: data.name,
                youtube_link: data.youtube_link,
                singers: data.singers
            });
        }
        setActive(false);
    }

    useEffect(() => {
        takeSongValuesForUpdate();
    }, [data, dataId])


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center p_song_modal">
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Song Name</label>
                            <input type="text" name='name' value={songLinks.name} onChange={handleChange} className="form-control mb-3" placeholder='eg: Tu jo nahi hai' id="exampleFormControlInput1" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Song Link</label>
                            <input type="text" name='youtube_link' value={songLinks.youtube_link} onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" placeholder='URL Link' />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Singer Name</label>
                            <input type="text" name='singers' value={songLinks.singers} onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Kishore Kumar,Mohd.Rafi etc.' />
                        </div>
                    </div>

                    <div
                        className='d-flex justify-content-end'
                    >
                        {enableUpdate ?
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleSongUpdate}
                            >
                                Update
                            </button>
                            :
                            <button
                                className='py-2 px-2 border-0 rounded font_weight_600'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleAddSongAdd}
                            >
                                ADD
                            </button>
                        }
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProjectSongModal
