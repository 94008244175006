import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import axios from 'axios';
import { baseURL } from '../../utils/api';
import { Link } from 'react-router-dom';

const ContactUs = () => {

  const [submitLoading, setSubmitLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });
  const notify = (message: any) => toast.success(message, {
    theme: "colored"
  });

  const maxMobileNumber = 10;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      if (value.length <= maxMobileNumber) {
        setFormVal({ ...formVal, phone: value });
      }
    } else {
      setFormVal({ ...formVal, [name]: value });
    }
  }

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    const { phone, email } = formVal;
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (!phone || !email) {
      notify('Please fill phone number and email');
    } else if (phone.length > 10 || phone.length < 10) {
      notify('Please enter 10 digit phone number');
    } else if (!regEx.test(email)) {
      notify('Invalid email');
    } else {
      setSubmitLoading(true);
      const { data } = await axios.post(`${baseURL}/api/contact_us`, formVal);
      notify(data.message);
      setFormVal({
        name: '',
        phone: '',
        email: '',
        message: ''
      });
      setSubmitLoading(false);
    }
  }

  return (
    <div>
      <div className='mt-5'>

        <div>
          <h5 className='m-0 mb-1 text-warning fw-bold font26 text-center'>Contact us</h5>
          <p className='m-0 text-white text-center'>
            If you have you any query, please fill in the form below
          </p>
        </div>

        <div className='contact_us_form_section theme_color py-lg-5 py-md-5 py-2 mt-4'>
          <form className='px-lg-5 px-md-5 px-1'>
            <div className="row p-0 mb-lg-5 mb-md-5 mb-3 d-flex justify-content-between align-items-center">
              <div className='col-lg-6 col-md-6 col-12 mb-lg-0 mb-md-0 mb-3'>
                <input type="text" className="form-control" onChange={handleChange} value={formVal.name} name='name' placeholder="Your Name" />
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <input type="text" className="form-control" onChange={handleChange} value={formVal.email} name='email' placeholder="Email" />
              </div>
            </div>

            <div className='mb-4'>
              <input type="number" className="form-control" onChange={handleChange} value={formVal.phone} name='phone' placeholder="Conatct Number" />
            </div>
            <div className="mb-3">
              <textarea className="form-control" onChange={handleChange} value={formVal.message} name='message' placeholder='Message'></textarea>
            </div>

            <button
              className='w-100 py-3 border-0 rounded fw-bold'
              style={{
                backgroundColor: 'red',
                color: 'white',
              }}
              onClick={handleSubmitForm}
              disabled={submitLoading}
            >
              {submitLoading ? 'Loading...' : 'Submit'}
            </button>

          </form>
        </div>

        <div className='row m-0 p-0 mt-5 theme_color p-4'>
          <div className='col-lg-4 col-md-4 col-12 d-flex justify-content-center align-items-center'>
            <div className='bg-dark p-5' style={{ height: '220px', width: '276px' }}>
              <div className='contact_icon text-white fs-2 border py-4 d-flex justify-content-center align-items-center'>
                <FaPhone />
              </div>
              <p className='m-0 mt-3 text-center text-white'>Call Us</p>
              <p className='m-0 text-center text-white'>+919980021200</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-12 my-lg-0 my-md-0 my-3 d-flex justify-content-center align-items-center'>
            <Link
              to={`https://wa.me/919980021200?text=I'm%20interested%20in%20your%20cinemdbs%20for%20onboarding`}
              target='_blank'
              className='text-decoration-none'
            >
              <div className='bg-dark p-5 h-100' style={{ width: '276px' }}>
                <div className='contact_icon text-white fs-2 border py-4 d-flex justify-content-center align-items-center'>
                  <FaWhatsapp />
                </div>
                <p className='m-0 mt-3 text-center text-white'>Whatsapp Chat</p>
              </div>
            </Link>
          </div>
          <div className='col-lg-4 col-md-4 col-12 d-flex justify-content-center align-items-center'>
            <div className='bg-dark p-5' style={{ height: '220px' }}>
              <div className='contact_icon text-white fs-2 border py-4 d-flex justify-content-center align-items-center'>
                <MdEmail />
              </div>
              <p className='m-0 mt-3 text-center text-white'>Email Us</p>
              <p className='m-0 text-center text-white'>contact@cinemadbs.com</p>
            </div>
          </div>
        </div>

        <p className='m-0 mt-4 text-white text-center'>
          We're based in Bangalore, Karnataka.
        </p>

      </div>
      <ToastContainer />
    </div >
  )
}

export default ContactUs
