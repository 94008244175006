import React, { useEffect, useState } from 'react';
import './commonfooterpagescss.css';
import { getFooterPagesAPI } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useLocation } from 'react-router-dom';

const CommonFooterPage = () => {

    const [isActive, setActive] = useState(false);
    const [data, setData] = useState<any>();
    const location = useLocation();

    const getPageDetail = async () => {
        setActive(true);
        const res = await getFooterPagesAPI(location.pathname.replace('/', ''));
        setData(res);
        setActive(false);
    }

    useEffect(() => {
        getPageDetail();
        console.log(location.pathname.replace('/', ''));
    }, [location.pathname])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='container text-white my-5'>
                <div dangerouslySetInnerHTML={{ __html: data?.content }} />
            </div>
        </LoadingOverlay>
    )
}

export default CommonFooterPage
