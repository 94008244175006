import axios from 'axios';
import './profileform.css';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import { ImCross } from 'react-icons/im';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getKycListAPI } from '../../utils/utils';
import KycModal from '../myprofilemodals/KycModal';
import { Table } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { IoTrash } from 'react-icons/io5';

const KycForm = () => {

    const kyc_detail = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    LoadingOverlay.propTypes = undefined;
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [kycList, setKycList] = useState<any>();
    const [all_kyc, setAllKyc] = useState([]);
    const [kyc, setKyc] = useState({
        document_id: '',
        document_image: '',
        type: ''
    });

    const getKycList = async () => {
        setActive(true);
        const data = await getKycListAPI();
        setAllKyc(data);
        setActive(false);
    };

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'document_image') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setKyc((prevKyc: any) => ({ ...prevKyc, document_image: document_image }));
        } else {
            setKyc((prevKyc) => ({ ...prevKyc, [name]: value }));
        }
    };

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    // const handleKYC = async (e: any) => {
    //     e.preventDefault();
    //     setActive(true);
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');

    //     if (kyc.document_id === '' || kyc.document_image === '' || kyc.type === '') {
    //         messageTxt('Please fill all fields !');
    //         setActive(false);
    //     } else if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 document_id: kyc.document_id,
    //                 document_image: kyc.document_image,
    //                 type: kyc.type
    //             }
    //             let result = await axios(`${baseURL}/api/subscriber/update-kyc`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //             setKyc({
    //                 document_id: '',
    //                 document_image: '',
    //                 type: ''
    //             });
    //             getKycList();
    //             setIsDisabled(!isDisabled);
    //             setActive(false);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const deleteKyc = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'kyc',
                    id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getKycList();
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    // checking accordion open or close then api call

    const handleModalForm = (val: any) => {
        setKyc(val);
        setOpenFormModal(!openFormModal);
        getKycList();
    }

    const handleAddmoreAwardModal = () => {
        getKycList();
        setOpenAdd(!openAdd);
        setOpenFormModal(false);
    }

    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            getKycList();
            setKycList(kyc_detail.kyc_document_list);
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('kyc', all_kyc);
    // }, [all_kyc])


    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='mt-3'>
                <div className="accordion accordion-flush" id="KycForm">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingKycForm">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseKycForm" aria-expanded="false" aria-controls="flush-collapseKycForm">
                                KYC
                            </button>
                        </h2>
                        <div id="flush-collapseKycForm" ref={myElementRef} className="accordion-collapse collapse" aria-labelledby="flush-headingKycForm" data-bs-parent="#KycForm">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                                {/* 
                                {openFormModal &&
                                    <KycModal handleModalBox={handleModalForm} data={kyc} kyc_document_list={kycList} headingText={'Update'} />
                                } */}
                                {openAdd &&
                                    <KycModal handleModalBox={handleAddmoreAwardModal} kyc_document_list={kycList} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>

                                {Array.isArray(all_kyc) && all_kyc.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Document type</th>
                                                <th>ID type</th>
                                                <th>Document</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(Array.isArray(all_kyc) && all_kyc.length > 0) &&
                                                all_kyc.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.kyc_type?.replace(/_/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase())}</td>
                                                            <td>{val.document_name}</td>
                                                            <td>
                                                                {val.image_path ?
                                                                    <div
                                                                        style={{ height: '66px', width: '50px' }}
                                                                    >
                                                                        <img src={`${baseURL}/${val.image_path}`} alt={val.name} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    {/* <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} /> */}
                                                                    <IoTrash className='pointer' onClick={(e) => deleteKyc(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white font_weight_600 font16 text-center"
                                    >
                                        Data not found, add new data.
                                    </p>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default KycForm
