import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const PoliticalInvolmentModal = (props: any) => {

    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [poly_id, setPolyId] = useState<any>();
    const [politicalInvolment, setPoliticalInvolment] = useState({
        title: '',
        description: '',
        image: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChangeVal = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const movement_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setPoliticalInvolment((prevVal: any) => ({ ...prevVal, image: movement_image }));
        } else {
            setPoliticalInvolment({ ...politicalInvolment, [name]: value });
        }
    }

    const handleSavePoliticalInvolment = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { title, description, image } = politicalInvolment;
        if (title === '' || description === '' || image === '') {
            messageTxt('Please fill all fields and select image');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    title,
                    description,
                    image,
                    political_data_id: poly_id
                }
                const res = await axios(`${baseURL}/api/subscriber/political_involvement/add`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(res.data.message);
                setPoliticalInvolment({
                    title: '',
                    description: '',
                    image: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const getData = () => {
        setActive(true);
        if (data) {
            setPolyId(data.id);
            setPoliticalInvolment(data);
        }
        setActive(false);
    }

    useEffect(() => {
        getData();
    }, [data])


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center modal_form_add_update">
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Political Involvement description</label>
                            <textarea name='description' onChange={handleChangeVal} value={politicalInvolment.description} className="form-control" placeholder='Describe how to involved in politics' style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1"></textarea>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Title</label>
                            <input type="text" onChange={handleChangeVal} value={politicalInvolment.title} name='title' placeholder='Enter the topic name' className="form-control mb-3" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Image</label>
                            <input type="file" onChange={handleChangeVal} name='image' className="form-control mb-3" />
                        </div>
                    </div>

                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                    <button
                                className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleSavePoliticalInvolment}
                            >
                                Save
                            </button>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default PoliticalInvolmentModal
