import React, { useState, useEffect } from 'react';
import './shopcss.css';
import { Modal } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ImCross } from 'react-icons/im';
import { BiSolidDiscount } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import UserAddressModal from './UserAddressModal';
import { getAllUserAddressEcomAPI, placedOrderEcomAPI } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

const ShopPaymentModalBox = (props: any) => {

    const { handleModalBox, cartId, items, total } = props;
    LoadingOverlay.propTypes = undefined;
    const navigate = useNavigate();
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [openUserAddress, setOpenUserAddress] = useState(false);
    const [exist_address, setExist_address] = useState('');
    const [full_address, setFullAddress] = useState<any>();

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const getAddress = async () => {
        setActive(true);
        const data = await getAllUserAddressEcomAPI();
        if (exist_address === '') {
            setFullAddress(data[0]);
            setExist_address(data[0]?.id)
        } else {
            const res = data.filter((val: any) => {
                return val.id == exist_address;
            })
            setFullAddress(res[0]);
        }
        setActive(false);

    }

    const handleUserdetailModal = (val: any) => {
        setOpenUserAddress(!openUserAddress);
        setExist_address(val);

    }

    const handlePlacedOrder = async (e: any, payment_id: any) => {
        e.preventDefault();
        setActive(true);
        if (!exist_address) {
            messageTxt('Please add yor address');
            setActive(false);
        } else {
            try {
                await placedOrderEcomAPI(cartId, exist_address, payment_id);
                navigate('/menu/shop');
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getAddress();
    }, [exist_address])

    useEffect(() => {
        console.log('payment', full_address);
    }, [full_address])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`lg`}
        >
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                {openUserAddress ?
                    <UserAddressModal handleModalBox={handleUserdetailModal} items={items} total={total} />
                    :
                    <div
                        className='bg-dark pb-lg-5 pb-md-5 pb-3 p-lg-4 p-md-4 p-1'
                    >
                        <div
                            className='d-flex justify-content-between align-items-center'
                        >
                            <p
                                className='m-0 text-warning font_weight_600 font20'
                            >
                                Checkout
                            </p>
                            <div
                                style={{
                                    height: '50px',
                                    width: '200px',
                                }}
                            >
                                <img src='../../images/logo.png' alt='logo' width={'100%'} height={'100%'} />
                            </div>
                            <button
                                className=''
                                onClick={handlClosedModal}
                                style={{ backgroundColor: 'transparent' }}
                            >
                                <ImCross />
                            </button>
                        </div>
                        <p
                            className='m-0 text-white font16 text-center mt-4'
                        >
                            10% Off On Order Above 1000/-
                        </p>
                        <div
                            className='text-warning d-flex justify-content-between align-items-center mt-5 pb-lg-3 pb-md-3 pb-2'
                        >
                            <p
                                className='m-0 font_weight_600 font16'
                            >
                                Order Summary ({items} ITEMS)
                            </p>
                            <p
                                className='m-0 font_weight_600 font16'
                            >
                                &#x20B9; {total}
                            </p>
                        </div>
                        <select className="form-select my-4 bg-transparent text-white" aria-label="Large select example">
                            <option selected>Add coupon</option>
                            {/* {Array.isArray(filmTypeList) &&
                        filmTypeList?.map((val: any) => {
                            return (
                                <option key={val.id} value={val.title}>{val.title}</option>
                            )
                        })
                    } */}
                        </select>

                        <div
                            className='d-flex justify-content-between align-items-center mt-5'
                        >
                            <p
                                className='m-0 text-warning font_weight_600 font18'
                            >
                                Deliver to
                            </p>
                            <button
                                className='payment_modal_address_button text-warning text-decoration-underline'
                                onClick={handleUserdetailModal}
                            >
                                Change
                            </button>
                        </div>

                        <div
                            className='text-white mt-3 font_weight_600 font16'
                        >
                            <p
                                className='m-0'
                            >
                                {full_address?.first_name} {full_address?.last_name}
                            </p>
                            <p
                                className='m-0'
                            >
                                {full_address?.address ? `${full_address?.address},` : ''} &nbsp;
                                {full_address?.street ? `${full_address?.street},` : ''} &nbsp;
                                {full_address?.landmark ? `${full_address?.landmark},` : ''} &nbsp;
                                {full_address?.city ? `${full_address?.city},` : ''} &nbsp;
                                {full_address?.state ? `${full_address?.state},` : ''} &nbsp;
                                {full_address?.zip_code ? `${full_address?.zip_code}` : ''}
                            </p>
                        </div>

                        <p
                            className='m-0 text-warning font_weight_600 font18 mt-5'
                        >
                            Get it by
                        </p>

                        <div
                            className='accordian_border_with_color py-2 px-3 mt-3 rounded'
                            style={{
                                width: 'fit-content'
                            }}
                        >
                            <p
                                className='m-0 text-white font16 font_weight_600 mb-1'
                            >
                                Thrusday, Jan 18
                            </p>
                            <p
                                className='m-0 text-warning font16 font_weight_600'
                            >
                                Standard FREE
                            </p>
                        </div>

                        <p
                            className='m-0 text-warning font_weight_600 font18 mt-5'
                        >
                            Select Payment Method
                        </p>

                        <div
                            className='cashondelivery_button pointer w-75 rounded-3 ps-4 py-2 theme_color mt-4'
                            onClick={(e: any) => handlePlacedOrder(e, 1)}
                        >
                            <img src='../images/cashondev.png' alt={'cashondev'} width={'30'} height={'30'} />
                            <span
                                className='text-white font16 font_weight_600 ms-4'
                            >
                                Cash on delivery
                            </span>
                        </div>

                    </div>
                }
            </LoadingOverlay>
            <ToastContainer />
        </Modal>
    )
}

export default ShopPaymentModalBox
