import React, { useEffect, useState } from 'react';
import { calculateAge, getLegendsDetails } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { FaRegStar, FaStar } from "react-icons/fa";
import { BiLike, BiDislike, BiSolidUser } from "react-icons/bi";
import { BsShareFill, BsFillHeartFill } from "react-icons/bs";
import ProfileSlider from '../../components/profileslider/ProfileSlider';
import { FaGenderless, FaAward, FaStarOfDavid, FaGraduationCap } from "react-icons/fa6";
import { GiBigDiamondRing } from "react-icons/gi";
import { PiFilmSlate, PiFilmReelBold, PiSignatureDuotone } from "react-icons/pi";
import { TiLocation } from "react-icons/ti";
import { Link, useLocation, useParams } from 'react-router-dom';
import { SlArrowDown } from "react-icons/sl";
import { calculateDeathAge, cmToFeetInches, convertWeightToPounds, extractIdFromUrl, getDOB } from '../../utils/jsfunctions';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import ProfileMovieSlider from '../../components/profilemovieslider/ProfileMovieSlider';
import FilmographySlider from '../../components/filmographyslider/FilmographySlider';
import VideoPlayer from '../../components/videoplayer/VideoPlayer';

const MyProfileDetails = () => {

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const params = useParams();
    const location = useLocation();
    const { id } = params;
    const [isActive, setActive] = useState(true);
    const [profile_data, setProfileData] = useState<any>();
    const [height, setHeight] = useState<any>();
    const [age, setAge] = useState<any>();

    const getAllDetail = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const data = await getLegendsDetails(urlId);
        setProfileData(data);
        setActive(false);
    }

    const copyToClipboard = () => {
        const currentUrl = window.location.href;

        navigator.clipboard.writeText(currentUrl)
            .then(() => {
                messageTxt('URL copied to clipboard !');
                // console.log('URL copied to clipboard!');
            })
            .catch((err) => {
                messageTxt('Error copying to clipboard !');
                // console.error('Error copying to clipboard:', err);
            });
    };

    useEffect(() => {
        setTimeout(() => {
            getAllDetail();
        }, 2000)
    }, [id])

    useEffect(() => {
        const data = cmToFeetInches(profile_data?.height);
        setHeight(data);
    }, [profile_data])

    // useEffect(() => {
    //     console.log('data', profile_data)
    // }, [profile_data])

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <div
                    className='mx-lg-5 mx-md-0 mx-0 px-lg-5 px-md-5 px-2 my-5'
                >
                    <div
                        className='row my-5'
                    >
                        <div
                            className='col-lg-8 col-md-12 col-12 mb-lg-0 mb-md-0 mb-3'
                        >
                            <div
                                className='mx-lg-5 mx-md-0 mx-0 px-lg-4 px-md-0 px-0 mb-lg-4 mb-md-0 mb-4'
                            >
                                <div
                                    className='d-lg-flex d-md-flex d-flex justify-content-lg-start align-items-center'
                                >
                                    <h2
                                        className='text-warning fw-bold'
                                    >
                                        {profile_data?.name}
                                    </h2>
                                    {profile_data?.name_given_by_industry !== null ?
                                        <p
                                            className='m-0 text-white font18 fw-bold ms-lg-3 ms-md-3 ms-3'
                                        >
                                            🌟🌟 {profile_data?.name_given_by_industry} 🌟🌟
                                        </p>
                                        : null
                                    }
                                </div>
                                {profile_data?.name_in_industry_native_language !== null ?
                                    <p
                                        className='m-0 text-white font18 fw-bold'
                                    >
                                        ({profile_data?.name_in_industry_native_language})
                                    </p>
                                    : null
                                }
                                {/* <div>
                                    <p
                                        className='m-0 text-warning font18 fw-bold'
                                    >
                                        {profile_data?.profession_in_industry}
                                    </p>
                                    {profile_data?.industry_type ?
                                        <p
                                            className='m-0 text-white font14 lh-1'
                                        >
                                            ({profile_data?.industry_type})
                                        </p>
                                        : null
                                    }
                                </div> */}
                            </div>
                            <div>
                                <ProfileSlider data={profile_data?.featured_images} />
                            </div>
                        </div>

                        <div
                            className='col-lg-4 col-md-12 col-12 px-0 px-lg-0 px-md-0 px-2'
                        >
                            <div
                                className='d-flex justify-content-between justify-content-center'
                            >
                                <div>
                                    <p
                                        className='m-0 text-warning font18 fw-bold'
                                    >
                                        {profile_data?.profession_in_industry}
                                    </p>
                                    {profile_data?.industries ?
                                        <p
                                            className='m-0 text-white font14 lh-1'
                                        >
                                            (
                                            {
                                                profile_data?.industries?.map((val: any, i: number) => {
                                                    return (
                                                        <span key={val.id}>
                                                            {val.title}{i !== profile_data?.industries.length - 1 && ','}
                                                        </span>
                                                    )
                                                })
                                            }
                                            )
                                        </p>
                                        : null
                                    }
                                </div>
                                <div>
                                    <div
                                        className='mb-lg-3 mb-md-3 mb-2'
                                    >
                                        <p
                                            className='m-0 text-white font12'
                                        >
                                            Rate Now
                                        </p>
                                        <p
                                            className='m-0 text-white font20 lh-1'
                                        >
                                            <FaRegStar className='me-1' />
                                            <FaRegStar className='me-1' />
                                            <FaRegStar className='me-1' />
                                            <FaRegStar className='me-1' />
                                            <FaRegStar />
                                        </p>
                                    </div>
                                    <div
                                        className=''
                                    >
                                        <BiLike className='text-white font26 me-3' />
                                        <button
                                            className='rounded border-0 fw-bold font18'
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                            }}
                                            onClick={copyToClipboard}
                                        >
                                            Share <BsShareFill className='fs-4' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p
                                    className='text-white font20 fw-bold m-0'
                                >
                                    Quick information
                                </p>

                                <div
                                    className='row m-0 theme_color rounded pb-3'
                                >
                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'

                                            >
                                                <FaGenderless />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    AGE
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.death_date ?
                                                        calculateDeathAge(profile_data?.birth_date, profile_data?.death_date)
                                                        :
                                                        calculateAge(profile_data?.birth_date)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'
                                            >
                                                <BiSolidUser />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    Original Name
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.first_name} {profile_data?.last_name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'
                                            >
                                                <GiBigDiamondRing />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    Marital Status
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.subscriber_detail?.marital_status}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'
                                            >
                                                <PiFilmSlate />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    Total Films
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.total_films_made}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'
                                            >
                                                <FaAward />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    Awards
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.awards.length} Award
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'
                                            >
                                                <PiFilmReelBold />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    First Debut
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.debut_performance_title}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'
                                            >
                                                <TiLocation />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    Birth Place
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.subscriber_detail?.birth_place}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='col-lg-6 col-md-6 col-12 p-0'
                                    >
                                        <div
                                            className='d-flex justify-content-around align-items-baseline mt-1'
                                        >
                                            <div
                                                className='text-warning font20'
                                            >
                                                <FaStarOfDavid />
                                            </div>
                                            <div
                                                className='text-warning w-50'
                                            >
                                                <p className='m-0 fw-bold font14'>
                                                    Zodiac Sign
                                                </p>
                                                <p className='m-0 text-white font16'>
                                                    {profile_data?.zodiac_sign}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div
                                    className='d-flex justify-content-between px-lg-0 px-md-0 px-2'
                                >
                                    <Link to='' className='text-decoration-none'>
                                        <div
                                            className='mt-3'
                                            style={{
                                                height: '50px',
                                                width: '50px'
                                            }}
                                        >
                                            <img src='../images/profilefb.png' alt='...' className='rounded-4' width={'100%'} height={'100%'} />
                                        </div>
                                    </Link>
                                    <Link to='' className='text-decoration-none'>
                                        <div
                                            className='mt-3'
                                            style={{
                                                height: '50px',
                                                width: '50px'
                                            }}
                                        >
                                            <img src='../images/insta.png' alt='...' className='rounded-4' width={'100%'} height={'100%'} />
                                        </div>
                                    </Link>
                                    <Link to='' className='text-decoration-none'>
                                        <div
                                            className='mt-3'
                                            style={{
                                                height: '50px',
                                                width: '50px'
                                            }}
                                        >
                                            <img src='../images/twitterx.png' alt='...' className='rounded-4' width={'100%'} height={'100%'} />
                                        </div>
                                    </Link>
                                    <Link to='' className='text-decoration-none'>
                                        <div
                                            className='mt-3'
                                            style={{
                                                height: '50px',
                                                width: '50px'
                                            }}
                                        >
                                            <img src='../images/profileyoutube.png' alt='...' className='rounded-4' width={'100%'} height={'100%'} />
                                        </div>
                                    </Link>
                                    <Link to='' className='text-decoration-none'>
                                        <div
                                            className='mt-3'
                                            style={{
                                                height: '50px',
                                                width: '50px'
                                            }}
                                        >
                                            <img src='../images/profilelinkedin.png' alt='...' className='rounded-4' width={'100%'} height={'100%'} />
                                        </div>
                                    </Link>
                                    <Link to='' className='text-decoration-none'>
                                        <div
                                            className='mt-3 p-2'
                                            style={{
                                                height: '50px',
                                                width: '50px'
                                            }}
                                        >
                                            <img src='../images/threads.png' alt='...' className='rounded-4' width={'100%'} height={'100%'} />
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Biography section */}

                    <div
                        className='theme_color mb-5'
                    >
                        <div
                            className='row m-0 py-4 px-2'
                        >
                            <div
                                className='col-lg-10 col-md-6 col-12 mb-lg-0 mb-md-0 mb-3 overflow-y-auto mb-lg-0 mb-md-0 mb-3 biographhy_scroll_style'
                                style={{
                                    maxHeight: '240px'
                                }}
                            >
                                <p
                                    className='text-white fw-bold font20'
                                >
                                    Biography
                                </p>
                                <p
                                    className='text-white font16'
                                >
                                    {profile_data?.subscriber_detail?.about}
                                </p>
                            </div>
                            <div
                                className='col-lg-2 col-md-6 col-12'
                            >
                                <div
                                    className='d-grid justify-content-center'
                                >
                                    <div
                                        style={{
                                            height: '180px',
                                            width: '120px'
                                        }}
                                    >
                                        <img src={`${baseURL}/${profile_data?.profile_image}`} alt='...' className='object-fit-content' width={'100%'} height={'100%'} />
                                    </div>
                                    <div
                                        className='text-warning font24 d-flex justify-content-around mt-2'
                                    >
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                    </div>
                                    <p
                                        className='m-0 mt-1 text-white fw-bold font16 text-end'
                                    >
                                        CDBS RATING
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            className='row m-0 px-2 pb-4'
                        >
                            <div
                                className='col-lg-6 col-md-6 col-12'
                            >
                                <div className='pb-5 border-end border-secondary'>
                                    <div
                                        className='text-warning font18 d-flex align-items-center'
                                    >
                                        <p
                                            className='m-0 mb-1 me-3 font20'
                                        >
                                            <FaGraduationCap />
                                        </p>
                                        <p
                                            className='m-0'
                                        >
                                            Educational Qualification
                                        </p>
                                    </div>

                                    <div
                                        className='d-flex mt-2'
                                    >
                                        <p
                                            className='fw-bold font14 text-white me-1'
                                        >
                                            School:
                                        </p>
                                        <p
                                            className='font14 text-white'
                                        >
                                            {profile_data?.tenth_pass_from}
                                        </p>
                                    </div>
                                    <div
                                        className='d-flex'
                                    >
                                        <p
                                            className='fw-bold font14 text-white me-1'
                                        >
                                            Graduation:
                                        </p>
                                        <p
                                            className='font14 text-white'
                                        >
                                            {profile_data?.graduated_from}
                                        </p>
                                    </div>
                                    <div
                                        className='d-flex'
                                    >
                                        <p
                                            className='fw-bold font14 text-white me-1'
                                        >
                                            Post Graduation:
                                        </p>
                                        <p
                                            className='font14 text-white'
                                        >
                                            {profile_data?.master_graduated_from}
                                        </p>
                                    </div>
                                    <div
                                        className='d-flex'
                                    >
                                        <p
                                            className='fw-bold font14 text-white me-1'
                                        >
                                            Special Qualification/Training:
                                        </p>
                                        <p
                                            className='font14 text-white'
                                        >
                                            {profile_data?.latest_art_training_institute_name}
                                        </p>
                                    </div>
                                    <div
                                        className='d-flex'
                                    >
                                        <p
                                            className='fw-bold font14 text-white me-1'
                                        >
                                            Others:
                                        </p>
                                        <p
                                            className='font14 text-white'
                                        >
                                            {profile_data?.other_skill_sets}
                                        </p>
                                    </div>

                                    <div>
                                        <div
                                            className='text-warning font18 d-flex align-items-center'
                                        >
                                            <p
                                                className='m-0 mb-1 me-3 font24'
                                            >
                                                <PiSignatureDuotone />
                                            </p>
                                            <p
                                                className='m-0'
                                            >
                                                Signature/Autograph
                                            </p>
                                        </div>
                                        <div
                                            className='mt-2'
                                            style={{
                                                width: '330px',
                                                height: '147px'
                                            }}
                                        >
                                            {profile_data?.autograph_picture ?
                                                <img src={`${baseURL}/${profile_data?.autograph_picture}`} className={`object-fit-contain`} alt={'...'} width={'100%'} height={'100%'} />
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className='col-lg-6 col-md-6 col-12'
                            >
                                <div
                                    className='text-warning font18 d-flex align-items-center'
                                >
                                    <p
                                        className='m-0 mb-1 me-3 font20'
                                    >
                                        <BsFillHeartFill />
                                    </p>
                                    <p
                                        className='m-0'
                                    >
                                        Favourite Things like
                                    </p>
                                </div>

                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Food:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_food ? profile_data?.favorite_things?.favourite_food : 'N/A'}
                                    </p>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Actor:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_actor ? profile_data?.favorite_things?.favourite_actor : 'N/A'}
                                    </p>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Actress:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_actress ? profile_data?.favorite_things?.favourite_actress : 'N/A'}
                                    </p>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Movies:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_film ? profile_data?.favorite_things?.favourite_film : 'N/A'}
                                    </p>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Singers:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_musician ? profile_data?.favorite_things?.favourite_musician : 'N/A'}
                                    </p>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Destinations:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_destination ? profile_data?.favorite_things?.favourite_destination : 'N/A'}
                                    </p>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Drinks:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_drink ? profile_data?.favorite_things?.favourite_drink : 'N/A'}
                                    </p>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-3 bg-dark py-2 rounded px-3'
                                >
                                    <p
                                        className='m-0 fw-bold font14 text-warning me-1'
                                    >
                                        Sports:
                                    </p>
                                    <p
                                        className='m-0 font14 text-white'
                                    >
                                        {profile_data?.favorite_things?.favourite_sport ? profile_data?.favorite_things?.favourite_sport : 'N/A'}
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>

                    {/* personal information */}

                    {profile_data?.profession && (profile_data?.profession.includes('Actor') || profile_data?.profession.includes('Actress')) ?

                        <div>
                            <div
                                className='row m-0'
                            >
                                <div
                                    className='col-lg-4 col-md-12 col-12 p-0 theme_color px-lg-3 px-md-3 px-2 pb-4 mb-lg-0 mb-md-0 mb-3 h-100'
                                >
                                    <p
                                        className='m-0 text-white font20 fw-bold ps-lg-3 ps-md-3 ps-2 my-4'
                                    >
                                        Personal Information
                                    </p>
                                    <div
                                        className='row m-0 border-bottom border-secondary'
                                    >
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Date of Birth
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {getDOB(profile_data?.birth_date)}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Gender
                                                </p>
                                                {profile_data?.subscriber_detail?.gender === 1 ?
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        Male
                                                    </p>
                                                    : profile_data?.subscriber_detail?.gender === 2 ?
                                                        <p
                                                            className='m-0 text-white font14'
                                                        >
                                                            Female
                                                        </p>
                                                        :
                                                        <p
                                                            className='m-0 text-white font14'
                                                        >
                                                            Other
                                                        </p>
                                                }

                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Nationality
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.subscriber_detail?.nationality ? profile_data?.subscriber_detail?.nationality : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Contact Number
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.subscriber_detail?.phone ? profile_data?.subscriber_detail?.phone : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    WhatsApp Number
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.subscriber_detail?.whatsapp_number ? profile_data?.subscriber_detail?.whatsapp_number : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Permanent Address
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.subscriber_detail?.address ? profile_data?.subscriber_detail?.address : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Payment Preferred
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.payment_preferred ? profile_data?.payment_preferred : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='row m-0 mt-3'
                                    >
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Different Work Profession
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.profession_in_industry ? profile_data?.profession_in_industry : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Habits
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.habits ? profile_data?.habits : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Type of Industries
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.industry_type ? profile_data?.industry_type : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-6 col-md-6 col-12 mb-3'
                                        >
                                            <div>
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Debut Performance
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.debut_performance ? profile_data?.debut_performance : 'N/A'}
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.debut_performance_title ? profile_data?.debut_performance_title : 'N/A'}
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    ({profile_data?.debut_performance_year}, {profile_data?.debut_performance_language} , {profile_data?.debut_performance_screen})
                                                </p>
                                            </div>
                                        </div>

                                        <p
                                            className='m-0 text-warning font20 my-3'
                                        >
                                            Total Work Experience
                                        </p>
                                        <div
                                            className='d-lg-flex d-md-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded w-50'
                                            >
                                                <p
                                                    className='m-0 fw-bold font14 text-warning me-1'
                                                >
                                                    TV:
                                                </p>
                                                <p
                                                    className='m-0 font14 text-white'
                                                >
                                                    {profile_data?.tv_experience ? profile_data?.tv_experience : 'N/A'}
                                                </p>
                                            </div>
                                            <div
                                                className='box_info_color d-flex align-items-center justify-content-between px-3 mx-lg-3 mx-md-3 mx-0 mt-3 py-3 rounded w-50'
                                            >
                                                <p
                                                    className='m-0 fw-bold font14 text-warning me-1'
                                                >
                                                    Film:
                                                </p>
                                                <p
                                                    className='m-0 font14 text-white'
                                                >
                                                    {profile_data?.total_films_made ? profile_data?.total_films_made : 'N/A'}
                                                </p>
                                            </div>
                                            <div
                                                className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded w-50'
                                            >
                                                <p
                                                    className='m-0 fw-bold font14 text-warning me-1'
                                                >
                                                    Media:
                                                </p>
                                                <p
                                                    className='m-0 font14 text-white'
                                                >
                                                    {profile_data?.media_experience ? profile_data?.media_experience : 'N/A'}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className='d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded me-3'
                                            >
                                                <p
                                                    className='m-0 fw-bold font14 text-warning me-5'
                                                >
                                                    Theater:
                                                </p>
                                                <p
                                                    className='m-0 font14 text-white'
                                                >
                                                    {profile_data?.theater_experience ? profile_data?.theater_experience : 'N/A'}
                                                </p>
                                            </div>
                                            <div
                                                className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded'
                                            >
                                                <p
                                                    className='m-0 fw-bold font14 text-warning me-5'
                                                >
                                                    Art:
                                                </p>
                                                <p
                                                    className='m-0 font14 text-white'
                                                >
                                                    {profile_data?.art_experience ? profile_data?.art_experience : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='row m-0 px-2 mt-3 d-flex justify-content-start align-items-center'
                                    >
                                        <div
                                            className='box_info_color rounded py-3 '
                                            style={{ height: '165px' }}
                                        >
                                            <p
                                                className='m-0 text-warning font14'
                                            >
                                                I am also intrested in :
                                            </p>
                                            <p
                                                className='m-0 text-warning font14'
                                            >
                                                {profile_data?.what_else_want_to_be ? profile_data?.what_else_want_to_be : 'N/A'}
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                {/* body measurment */}

                                <div
                                    className='col-lg-8 col-md-12 col-12 p-0 h-100 px-lg-5 px-md-0 px-2 mt-lg-0 mt-md-4'
                                >
                                    <div
                                        className='text-white d-flex justify-content-center align-items-center mb-4'
                                    >
                                        <p
                                            className='m-0 font20 me-1 fw-bold'
                                        >
                                            Physical Measurements
                                        </p>
                                        <p
                                            className='m-0 font14'
                                        >
                                            (This information is shared by the user only)
                                        </p>
                                    </div>


                                    <div
                                        className='row m-0'
                                    >
                                        <div
                                            className='col-lg-4 col-md-4 col-12'
                                        >
                                            <div
                                                className='py-lg-5 py-md-5 py-3'
                                            >
                                                <div
                                                    className='border-bottom border-start px-2 pb-2 border-warning'
                                                >
                                                    <p
                                                        className='m-0 text-warning font16'
                                                    >
                                                        Shoulder Size
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.shoulder_size ? profile_data?.shoulder_size : 'Not available'}
                                                    </p>
                                                </div>
                                                <div
                                                    className='border-bottom border-start px-2 pb-2 border-warning my-5'
                                                >
                                                    <p
                                                        className='m-0 text-warning font16'
                                                    >
                                                        Height (approx)
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.height ? `in centimeters - ${profile_data?.height} cm` : 'Not available'}
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {height?.meter ? `in meters - ${height?.meter} m` : 'Not available'}
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {height?.feet ? `in feet inches - ${height?.feet}' ${height?.inches}''` : 'Not available'}

                                                    </p>
                                                </div>
                                                <div
                                                    className='border-bottom border-start px-2 pb-2 border-warning'
                                                >
                                                    <p
                                                        className='m-0 text-warning font16'
                                                    >
                                                        Weight (approx)
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.current_weight ? `in kilograms - ${profile_data?.current_weight} kg` : 'Not available'}

                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.current_weight ? `n pounds - ${convertWeightToPounds(profile_data?.current_weight)} lbs` : 'Not available'}

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className='col-lg-4 col-md-4 col-12'
                                        >
                                            {profile_data?.subscriber_detail?.gender === 1 ?
                                                <div
                                                    style={{
                                                        height: '60vh'
                                                    }}
                                                >
                                                    <img src='../images/maleshape.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                </div>
                                                :
                                                <div
                                                    style={{
                                                        height: '60vh'
                                                    }}
                                                >
                                                    <img src='../images/femaleshape.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                </div>
                                            }
                                        </div>

                                        <div
                                            className='col-lg-4 col-md-4 col-12'
                                        >
                                            <div>
                                                <div
                                                    className='border-bottom border-start px-2 pb-2 border-warning'
                                                >
                                                    <p
                                                        className='m-0 text-warning font16'
                                                    >
                                                        Face Profile
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.face_profile ? profile_data?.face_profile : 'Not available'}
                                                    </p>
                                                </div>
                                                <div
                                                    className='border-bottom border-start px-2 pb-2 border-warning my-5'
                                                >
                                                    <p
                                                        className='m-0 text-warning font16'
                                                    >
                                                        Chest Size
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.chest_size ? `${profile_data?.chest_size} inches` : 'Not available'}
                                                    </p>
                                                </div>
                                                <div
                                                    className='border-bottom border-start px-2 pb-2 border-warning'
                                                >
                                                    <p
                                                        className='m-0 text-warning font16'
                                                    >
                                                        Hip Size
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.hip_size ? `${profile_data?.hip_size} inches` : 'Not available'}
                                                    </p>
                                                </div>
                                                <div
                                                    className='border-bottom border-start px-2 pb-2 border-warning my-5'
                                                >
                                                    <p
                                                        className='m-0 text-warning font16'
                                                    >
                                                        Thigh Size
                                                    </p>
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        {profile_data?.thigh_size ? `${profile_data?.thigh_size} inches` : 'Not available'}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className='px-2 pb-2 border-warning'
                                                style={{
                                                    height: '100px',
                                                    width: '200px'
                                                }}
                                            >
                                            </div>
                                            <div
                                                className='border-bottom border-start px-2 pb-2 border-warning'
                                            >
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Foot Size
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.foot_size ? `${profile_data?.foot_size} inches` : 'Not available'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='row m-0 mt-2'
                                    >
                                        <div
                                            className='col-lg-4 col-md-4 col-12'
                                        >
                                            <div
                                                className='border-bottom border-start px-2 pb-2 border-warning'
                                            >
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Hair Colour
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.hair_color ? profile_data?.hair_color : 'Not available'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-4 col-md-4 col-12'
                                        >
                                            <div
                                                className='border-bottom border-start px-2 pb-2 border-warning'
                                            >
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Eye Colour
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.eye_color ? profile_data?.eye_color : 'Not available'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-4 col-md-4 col-12'
                                        >
                                            <div
                                                className='border-bottom border-start px-2 pb-2 border-warning'
                                            >
                                                <p
                                                    className='m-0 text-warning font16'
                                                >
                                                    Types of Dress Wear
                                                </p>
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    {profile_data?.preffered_dressware ? profile_data?.preffered_dressware : 'Not available'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* showreels video */}
                                    {Array.isArray(profile_data?.showreels) && profile_data?.showreels.length > 0 ?
                                        <div
                                            className='row mt-3'
                                        >
                                            {
                                                profile_data?.showreels.map((val: any) => {
                                                    return (
                                                        <div
                                                            className='col-lg-4 col-md-12 col-12 d-md-flex justify-content-md-center mb-lg-0 mb-md-2 mb-2'
                                                            key={val.id}
                                                        >
                                                            <div>
                                                                {val.link ?
                                                                    <VideoPlayer videoUrl={val.link} height={'130px'} width={'340px'} />
                                                                    :
                                                                    <img src='../images/youtubenotavailable.png' alt={val.title} width={'340'} height={'170'} />
                                                                }
                                                                <p
                                                                    className='m-0 text-white mt-1'
                                                                >
                                                                    {val.title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <div
                                                className='theme_color pt-3 pb-3 mt-1'
                                            >
                                                <ProfileSlider data={profile_data?.featured_images} showReel={true} />
                                            </div> */}
                                        </div>
                                        : null
                                    }
                                </div>

                            </div>
                        </div>
                        :
                        <div
                            className='row m-0 theme_color py-3'
                        >
                            <p
                                className='m-0 text-white font20 fw-bold ps-lg-3 ps-md-3 ps-2 mb-4'
                            >
                                Personal Information
                            </p>
                            <div
                                className='col-lg-4 col-md-4 col-12 p-0 theme_color px-lg-3 px-md-3 px-2 pb-4 mb-lg-0 mb-md-0 mb-3 border-end border-secondary'
                            >
                                <div
                                    className='row m-0'
                                >
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Date of Birth
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {getDOB(profile_data?.birth_date)}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Gender
                                            </p>
                                            {profile_data?.subscriber_detail?.gender === 1 ?
                                                <p
                                                    className='m-0 text-white font14'
                                                >
                                                    Male
                                                </p>
                                                : profile_data?.subscriber_detail?.gender === 2 ?
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        Female
                                                    </p>
                                                    :
                                                    <p
                                                        className='m-0 text-white font14'
                                                    >
                                                        Other
                                                    </p>
                                            }

                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Nationality
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.subscriber_detail?.nationality ? profile_data?.subscriber_detail?.nationality : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Contact Number
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.subscriber_detail?.phone ? profile_data?.subscriber_detail?.phone : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                WhatsApp Number
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.subscriber_detail?.whatsapp_number ? profile_data?.subscriber_detail?.whatsapp_number : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Permanent Address
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.subscriber_detail?.address ? profile_data?.subscriber_detail?.address : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Payment Preferred
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.payment_preferred ? profile_data?.payment_preferred : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className='col-lg-4 col-md-4 col-12 p-0 theme_color px-lg-3 px-md-3 px-2 pb-4 mb-lg-0 mb-md-0 mb-3 border-end border-secondary'
                            >
                                <div
                                    className='row m-0'
                                >
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Different Work Profession
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.profession_in_industry ? profile_data?.profession_in_industry : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Habits
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.habits ? profile_data?.habits : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Type of Industries
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.industry_type ? profile_data?.industry_type : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-6 col-md-6 col-12 mb-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16 font_weight_600'
                                            >
                                                Debut Performance
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.debut_performance ? profile_data?.debut_performance : 'N/A'}
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {profile_data?.debut_performance_title ? profile_data?.debut_performance_title : 'N/A'}
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                ({profile_data?.debut_performance_year}, {profile_data?.debut_performance_language} , {profile_data?.debut_performance_screen})
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className='col-lg-4 col-md-4 col-12 p-0 theme_color px-lg-3 px-md-3 px-2 pb-4 mb-lg-0 mb-md-0 mb-3 h-100'
                            >
                                <p
                                    className='m-0 text-warning font20 mb-3'
                                >
                                    Total Work Experience
                                </p>
                                <div
                                    className='d-lg-flex d-md-flex justify-content-start align-items-center'
                                >
                                    <div
                                        className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded w-50'
                                    >
                                        <p
                                            className='m-0 fw-bold font14 text-warning me-1'
                                        >
                                            TV:
                                        </p>
                                        <p
                                            className='m-0 font14 text-white'
                                        >
                                            {/* 4 years */}
                                        </p>
                                    </div>
                                    <div
                                        className='box_info_color d-flex align-items-center justify-content-between px-3 mx-lg-3 mx-md-3 mx-0 mt-3 py-3 rounded w-50'
                                    >
                                        <p
                                            className='m-0 fw-bold font14 text-warning me-1'
                                        >
                                            Film:
                                        </p>
                                        <p
                                            className='m-0 font14 text-white'
                                        >
                                            {/* 10 years */}
                                        </p>
                                    </div>
                                    <div
                                        className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded w-50'
                                    >
                                        <p
                                            className='m-0 fw-bold font14 text-warning me-1'
                                        >
                                            Media:
                                        </p>
                                        <p
                                            className='m-0 font14 text-white'
                                        >
                                            {/* 1 year */}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className='d-flex justify-content-start align-items-center'
                                >
                                    <div
                                        className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded me-3'
                                    >
                                        <p
                                            className='m-0 fw-bold font14 text-warning me-5'
                                        >
                                            Theater:
                                        </p>
                                        <p
                                            className='m-0 font14 text-white'
                                        >
                                            {/* 2 years */}
                                        </p>
                                    </div>
                                    <div
                                        className='box_info_color d-flex align-items-center justify-content-between px-3 mt-3 py-3 rounded'
                                    >
                                        <p
                                            className='m-0 fw-bold font14 text-warning me-5'
                                        >
                                            Art:
                                        </p>
                                        <p
                                            className='m-0 font14 text-white'
                                        >
                                            {/* 0 year */}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className='row m-0 px-2 mt-3 d-flex justify-content-start align-items-center'
                                >
                                    <div
                                        className='box_info_color rounded py-3 '
                                        style={{ height: '145px' }}
                                    >
                                        <p
                                            className='m-0 text-warning font14'
                                        >
                                            I am also intrested in :
                                        </p>
                                        <p
                                            className='m-0 text-warning font14'
                                        >
                                            {profile_data?.what_else_want_to_be ? profile_data?.what_else_want_to_be : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/* accordion of written script */}

                    {Array.isArray(profile_data?.scripts) && profile_data?.scripts.length > 0 ?

                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed py-4 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="true" aria-controls="flush-collapseThree">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p className='m-0 fw-bold font20'>
                                            My Written Scripts
                                        </p>
                                        <span
                                            className='fs-4'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 overflow-y-auto" style={{ height: '350px' }}>
                                    {Array.isArray(profile_data?.scripts) && profile_data?.scripts.length > 0 ?
                                        profile_data?.scripts.map((val: any) => {
                                            return (
                                                <div className="accordion-item mb-3 rounded" key={val.id}>
                                                    <h2 className="accordion-header" id="flush-story">
                                                        <button className="accordion-button collapsed rounded-4 border px-4 py-2 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseStory" aria-expanded="true" aria-controls="flush-collapseStory">
                                                            <div
                                                                className='w-100 d-flex justify-content-between align-items-center'
                                                            >
                                                                <span>{val.title}</span>
                                                                <div
                                                                    className='d-flex justify-content-between align-items-center'
                                                                >
                                                                    <button
                                                                        className='bg-transparent border border-warning rounded text-warning me-2'
                                                                    >
                                                                        Message me
                                                                    </button>
                                                                    <SlArrowDown className='fs-5' />
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseStory" className="accordion-collapse collapse show" aria-labelledby="flush-story" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body theme_color py-2 px-3 rounded-4 border mt-2">

                                                            <div
                                                                className='d-flex align-items-center breadcrumb'
                                                            >
                                                                <div
                                                                    className='d-flex align-items-center me-5'
                                                                >
                                                                    <p
                                                                        className='text-warning m-0 font16'
                                                                    >
                                                                        Budget
                                                                    </p>
                                                                    <p
                                                                        className='text-white m-0 font16'
                                                                    >
                                                                        : {val.budget}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className='d-flex align-items-center me-5'
                                                                >
                                                                    <p
                                                                        className='text-warning m-0 font16'
                                                                    >
                                                                        Genre
                                                                    </p>
                                                                    <p
                                                                        className='text-white m-0 font16'
                                                                    >
                                                                        : {val.genre}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className='d-flex align-items-center me-5'
                                                                >
                                                                    <p
                                                                        className='text-warning m-0 font16'
                                                                    >
                                                                        IP registered
                                                                    </p>
                                                                    <p
                                                                        className='text-white m-0 font16'
                                                                    >
                                                                        : {val.ip_registered ? 'Yes' : 'No'}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className='d-flex align-items-center me-5'
                                                                >
                                                                    <p
                                                                        className='text-warning m-0 font16'
                                                                    >
                                                                        Sharable
                                                                    </p>
                                                                    <p
                                                                        className='text-white m-0 font16'
                                                                    >
                                                                        : {val.sharable ? 'Yes' : 'No'}
                                                                    </p>
                                                                </div>


                                                            </div>

                                                            <div
                                                                className='d-flex'
                                                            >
                                                                <span
                                                                    className='m-0 me-3 text-white font16 fw-bold'
                                                                >
                                                                    Log Line :
                                                                </span>
                                                                <p
                                                                    className='text-white m-0 font16'
                                                                >
                                                                    {val.log_line}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    {/* Filmography */}

                    <div
                        className='row m-0 mt-5'
                    >
                        <p
                            className='text-white font20'
                        >
                            Filmography
                        </p>
                        <div>
                            {/* <ProfileSlider data={profile_data?.featured_images} /> */}
                            {/* <ProfileMovieSlider data={profile_data?.projects} /> */}
                            <FilmographySlider data={profile_data?.projects} />
                        </div>
                    </div>


                    {/* discography */}

                    {Array.isArray(profile_data?.discography) && profile_data?.discography.length > 0 ?
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingDiscography">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreediscography" aria-expanded="true" aria-controls="flush-collapseThreediscography">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Discography
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreediscography" className="accordion-collapse collapse show" aria-labelledby="flush-headingDiscography" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 overflow-auto" style={{ height: '350px' }}>

                                    <table className="table table-dark table-striped">
                                        <thead>
                                            <tr>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Song Name
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Movie
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Year
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Music by
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-bottom-0'>
                                                    Remarks
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                profile_data?.discography.map((val: any, i: any) => {
                                                    return (
                                                        <tr className="discography_table_color" key={val.id}>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.song_name}
                                                            </td>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.film}
                                                            </td>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.year}
                                                            </td>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.composer}
                                                            </td>
                                                            <td className='text-center py-4 border-bottom-0'>
                                                                {val.notes}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    {/* Awards and honors */}

                    {!Array.isArray(profile_data?.awards) && profile_data?.awards.length === 0 ?
                        null
                        :
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreeAwards">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreeawards" aria-expanded="true" aria-controls="flush-collapseThreeawards">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Awards and Honors
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreeawards" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreeAwards" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 overflow-auto" style={{ height: '350px' }}>

                                    <table className="table table-dark table-striped">
                                        <thead>
                                            <tr>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Award name
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Award type
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Award for
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-end border-warning border-bottom-0'>
                                                    Award year
                                                </th>
                                                <th className='text-warning font20 text-center py-3 bg-transparent border-bottom-0'>
                                                    Remarks
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                profile_data?.awards.map((val: any, i: any) => {
                                                    return (
                                                        <tr className="discography_table_color" key={val.id}>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.award_name ? val.award_name : 'N/A'}
                                                            </td>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.award_category ? val.award_category : 'N/A'}
                                                            </td>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.award_for ? val.award_for : 'N/A'}
                                                            </td>
                                                            <td className='text-center py-4 border-end border-warning border-bottom-0'>
                                                                {val.award_year ? val.award_year : 'N/A'}
                                                            </td>
                                                            <td className='text-center py-4 border-bottom-0'>
                                                                {val.award_details ? val.award_details : 'N/A'}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }

                    {/* Other achivements */}

                    {!Array.isArray(profile_data?.other_achievements) && profile_data?.other_achievements.length === 0 ?
                        null
                        :
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreeotherachivement">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreeotherachivement" aria-expanded="true" aria-controls="flush-collapseThreeotherachivement">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Other Achievements
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreeotherachivement" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreeotherachivement" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color pb-3 px-4">
                                    {
                                        profile_data?.other_achievements.map((val: any, i: any) => {
                                            return (
                                                <div
                                                    key={val.id}
                                                    className={`row m-0 ${i % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
                                                >
                                                    <div
                                                        className='col-lg-11 col-md-11 col-12 d-flex align-items-center mt-4'
                                                    >
                                                        <p
                                                            className='m-0 text-white font16'
                                                        >
                                                            {val.description ? val.description : 'N/A'}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`col-lg-1 col-md-1 col-12 d-flex ${i % 2 === 0 ? 'justify-content-center' : 'justify-content-start'} mt-4`}
                                                    >
                                                        <div
                                                            style={{
                                                                height: '80px',
                                                                width: '100px'
                                                            }}
                                                        >
                                                            {val?.images[0]?.path ?
                                                                <img src={`${baseURL}/${val?.images[0]?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                :
                                                                <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {Array.isArray(profile_data?.happy_moments) && profile_data?.happy_moments.length > 0 ?
                                        <p
                                            className='m-0 fw-bold font20 text-white'
                                        >
                                            Happy Movements
                                        </p>
                                        : null
                                    }

                                    {
                                        profile_data?.happy_moments.map((val: any, i: any) => {
                                            return (
                                                <div
                                                    key={val.id}
                                                    className={`row m-0 ${i % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
                                                >
                                                    <div
                                                        className='col-lg-11 col-md-11 col-12 d-flex align-items-center mt-4'
                                                    >
                                                        <p
                                                            className='m-0 text-white font14'
                                                        >
                                                            {val.description ? val.description : 'N/A'}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`col-lg-1 col-md-1 col-12 d-flex ${i % 2 === 0 ? 'justify-content-center' : 'justify-content-start'} mt-4`}
                                                    >
                                                        {Array.isArray(val?.images) && val.images.length > 0 ?
                                                            <div
                                                                style={{
                                                                    height: '80px',
                                                                    width: '100px'
                                                                }}
                                                            >
                                                                {val?.images[0]?.path ?
                                                                    <img src={`${baseURL}/${val?.images[0]?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            :
                                                            <div
                                                                style={{
                                                                    height: '80px',
                                                                    width: '100px'
                                                                }}
                                                            >
                                                                <img src='../images/noimage.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/* controversy */}

                    {!Array.isArray(profile_data?.controversies) && profile_data?.controversies.length === 0 ?
                        null
                        :
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreecontroversy">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreecontroversy" aria-expanded="true" aria-controls="flush-collapseThreecontroversy">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Controversy
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreecontroversy" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreecontroversy" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 pb-4">
                                    {
                                        profile_data?.controversies.map((val: any, i: any) => {
                                            return (
                                                <div
                                                    key={val.id}
                                                    className={`row m-0`}
                                                >
                                                    <div
                                                        className='col-lg-11 col-md-11 col-12 d-flex align-items-center mt-4'
                                                    >
                                                        <p
                                                            className='m-0 text-white font14'
                                                        >
                                                            {val.controversy_description ? val.controversy_description : 'N/A'}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`col-lg-1 col-md-1 col-12 d-flex justify-content-center'} mt-4`}
                                                    >
                                                        {Array.isArray(val?.images) && val.images.length > 0 ?
                                                            <div
                                                                style={{
                                                                    height: '80px',
                                                                    width: '100px'
                                                                }}
                                                            >
                                                                <img src={`${baseURL}/${val?.images[0]?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                            </div>
                                                            :
                                                            <div
                                                                style={{
                                                                    height: '80px',
                                                                    width: '100px'
                                                                }}
                                                            >
                                                                <img src='../images/noimage.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {profile_data?.pollitically_involvement ?
                                        <>
                                            <p
                                                className='m-0 fw-bold font20 text-white'
                                            >
                                                Political Involvement
                                            </p>

                                            <p className='text-white m-0 px-4'>
                                                {profile_data?.pollitically_involvement}
                                            </p>
                                        </>
                                        :
                                        null
                                    }

                                    {/* {Array.isArray(profile_data?.pollitically_involvement) &&
                                    profile_data?.pollitically_involvement.map((val: any, i: any) => {
                                        return (
                                            <>
                                                <div
                                                    key={val.id}
                                                    className={`row m-0`}
                                                >
                                                    <div
                                                        className='col-lg-11 col-md-11 col-12 d-flex align-items-center mt-4'
                                                    >
                                                        <p
                                                            className='m-0 text-white font14'
                                                        >
                                                            {val.controversy_description}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`col-lg-1 col-md-1 col-12 d-flex justify-content-center'} mt-4`}
                                                    >
                                                        {Array.isArray(val?.images) && val.images.length > 0 ?
                                                            <div
                                                                style={{
                                                                    height: '80px',
                                                                    width: '100px'
                                                                }}
                                                            >
                                                                <img src={`${baseURL}/${val?.images[0]?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                            </div>
                                                            :
                                                            <div
                                                                style={{
                                                                    height: '80px',
                                                                    width: '100px'
                                                                }}
                                                            >
                                                                <img src='../images/noimage.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                } */}

                                </div>
                            </div>
                        </div>
                    }

                    {/* Family */}

                    {!Array.isArray(profile_data?.father) && profile_data?.father.length === 0 ?
                        null
                        :
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreefamily">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreefamily" aria-expanded="true" aria-controls="flush-collapseThreefamily">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Family
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreefamily" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreefamily" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 pb-4">
                                    <div
                                        className={`row`}
                                    >
                                        {
                                            profile_data?.father.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-4 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            profile_data?.mother.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-1 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            profile_data?.brother.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-1 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            profile_data?.sister.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-1 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div
                                        className={`row`}
                                    >
                                        {
                                            profile_data?.spouse.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-2 col-md-2 col-12 d-flex justify-content-center align-items-center mt-4 w-auto'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            profile_data?.son.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-1 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            profile_data?.daughter.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-1 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            profile_data?.relatives.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-1 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div className=''>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.family_member}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                            <p
                                                                className='m-0 text-white text-center font14'
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p
                                        className='m-0 mt-4 text-white font20'
                                    >
                                        Properties and Assets
                                    </p>
                                    <div
                                        className={`row`}
                                    >
                                        {
                                            profile_data?.assets.map((val: any, i: any) => {
                                                return (
                                                    <div
                                                        key={val.id}
                                                        className='col-lg-1 col-md-1 col-12 d-flex justify-content-center align-items-center mt-4'
                                                    >
                                                        <div>
                                                            <p
                                                                className='m-0 mb-2 text-warning text-center font20 font_weight_600'
                                                            >
                                                                {val.property_type}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    height: '140px',
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {val?.image?.path ?
                                                                    <img src={`${baseURL}/${val?.image?.path}`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                    :
                                                                    <img src={`../images/noimage.png`} alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/* Childhood */}

                    {!Array.isArray(profile_data?.about_childhood) && profile_data?.about_childhood !== 0 ?
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreechildhood">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreechildhood" aria-expanded="true" aria-controls="flush-collapseThreechildhood">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Childhood
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreechildhood" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreechildhood" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 pt-3">
                                    <p className='text-white m-0 px-4'>
                                        {profile_data?.about_childhood}
                                    </p>
                                    <div className='row m-0 p-0 px-2 mt-2'>
                                        {Array.isArray(profile_data?.child_hood_images) &&
                                            profile_data?.child_hood_images.map((val: any) => {
                                                return (
                                                    <div className='col-lg-2 col-md-2 col-12 d-flex justify-content-center mb-lg-4 mb-md-4 mb-3' key={val.id}>
                                                        <div>
                                                            <img src={`${baseURL}/${val?.path}`} alt={val?.path} width={'100%'} height={'100%'} className='' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    {/* Philanthropic Work (Donations) */}

                    {!profile_data?.philantropic_works ?
                        null
                        :
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreedonation">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreedonation" aria-expanded="true" aria-controls="flush-collapseThreedonation">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Philanthropic Work (Donations)
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreedonation" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreedonation" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4">

                                    <p className='text-white m-0 px-4'>
                                        {profile_data?.philantropic_works}
                                    </p>
                                    {/* {
                                    profile_data?.other_achievements.map((val: any, i: any) => {
                                        return (
                                            <>
                                                <div
                                                    key={val.id}
                                                    className={`row m-0 ${i % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
                                                >
                                                    <div
                                                        className='col-lg-11 col-md-11 col-12 d-flex align-items-center mt-4'
                                                    >
                                                        <p
                                                            className='m-0 text-white font14'
                                                        >
                                                            {val.description}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`col-lg-1 col-md-1 col-12 d-flex ${i % 2 === 0 ? 'justify-content-center' : 'justify-content-start'} mt-4`}
                                                    >
                                                        <div
                                                            style={{
                                                                height: '80px',
                                                                width: '100px'
                                                            }}
                                                        >
                                                            <img src='../images/noimage.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                } */}
                                </div>
                            </div>
                        </div>
                    }

                    {/* Political Affiliations */}
                    {!Array.isArray(profile_data?.other_achievements) && profile_data?.other_achievements.length === 0 ?
                        null
                        :
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreePolitical">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreePolitical" aria-expanded="true" aria-controls="flush-collapseThreePolitical">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Political Affiliations
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreePolitical" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreePolitical" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4">
                                    {
                                        profile_data?.other_achievements.map((val: any, i: any) => {
                                            return (
                                                <div
                                                    key={val.id}
                                                    className={`row m-0 ${i % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
                                                >
                                                    <div
                                                        className='col-lg-11 col-md-11 col-12 d-flex align-items-center'
                                                    >
                                                        <p
                                                            className='m-0 text-white font16'
                                                        >
                                                            {val.description}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`col-lg-1 col-md-1 col-12 d-flex ${i % 2 === 0 ? 'justify-content-center' : 'justify-content-start'} mt-4`}
                                                    >
                                                        <div
                                                            style={{
                                                                height: '80px',
                                                                width: '100px'
                                                            }}
                                                        >
                                                            <img src='../images/noimage.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/* death and memories */}

                    {profile_data?.death_date ?
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreedeath">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreedeath" aria-expanded="true" aria-controls="flush-collapseThreedeath">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            Death and Memorial
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreedeath" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreedeath" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 pt-3">
                                    <p className='text-white m-0 px-4'>
                                        {profile_data?.about_death}
                                    </p>
                                    <div className='row m-0 p-0 px-2 mt-2'>
                                        {Array.isArray(profile_data?.death_images) &&
                                            profile_data?.death_images.map((val: any) => {
                                                return (
                                                    <div className='col-lg-2 col-md-2 col-12 d-flex justify-content-center mb-lg-4 mb-md-4 mb-3' key={val.id}>
                                                        <div>
                                                            <img src={`${baseURL}/${val.path}`} alt={val.path} width={'100%'} height={'100%'} className='' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    {/* International recognition */}

                    {!Array.isArray(profile_data?.other_achievements) && profile_data?.other_achievements.length === 0 ?
                        null
                        :
                        <div className="accordion-item mb-3 mt-4">
                            <h2 className="accordion-header" id="flush-headingThreeinternationrecog">
                                <button className="accordion-button collapsed rounded py-3 px-4 text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreeinternationrecog" aria-expanded="true" aria-controls="flush-collapseThreeinternationrecog">
                                    <div
                                        className='w-100 d-flex justify-content-between align-items-center'
                                    >
                                        <p
                                            className='m-0 fw-bold font20'
                                        >
                                            International Recognition
                                        </p>
                                        <span
                                            className='font22'
                                        >
                                            <SlArrowDown />
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThreeinternationrecog" className="accordion-collapse collapse show" aria-labelledby="flush-headingThreeinternationrecog" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body theme_color px-4 pb-4">
                                    {
                                        profile_data?.other_achievements.map((val: any, i: any) => {
                                            return (
                                                <div
                                                    key={val.id}
                                                    className={`row m-0 ${i % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
                                                >
                                                    <div
                                                        className='col-lg-11 col-md-11 col-12 d-flex align-items-center mt-4'
                                                    >
                                                        <p
                                                            className='m-0 text-white font16'
                                                        >
                                                            {val.description}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`col-lg-1 col-md-1 col-12 d-flex ${i % 2 === 0 ? 'justify-content-center' : 'justify-content-start'} mt-4`}
                                                    >
                                                        <div
                                                            style={{
                                                                height: '80px',
                                                                width: '100px'
                                                            }}
                                                        >
                                                            <img src='../images/noimage.png' alt='...' width={'100%'} height={'100%'} className='object-fit-contain' />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }


                </div>
            </LoadingOverlay>
            <ToastContainer />
        </>
    )
}

export default MyProfileDetails
