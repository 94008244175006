import React, { useEffect, useState } from 'react';
import './profileform.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import { isValidEmail } from '../../utils/jsfunctions';

const GiveRefference = () => {

    const { industry_reference } = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [reference, setRef] = useState<any>([{
        full_name: '',
        phone: '',
        email: ''
    }]);

    const maxMobileNumber = 10;

    const handleAddIntroduce = (e: any) => {
        setRef([...reference, { full_name: '', phone: '', email: '' }]);
    }

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleChange = (e: any, i: any) => {
        const { name, value } = e.target;
        const updatedcastData = [...reference];
        if (typeof i === 'number' && i >= 0 && i < updatedcastData.length) {
            if (name === 'phone') {
                if (value.length <= maxMobileNumber) {
                    updatedcastData[i] = { ...updatedcastData[i], phone: value };
                    setRef(updatedcastData);
                }
            } else {
                updatedcastData[i] = { ...updatedcastData[i], [name]: value };
                setRef(updatedcastData);
            }
        } else {
            console.error('Invalid index provided for array update.');
        }
    };

    const handleDeleteData = (e: any, i: any) => {
        e.preventDefault();
        const deletedData = [...reference];
        deletedData.splice(i, 1);
        setRef(deletedData);
    }

    const handleWhoReferenceUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (!isValidEmail(reference[0]?.email) || !isValidEmail(reference[1]?.email)) {
            messageTxt('Please enter valid email');
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    reference_contact: JSON.stringify(reference)
                }
                let result = await axios(`${baseURL}/api/subscriber/update-biography`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setIsDisabled(!isDisabled);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        if (industry_reference.length > 0 && Array.isArray(industry_reference)) {
            setRef(industry_reference);
        }
    }, [])

    // useEffect(() => {
    //     console.log('ref', reference);
    // }, [reference])

    return (
        <>
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="referenceForm">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsereferenceForm" aria-expanded="false" aria-controls="flush-collapsereferenceForm">
                                Give 2 references & contacts from the film industry
                            </button>
                        </h2>
                        <div id="flush-collapsereferenceForm" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#referenceForm">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                                <div className='text-end mb-3'>
                                    {reference.length < 2 ?
                                        <button
                                            className='py-2 px-2 rounded font_weight_600 border-0'
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                width: '100px'
                                            }}
                                            onClick={handleAddIntroduce}
                                        >
                                            + Add
                                        </button>
                                        : null
                                    }
                                </div>
                                {
                                    reference.map((val: any, i: any) => {
                                        return (
                                            <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form mb-4 accordian_border_with_color" key={i}>
                                                <div
                                                    className='d-flex justify-content-end mt-2'
                                                >
                                                    {!isDisabled ?
                                                        <button
                                                            className='py-2 px-2 common_button rounded'
                                                            style={{
                                                                backgroundColor: 'red',
                                                            }}
                                                            onClick={(e) => handleDeleteData(e, i)}
                                                        >
                                                            <ImCross className='text-white' />
                                                        </button>
                                                        : null
                                                    }
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Full name</label>
                                                    <input type="text" name='full_name' value={val.full_name} onChange={(e) => handleChange(e, i)} placeholder='Enter name' className="form-control mb-3" disabled={isDisabled} />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Contact number</label>
                                                    <input type="number" name='phone' value={val.phone} onChange={(e) => handleChange(e, i)} placeholder='Phone number' className="form-control mb-3" disabled={isDisabled} />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Email</label>
                                                    <input type="email" name='email' value={val.email} onChange={(e) => handleChange(e, i)} placeholder='Email' className="form-control mb-3" disabled={isDisabled} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        {isDisabled ? 'Edit' : 'Cancel'}
                                    </button>
                                    {!isDisabled ?
                                        <button
                                            className='py-2 px-2 common_button rounded fw-bold'
                                            style={{
                                                width: '150px'
                                            }}
                                            onClick={handleWhoReferenceUpdate}
                                        >
                                            Save
                                        </button>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default GiveRefference
