import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Layout from './layout/Layout';
import Home from './pages/Home';
import FilmUpdate from './pages/filmupdate/FilmUpdate';
import CommingSoonMovies from './pages/filmupdate/CommingSoonMovies';
import NewMovies from './pages/filmupdate/NewMovies';
import Trailers from './pages/filmupdate/Trailers';
import Songs from './pages/filmupdate/Songs';
import MovieNews from './pages/filmupdate/MovieNews';
import ErrorPage from './pages/ErrorPage';
import Profile from './pages/profile/Profile';
import PlanYourEntertainment from './pages/profile/PlanYourEntertainment';
import WatchList from './pages/profile/WatchList';
import SearchFilmFaternity from './pages/profile/SearchFilmFaternity';
import CreateEvents from './pages/profile/CreateEvents';
import PromoteYourMovie from './pages/profile/PromoteYourMovie';
import CreateAuditionCall from './pages/profile/CreateAuditionCall';
import UploadToOtt from './pages/profile/UploadToOtt';
import AddMovieToFilmFestival from './pages/profile/AddMovieToFilmFestival';
import SendDonations from './pages/profile/SendDonations';
import Offers from './pages/profile/Offers';
import Login from './pages/auth/Login';
import Menu from './pages/menu/Menu';
import LegendsOfTheIndustry from './pages/menu/LegendsOfTheIndustry';
import LivingLegends from './pages/menu/LivingLegends';
import FilmFestival from './pages/menu/FilmFestival';
import AwardNews from './pages/menu/AwardNews';
import Ott from './pages/menu/Ott';
import FreeTickets from './pages/menu/FreeTickets';
import EventsTickets from './pages/menu/EventsTickets';
import MasterClass from './pages/menu/MasterClass';
import AuditionCall from './pages/menu/AuditionCall';
import FilmInstitute from './pages/menu/FilmInstitute';
import Birthdays from './pages/menu/Birthdays';
import Shop from './pages/menu/Shop';
import ContactUs from './pages/menu/ContactUs';
import AuditionCallDetail from './pages/menu/AuditionCallDetail';
import EventTicketDetail from './pages/menu/EventTicketDetail';
import MyProfile from './pages/profile/MyProfile';
import FreeTicketsDetails from './pages/menu/FreeTicketsDetails';
import Career from './pages/footerpage/Career';
import AboutUs from './pages/footerpage/AboutUs';
import CopyRight from './pages/footerpage/CopyRight';
import TermsAndConditio from './pages/footerpage/TermsAndConditio';
import SearchFilmFraternityDetails from './pages/profile/SearchFilmFraternityDetails';
import FilmInstituteDetail from './pages/menu/FilmInstituteDetail';
import MasterClassDetails from './pages/menu/MasterClassDetails';
import LegendOfTheIndustryDetail from './pages/menu/LegendOfTheIndustryDetail';
import FilmFestivalTicket from './pages/profile/FilmFestivalTicket';
import AwardNewsDetail from './pages/menu/AwardNewsDetail';
import FilmFestivalDetail from './pages/menu/FilmFestivalDetail';
import { useSelector } from 'react-redux';
import { getConnectionId } from './utils/utils';
import ProjectDetail from './pages/profile/ProjectDetail';
import SingleVideoPlayer from './pages/videoplayer/SingleVideoPlayer';
import CreateFilmFestival from './pages/menu/CreateFilmFestival';
import MyFilmFestival from './pages/profile/MyFilmFestival';
import FilmFestivalForms from './pages/profile/FilmFestivalForms';
import QuestionAnswers from './pages/footerpage/QuestionAnswers';
import Notification from './pages/Notification';
import CommonFooterPage from './pages/footerpage/CommonFooterPage';
import MyProfileDetails from './pages/profile/MyProfileDetails';
import AllMovies from './pages/AllMovies';
import AllSponsoredMovies from './pages/AllSponsoredMovies';
import MasterClassDetail from './pages/profile/MasterClassDetail';
import FilmInstituteDetailCrud from './pages/profile/FilmInstituteDetailCrud';
import ShopProductDetail from './pages/menu/ShopProductDetail';
import ShoppingCart from './pages/menu/ShoppingCart';
import OttGenreMovies from './pages/menu/OttGenreMovies';
import MyOrder from './pages/menu/MyOrder';

function App() {

  const profile_data = useSelector((state: any) => state.user.user);
  // const [userType, setUserType] = useState<any>();
  const auth_code = localStorage.getItem("auth_code");
  const connection_id = localStorage.getItem("connection_id");

  useEffect(() => {
    // const user = getUserProfileData(profile_data);
    // setUserType(user);
  },[profile_data]);

  useEffect(() => {
    if (!connection_id) {
      getConnectionId();
    }
  },[]);

  return (
    <Routes>
        {/* <Route path='login' element={<Login />} /> */}
        <Route path="/" element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/notification' element={<Notification />} />
          <Route path='/video-detail/:id' element={<SingleVideoPlayer />} />
          <Route path='/allmovies' element={<AllMovies />} />
          <Route path='/allsponsoredmovies' element={<AllSponsoredMovies />} />
          <Route path='film-update' element={<FilmUpdate />}>
            <Route path='commingsoonmovies' element={<CommingSoonMovies />} />
            <Route path='newmovies' element={<NewMovies />} />
            <Route path='trailers' element={<Trailers />} />
            <Route path='songs' element={<Songs />} />
            <Route path='movie-news' element={<MovieNews />} />
          </Route>
          <Route path='menu' element={<Menu />}>
            <Route path='legendsoftheindustry' element={<LegendsOfTheIndustry />} />
            <Route path='legendsoftheindustrydetails/:id' element={<LegendOfTheIndustryDetail />} />
            <Route path='livinglegends' element={<LivingLegends />} />
            <Route path='filmfestival' element={<FilmFestival />} />
            <Route path='film-festival-details/:id' element={<FilmFestivalDetail />} />
            <Route path='createfilmfestival' element={<CreateFilmFestival />} />
            {/* <Route path='awardnews' element={<AwardNews />} />
            <Route path='awardnewsdetails' element={<AwardNewsDetail />} />
            <Route path='freetickets' element={<FreeTickets />} /> */}
            <Route path='ott' element={<Ott />} />
            <Route path='ott-category/:id' element={<OttGenreMovies />} />
            <Route path='eventstickets' element={<EventsTickets />} />
            <Route path='masterclass' element={<MasterClass />} />
            <Route path='master-class-details/:id' element={<MasterClassDetails />} />
            <Route path='event-ticket-details/:id' element={<EventTicketDetail />} />
            {/* <Route path='freeticketdetails' element={<FreeTicketsDetails />} /> */}
            <Route path='shop' element={<Shop />} />
            <Route path='shop-product-details/:id' element={<ShopProductDetail />} />
            <Route path='shopping-cart' element={<ShoppingCart />} />
            <Route path='my-order' element={<MyOrder />} />
            <Route path='auditioncall' element={<AuditionCall />} />
            <Route path='audition-call-details/:id' element={<AuditionCallDetail />} />
            <Route path='filminstitute' element={<FilmInstitute />} />
            <Route path='film-institute-details/:id' element={<FilmInstituteDetail />} />
            <Route path='birthdays' element={<Birthdays />} />
            <Route path='contactus' element={<ContactUs />} />
          </Route>
          <Route path='profile' element={<Profile />}>

            {profile_data?.subscriber_type_id === 1 ?
              <>
                {/* <Route path='myfilmfestival' element={<MyFilmFestival />} /> */}
                <Route path='filmfestivalform' element={<FilmFestivalForms />} />
                {/* <Route path='searchfilmfaternity' element={<SearchFilmFaternity />} />
                <Route path='searchfilmfaternitydetails' element={<SearchFilmFraternityDetails />} /> */}
                <Route path='createevents' element={<CreateEvents />} />
                {/* <Route path='promoteyourmovie' element={<PromoteYourMovie />} /> */}
                {/* <Route path='createauditioncall' element={<CreateAuditionCall />} /> */}
                {/* <Route path='uploadtoott' element={<UploadToOtt />} /> */}
                <Route path='addmovietofilmfestival' element={<AddMovieToFilmFestival />} />
                <Route path='filmfestivalticket' element={<FilmFestivalTicket />} />
              </>
              : null
            }
                <Route path='myprofile' element={<MyProfile />} />
                <Route path='projectdetail' element={<ProjectDetail />} />
                <Route path='master-class-detail/:id' element={<MasterClassDetail />} />
                <Route path='film-institute-detail/:id' element={<FilmInstituteDetailCrud />} />
                {/* <Route path='planyourentertainment' element={<PlanYourEntertainment />} /> */}
                <Route path='watchlist' element={<WatchList />} />
                {/* <Route path='senddonations' element={<SendDonations />} /> */}
                {/* <Route path='offers' element={<Offers />} /> */}
          </Route>
          <Route path='profile-details/:id' element={<MyProfileDetails />} />
          <Route path='career' element={<CommonFooterPage />} />
          <Route path='Privacy_policy' element={<CommonFooterPage />} />
          <Route path='terms&conditions' element={<CommonFooterPage />} />
          <Route path='about_Us' element={<CommonFooterPage />} />
          <Route path='q&a' element={<QuestionAnswers />} />
          <Route path='disclaimer' element={<CommonFooterPage />} />
          <Route path='copyright' element={<CommonFooterPage />} />
          <Route path='socialmediapromotion-terms&conditions' element={<CommonFooterPage />} />
          <Route path='sandalwood_legacy' element={<CommonFooterPage />} />
          <Route path='help' element={<CommonFooterPage />} />
          <Route path='cookie_policy' element={<CommonFooterPage />} />
        </Route>
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
  );
}

export default App;




// Fraternity demo number
// Number: 8687654104
// OTP: 2023

// cinema fan demo number
// Number: 8574758465
// OTP: 2023
