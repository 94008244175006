import React, { useEffect, useState } from 'react';
import './moviecard.css';
import { baseURL } from '../../utils/api';
import { AiOutlineLike, AiOutlineShareAlt, AiOutlineHeart } from 'react-icons/ai';
import { addToWatchListAPI, likeAndViewAPI } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import SignInModal from '../signinmodal/SignInModal';
import { format } from 'date-fns';
import CardRating from '../ratings/cards/CardRating';
import { BsFillBookmarkPlusFill } from 'react-icons/bs';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';


const MovieCard = (props: any) => {

    const { id, cover, title, dob, genre, like, watch, birthday, cdbs_rating, rating } = props;
    const auth_code = localStorage.getItem("auth_code");
    const [show, setShow] = useState(false);
    const messageTxt = (message: any) => toast(message);
    const [toggle, setToggle] = useState(like);
    const [addToWatch, setWatchList] = useState(watch);

    const addRecentView = (action: any, id: any) => {
        likeAndViewAPI(action, id);
    }
    const giveLike = async (e: any, action: any, id: any) => {
        e.stopPropagation();
        if (!auth_code) {
            setShow(true);
        } else {
            const msg = await likeAndViewAPI(action, id);
            console.log(msg);
            messageTxt(msg);
            setToggle(!toggle);
        }
    }
    const addWatchList = async (e: any, id: any) => {
        e.stopPropagation();
        if (!auth_code) {
            setShow(true);
        } else {
            const msg = await addToWatchListAPI(id);
            setWatchList(!addToWatch)
        }
    }

    const handleLoginModalBox = (val: any) => {
        val ? setShow(false) : setShow(false);
    }

    return (
        <>
            {show ?
                <SignInModal handleModalBox={handleLoginModalBox} />
                : null
            }
            <div className='d-flex justify-content-center pointer' style={{ width: 'fit-content' }}>
                <div className="image" key={id}>
                    <div className='position-relative movie_card_section' onClick={() => addRecentView('view', id)}>
                        {birthday ?
                            <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(title)}-${id}`}>
                                <img src={`${baseURL}/${cover}`} className="rectangle rounded-4" alt={title} />
                            </Link>
                            :
                            <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(title)}-${id}`}>
                                <img src={`${baseURL}/${cover}`} className="rectangle rounded-4" alt={title} />
                            </Link>
                        }
                        {!birthday ?
                            <div
                                className='position-absolute'
                                style={{
                                    top: '1%',
                                    right: '3%'
                                }}
                            >
                                <span
                                    className={`font34`}
                                    style={{
                                        color: `${addToWatch ? 'rgb(232, 215, 66)' : '#4c4c4c'}`,
                                        zIndex: '2'
                                    }}
                                    onClick={(e: any) => addWatchList(e, id)}
                                >
                                    <BsFillBookmarkPlusFill />
                                </span>
                            </div>
                            : null
                        }

                        <div
                            className='position-absolute text-white d-flex justify-content-around align-items-center w-100 movie_card_icons rounded-bottom-4'
                            style={{
                                bottom: '0px',
                                zIndex: 1
                            }}
                        >
                            {birthday ?
                                null :
                                <CardRating id={id} cdbs_rating={cdbs_rating} rating={rating} />
                            }
                            {/* <span>
                                <AiOutlineLike
                                    className={`${toggle ? 'text-primary' : ''}`}
                                    onClick={(e: any) => giveLike(e, 'like', id)}
                                />
                            </span>
                            <span>
                                <AiOutlineHeart
                                    className={`${addToWatch ? 'text-danger' : ''}`}
                                    onClick={(e: any) => addWatchList(e, id)}
                                />
                            </span>
                            <span>
                                <AiOutlineShareAlt />
                            </span> */}
                        </div>
                    </div>

                    {genre ?
                        <p className='m-0 text-white font14 ps-1 mt-2'>{genre}</p> : null
                    }
                    {dob ?
                        <p className='m-0 text-white font16 font_bebas_neue ps-1'> {format(new Date(dob), 'dd MMM yyyy')}</p> : null
                    }
                    {title ?
                        <p className='m-0 text-white font16 font_bebas_neue ps-1' style={{ fontWeight: '600' }}>{title}</p> : null
                    }
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default MovieCard
