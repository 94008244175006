import React, { useEffect, useState, useRef } from 'react';
import './profileform.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Table } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { IoTrash } from 'react-icons/io5';
import UserAwardModal from '../myprofilemodals/UserAwardModal';
import { getAwardAndHonorsListAPI } from '../../utils/utils';

const UserAward = () => {

    const { subscriber_awards } = useSelector((state: any) => state.user.user);
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const userAwardRef = useRef<HTMLDivElement | null>(null);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [award_data, setAwardData] = useState<any>([]);
    const [award, setAward] = useState<any>();

    const getAwardList = async () => {
        setActive(true);
        const data = await getAwardAndHonorsListAPI();
        setAwardData(data);
        setActive(false);
    }

    // const handleChange = (e: any) => {
    //     const { name, value } = e.target;
    //     if (name === 'award_image') {
    //         const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    //         setAward((prevVal: any) => ({ ...prevVal, award_image: document_image }));
    //     } else {
    //         setAward({ ...awards, [name]: value });
    //     }
    // }

    // const handleEdit = (e: any) => {
    //     e.preventDefault();
    //     if (isDisabled) {
    //         messageTxt('You can edit your profile...');
    //     } else {
    //         messageTxt("You can't edit your profile...");
    //     }
    //     setIsDisabled(!isDisabled);
    // }

    // const handleUserAwardSubmit = async (e: any) => {
    //     e.preventDefault();
    //     setActive(true);
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     const { award_name, award_details, award_category, award_image, award_result, award_for, award_year } = awards;
    //     if (award_name === '' || award_category === '') {
    //         messageTxt('Please fill award name and category...');
    //         setActive(false);
    //     } else if (award_year.length > 4 && award_year.length < 4) {
    //         messageTxt('Please fill award year should be 4 digits...');
    //         setActive(false);
    //     } else if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 award_name,
    //                 award_details,
    //                 award_category,
    //                 award_image,
    //                 award_result,
    //                 award_for,
    //                 award_year
    //             }
    //             let result = await axios(`${baseURL}/api/subscriber/awards/addUpdate`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //             setActive(false);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const handleDeleteUserAward = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'award',
                    id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleModalForm = (val:any) => {
        setAward(val);
        setOpenFormModal(!openFormModal);
        getAwardList();
    }

    const handleAddmoreAwardModal = () => {
        getAwardList();
        setOpenAdd(!openAdd);
        setOpenFormModal(false);
    }

    useEffect(() => {
        const element = userAwardRef.current;

        const handleShow = () => {
            getAwardList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="userAward">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{backgroundColor: '#3a3a3c'}} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseuserAward" aria-expanded="false" aria-controls="flush-collapseuserAward">
                                Awards & Honors
                            </button>
                        </h2>
                        <div id="flush-collapseuserAward" ref={userAwardRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#userAward">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openFormModal &&
                                    <UserAwardModal handleModalBox={handleModalForm} data={award} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <UserAwardModal handleModalBox={handleAddmoreAwardModal} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(award_data) && award_data.length > 0 &&
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Award name</th>
                                                <th>Award category</th>
                                                <th>Award for</th>
                                                <th>Award year</th>
                                                <th>Award picture</th>
                                                <th>Award details</th>
                                                <th>Result</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(award_data) && award_data.length > 0 &&
                                                award_data.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.award_name}</td>
                                                            <td>{val.award_category}</td>
                                                            <td>{val.award_for}</td>
                                                            <td>{val.award_year}</td>
                                                            <td
                                                                className='p-0'
                                                            >
                                                                {val.award_image ?
                                                                    <img src={`${baseURL}/${val.award_image}`} alt={val.award_name} className='object-fit-contain' height={'50'} width={'50'} />
                                                                    :
                                                                    <img src={`../images/noimage.png`} className='object-fit-contain' height={'50'} width={'50'} />
                                                                }
                                                            </td>
                                                            <td>{val.award_details}</td>
                                                            <td>{val.result}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} />
                                                                    <IoTrash className='pointer' onClick={(e) => handleDeleteUserAward(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                }

                                {/* {award_data.length > 0 && Array.isArray(award_data) ?
                                    award_data.map((val: any) => {
                                        return (
                                            <div className="row p-0 mb-5 d-flex justify-content-between align-items-center accordian_border_with_color mb-4" key={val.id}>
                                                <div
                                                    className='d-flex justify-content-end mt-2'
                                                >
                                                    {!isDisabled ?
                                                        <button
                                                            className='py-2 px-2 common_button ms-2 fw-bold rounded'
                                                            onClick={(e) => handleDeleteUserAward(e, val.id)}
                                                        >
                                                            <ImCross className='text-white' />
                                                        </button>
                                                        : null
                                                    }
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award details ( 100 words )</label>
                                                    <textarea value={val.award_details} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" placeholder='Eg I am Krishna'></textarea>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award name</label>
                                                    <input type="text" value={val.award_name} className="form-control mb-3" />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award category</label>
                                                    <input type="text" value={val.award_category} className="form-control mb-3" />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award for</label>
                                                    <input type="text" value={val.award_for} className="form-control mb-3" />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award year</label>
                                                    <input type="text" value={val.award_year} className="form-control mb-3" />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Result</label>
                                                    <input type="text" value={val.result} className="form-control mb-3" />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <div
                                                        style={{ height: '100px', width: '100px' }}
                                                    >
                                                        <img src={`${baseURL}/${val.award_image}`} className='object-fit-contain' height={'100%'} width={'100%'} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                                } */}



                                {/* <form className='px-5 personal_info_form'>
                                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award details ( 100 words )</label>
                                            <textarea name='award_details' onChange={handleChange} value={awards.award_details} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" disabled={isDisabled}></textarea>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award name</label>
                                            <input type="text" onChange={handleChange} value={awards.award_name} name='award_name' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award category</label>
                                            <input type="text" onChange={handleChange} value={awards.award_category} name='award_category' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award for</label>
                                            <input type="text" onChange={handleChange} value={awards.award_for} name='award_for' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award year</label>
                                            <input type="number" onChange={handleChange} value={awards.award_year} name='award_year' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award Picture</label>
                                            <input type="file" onChange={handleChange} name='award_image' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Result</label>
                                            <input type="text" onChange={handleChange} value={awards.award_result} name='award_result' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                    </div>

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        <button
                                            className='py-2 px-2 border me-3 rounded fw-bold'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleEdit}
                                        >
                                            Edit
                                        </button>
                                        {!isDisabled ?
                                            <button
                                                className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                                onClick={handleUserAwardSubmit}
                                            >
                                                Save
                                            </button>
                                            : null
                                        }
                                    </div>
                                </form> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default UserAward
