import React, { useEffect, useRef, useState } from 'react';
import './profileform.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import { FiEdit } from 'react-icons/fi';
import { IoTrash } from 'react-icons/io5';
import PropertyAssetModal from '../myprofilemodals/PropertyAssetModal';
import { getAssetsListAPI } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Table } from 'react-bootstrap';

const PropertyAssets = () => {

    const data = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const myAssetRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [assetsList, setAssetsList] = useState<any>([]);
    const [assets, setAssets] = useState<any>({
        property_type: '',
        name: '',
        image: '',
        location: ''
    });

    const getAssetList = async () => {
        setActive(true);
        const data = await getAssetsListAPI();
        setAssetsList(data);
        setActive(false);
    }

    // const handleEdit = (e: any) => {
    //     e.preventDefault();
    //     if (isDisabled) {
    //         messageTxt('You can edit your profile...');
    //     } else {
    //         messageTxt("You can't edit your profile...");
    //     }
    //     setIsDisabled(!isDisabled);
    // }

    // const handleChange = (e: any) => {
    //     const { name, value } = e.target;
    //     if (name === 'image') {
    //         const asset_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    //         setAssets((prevVal: any) => ({ ...prevVal, image: asset_image }));
    //     } else {
    //         setAssets({ ...assets, [name]: value });
    //     }
    // };

    // const handleWhoAssetsUpdate = async (e: any) => {
    //     e.preventDefault();
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     const { property_type, name, location, image } = assets;
    //     if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 property_type,
    //                 name,
    //                 location,
    //                 image

    //             }
    //             let result = await axios(`${baseURL}/api/subscriber/assets/addUpdate`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //             setIsDisabled(!isDisabled);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const deleteAssets = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'asset',
                    id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                getAssetList();
                messageTxt(result.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleModalForm = (val: any) => {
        setAssets(val);
        setOpenFormModal(!openFormModal);
        getAssetList();
    }

    const handleAddmoreAwardModal = () => {
        getAssetList();
        setOpenAdd(!openAdd);
        setOpenFormModal(false);
    }

    useEffect(() => {
        const element = myAssetRef.current;

        const handleShow = () => {
            getAssetList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="assetsForm">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseassetsForm" aria-expanded="false" aria-controls="flush-collapseassetsForm">
                                Properties/Assets
                            </button>
                        </h2>
                        <div id="flush-collapseassetsForm" ref={myAssetRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#assetsForm">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openFormModal &&
                                    <PropertyAssetModal handleModalBox={handleModalForm} data={assets} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <PropertyAssetModal handleModalBox={handleAddmoreAwardModal} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(assetsList) && assetsList.length > 0 &&
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Properties/Assets</th>
                                                <th>Image</th>
                                                <th>Location</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(assetsList) && assetsList.length > 0 &&
                                                assetsList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.name}</td>
                                                            <td>{val.property_type}</td>
                                                            <td
                                                                className='p-0'
                                                            >
                                                                {val.image ?
                                                                    <img src={`${baseURL}/${val.image}`} alt={val.award_name} className='object-fit-contain' height={'50'} width={'50'} />
                                                                    :
                                                                    <img src={`../images/noimage.png`} className='object-fit-contain' height={'50'} width={'50'} />
                                                                }
                                                            </td>
                                                            <td>{val.location}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} />
                                                                    <IoTrash className='pointer' onClick={(e) => deleteAssets(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                }

                                {/* {assets.length > 0 && Array.isArray(assets) ?
                                    assets.map((val, i) => {
                                        return (
                                            <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form accordian_border_with_color mb-4" key={i}>
                                                <div
                                                    className='d-flex justify-content-end mt-2'
                                                >
                                                    {!isDisabled ?
                                                        <button
                                                            className='py-2 px-2 common_button rounded'
                                                            style={{
                                                                backgroundColor: 'red',
                                                            }}
                                                        // onClick={(e) => handleDeleteData(e, i)}
                                                        >
                                                            <ImCross className='text-white' />
                                                        </button>
                                                        : null
                                                    }
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Properties/Assets</label>
                                                    <select className="form-select mb-3" value={val.property_type} name='property_type' aria-label="Default select example" disabled={isDisabled}>
                                                        <option selected>Select your assets..</option>
                                                        <option value="Home">Home</option>
                                                        <option value="Farm House">Farm House</option>
                                                        <option value="Farm Land">Farm Land</option>
                                                        <option value="Beach House">Beach House</option>
                                                        <option value="Car">Car</option>
                                                        <option value="Aircraft">Aircraft</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                                                    <input type="text" name='name' value={val.name} className="form-control mb-3" placeholder='eg: car, home etc.' disabled={isDisabled} />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Location</label>
                                                    <input type="text" name='location' value={val.location} className="form-control mb-3" disabled={isDisabled} />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                                } */}

                                {/* <div className="row mt-5 p-0 d-flex justify-content-between align-items-center create_cast_form biography_form">
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Properties/Assets</label>
                                        <select className="form-select mb-3" onChange={handleChange} value={assets.property_type} name='property_type' aria-label="Default select example" disabled={isDisabled}>
                                            <option selected>Select your assets..</option>
                                            <option value="Home">Home</option>
                                            <option value="Farm House">Farm House</option>
                                            <option value="Farm Land">Farm Land</option>
                                            <option value="Beach House">Beach House</option>
                                            <option value="Car">Car</option>
                                            <option value="Aircraft">Aircraft</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                                        <input type="text" name='name' value={assets.name} onChange={handleChange} className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Location</label>
                                        <input type="text" name='location' value={assets.location} onChange={handleChange} className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Controversy image</label>
                                        <input type="file" onChange={handleChange} name='image' className="form-control mb-3" />
                                    </div>
                                </div>

                                <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border me-3  rounded fw-bold'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        {isDisabled ? 'Edit' : 'Cancel'}
                                    </button>
                                    {!isDisabled ?
                                        <button
                                            className='py-2 px-2 common_button rounded fw-bold'
                                            style={{
                                                width: '150px'
                                            }}
                                            onClick={handleWhoAssetsUpdate}
                                        >
                                            Save
                                        </button>
                                        : null
                                    }
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default PropertyAssets
