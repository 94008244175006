import React from 'react';
import './category.css';
import { baseURL } from '../../utils/api';
import { getChangeIndustryTypeAPI } from '../../utils/utils';

const CategoryCard = (props: any) => {

    const { id, title, image, bgColor } = props;
    const multiIndustryVal = localStorage.getItem('multiindustry');

    const industryTypeSelected = async (id: any) => {
        localStorage.setItem('multiindustry', id);
        await getChangeIndustryTypeAPI(id);
        window.location.reload();
    }

    return (
        <div
            className='d-grid justify-content-center align-items-center'
            role='button'
            onClick={() => industryTypeSelected(id)}
        >
            <div
                className={`mainBox rounded d-flex justify-content-center align-items-center pointer ${multiIndustryVal == id ? 'mainBoxActive_industry' : bgColor}`}
            >
                <div
                    style={{
                        height: 'auto',
                        width: '55px'
                    }}
                >
                    <img src={`${baseURL}/${image}`} alt={title} className='img-fluid' width={'100%'} height={'100%'} />
                </div>
            </div>
            <p
                className='m-0 mt-2 text-center text-white font14'
            >
                {title}
            </p>
        </div>
    )
}

export default CategoryCard