import React, { useState, useEffect, useRef } from 'react';
import '../../components/myprofileforms/profileform.css';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { baseURL } from '../../utils/api';
import { ImCross } from 'react-icons/im';
import LoadingOverlay from 'react-loading-overlay-ts';
import { deleteMasterClassAPI, deleteMasterClassVideoAPI, getAllIndustryTypeAPI, getMasterClassDetailAPI } from '../../utils/utils';
import { extractIdFromUrl } from '../../utils/jsfunctions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import { Table } from 'react-bootstrap';
import { IoTrash } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';
import MasterClassVideoModal from '../../components/myprofilemodals/MasterClassVideoModal';

const MasterClassDetail = () => {

    const messageTxt = (message: any) => toast(message);
    LoadingOverlay.propTypes = undefined;
    const profile_data = useSelector((state: any) => state.user.user);
    const params = useParams();
    const { id } = params;
    const [selectIndustry, setSelectIndustry] = useState<any>(profile_data.industries);
    const navigate = useNavigate();
    const myMasterClassDetailRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [industryList, setIndustryList] = useState<any>([]);
    const [getId, setId] = useState<any>('');
    const [masterClass, setMasterClass] = useState({
        master_class_name: '',
        class_type: '',
        description: '',
        industry_id: '',
        banner: '',
        cover: ''
    });
    const [upload_video_list, setUpload_video_list] = useState([{
        title: '',
        video_link: '',
        description: ''
    }]);
    const [uploadVideos, setUploadVideos] = useState({
        title: '',
        video_link: '',
        description: ''
    });

    const getEventDetails = async () => {
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const data = await getMasterClassDetailAPI(urlId);
        setMasterClass(data);
        setUpload_video_list(data?.class_videos);
        setActive(false);
    }

    const getIndustryList = async () => {
        setActive(true);
        const response = await getAllIndustryTypeAPI();
        const data = response.filter((val: any) => {
            return selectIndustry?.includes(val.id);
        })
        setIndustryList(data);
        setActive(false);
    }

    const handleMasterClassUpdateChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'banner') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setMasterClass((prevKyc: any) => ({ ...prevKyc, banner: document_image }));
        } else if (name === 'cover') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setMasterClass((prevKyc: any) => ({ ...prevKyc, cover: document_image }));
        } else {
            setMasterClass({ ...masterClass, [name]: value });
        }
    };

    const handleClassVideoChangeValue = (e: any) => {
        const { name, value } = e.target;
        setUploadVideos({ ...uploadVideos, [name]: value });
    };

    const handleEditMasterClassUpdate = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleUpdateeMasterClass = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const urlId = extractIdFromUrl(id);
        const { master_class_name, class_type, description, industry_id, banner, cover } = masterClass;

        if (master_class_name === '' || class_type === '' || description === '' || industry_id === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    master_class_name,
                    class_type,
                    description,
                    industry_id,
                    banner,
                    cover,
                    master_class_id: urlId
                }
                const res = await axios(`${baseURL}/api/master_class/create`, {
                    method: 'POST',
                    data: body,
                    headers: { 'Content-Type': "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handleDeleteClass = async () => {
        const urlId = extractIdFromUrl(id);
        await deleteMasterClassAPI(urlId);
        navigate('/profile/myprofile')
    }

    const handleUploadVideo = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const urlId = extractIdFromUrl(id);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { title, video_link, description } = uploadVideos;

        if (title === '' || video_link === '') {
            messageTxt('Please fill video title and video link field.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    title,
                    video_link,
                    description,
                    master_class_id: urlId,
                    id: getId
                }
                const res = await axios(`${baseURL}/api/master_class/add_video`, {
                    method: 'POST',
                    data: body,
                    headers: { 'Content-Type': "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setUploadVideos({
                    title: '',
                    video_link: '',
                    description: ''
                });
                getEventDetails();
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const getVideoUpdateValue = (e: any, val: any) => {
        e.preventDefault();
        const data = val;
        setUploadVideos({
            ...uploadVideos,
            title: data.title,
            video_link: data.video_link,
            description: data.description
        });
        setId(data.id);
        setOpenAdd(!openAdd);
    }

    const deleteVideo = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        await deleteMasterClassVideoAPI(id);
        getEventDetails();
        setActive(false);
    }

    const handleAddmoreVideoModal = () => {
        getEventDetails();
        setOpenAdd(!openAdd);
        setUploadVideos({
            title: '',
            video_link: '',
            description: ''
        });
        setId('');
    }

    useEffect(() => {
        getEventDetails();
        getIndustryList();
    }, []);

    useEffect(() => {
        // console.log(upload_video);
    }, [selectIndustry, masterClass])

    return (
        <>
            <div className='mt-5 d-flex justify-content-between align-items-center px-4'>
                <div className='text-white'>
                    <Link className='text-decoration-none text-white' to='/'>Home</Link>
                    <span className='mx-1'>/</span>
                    <Link className='text-decoration-none text-white' to='/profile/myprofile'>MyProfile</Link>
                    <span className='mx-1'>/</span>
                    <span className='pointer'>Master class detail</span>
                </div>
                <button
                    className='border-0 fs-4 bg-dark'
                    style={{
                        right: '10px',
                        top: '5px'
                    }}
                    onClick={handleDeleteClass}
                >
                    <BsFillTrashFill className='text-white' />
                </button>
            </div>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <div className='mt-3'>
                    <div className="accordion accordion-flush" id="masterClass">

                        <div className="accordion-item rounded">
                            <h2 className="accordion-header" id="flush-headingmasterClass">
                                <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsemasterClass" aria-expanded="true" aria-controls="flush-collapsemasterClass">
                                    Update master class / class videos
                                </button>
                            </h2>
                            <div id="flush-collapsemasterClass" ref={myMasterClassDetailRef} className="accordion-collapse show collapse" aria-labelledby="flush-headingKycForm" data-bs-parent="#KycForm">
                                <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openAdd &&
                                    <MasterClassVideoModal handleModalBox={handleAddmoreVideoModal} data={uploadVideos} getId={getId} dataId={id} headingText={'Add New'} />
                                }

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                        <button
                                            className='py-2 px-2 border me-3 rounded font_weight_600'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '100px'
                                            }}
                                            onClick={handleEditMasterClassUpdate}
                                        >
                                            Edit
                                        </button>
                                    </div>

                                    <div className='px-4 master_class_form'>
                                        <p
                                            className='m-0 mb-1 text-white font20'
                                        >
                                            Update master class
                                        </p>
                                        <div className="row p-0 d-flex justify-content-between align-items-center mb-3">
                                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                                <label htmlFor="className" className="form-label text-white m-0">Class name</label>
                                                <input type="text" onChange={handleMasterClassUpdateChangeValue} name='master_class_name' value={masterClass?.master_class_name} className="form-control" id="exampleFormControlInput1" disabled={isDisabled} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                                <label htmlFor="className" className="form-label text-white m-0">Class description</label>
                                                <textarea
                                                    className="form-control"
                                                    id="exampleFormControlTextarea1"
                                                    disabled={isDisabled}
                                                    onChange={handleMasterClassUpdateChangeValue}
                                                    name='description'
                                                    value={masterClass?.description}
                                                >
                                                </textarea>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Select class type</label>
                                                <select onChange={handleMasterClassUpdateChangeValue} name='class_type' value={masterClass?.class_type} className="form-select" aria-label="Default select example" disabled={isDisabled}>
                                                    <option selected>Select class type</option>
                                                    <option value="1">Free</option>
                                                    <option value="2">Paid</option>
                                                </select>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Industry</label>
                                                <select onChange={handleMasterClassUpdateChangeValue} name='industry_id' value={masterClass?.industry_id} className="form-select" aria-label="Default select example" disabled={isDisabled}>
                                                    <option selected>Select industry</option>
                                                    {Array.isArray(industryList) &&
                                                        industryList?.map((val: any) => {
                                                            return (
                                                                <option key={val.id} value={val.id}>{val.title}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload banner image (optional)</label>
                                                {isDisabled && masterClass?.banner ?
                                                    <div
                                                        style={{ height: '100px', width: '150px' }}
                                                    >
                                                        <img src={`${baseURL}/${masterClass?.banner}`} alt={masterClass?.master_class_name} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                    </div>
                                                    :
                                                    <input type="file" onChange={handleMasterClassUpdateChangeValue} name='banner' className="form-control" id="exampleFormControlInput1" accept="image/*" disabled={isDisabled} />
                                                }
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload cover image (optional)</label>
                                                {isDisabled && masterClass?.cover ?
                                                    <div
                                                        style={{ height: '100px', width: '150px' }}
                                                    >
                                                        <img src={`${baseURL}/${masterClass?.cover}`} alt={masterClass?.master_class_name} className='object-fit-contain' width={'100%'} height={'100%'} />
                                                    </div>
                                                    :
                                                    <input type="file" onChange={handleMasterClassUpdateChangeValue} name='cover' className="form-control" id="exampleFormControlInput1" accept="image/*" disabled={isDisabled} />
                                                }
                                            </div>
                                        </div>

                                        {/* <div className="row p-0 d-flex justify-content-between align-items-center mt-4 mb-3">
                                            <p
                                                className='m-0 font16 text-white mb-3'
                                            >
                                                Upload/Update video here.
                                            </p>
                                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                                <label htmlFor="title" className="form-label text-white m-0">Title</label>
                                                <input type="text" onChange={handleClassVideoChangeValue} name='title' value={uploadVideos.title} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                                <label htmlFor="video_link" className="form-label text-white m-0">Video link</label>
                                                <input type="text" onChange={handleClassVideoChangeValue} name='video_link' value={uploadVideos.video_link} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div
                                                className='text-end'
                                            >
                                                <button
                                                    className='py-2 px-2 border-0 common_button rounded font_weight_600'
                                                    style={{
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        width: '150px'
                                                    }}
                                                    onClick={handleUploadVideo}
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        </div> */}

                                        <div
                                            className='d-flex justify-content-between align-items-center'
                                        >
                                            <p
                                                className='m-0 font20 text-white mt-5 mb-3'
                                            >
                                                Class videos list
                                            </p>
                                            <button
                                                className='py-2 border-0 rounded fw-bold'
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    width: '100px'
                                                }}
                                                onClick={handleAddmoreVideoModal}
                                            >
                                                + Add
                                            </button>
                                        </div>

                                        {Array.isArray(upload_video_list) && upload_video_list.length > 0 ?
                                            <Table striped bordered hover responsive variant="dark">
                                                <thead>
                                                    <tr>
                                                        <th>SL</th>
                                                        <th>Title</th>
                                                        <th>Video link</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(Array.isArray(upload_video_list) && upload_video_list.length > 0) &&
                                                        upload_video_list.map((val: any, i: any) => {
                                                            return (
                                                                <tr
                                                                    key={val.id}
                                                                >
                                                                    <td>{i + 1}</td>
                                                                    <td>{upload_video_list[i].title}</td>
                                                                    <td>{upload_video_list[i].video_link}</td>
                                                                    <td>
                                                                        <div
                                                                            className='font20 d-flex justify-content-between align-items-center'
                                                                        >
                                                                            <FiEdit className='pointer' onClick={(e) => getVideoUpdateValue(e, val)} />
                                                                            <IoTrash className='pointer' onClick={(e) => deleteVideo(e, val.id)} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <p
                                                className="m-0 text-white font_weight_600 font16 text-center"
                                            >
                                                Data not found, add new data.
                                            </p>
                                        }

                                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end mt-lg-5 mt-md-5 mt-3'>
                                            {!isDisabled ?
                                                <button
                                                    className='py-2 px-2 border-0 common_button rounded font_weight_600'
                                                    style={{
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        width: '150px'
                                                    }}
                                                    onClick={handleUpdateeMasterClass}
                                                >
                                                    Update
                                                </button>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </LoadingOverlay>
        </>
    )
}

export default MasterClassDetail
