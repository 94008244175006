import React from 'react';
import { IoMdPlay } from "react-icons/io";

const RecommendedMovieCard = (props: any) => {
    const { id, title, duration, image, height, width } = props;
    return (
        <div
            className='hover_zoom position-relative pointer my-3'
            style={{
                height: `${height}`,
                width: `${width}`
            }}
        >
            <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
            <p
                className='m-0 text-white font18 font_weight_600 position-absolute'
                style={{
                    top: '23%',
                    left: '15px'
                }}
            >
                {title}
            </p>
            <div
                className='position-absolute'
                style={{
                    top: '42%',
                    left: '15px'
                }}
            >
                <div
                    className=''
                >
                    <span
                        className='text-white font16 theme_color p-1 me-1'
                    >
                        PG
                    </span>
                    <span
                        className='text-white font16'
                    >
                        {duration}
                    </span>
                </div>
            </div>
            <div
                className='position-absolute'
                style={{
                    top: '60%',
                    left: '15px'
                }}
            >
                <button
                    className='ott_play_now_button rounded py-3'
                >
                    <IoMdPlay /> Play Now
                </button>
            </div>
        </div>
    )
}

export default RecommendedMovieCard
