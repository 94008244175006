import React, { useEffect, useRef } from 'react';
import './style.moviedetail.css';
import Slider from "react-slick";
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import ImageCard from '../imagecard/ImageCard';

const SynopsisSlider = (props:any) => {

    const { data } = props;
    // console.log('slider',data);

    let sliderRef = useRef<any>(null);
    // const next = () => {
    //     sliderRef.current?.slickNext();
    // };
    // const previous = () => {
    //     sliderRef.current?.slickPrev();
    // };

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 6,
                    infinite: data.length > 7,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 6,
                    infinite: data.length > 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 6,
                    infinite: data.length > 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    infinite: data.length > 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    infinite: data.length > 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    infinite: data.length > 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    infinite: data.length > 1,
                },
            },
        ],
    };

    // useEffect(() => {
    //     console.log('ref',data);
    // }, [data])

    return (
        <>
            <div className=''>
                <Slider {...settings} ref={sliderRef}>
                    {
                        data?.map((e:any, i:any) => {
                            return (
                                <div key={e?.title} className='d-flex justify-content-center'>
                                    <ImageCard cover={e.img_path} title={e.name} smallImage={true} />
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
            {/* <div className='text-white d-flex justify-content-center align-items-center mt-2'>
                <div className='border rounded-pill p-1 d-flex align-items-center justify-content-center' onClick={previous}>
                    <PiCaretLeftBold />
                </div>
                <div className='border mx-4 rounded-pill p-1 d-flex align-items-center justify-content-center' onClick={next}>
                    <PiCaretRightBold />
                </div>
            </div> */}
        </>
    )
}

export default SynopsisSlider
