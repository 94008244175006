import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const ComingSoonModal = (props: any) => {
    const { handleModalBox } = props;
    const [show, setShow] = useState(true);
    const handlClosedModal = () => {
        handleModalBox(false);
        setShow(false);
    }
    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
        >
            <div className='d-flex justify-content-center align-items-center'>
                <div className='p-5 w-100'>
                    <Modal.Body className='p-0 text-center text-white fs-5'>
                        Coming Soon...
                    </Modal.Body>
                    <div className='d-flex justify-content-center align-items-center mt-4 px-4'>
                        <button
                            className='modal_box_cancel_button py-2 rounded'
                            style={{ width: '150px' }}
                            onClick={handlClosedModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ComingSoonModal
