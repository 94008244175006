import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { getAllGenres, getWrittenScriptListAPI } from "../../utils/utils";
import Select from "react-dropdown-select";
import LoadingOverlay from 'react-loading-overlay-ts';
import { baseURL } from "../../utils/api";
import axios from "axios";
import { ImCross } from 'react-icons/im';
import { Table } from "react-bootstrap";
import { IoTrash } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import WrittenScriptModal from "../myprofilemodals/WrittenScriptModal";

const WrittenScripts = () => {

    const messageTxt = (message: any) => toast(message);
    LoadingOverlay.propTypes = undefined;
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [genres, setGenres] = useState<any>();
    const [scriptList, setScriptList] = useState<any>();
    const [writtenScriptVal, setWrittenScriptVal] = useState({
        ip_registered: '',
        sharable: '',
        log_line: '',
        budget: '',
        id: '',
        title: '',
        genre_id: ''
    })

    const getGeneres = async () => {
        setActive(true);
        const data = await getAllGenres();
        setGenres(data);
        setActive(false);
    }

    const getScriptList = async () => {
        setActive(true);
        const data = await getWrittenScriptListAPI();
        setScriptList(data);
        setActive(false);
    }

    // const handleChangeWrittenScriptVal = (e: any) => {
    //     const { name, value } = e.target;
    //     setWrittenScriptVal({ ...writtenScriptVal, [name]: value });
    // }

    // const handleEditWrittenScript = (e: any) => {
    //     e.preventDefault();
    //     if (isDisabled) {
    //         messageTxt('You can edit your profile...');
    //     } else {
    //         messageTxt("You can't edit your profile...");
    //     }
    //     setIsDisabled(!isDisabled);
    // }

    // const handleSubmitWrittenScript = async (e: any) => {
    //     e.preventDefault();
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     const { ip_registered, sharable, log_line, budget, genre_id, id, title } = writtenScriptVal;
    //     if (log_line === '' || title === '') {
    //         messageTxt('Please enter a log line and title !');
    //         setIsDisabled(false)
    //     } else if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 ip_registered,
    //                 sharable,
    //                 log_line,
    //                 title,
    //                 budget,
    //                 genre_id,
    //                 script_id: id
    //             }
    //             let result = await axios(`${baseURL}/api/subscriber/scripts/addUpdate`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //             getScriptList();
    //             setActive(false);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // };

    const deleteScript = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'script',
                    id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getScriptList();
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const takeValueForUpdate = (e: any, val: any) => {
        const data = val;
        setWrittenScriptVal(data);
    }

    const handleModalForm = (val: any) => {
        setWrittenScriptVal(val);
        setOpenFormModal(!openFormModal);
        getScriptList();
    }

    const handleAddmoreAwardModal = () => {
        getScriptList();
        setOpenAdd(!openAdd);
        setOpenFormModal(false);
    }

    // checking accordion open or close then api call
    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            getScriptList();
            getGeneres();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('ws', writtenScriptVal);
    // }, [writtenScriptVal])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='written_script_accordion_button mt-3'>
                <div className="accordion accordion-flush" id="writeenScript">
                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed ac_button rounded accordian_border_with_color text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsewriteenScript" aria-expanded="false" aria-controls="flush-collapsewriteenScript">
                                My written script
                            </button>
                        </h2>
                        <div id="flush-collapsewriteenScript" ref={myElementRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#writeenScript">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openFormModal &&
                                    <WrittenScriptModal handleModalBox={handleModalForm} data={writtenScriptVal} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <WrittenScriptModal handleModalBox={handleAddmoreAwardModal} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreAwardModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(scriptList) && scriptList.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Title</th>
                                                <th>Budget</th>
                                                <th>Genre</th>
                                                <th>IP registered</th>
                                                <th>Log line</th>
                                                <th>Sharable</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(Array.isArray(scriptList) && scriptList.length > 0) &&
                                                scriptList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.title}</td>
                                                            <td>{val.budget}</td>
                                                            <td>{val.genre_id}</td>
                                                            <td>{val.ip_registered === 1 ? 'Yes' : 'No'}</td>
                                                            <td>{val.log_line}</td>
                                                            <td>{val.sharable === 1 ? 'Yes' : 'No'}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} />
                                                                    <IoTrash className='pointer' onClick={(e) => deleteScript(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white font_weight_600 font16 text-center"
                                    >
                                        Data not found, add new data.
                                    </p>
                                }

                                {/* {scriptList.length > 0 && Array.isArray(scriptList) ?
                                            scriptList.map((val: any) => {
                                                return (
                                                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center accordian_border_with_color mb-4" key={val.id}>
                                                        <div
                                                            className='d-flex justify-content-end mt-2'
                                                        >
                                                            {!isDisabled ?
                                                                <>
                                                                    <button
                                                                        className='py-2 px-2 ms-2 fw-bold rounded bg-danger'
                                                                        onClick={(e) => takeValueForUpdate(e, val)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        className='py-2 px-2 ms-2 fw-bold rounded bg-danger'
                                                                        onClick={(e) => deleteScript(e, val.id)}
                                                                    >
                                                                        <ImCross className='text-white' />
                                                                    </button>
                                                                </>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">title</label>
                                                            <input type="text" value={val.title} className="form-control mb-3" disabled={isDisabled} />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Log line ( 100 words )</label>
                                                            <textarea
                                                                className="form-control mb-3"
                                                                style={{ border: '1px solid gray' }}
                                                                id="exampleFormControlTextarea1"
                                                                disabled={isDisabled}
                                                                value={val.log_line}
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">Budget</label>
                                                            <input type="text" value={val.budget} className="form-control mb-3" placeholder="100 cr" disabled={isDisabled} />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12 written_script_multi_select'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">Genre</label>
                                                            <select className="form-select mb-3" value={val.genre_id} aria-label="Default select example" disabled={isDisabled}>
                                                                <option selected>Select genre...</option>
                                                                {(Array.isArray(genres) && genres.length > 0) &&
                                                                    genres.map((val: any) => {
                                                                        return (
                                                                            <option key={val.id} value={val.id}>{val.title}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">IP registered</label>
                                                            <select className="form-select mb-3" value={val.ip_registered} aria-label="Default select example" disabled={isDisabled}>
                                                                <option selected>Select...</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12'>
                                                            <label htmlFor="movieTitle" className="form-label text-white m-0">Sharable</label>
                                                            <select className="form-select mb-3" value={val.sharable} aria-label="Default select example" disabled={isDisabled}>
                                                                <option selected>Select...</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                    </>
                                    :
                                    null
                                } */}

                                {/* <p className='m-0 mb-3 text-white font18'>Add/Update</p>
                                <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form">
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Title</label>
                                        <input type="text" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.title} name="title" className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Log line ( 100 words )</label>
                                        <textarea
                                            className="form-control mb-3"
                                            style={{ border: '1px solid gray' }}
                                            id="exampleFormControlTextarea1"
                                            disabled={isDisabled}
                                            onChange={handleChangeWrittenScriptVal}
                                            name="log_line"
                                            value={writtenScriptVal.log_line}
                                        >
                                        </textarea>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Budget</label>
                                        <input type="text" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.budget} name="budget" className="form-control mb-3" placeholder="100 cr" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 written_script_multi_select'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Genre</label>
                                        <select className="form-select mb-3" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.genre_id} name="genre_id" aria-label="Default select example" disabled={isDisabled}>
                                            <option selected>Select genre...</option>
                                            {(Array.isArray(genres) && genres.length > 0) &&
                                                genres.map((val: any) => {
                                                    return (
                                                        <option key={val.id} value={val.id}>{val.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">IP registered</label>
                                        <select className="form-select mb-3" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.ip_registered} name="ip_registered" aria-label="Default select example" disabled={isDisabled}>
                                            <option selected>Select...</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Sharable</label>
                                        <select className="form-select mb-3" onChange={handleChangeWrittenScriptVal} value={writtenScriptVal.sharable} name="sharable" aria-label="Default select example" disabled={isDisabled}>
                                            <option selected>Select...</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        <button
                                            className='py-2 px-2 border me-3 rounded fw-bold'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleEditWrittenScript}
                                        >
                                            Edit
                                        </button>
                                        {!isDisabled ?
                                            <button
                                                className='py-2 px-2 border-0 rounded fw-bold save_button'
                                                style={{
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                                onClick={handleSubmitWrittenScript}
                                            >
                                                Save
                                            </button>
                                            : null
                                        }
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    );
};

export default WrittenScripts;
