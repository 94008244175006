import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import Tabs from 'react-bootstrap/Tabs';
import TopSlider from '../../components/movienewscomponents/TopSlider';
import { baseURL } from '../../utils/api';
import { getBannerAPI, newlyAddedMovies } from '../../utils/utils';
import NewlyAddedSlider from '../../components/newlyaddedmovieslider/NewlyAddedSlider';
import MovieDetails from '../../components/singlemoviedetails/MovieDetails';
import NewsNewlyAddedSlider from '../../components/movienewscomponents/NewsNewlyAddedSlider';
import MovieReviewCard from '../../components/movienewscomponents/MovieReviewCard';
import EntertainmentModal from '../../components/movienewscomponents/EntertainmentModal';


const MovieNews = () => {

    const [isActive, setActive] = useState(false);
    const [banner, setBanner] = useState<any>([]);
    const [movie_detail_id, setMovieDetailId] = useState<number>();
    const [openEntertainModal, setOpenEntertainModal] = useState(false);
    const [Newly_added_movies, setNewlyAddedMovies] = useState<Array<any>>([]);

    const data = [
        { title: 'Suriya breaks down while paying his respects to late actor Vijayakanth', text: 'Suriya breaks down while paying his respects to late actor', image: '../images/enews1.png' },
        { title: 'Suriya breaks down while paying his respects to late actor Vijayakanth', text: 'Suriya breaks down while paying his respects to late actor', image: '../images/enews2.png' },
        { title: 'Suriya breaks down while paying his respects to late actor Vijayakanth', text: 'Suriya breaks down while paying his respects to late actor', image: '../images/enews3.png' },
        { title: 'Suriya breaks down while paying his respects to late actor Vijayakanth', text: 'Suriya breaks down while paying his respects to late actor', image: '../images/enews4.png' },
        { title: 'Suriya breaks down while paying his respects to late actor Vijayakanth', text: 'Suriya breaks down while paying his respects to late actor', image: '../images/enews5.png' },
        { title: 'Suriya breaks down while paying his respects to late actor Vijayakanth', text: 'Suriya breaks down while paying his respects to late actor', image: '../images/enews6.png' },
    ];

    const topData = [
        { title: 'title1', image: '../images/newsimg1.png' },
        { title: 'title2', image: '../images/newsimg2.png' },
        { title: 'title3', image: '../images/newsimg3.png' },
        { title: 'title4', image: '../images/newsimg4.png' },
        { title: 'title5', image: '../images/newsimg5.png' },
        { title: 'title6', image: '../images/newsimg6.png' },
        { title: 'title7', image: '../images/newsimg7.png' },
        { title: 'title8', image: '../images/newsimg8.png' },
        { title: 'title9', image: '../images/newsimg9.png' },
    ];

    const eaData = [
        { title: 'title1', image: '../images/ea1.png' },
        { title: 'title2', image: '../images/ea2.png' },
        { title: 'title3', image: '../images/ea3.png' },
        { title: 'title4', image: '../images/ea4.png' },
        { title: 'title5', image: '../images/ea5.png' },
        { title: 'title6', image: '../images/ea6.png' }
    ];

    const reviewData = [
        { id: 1, title: 'Kho Gaye Hum Kahan', image: '../images/mnews1.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 2, title: 'Salaar', image: '../images/mnews2.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 3, title: 'Dunki', image: '../images/mnews3.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 4, title: 'Kadak Singh', image: '../images/mnews4.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 5, title: 'The archies', image: '../images/mnews5.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 6, title: 'Animal', image: '../images/mnews6.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 7, title: 'Kho Gaye Hum Kahan', image: '../images/mnews7.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 8, title: 'Salaar', image: '../images/mnews8.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
        { id: 9, title: 'Kadak Singh', image: '../images/mnews9.png', genre: 'drama, action', reviewCount: 3, text: 'Lorem ipsum dolor sit amet euismod sed amet donec.' },
    ];

    const medal = ['bmedal1', 'bmedal2', 'bmedal3', 'bmedal4', 'bmedal5', 'bmedal6', 'bmedal7', 'bmedal8'];

    const newlyAddedMovie = async () => {
        setActive(true);
        const newMovie = await newlyAddedMovies();
        setNewlyAddedMovies(newMovie);
        setActive(false);
    }

    const sponsorBanner = async () => {
        setActive(true);
        const data = await getBannerAPI();
        setBanner(data);
        setActive(false);
    }

    const handleEntertainModal = () => {
        setOpenEntertainModal(!openEntertainModal)
    }

    useEffect(() => {
        sponsorBanner();
        newlyAddedMovie();
    }, [])


    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='container my-5'>

                {openEntertainModal &&
                    <EntertainmentModal handleModalBox={handleEntertainModal} />
                }

                <p
                    className='m-0 text-warning font18 font_weight_600 mb-4'
                >
                    Cinema News
                </p>

                <div
                    className='row'
                >
                    <TopSlider data={topData} />
                </div>

                <p
                    className='m-0 text-warning font18 font_weight_600 mt-5 mb-3'
                >
                    Entertainment News
                </p>
                <hr className='accordian_border_with_color' />
                <div
                    className='row mt-2 entertainment_news_section'
                    style={{
                        height: '700px',
                        overflowY: 'auto'
                    }}
                >
                    {(Array.isArray(data) && data.length > 0) &&
                        data.map((val) => {
                            return (
                                <>
                                    <div
                                        className='col-lg-4 col-md-6 col-12 mb-3 pointer'
                                        onClick={handleEntertainModal}
                                    >
                                        <div>
                                            <img src={`${val.image}`} alt={val.title} width={'100%'} height={'100%'} />
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-8 col-md-6 col-12 mb-3 pointer'
                                        onClick={handleEntertainModal}
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-white font18 font_weight_600'
                                            >
                                                {val.title}
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                {val.text}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>

                <div className='row my-5 mx-lg-5 mx-md-5 mx-2'>
                    <div className='imageBox'>
                        <p className='text-white font14 m-0'>
                            Advertisement
                        </p>
                        <img src={`${baseURL}/${banner}`} alt='sponsor' />
                    </div>
                </div>

                <p
                    className='m-0 mb-3 text-warning font20 font_weight_600'
                >
                    Newly Added Movies on CDBS
                </p>

                {(Array.isArray(Newly_added_movies) && Newly_added_movies.length > 0) &&
                    <NewsNewlyAddedSlider data={Newly_added_movies} />
                }

                <p
                    className='m-0 text-warning font18 font_weight_600 mt-5 mb-3'
                >
                    Movie Reviews
                </p>
                <hr className='accordian_border_with_color' />
                <div
                    className='row entertainment_news_section'
                    style={{
                        height: '490px',
                        overflowY: 'auto'
                    }}
                >
                    {(Array.isArray(reviewData) && reviewData.length > 0) &&
                        reviewData.map((val: any) => {
                            return (
                                <div
                                    key={val}
                                    className='col-lg-4 col-md-6 col-12 mb-3'
                                >
                                    <MovieReviewCard id={val.id} title={val.title} image={val.image} genre={val.genre} reviewCount={val.reviewCount} text={val.text} />
                                </div>
                            )
                        })
                    }
                </div>


                <p
                    className='m-0 text-warning font18 font_weight_600 mt-5'
                >
                    Events & Awards
                </p>

                <div
                    className='row'
                >
                    <TopSlider data={eaData} isEandAactive={true} />
                </div>

                {/* <p
                    className='m-0 text-warning font18 font_weight_600 mt-5'
                >
                    Behindwoods Gold Medals
                </p>

                <p
                    className='m-0 text-white font16 mt-2 mb-4'
                >
                    Lorem ipsum dolor sit amet consectetur. Amet vitae sem vulputate venenatis ornare. Velit mattis purus sagittis enim pretium fermentum erat vehicula. Diam aenean mollis ultrices convallis vel elementum fusce faucibus sed. Gravida nec venenatis consectetur nulla ultricies lectus facilisi semper. Faucibus a lorem malesuada ut est at. Proin eu lacus ornare sed in nisl tempus. Viverra nec augue amet vel viverra risus sagittis aliquam nisl. Tempor orci neque vulputate fringilla. Aliquam tempus sit aenean euismod aenean mauris porta duis velit. Nibh suspendisse sagittis et adipiscing arcu augue nullam sem id. Euismod et aenean amet egestas vel consequat justo. Justo conm neque posuere cras venenatis cras mi semper. Lacinia vitae dictumst vitae platea ac non pulvinar eget aliquam. Amet proin nec diam elementum quis amet. Turpis condimentum ultrices pretium sed facilisis tristique. Ullamcorper vitae ornare netus vehicula amet ante mattis erat. Libero iaculis felis et eget erat lectus sed et. Massa tincidunt faucibus in nulla eros phasellus. Risus ultrices nunc nunc dignissim potenti. Arcu auctor mauris in sed enim sit sed. Morbi amet ornare turpis mauris mattis consectetur. A ultrices volutpat eros porta eget orci mi integer aliquam. Massa cursus non at sodales facilisi.
                </p>

                {[0, 1].map((rowIndex) => (
                    <div className="row mb-4" key={rowIndex}>
                        {medal.slice(rowIndex * 5, (rowIndex + 1) * 5).map((imageUrl, columnIndex) => (
                            <div className="col" key={columnIndex}>
                                <div
                                    style={{
                                        width: '240px',
                                        height: '265px',
                                    }}
                                >
                                    <img src={`../images/${imageUrl}.png`} alt={`Image ${rowIndex * 5 + columnIndex + 1}`} width={'100%'} height={'100%'} className="img-fluid" />
                                </div>
                            </div>
                        ))}
                    </div>
                ))} */}

                {/* <div className='row m-0 p-0'>
                    <div className='col-lg-7 col-md-7 col-12 d-flex justify-content-center align-items-center mb-lg-0 mb-md-0 mb-4'>
                        <div>
                            <img src='../images/rammahesh.png' className='object-fit-contain' height={'100%'} width={'100%'} alt='ram mahesh' />
                        </div>
                    </div>

                    <div className='col-lg-5 col-md-5 col-12'>
                        <div className='row m-0 p-0'>
                            <img src='../images/jrntr.png' className='w-100' alt='ram charan' />
                        </div>
                        <div className='row p-0 m-0 mt-2'>
                            <img src='../images/vijaydev.png' className='w-100' alt='ram charan' />
                        </div>
                    </div>
                </div>

                <div className='row m-0 p-0 mt-5'>
                    <p className='text-white font20'>Entertainment News</p>
                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center'>
                        <img src='../images/chiranjeevi.png' height={'300'} width={'500'} alt='ram charan' />
                    </div>

                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center'>
                        <div className='text-white pt-lg-0 pt-md-0 pt-2'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </div>
                    </div>
                </div>

                <div className='row m-0 p-0 mt-4'>
                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center'>
                        <img src='../images/entertainment2.png' height={'300'} width={'500'} alt='ram charan' />
                    </div>

                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center'>
                        <div className='text-white pt-lg-0 pt-md-0 pt-2'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </div>
                    </div>
                </div>

                <div className='row m-0 p-0 mt-5'>
                    <p className='text-white font20'>Movie News</p>
                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center mb-lg-0 mb-md-0 mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12 d-lg-block d-md-block d-flex justify-content-center'>
                                <img src='../images/news1.png' height={'200'} width={'300'} alt='ram charan' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='text-white text-center pt-lg-0 pt-md-0 pt-2'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12 d-lg-block d-md-block d-flex justify-content-center'>
                                <img src='../images/news1.png' height={'200'} width={'300'} alt='ram charan' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='text-white text-center pt-lg-0 pt-md-0 pt-2'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row m-0 p-0 mt-5'>
                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center mb-lg-0 mb-md-0 mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12 d-lg-block d-md-block d-flex justify-content-center'>
                                <img src='../images/news2.png' height={'200'} width={'300'} alt='ram charan' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='text-white text-center pt-lg-0 pt-md-0 pt-2'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12 d-lg-block d-md-block d-flex justify-content-center'>
                                <img src='../images/news2.png' height={'200'} width={'300'} alt='ram charan' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='text-white text-center pt-lg-0 pt-md-0 pt-2'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </LoadingOverlay>
    )
}

export default MovieNews
