import { configureStore } from '@reduxjs/toolkit';
import { userSlice, projectSlice, pageNumberSlice, searchTextSlice, industryTypeSlice, userLocationSlice } from './reducers/index';

export default configureStore ({
    reducer: {
        user: userSlice.reducer,
        project: projectSlice.reducer,
        pNumber: pageNumberSlice.reducer,
        searchText: searchTextSlice.reducer,
        industryType: industryTypeSlice.reducer,
        userLocation: userLocationSlice.reducer,
    },
}, window._REDUX_DEVTOOLS_EXTENSION_ && window._REDUX_DEVTOOLS_EXTENSION_());