import React, { useEffect, useState } from 'react';
import './shopcss.css';
import { PiCaretDownBold } from 'react-icons/pi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getEcomCategoriesAPI } from '../../utils/utils';

const ShopSidePannel = (props: any) => {
    const { onProductSelect } = props;
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
    const [isActive, setActive] = useState(false);
    const [categories, setCategories] = useState<any>([]);

    const getCategory = async () => {
        setActive(true);
        const data = await getEcomCategoriesAPI();
        setCategories(data);
        setActive(false);
    }

    const handleCategoryClick = (categoryId: string) => {
        setSelectedCategory(selectedCategory === categoryId ? null : categoryId);
    };

    const handleSubCategoryClick = (subCategoryId: string) => {
        onProductSelect(subCategoryId);
        setSelectedSubCategory(selectedSubCategory === subCategoryId ? null : subCategoryId);
    };

    useEffect(() => {
        getCategory();
    }, [])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className="side-panel mt-4">
                <h3 className='text-warning'>Products</h3>
                <ul>
                    {(Array.isArray(categories) && categories.length > 0) && categories.map((category: any) => (
                        <li
                            key={category.id}
                            className={`${selectedCategory !== category.id ? 'side_pannel_bg': ''}`}
                        >
                            <div
                                className={`d-flex justify-content-between align-items-center py-2 px-2`}
                                onClick={() => handleCategoryClick(category.id)}
                            >
                                <strong>{category.category_name}</strong>
                                <span>
                                    <PiCaretDownBold />
                                </span>
                            </div>
                            {selectedCategory === category.id && (
                                <ul>
                                    {(Array.isArray(category.subcategory) && category.subcategory.length > 0) && category.subcategory.map((subcategory: any) => (
                                        <li 
                                            key={subcategory.id} 
                                            onClick={() => handleSubCategoryClick(subcategory.id)} 
                                            className={`font14 sub_category_bg_color ${selectedSubCategory === subcategory.id ? 'sub_category_bg_text_color' : ''}`}
                                        >
                                            {subcategory.subcategory_name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </LoadingOverlay>
    )
}

export default ShopSidePannel
