import React from 'react'

const TopMovieCard = (props: any) => {
    const { id, title, image, count } = props;
    return (
        <div
            className='hover_zoom position-relative pointer my-3'
            style={{
                height: '200px',
                width: '200px'
            }}
        >
            <div
                className='position-absolute'
                style={{
                    height: '200px',
                    width: '150px',
                    right: '0px'
                }}
            >
                <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
            </div>
            <p
                className='m-0 text-warning font_weight_600 text-center position-absolute'
                style={{
                    fontSize: '110px',
                    left: '8px',
                    zIndex: '-1'
                }}
            >
                {count + 1}
            </p>
        </div>
    )
}

export default TopMovieCard
