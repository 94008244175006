import React from 'react'
import { baseURL } from '../../utils/api'

const ProfileImageCard = (props: any) => {

    const { image } = props;

    return (
        <div
            style={{
                height: '420px',
                width: '290px'
            }}
        >
            <img src={`${baseURL}/${image}`} className="object-fit-contain" alt={image} width={'100%'} height={'100%'} />
        </div>
    )
}

export default ProfileImageCard
