import React, { useEffect, useState } from 'react';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';
import { baseURL } from '../../utils/api';

const ProductImageGallery = (props: any) => {

    const { images } = props;
    const [allImages, setAllImages] = useState([{
        original: '',
        thumbnail: ''
    }])

    const handleImage = () => {
        // console.log('images',images);
        const data = images?.map((val: any, index: number) => {
            setAllImages(prevImages => {
                const updatedImages = [...prevImages];
                updatedImages[index] = {
                    original: `${baseURL}/${val.path}`,
                    thumbnail: `${baseURL}/${val.path}`
                };
                return updatedImages;
            });
        })
    }

    // const images = [
    //     {
    //         original: '../../images/cartmodalimage.png',
    //         thumbnail: '../../images/cartmodalimage.png',
    //     },
    //     {
    //         original: '../../images/cartmodalimage.png',
    //         thumbnail: '../../images/cartmodalimage.png',
    //     },
    // ];

    useEffect(() => {
        handleImage();
    }, [images])

    return (
        <div>
            {(Array.isArray(allImages) && allImages.length > 0) &&
                <ImageGallery
                    items={allImages}
                    showPlayButton={false}
                />
            }
            {/* <img src={`${allImages[0]?.original}`} height={'200'} width={'200'} /> */}
        </div>
    )
}

export default ProductImageGallery
