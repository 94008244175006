import React from 'react';
import CommonSlider from '../../components/commonslider/CommonSlider';

const CommingSoon = () => {

    return (
        <div className='container mb-5'>
            <div className='row m-0 p-0'>
                <div className='text-white mt-3'>
                    <p className='m-0 mx-4 px-3 mb-3'>Today</p>
                    <CommonSlider />
                </div>
            </div>
            <div className='row m-0 p-0'>
                <div className='text-white mt-3'>
                    <p className='m-0 mx-4 px-3 mb-3'>This Week Release</p>
                    <CommonSlider />
                </div>
            </div>
            <div className='row m-0 p-0'>
                <div className='text-white mt-3'>
                    <p className='m-0 mx-4 px-3 mb-3'>This Week Month</p>
                    <CommonSlider />
                </div>
            </div>
        </div>
    )
}

export default CommingSoon
