import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {user: {}},
    reducers: {
        user(state, action) {
            state.user = action.payload;
        }
    }
});

const projectSlice = createSlice({
    name: 'project',
    initialState:{project:[]},
    reducers: {
        project(state, action) {
            state.project = action.payload;
        }
    }
});

// page number for pagination
const pageNumberSlice = createSlice({
    name: 'pageNumber',
    initialState:{pNumber: 1},
    reducers: {
        pageNumber(state, action) {
            state.pNumber = action.payload;
        }
    }
});

// search text on for header
const searchTextSlice = createSlice({
    name: 'searchText',
    initialState: { searchText: "" },
    reducers: {
        searchText(state, action) {
            state.searchText = action.payload;
        }
    }
})

// selected industry type 
const industryTypeSlice = createSlice({
    name: 'industryType',
    initialState: { industryType: [] },
    reducers: {
        industryType(state, action) {
            state.industryType = action.payload;
        }
    }
})

// get user location
const userLocationSlice = createSlice({
    name: 'userLocation',
    initialState: {},
    reducers: {
        userLocation(state, action) {
            state.userLocation = action.payload;
        }
    }
})

export {userLocationSlice};
export const {userLocation} = userLocationSlice.actions;
export {industryTypeSlice};
export const {industryType} = industryTypeSlice.actions;
export {searchTextSlice};
export const {searchText} = searchTextSlice.actions;
export {pageNumberSlice};
export const {pageNumber} = pageNumberSlice.actions
export {userSlice};
export const {user} = userSlice.actions
export {projectSlice};
export const {project} = projectSlice.actions