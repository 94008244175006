import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ImCross } from 'react-icons/im';
import { ToastContainer, toast } from 'react-toastify';
import { getOrderDetailsEcomAPI } from '../../utils/utils';
import { baseURL } from '../../utils/api';

const ProductDetailModal = (props: any) => {

    const { handleModalBox, id } = props;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [product_details, setProduct_detail] = useState<any>([]);

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const getDetail = async () => {
        setActive(true);
        const data = await getOrderDetailsEcomAPI(id);
        setProduct_detail(data);
        setActive(false);
    }

    useEffect(() => {
        getDetail();
    }, [])

    // useEffect(() => {
    //     console.log('order detail', product_details)
    // }, [product_details])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`lg`}
        >
            <div
                className='bg-dark pb-lg-5 pb-md-5 pb-3 p-lg-4 p-md-4 p-1'
            >
                <div
                    className='d-flex justify-content-between align-items-center'
                >
                    <p
                        className='m-0 text-warning font_weight_600 font20'
                    >
                        Order Details
                    </p>
                    <button
                        className=''
                        onClick={handlClosedModal}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <ImCross />
                    </button>
                </div>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    {Array.isArray(product_details?.order_items) && product_details?.order_items.length > 0 &&
                        product_details?.order_items.map((val: any, i: any) => {
                            return (
                                <div
                                    className='row pb-2 mt-lg-5 mt-md-5 mb-3'
                                >
                                    <div
                                        className='col-lg-3 col-md-12 col-12 d-flex justify-content-center'
                                    >
                                        <div
                                            style={{
                                                aspectRatio: '1/1',
                                                width: '150px',
                                                height: '150px'
                                            }}
                                        >
                                            <img src={`${baseURL}/${val.img}`} alt={val.inventory_name} width={'100%'} height={'100%'} />
                                        </div>
                                    </div>
                                    <div
                                        className='col-lg-9 col-md-12 col-12 d-flex justify-content-between align-items-center mt-lg-0 mt-md-3 mt-3'
                                    >
                                        <div>
                                            <p
                                                className='m-0 text-warning font16'
                                            >
                                                {val.inventory_name}
                                            </p>
                                            <p
                                                className='m-0 text-white font14 mt-1'
                                            >
                                                Sold by: <span className='text-warning'>CDBS</span>
                                            </p>
                                            <p
                                                className='m-0 text-white font14 mt-1'
                                            >
                                                Color: <span className='text-warning'>{val.color}</span>
                                            </p>
                                            <p
                                                className='m-0 text-white font14 mt-1'
                                            >
                                                Size: <span className='text-warning'>{val.size}</span>
                                            </p>

                                            <p
                                                className='m-0 text-white font14 mt-1'
                                            >
                                                Quantity: <span className='text-warning'>{val.quantity}</span>
                                            </p>
                                        </div>
                                        <div
                                            className='d-flex justify-content-between align-items-center'
                                        >
                                            <p
                                                className='m-0 text-white font14 me-5'
                                            >
                                                Price: <span className='text-warning'>{val.unit_price}</span>
                                            </p>
                                            <p
                                                className='m-0 text-white font14'
                                            >
                                                Total: <span className='text-warning'>{val.total}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div
                        className='d-flex justify-content-between align-items-center text-warning mt-4'
                    >
                        <p
                            className='m-0 font_weight_600 font20'
                        >
                            TOTAL
                        </p>
                        <p
                            className='m-0 font_weight_600 font20'
                        >
                            &#x20B9; {product_details?.grand_total}
                        </p>
                    </div>
                    <p
                        className='text-white m-0 font14 mt-2'
                    >
                        <i>Tax included. Shipping calculated at checkout.</i>
                    </p>

                    <div
                        className=''
                    >
                        <p
                            className='m-0 text-warning font_weight_600 font18 mb-2 mt-4'
                        >
                            Shipping Detail
                        </p>

                        <p
                            className='m-0 text-warning font16 text-capitalize'
                        >
                            Name: <span className='text-white'>{product_details?.billing_address?.first_name} {product_details?.billing_address?.last_name}</span>
                        </p>
                        <p
                            className='m-0 text-warning font16 text-capitalize'
                        >
                            Address: <span className='text-white'>
                            {product_details?.billing_address?.address}&nbsp;
                                    {product_details?.billing_address?.street}&nbsp;
                                    {product_details?.billing_address?.landmark}
                            </span>
                        </p>
                        <p
                            className='m-0 text-warning font16 text-capitalize'
                        >
                            City: <span className='text-white'>{product_details?.billing_address?.city}</span>
                        </p>
                        <p
                            className='m-0 text-warning font16 text-capitalize'
                        >
                            State: <span className='text-white'>{product_details?.billing_address?.state}</span>
                        </p>
                        <p
                            className='m-0 text-warning font16 text-capitalize'
                        >
                            Zip code: <span className='text-white'>{product_details?.billing_address?.zip_code}</span>
                        </p>
                    </div>

                    {/* <div
                        className='mt-lg-5 mt-md-5 mt-3 d-flex justify-content-center align-items-center'
                    >
                        <button
                            className='by_now_button font_weight_600 me-lg-5 me-md-5 me-3'
                        >
                            View Cart
                        </button>
                        <button
                            className='add_to_cart_button font_weight_600 me-lg-5 me-md-5 me-0'
                        >
                            Buy Now
                        </button>
                    </div> */}
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProductDetailModal
