import React, { useState, useEffect } from 'react';
import './shopcss.css';
import { PiCaretDownBold } from 'react-icons/pi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getEcomCategoriesAPI } from '../../utils/utils';
import { RxCross2 } from 'react-icons/rx';

const ShopMobileMenu = (props: any) => {
    const { onProductSelect, onShowModal, showVal } = props;
    // const [isVisible, setIsVisible] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
    const [isActive, setActive] = useState(false);
    const [categories, setCategories] = useState<any>([]);

    const getCategory = async () => {
        setActive(true);
        const data = await getEcomCategoriesAPI();
        setCategories(data);
        setActive(false);
    }

    const handleCategoryClick = (categoryId: string) => {
        setSelectedCategory(selectedCategory === categoryId ? null : categoryId);
    };

    const handleSubCategoryClick = (subCategoryId: string) => {
        onProductSelect(subCategoryId);
        setSelectedSubCategory(selectedSubCategory === subCategoryId ? null : subCategoryId);
        onShowModal();
    };

    const handleClose = () => {
        onShowModal();
    };

    useEffect(() => {
        getCategory();
    }, [])


    return (
        <div className={`popup-sidebar ${showVal ? 'visible' : 'hidden'}`}>
            <div className="content">
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="side-panel mt-4">
                        <div
                            className='d-flex justify-content-between align-items-center mb-4'
                        >
                            <h3 className='m-0 text-warning'>Products</h3>
                            <RxCross2 onClick={handleClose}
                                className='pointer text-white'
                                style={{
                                    backgroundColor: '#3a3a3c',
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%'
                                }}
                            />
                        </div>
                        <ul>
                            {(Array.isArray(categories) && categories.length > 0) && categories.map((category: any) => (
                                <li
                                    key={category.id}
                                    className={`${selectedCategory !== category.id ? 'side_pannel_bg' : ''}`}
                                >
                                    <div
                                        className={`d-flex justify-content-between align-items-center py-2 px-2`}
                                        onClick={() => handleCategoryClick(category.id)}
                                    >
                                        <strong>{category.category_name}</strong>
                                        <span>
                                            <PiCaretDownBold />
                                        </span>
                                    </div>
                                    {selectedCategory === category.id && (
                                        <ul>
                                            {(Array.isArray(category.subcategory) && category.subcategory.length > 0) && category.subcategory.map((subcategory: any) => (
                                                <li
                                                    key={subcategory.id}
                                                    onClick={() => handleSubCategoryClick(subcategory.id)}
                                                    className={`font14 sub_category_bg_color ${selectedSubCategory === subcategory.id ? 'sub_category_bg_text_color' : ''}`}
                                                >
                                                    {subcategory.subcategory_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </LoadingOverlay>
            </div>
        </div>
    )
}

export default ShopMobileMenu
