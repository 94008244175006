import React, { useState, useEffect } from 'react';
import './Signinmodal.css';
import { baseURL } from '../../utils/api';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { user } from '../../states/reducers';
import LoginModal from '../modalbox/LoginModal';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import GoogleLoginAuth from '../../pages/auth/googlelogin/GoogleLoginAuth';
import ProfessionForm from './ProfessionForm';
import FacebookLoginAuth from '../../pages/auth/facebooklogin/FacebookLoginAuth';
import IndustryForm from './IndustryForm';
import LoadingOverlay from 'react-loading-overlay-ts';

const SignInModal = (props: any) => {

    const { handleModalBox, redirectPath } = props;
    LoadingOverlay.propTypes = undefined;
    const routePath = redirectPath || '/';
    const userData = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    const [data, setData] = useState<number>(0);
    const messageTxt = (message: any) => toast(message);
    const navigate = useNavigate();
    const location = useLocation();
    const [register, setRegister] = useState({ number: '', otp: '', dob: '', name: '', subscriber_type: data });
    const [userLogin, setUserLogin] = useState({ number: '', otp: '' });
    const connection_id = localStorage.getItem('connection_id');
    const [tc, setTc] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [show, setShow] = useState(true);
    const [disabledOtp, setDisabledOtp] = useState(false);
    const [openProfessionModal, setOpenProfessionModal] = useState(false);
    const [isActive, setActive] = useState(false);

    const handlClosedModal = () => {
        handleModalBox(false);
        setShow(false);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        if (name === 'tc') {
            setTc(checked);
        }
        if (data === 1 || data === 2) {
            setRegister(prevRegister => ({
                ...prevRegister,
                [name]: value,
                subscriber_type: data,
            }));
        } else {
            setUserLogin(prevUserLogin => ({
                ...prevUserLogin,
                [name]: value,
            }));
        }
    };

    let body = {
        phone: parseInt(register.number) || parseInt(userLogin.number),
        connection_id: `${connection_id}`,
    };

    const handleGetOtp = async () => {
        setDisabledOtp(true);
        const { number } = register;
        if (number.length > 10 || number.length < 10) {
            messageTxt('Please enter 10 digit number !');
            setDisabledOtp(false);
        } else {
            await axios(`${baseURL}/api/get-otp`, {
                method: "post",
                data: body,
            })
                .then((res) => {
                    // console.log(res.data.status);
                    messageTxt(res.data.message);
                    setDisabledOtp(false);
                })
                .catch((err) => {
                    console.log("Error:", err);
                });
        }
    }

    const handleRegister = async (e: any) => {
        e.preventDefault();
        setActive(true);

        const { number, otp, dob, subscriber_type, name } = register;

        if (!number || !otp || !dob || !subscriber_type || !name) {
            messageTxt('Please fill all fields')
            setActive(false);
        } else if (number.length > 10 || number.length < 10) {
            messageTxt('Please enter 10 digit number !')
            setActive(false)
        } else if (!tc) {
            messageTxt('please check terms and conditions')
            setActive(false)
        } else {
            let body = {
                connection_id: `${connection_id}`,
                phone: parseInt(register.number),
                otp,
                subscriber_type,
                dob,
                name
            };
            const { data } = await axios(`${baseURL}/api/verify-register-otp`, {
                method: "post",
                data: body,
            })
            // console.log(data.message);
            setActive(false);
            if (data.status === 'error') {
                messageTxt(data.message);
            }
            if (data.status === 'success') {
                messageTxt(data.message);
                setActive(false);
                dispatch(user(data.data.user_detail))
                localStorage.setItem("auth_code", data.data.auth_code);
                navigate('/');
                if (data.data.user_detail.subscriber_type_id === 2) {
                    setOpenProfessionModal(false);
                    handlClosedModal();
                    window.location.reload();
                } else {
                    setOpenProfessionModal(true);
                }
                // handlClosedModal();
            }
        }
    }

    const handleLogin = async () => {
        setActive(true);
        const { number, otp } = userLogin;

        if (!number || !otp) {
            messageTxt('Please fill all fields')
            setActive(false);
        } else if (number.length > 10 || number.length < 10) {
            messageTxt('Please enter 10 digit number !')
            setActive(false);
        } else if (!tc) {
            messageTxt('please check terms and conditions')
            setActive(false)
        } else {
            let body = {
                connection_id: `${connection_id}`,
                phone: parseInt(number),
                otp
            };
            const { data } = await axios(`${baseURL}/api/verify-otp`, {
                method: "post",
                data: body,
            })

            messageTxt(data.message);
            setActive(false);

            if (data.status === 'error') {
                messageTxt(data.message);
                setActive(false)
            }
            if (data.status === 'success') {
                window.location.reload();
                setActive(false)
                messageTxt(data.message);
                dispatch(user(data.data.user_detail))
                localStorage.setItem("auth_code", data.data.auth_code);
                navigate(routePath);
                handlClosedModal();
            }
        }

    }

    const handleLoginModalBox = (val: any) => {
        if (val) {
            setUserLogin({ number: '', otp: '' });
        }
        setData(val);
        val ? setOpenLogin(false) : setOpenLogin(false);
    }

    useEffect(() => {
        // console.log('login', userLogin);
        // console.log('register',register);
        // console.log(data);
        // console.log('redirectPath', redirectPath);
    }, [userLogin, register, userData, data])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`${openProfessionModal ? '' : 'd-flex'} justify-content-center align-items-center opacity-75`}
            // fullscreen={true}
            size={`${openProfessionModal ? 'xl' : 'lg'}`}
        >
            <div
                className='text-end pe-2'
            >
                {openProfessionModal ?
                    null
                    :
                    <button
                        className=''
                        onClick={handlClosedModal}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <ImCross />
                    </button>
                }
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-4`}>

                {!openProfessionModal ?
                    <>
                        {openLogin ?
                            <div
                                className='d-grid justify-content-center'
                            >
                                <div
                                    className='mb-2 px-2'
                                    style={{ width: '350px', height: '250px' }}
                                    onClick={() => handleLoginModalBox(1)}
                                >
                                    <img alt='film fraternity' src='../images/loginselection1.png' className='object-fit-contain' width={'100%'} height={'100%'} />
                                </div>
                                <div
                                    style={{ width: '350px', height: '250px' }}
                                    onClick={() => handleLoginModalBox(2)}
                                >
                                    <img alt='cinema fan' src='../images/loginselection2.png' className='object-fit-contain' width={'100%'} height={'100%'} />
                                </div>
                            </div>
                            :
                            <>

                                <div className='pb-lg-0 pb-md-0 pb-3 p-0'>
                                    <div className='login_form'>
                                        <h3 className='mb-2 text-warning'>{data !== 0 ? 'Register Now' : 'Login Now'}</h3>
                                        <div className='mb-4'>
                                            <p>
                                                <span className='text-white'>{data !== 0 ? 'Have already account? ' : 'New Customer?'}</span>
                                                <span className='text-danger ps-2'>
                                                    {data !== 0 ?
                                                        <span className='pointer' onClick={() => setData(0)}>
                                                            Login
                                                        </span>
                                                        :
                                                        <span className='pointer' onClick={() => setOpenLogin(true)}>
                                                            Register
                                                        </span>
                                                    }

                                                </span>
                                            </p>
                                        </div>
                                        <Form onSubmit={handleRegister}>
                                            <LoadingOverlay
                                                active={isActive}
                                                spinner
                                                text='Loading your content...'
                                            >
                                                <div className='login_box_otp mb-3'>
                                                    <input type='number' name='number' value={register.number || userLogin.number} onChange={handleChange} placeholder='Mobile Number' />
                                                    {disabledOtp ?
                                                        <span
                                                            className='fw-bold fs-6'
                                                            style={{
                                                                color: 'red',
                                                                fontSize: '12px',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            Loading...
                                                        </span>
                                                        :
                                                        <span
                                                            className='fw-bold fs-6'
                                                            style={{
                                                                color: 'red',
                                                                fontSize: '12px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={handleGetOtp}
                                                        >
                                                            Get OTP
                                                        </span>
                                                    }
                                                </div>
                                                <div className='login_box_otp'>
                                                    <input type='number' name='otp' value={register.otp || userLogin.otp} onChange={handleChange} placeholder='Enter OTP' />
                                                </div>
                                                {data !== 0 ?
                                                    <>
                                                        <div className='login_box_otp my-3'>
                                                            <input type='text' name='name' value={register.name} placeholder='Enter your name' onChange={handleChange} />
                                                        </div>
                                                        <label htmlFor="movieTitle" className="m-0">DOB</label>
                                                        <div className='login_box_otp'>
                                                            <input type='date' name='dob' value={register.dob} onChange={handleChange} />
                                                        </div>
                                                    </>
                                                    : ''
                                                }

                                                <div className="form-check mt-2">
                                                    <input className="form-check-input" onChange={handleChange} type="checkbox" name='tc' id="flexCheckDefault" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        <Link to='/terms&conditions' target='_blank' className='text-danger text-decoration-none font14'>
                                                            Terms & Conditions
                                                        </Link>
                                                        <span className='mx-1 text-white font14'>and</span>
                                                        <Link to='/Privacy_policy' target='_blank' className='text-danger text-decoration-none font14'>
                                                            Privacy Policy.
                                                        </Link>
                                                    </label>
                                                </div>
                                                {data !== 0 ?
                                                    <button
                                                        className='py-1 fw-bold text-white mt-3 py-2 rounded'
                                                        style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                                        type="submit"
                                                    >
                                                        Verify
                                                    </button>
                                                    :
                                                    <button
                                                        className='py-1 fw-bold text-white mt-3 py-2 rounded'
                                                        style={{ width: '100%', backgroundColor: 'red', border: 'none' }}
                                                        type="button"
                                                        onClick={handleLogin}
                                                    >
                                                        Verify
                                                    </button>
                                                }

                                                <div className='d-flex justify-content-center align-items-center my-4'>
                                                    <div
                                                        style={{
                                                            border: '1px solid black',
                                                            width: '100%',
                                                        }}
                                                    ></div>
                                                    <span className='mx-2 fw-bold fs-5 text-white'>or</span>
                                                    <div
                                                        style={{
                                                            border: '1px solid black',
                                                            width: '100%'
                                                        }}
                                                    ></div>
                                                </div>

                                                <div
                                                    className='text-center mt-4'
                                                >
                                                    <div>
                                                        <GoogleLoginAuth />
                                                        {/* <img src='../images/loginemail.png' alt='gpay' className='rounded-circle' width={'100%'} height={'100%'} /> */}
                                                    </div>
                                                    <div className='mt-2'>
                                                        <FacebookLoginAuth />
                                                        {/* <img src='../images/loginfb.png' alt='phonepay' className='rounded-circle' width={'100%'} height={'100%'} /> */}
                                                    </div>
                                                    {/* <div
                                            style={{
                                                width: '50px',
                                                height: '50px'
                                            }}
                                        >
                                            <img src='../images/logininsta.png' alt='paytm' className='rounded-circle' width={'100%'} height={'100%'} />
                                        </div>
                                        <div
                                            style={{
                                                width: '50px',
                                                height: '50px'
                                            }}
                                        >
                                            <img src='../images/logintw.png' alt='paytm' className='rounded-circle' width={'100%'} height={'100%'} />
                                        </div> */}
                                                </div>
                                            </LoadingOverlay>
                                        </Form>
                                    </div>
                                </div>

                                {/* <div className='m-0 p-0 col-lg-6 col-md-6 col-12 bg-secondary-subtle d-flex justify-content-center align-items-center'>
                                <div>
                                    <img src='../images/loginimage.png' alt='login' width={'100%'} height={'100%'} />
                                </div>
                            </div> */}

                            </>
                        }
                    </>
                    :
                    // <ProfessionForm onCloseModal={handlClosedModal} />
                    <IndustryForm onCloseModal={handlClosedModal} />
                }
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default SignInModal
