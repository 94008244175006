import React, { useState, useEffect } from 'react';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const PropertyAssetModal = (props: any) => {

    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [assetId, setAssetId] = useState<any>();
    const [assets, setAssets] = useState<any>({
        property_type: '',
        name: '',
        image: '',
        location: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const asset_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setAssets((prevVal: any) => ({ ...prevVal, image: asset_image }));
        } else {
            setAssets({ ...assets, [name]: value });
        }
    };

    const handleWhoAssetsUpdate = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { property_type, name, location, image } = assets;
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    property_type,
                    name,
                    location,
                    image,
                    asset_id: assetId
                }
                let result = await axios(`${baseURL}/api/subscriber/assets/addUpdate`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setAssets({
                    property_type: '',
                    name: '',
                    image: '',
                    location: ''
                });
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const getData = () => {
        setActive(true);
        if (data) {
            setAssetId(data.id);
            setAssets(data);
        }
        setActive(false);
    }

    useEffect(() => {
        getData();
    }, [data])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row mt-5 p-0 d-flex justify-content-between align-items-center modal_form_add_update">
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Properties/Assets</label>
                            <select className="form-select mb-3" onChange={handleChange} value={assets.property_type} name='property_type' aria-label="Default select example">
                                <option selected>Select your assets..</option>
                                <option value="Home">Home</option>
                                <option value="Farm House">Farm House</option>
                                <option value="Farm Land">Farm Land</option>
                                <option value="Beach House">Beach House</option>
                                <option value="Car">Car</option>
                                <option value="Aircraft">Aircraft</option>
                            </select>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                            <input type="text" name='name' value={assets.name} onChange={handleChange} className="form-control mb-3" placeholder='eg: Mannat, Swarg etc.' />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="movieTitle" className="form-label text-white m-0">Location</label>
                            <input type="text" name='location' value={assets.location} onChange={handleChange} placeholder='eg: Mumbai, Delhi etc.' className="form-control mb-3" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Controversy image</label>
                            <input type="file" onChange={handleChange} name='image' className="form-control mb-3" />
                        </div>
                    </div>

                    <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                        <button
                            className='py-2 px-2 bg-danger rounded fw-bold'
                            style={{
                                width: '150px'
                            }}
                            onClick={handleWhoAssetsUpdate}
                        >
                            Save
                        </button>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default PropertyAssetModal
