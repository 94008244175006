import React, { useEffect, useState } from 'react';
import './festivalform.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import axios from 'axios';

const BasicDetailForm = (props: any) => {

    const { festival_data, festival_id } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [createFestival, setCreateFestival] = useState<any>({
        name: '',
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        address: '',
        latitude: '',
        longitude: '',
        location_link: '',
        chairman: '',
        about_us: '',
        last_date_to_apply: '',
        entry_status: '',
        ticket_price: '',
        city: '',
        banner_image: ''
    });

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'banner_image') {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setCreateFestival({ ...createFestival, banner_image: file })
        } else {
            setCreateFestival({ ...createFestival, [name]: value });
        }
    }

    const handleSubmitFestival = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        const { name, start_date, end_date, start_time, end_time, address, latitude, longitude, location_link, chairman, about_us, last_date_to_apply,
            entry_status, ticket_price, city, banner_image } = createFestival;

        if (connection_id && auth_code) {
            if (!name || !start_date || !end_date || !address || !start_time || !ticket_price || !last_date_to_apply || !entry_status) {
                messageTxt('Please Fill Mandatory Fields...');
                setActive(false);
            } else {
                try {
                    let body = {
                        connection_id,
                        auth_code,
                        id: festival_id,
                        name, start_date, end_date, start_time, end_time, address, latitude, longitude, location_link, chairman, about_us, last_date_to_apply,
                        entry_status, ticket_price, city, banner_image
                    }
                    let result = await axios(`${baseURL}/api/film_festival/update`, {
                        method: 'POST',
                        data: body,
                        headers: { "Content-Type": "multipart/form-data" }
                    });
                    messageTxt(result.data.message);
                    setActive(false);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    useEffect(() => {
        setCreateFestival((prevFestival: any) => ({
            ...prevFestival,
            ...festival_data
        }));
    }, [festival_data]);

    // useEffect(()=> {
    //     console.log(festival_id)
    // },[createFestival])

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <div className="accordion accordion-flush mt-4" id="awardForm">
                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded border text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseawardForm" aria-expanded="false" aria-controls="flush-collapseawardForm">
                                Basic detail
                            </button>
                        </h2>
                        <div id="flush-collapseawardForm" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#awardForm">
                            <div className="accordion-body border mb-5 awards_form theme_color">
                                <div className='theme_color py-3 px-5'>
                                    <form className='create_event_form' onSubmit={handleSubmitFestival}>
                                        <div className="row p-0 d-flex justify-content-between align-items-center">
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="title" className="form-label text-white m-0">Film festival name</label>
                                                <input type="text" onChange={handleChangeValue} name='name' value={createFestival.name} className="form-control mb-3" disabled={isDisabled} />
                                                <label htmlFor="eventmode" className="form-label text-white m-0">End date</label>
                                                <input type="date" onChange={handleChangeValue} name='end_date' value={createFestival.end_date} className="form-control mb-3" disabled={isDisabled} placeholder='Online/Offline' />
                                                <label htmlFor="startdate" className="form-label text-white m-0">End time</label>
                                                <input type="time" onChange={handleChangeValue} value={createFestival.end_time} name='end_time' className="form-control mb-3" disabled={isDisabled} />
                                                {/* <label htmlFor="latitude" className="form-label text-white m-0">Latitude</label>
                                <input type="text" onChange={handleChangeValue} value={createFestival.latitude} name='latitude' className="form-control mb-3" disabled={isDisabled} /> */}
                                                <label htmlFor="movieTitle" className="form-label text-white m-0">Location link</label>
                                                <input type="text" onChange={handleChangeValue} value={createFestival.location_link} name='location_link' className="form-control mb-3" disabled={isDisabled} />
                                                <label htmlFor="last_date_to_apply" className="form-label text-white m-0">Last date to apply</label>
                                                <input type="date" onChange={handleChangeValue} value={createFestival.last_date_to_apply} name='last_date_to_apply' className="form-control mb-3" disabled={isDisabled} />
                                                <label htmlFor="ticket_price" className="form-label text-white m-0">Ticket price</label>
                                                <input type="number" onChange={handleChangeValue} value={createFestival.ticket_price} name='ticket_price' className="form-control mb-3" disabled={isDisabled} step="any" />
                                                <label htmlFor="about_us" className="form-label text-white m-0">About us</label>
                                                <textarea onChange={handleChangeValue} value={createFestival.about_us} name='about_us' className="form-control mb-3" disabled={isDisabled}></textarea>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <label htmlFor="enddate" className="form-label text-white m-0">Start date</label>
                                                <input type="date" onChange={handleChangeValue} value={createFestival.start_date} name='start_date' className="form-control mb-3" disabled={isDisabled} />
                                                <label htmlFor="enddate" className="form-label text-white m-0">Start time</label>
                                                <input type="time" onChange={handleChangeValue} value={createFestival.start_time} name='start_time' className="form-control mb-3" disabled={isDisabled} />
                                                <label htmlFor="address" className="form-label text-white m-0">Address</label>
                                                <input type="text" onChange={handleChangeValue} value={createFestival.address} name='address' className="form-control mb-3" disabled={isDisabled} />
                                                <label htmlFor="city" className="form-label text-white m-0">City</label>
                                                <input type="text" onChange={handleChangeValue} value={createFestival.city} name='city' className="form-control mb-3" disabled={isDisabled} />
                                                {/* <label htmlFor="longitude" className="form-label text-white m-0">Longitude</label>
                                <input type="text" onChange={handleChangeValue} value={createFestival.longitude} name='longitude' className="form-control mb-3" disabled={isDisabled} /> */}
                                                <label htmlFor="chairman" className="form-label text-white m-0">Chairman</label>
                                                <input type="text" onChange={handleChangeValue} value={createFestival.chairman} name='chairman' className="form-control mb-3" disabled={isDisabled} />

                                                <label htmlFor="entry_status" className="form-label text-white m-0">Entry status</label>
                                                <select className="form-select form-select-lg mb-3" onChange={handleChangeValue} value={createFestival.entry_status} name='entry_status' aria-label="Large select example1" disabled={isDisabled}>
                                                    <option selected>Select status...</option>
                                                    <option value={1}>Open</option>
                                                    <option value={2}>Close</option>
                                                </select>
                                                <label htmlFor="banner_image" className="form-label text-white m-0">Banner image</label>
                                                <input type="file" onChange={handleChangeValue} name='banner_image' className="form-control mb-3" disabled={isDisabled} />
                                            </div>

                                            <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                                <button
                                                    className='py-2 px-2 border me-3 rounded fw-bold'
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        color: 'white',
                                                        width: '150px'
                                                    }}
                                                    onClick={handleEdit}
                                                >
                                                    {!isDisabled ? 'Cancle' : 'Edit'}
                                                </button>
                                                {!isDisabled ?
                                                    <button
                                                        className='py-2 px-2 border-0 rounded fw-bold'
                                                        style={{
                                                            backgroundColor: 'red',
                                                            color: 'white',
                                                            width: '150px'
                                                        }}
                                                        type='submit'
                                                    >
                                                        Save
                                                    </button>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </LoadingOverlay>
            <ToastContainer />
        </>
    )
}

export default BasicDetailForm
