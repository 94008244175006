import React, { useEffect, useRef, useState } from 'react';
import './header.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegBell } from "react-icons/fa";
import { GiHamburgerMenu, GiFilmProjector } from "react-icons/gi";
import { PiCaretDownBold, PiCaretRightBold } from "react-icons/pi";
import { menu, filmIndustry, filmUpdate, fraternityUser, cinemaFans, languages } from '../../utils/constaints';
import LogoutModal from '../modalbox/LogoutModal';
import LoginModal from '../modalbox/LoginModal';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { industryType, project, user, userLocation } from '../../states/reducers';
import SignInModal from '../signinmodal/SignInModal';
import { getAllIndustryTypeAPI, getSearchProfileAPI } from '../../utils/utils';
import { FaSearch } from "react-icons/fa";
import MultiIndustryModal from '../multiindustrymodal/MultiIndustryModal';
import { removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';

const Header = () => {

    const navigate = useNavigate();
    const multiIndustryVal = localStorage.getItem('multiindustry');
    const profile_data = useSelector((state: any) => state.user.user);
    const [industry_name, setIndustryName] = useState<any>();
    const [auth, setAuth] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    const [openFilmUpdate, setOpenFilmUpdate] = useState(false);
    const [openIndustry, setOpenIndustry] = useState(false);
    const [openIndustryModalMobileView, setOpenIndustryModalMobileView] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openProfileMobile, setOpenProfileMobile] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const menuRef = useRef<any>(null);
    const mobilemenuRef = useRef<any>(null);
    const [openIndustryModal, setOpenIndustryModal] = useState(false);
    const mobileProfileRef = useRef<any>(null);
    const [searchText, setSearchText] = useState<any>({
        textSearch: '',
        select_for_search: 'all'
    });
    const [searchResult, setSearchResult] = useState<any>();
    const [searchWait, setSearchwait] = useState(false);
    const [openMobileSearch, setOpenMobileSearch] = useState(false);

    // get user details
    const fetchUserData = async () => {
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code
                }
                let result = await axios.post(`${baseURL}/api/subscriber/details`, body);
                // console.log('reducer', result.data.data);
                dispatch(user(result.data.data));
            } catch (error) {
                // Handle errors here
            }
        }
    };

    // get industry type
    const getIndustryData = async () => {
        const data = await getAllIndustryTypeAPI();
        const res = data?.filter((val: any) => {
            return val.id === Number(multiIndustryVal) ? val.title : '';
        });
        setIndustryName(res);
        dispatch(industryType(res));
    }

    // get user location
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch(userLocation({ latitude, longitude }));
                },
                (error) => {
                    console.error('Error getting geolocation:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleSearchText = (e: any) => {
        const { name, value } = e.target;
        setSearchText({ ...searchText, [name]: value });
    }

    const getSearchData = async () => {
        setSearchwait(true);
        if (searchText.textSearch !== '') {
            const data = await getSearchProfileAPI(searchText);
            setSearchResult(data);
            setSearchwait(false);
        } else {
            setSearchResult('');
        }
    }

    const handleModalBox = (val: any) => {
        val ? setOpenModal(false) : setOpenModal(false);
        val ? setOpenIndustryModal(false) : setOpenIndustryModal(false);
        val ? setOpenIndustryModalMobileView(false) : setOpenIndustryModalMobileView(false)
    }

    const handleLoginModalBox = (val: any) => {
        val ? setOpenLogin(false) : setOpenLogin(false);
    }

    useEffect(() => {
        if (Object.keys(profile_data).length === 0) {
            fetchUserData();
            getIndustryData();
        }
        localStorage.getItem('auth_code') ? setAuth(true) : setAuth(false);
        getLocation();
        // console.log('header',projects);
    }, [auth, profile_data])

    useEffect(() => {
        setSearchwait(true);
        const timeOut = setTimeout(() => {
            getSearchData();
        }, 1000);
        return () => clearTimeout(timeOut);
    }, [searchText])

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if ((openIndustry || openMenu || openFilmUpdate || openProfile || openMenuMobile) && menuRef.current && !menuRef.current.contains(e.target) ||
                mobilemenuRef.current && !mobilemenuRef.current.contains(e.target) || mobileProfileRef.current && !mobileProfileRef.current.contains(e.target)) {
                setOpenIndustry(false);
                setOpenMenu(false);
                setOpenMenuMobile(false);
                setOpenFilmUpdate(false);
                setOpenProfile(false);
                setOpenProfileMobile(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [openIndustry, openMenu, openFilmUpdate, openProfile])

    const goNotification = () => {
        if (!auth) {
            setOpenLogin(true);
        } else {
            navigate('/notification');
        }
    }

    // useEffect(() => {
    //     console.log('aaaa',searchResult);
    // }, [searchResult])

    return (
        <>
            {openLogin ?
                <SignInModal handleModalBox={handleLoginModalBox} />
                : null
            }
            {openModal ?
                <LogoutModal handleModalBox={handleModalBox} />
                : null
            }
            {openIndustryModal ?
                <MultiIndustryModal handleModalBox={handleModalBox} crossButton={true} />
                : null
            }
            {openIndustryModalMobileView ?
                <MultiIndustryModal handleModalBox={handleModalBox} crossButton={true} />
                : null
            }
            <nav className="navbar navbar-expand-lg navbar-dark px-lg-5 px-md-5 px-2">
                {/* <div className="container"> */}
                <Link className="navbar-brand ps-lg-3 ps-md-3 ps-0" to={'/'}>
                    <img src='/images/logo.png' alt='logo' width='auto' height='50' />
                </Link>
                <div className='text-white d-lg-none d-md-none fs-3' data-bs-target="#mobalNavebarSearch" data-bs-toggle="modal" aria-expanded="false">
                    <FaSearch />
                </div>
                <div
                    className='d-flex align-items-center slign-items-center d-lg-none d-md-flex d-none'
                >
                    <select className="form-select border-0 border-end" aria-label="Default select example"
                        style={{
                            height: '40px', width: '8rem',
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px'
                        }}
                        name='select_for_search'
                        onChange={handleSearchText}
                        value={searchText.select_for_search}
                    >
                        <option className='text-white' value="all">All</option>
                        <option className='text-white' value="movie">Movie</option>
                        <option className='text-white' value="artist">Artist</option>
                        <option className='text-white' value="festivl">Film Festival</option>
                        <option className='text-white' value="event">Event</option>

                    </select>
                    <div className='searchBox w-100 position-relative'>
                        <input type='text' placeholder='search' onChange={handleSearchText} value={searchText.textSearch} name='textSearch' />
                        <BiSearchAlt className='searchIcon' />
                        {searchText.textSearch !== '' && searchWait ?
                            <div
                                className='searchsuggestion position-absolute bg-dark text-white w-100 font20'
                            >
                                <h5 className='text-white ps-2 py-2'>Loading...</h5>
                            </div>
                            :
                            <>
                                {searchText && Array.isArray(searchResult) ?
                                    <div
                                        className='searchsuggestion position-absolute bg-dark text-white w-100 font20'
                                        onClick={() => setSearchText({ ...searchText, textSearch: '' })}
                                    >
                                        {searchText.select_for_search !== 'all' ?
                                            <>
                                                {Array.isArray(searchResult) &&
                                                    searchResult?.map((val: any) => {
                                                        return (
                                                            <>
                                                                {val.type === 0 ?
                                                                    <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white'
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {Array.isArray(searchResult) &&
                                                    searchResult?.map((val: any) => {
                                                        return (
                                                            <>
                                                                {val.type !== 1 ?
                                                                    <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        }
                                    </div>
                                    : null
                                }
                            </>
                        }
                    </div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-target="#mobalNavebar" data-bs-toggle="modal" aria-expanded="false">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                    <span className="navbar-toggler-icon"></span>
                </button> */}

                {/* large screen search bar */}
                <div
                    className='w-100 ms-2 d-none d-lg-block d-xl-block d-lg-flex d-xl-flex'
                >
                    <select className="form-select border-0 border-end" aria-label="Default select example"
                        style={{
                            height: '40px', width: '8rem',
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px'
                        }}
                        name='select_for_search'
                        onChange={handleSearchText}
                        value={searchText.select_for_search}
                    >
                        <option className='text-white' value="all">All</option>
                        <option className='text-white' value="movie">Movie</option>
                        <option className='text-white' value="artist">Artist</option>
                        <option className='text-white' value="festival">Film Festival</option>
                        <option className='text-white' value="event">Event</option>
                    </select>
                    <div className='searchBox w-100 position-relative'>
                        <input type='text' placeholder='search' onChange={handleSearchText} value={searchText.textSearch} name='textSearch' />
                        <BiSearchAlt className='searchIcon' />
                        {searchText.textSearch !== '' && searchWait ?
                            <div
                                className='searchsuggestion position-absolute bg-dark text-white w-100 font20'
                            >
                                <h5 className='text-white ps-2 py-2'>Loading...</h5>
                            </div>
                            :
                            <>
                                {searchText && Array.isArray(searchResult) ?
                                    <div
                                        className='searchsuggestion position-absolute bg-dark text-white w-100 font20'
                                        onClick={() => setSearchText({ ...searchText, textSearch: '' })}
                                    >
                                        {searchText.select_for_search !== 'all' ?
                                            <>
                                                {Array.isArray(searchResult) &&
                                                    searchResult?.map((val: any) => {
                                                        return (
                                                            <>
                                                                {val.type === 0 ?
                                                                    <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {/* {val?.first_name} {val?.last_name}
                                                                                        {val?.first_name || val?.last_name ? `(${val?.name})` : val?.name} */}
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {Array.isArray(searchResult) &&
                                                    searchResult?.map((val: any) => {
                                                        return (
                                                            <>
                                                                {val.type !== 1 ?
                                                                    <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {/* {val?.first_name} {val?.last_name}
                                                                                        {val?.first_name || val?.last_name ? `(${val?.name})` : val?.name} */}
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                        <div
                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                        >
                                                                            <p
                                                                                className='m-0'
                                                                            >
                                                                                {val.title}
                                                                            </p>
                                                                            <span>
                                                                                <BiSearchAlt />
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        }
                                    </div>
                                    : null
                                }
                            </>
                        }
                    </div>
                </div>

                <div className="collapse navbar-collapse me-4 pe-3 dropdown-menu-end" id="">
                    <ul className="navbar-nav mb-2 mb-lg-0 d-flex align-items-center dropdown-menu-end me-4">
                        <li style={{ minWidth: 'fit-content' }} className="nav-item text-white ms-lg-4 position-relative" onClick={() => setOpenIndustryModal(!openIndustryModal)}>
                            {(Array.isArray(industry_name) && industry_name.length > 0) && industry_name[0]?.title ?
                                <div
                                    className='pointer'
                                >
                                    <span>
                                        {industry_name[0]?.title}
                                    </span>
                                    <span className='ms-2'>
                                        <PiCaretDownBold />
                                    </span>
                                </div>
                                : null
                            }
                        </li>
                        <li style={{ minWidth: 'fit-content' }} className="nav-item text-white ms-lg-4 position-relative" onClick={() => setOpenMenu(!openMenu)}>
                            <span className='d-flex align-items-center pointer'>
                                <span className='ps-1 font16'>Menu</span>
                                <span className='ms-2'>
                                    <PiCaretDownBold />
                                </span>
                            </span>
                            {openMenu && (
                                <div className="text-white bg-dark menuBox" ref={menuRef}>
                                    <ul className="p-2">
                                        {
                                            menu.map((menuItem) => {
                                                return (
                                                    // <li
                                                    //     key={menuItem}
                                                    //     className="menuList text-capitalize my-1 font14"
                                                    //     onClick={() => setOpenMenu(false)}
                                                    // >
                                                    //     {menuItem}
                                                    // </li>
                                                    <NavLink to={`/menu/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList text-decoration-none">
                                                        <Typography
                                                            className="text-white lh-lg"
                                                            variant="overline"
                                                            gutterBottom
                                                            component="li"
                                                            style={{ textTransform: 'none', fontSize: '14px' }}
                                                        >
                                                            <span>{menuItem}</span>
                                                        </Typography>
                                                    </NavLink>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                            }
                        </li>

                        <li style={{ minWidth: 'fit-content' }} className="nav-item text-white ms-lg-4 position-relative" onClick={() => setOpenIndustry(!openIndustry)}>
                            <span className='pointer'>
                                <span className='pe-2 font16'>EN</span>
                                <PiCaretDownBold />
                            </span>
                            {openIndustry && (
                                <div className="text-white bg-dark menuBox" ref={menuRef}>
                                    <ul className="p-2">
                                        {
                                            languages.map((lang) => {
                                                return (
                                                    <li
                                                        key={lang}
                                                        className="menuList text-capitalize my-1 font14"
                                                        onClick={() => setOpenIndustry(false)}
                                                    >
                                                        {lang}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                            }
                        </li>


                        <li className='ms-lg-4'>
                            {/* <Link to='/notification' > */}
                            <div
                                className='text-white font22 pointer'
                                onClick={goNotification}
                            >
                                <FaRegBell />
                            </div>
                            {/* </Link> */}
                        </li>

                        {(auth !== true && (Object.keys(profile_data).length === 0)) ?
                            <>
                                <li style={{ minWidth: 'fit-content' }} onClick={() => setOpenLogin(true)} className="nav-item text-white ms-lg-4 position-relative me-lg-0 pointer font16">
                                    <p className='text-white m-0 text-center'>Sign-in</p>
                                </li>
                                {/* <li style={{ minWidth: 'fit-content' }} className="nav-item mx-lg-5 mx-md-5 text-white position-relative me-lg-0 pointer font16" onClick={() => setOpenLogin(true)}>
                                    <p className='text-danger m-0'>Register Now</p>
                                </li> */}
                            </>
                            :
                            <li className="nav-item ms-lg-4 text-white position-relative me-lg-0" onClick={() => setOpenProfile(!openProfile)}>
                                <div className="h-screen position-relative pointer">
                                    {profile_data.avatar ?
                                        <img src={`${baseURL}/${profile_data.avatar}`} className='user_avatar rounded-5' onClick={() => setOpenProfile(!openProfile)} alt={profile_data.name} width='50' height='50' />
                                        :
                                        // <img src='../images/myprofileimage.png' className='user_avatar rounded-5' onClick={() => setOpenProfile(!openProfile)} alt={profile_data.name} width='50' height='50' />
                                        <div
                                            className='profile_name_character d-flex justify-content-center align-items-center'
                                        >
                                            <span
                                                className='font_weight_600 font26'
                                            >
                                                {profile_data?.name ? profile_data?.name.charAt(0).toUpperCase() : ''}
                                            </span>
                                        </div>
                                    }

                                    {openProfile && (
                                        <div className="text-white bg-dark userBox" ref={menuRef}>
                                            <ul className="p-2">
                                                {profile_data?.subscriber_type_id === 1 ?
                                                    <>
                                                        {
                                                            fraternityUser.map((menuItem: any) => {
                                                                return (
                                                                    <NavLink to={`/profile/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList text-capitalize my-1 text-decoration-none">
                                                                        <Typography
                                                                            className="text-white lh-lg"
                                                                            variant="overline"
                                                                            gutterBottom
                                                                            component="li"
                                                                            style={{ textTransform: 'none', fontSize: '14px' }}
                                                                        >
                                                                            <p>{menuItem}</p>
                                                                        </Typography>
                                                                    </NavLink>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            cinemaFans.map((menuItem: any) => {
                                                                return (
                                                                    <NavLink to={`/profile/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList text-capitalize my-1 text-decoration-none font14">
                                                                        <Typography
                                                                            className="text-white"
                                                                            variant="overline"
                                                                            gutterBottom
                                                                            component="li"
                                                                        >
                                                                            <b>{menuItem}</b>
                                                                        </Typography>
                                                                    </NavLink>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                                <NavLink to={`#`}
                                                    className="menuList text-capitalize my-1 text-decoration-none font14"
                                                    onClick={() => { setOpenModal(true) }}
                                                >
                                                    <Typography
                                                        className="text-white"
                                                        variant="overline"
                                                        gutterBottom
                                                        component="li"
                                                    >
                                                        <b>Sign Out</b>
                                                    </Typography>
                                                </NavLink>
                                            </ul>
                                        </div>
                                    )
                                    }
                                </div>
                            </li>
                        }
                    </ul>
                </div>
                {/* </div> */}
            </nav>



            {/* mobile search modal */}

            <div className="modal fade" id="mobalNavebarSearch" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header theme_color border-0">
                            {/* <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body theme_color">
                            <div className="pe-lg-4 pe-md-4 dropdown-menu-end" id="">
                                <div
                                    className='d-flex align-items-center slign-items-center'
                                >
                                    <select className="form-select border-0 border-end" aria-label="Default select example"
                                        style={{
                                            height: '40px', width: '8rem',
                                            borderTopRightRadius: '0px',
                                            borderBottomRightRadius: '0px'
                                        }}
                                        name='select_for_search'
                                        onChange={handleSearchText}
                                        value={searchText.select_for_search}
                                    >
                                        <option className='text-white' value="all">All</option>
                                        <option className='text-white' value="movie">Movie</option>
                                        <option className='text-white' value="artist">Artist</option>
                                        <option className='text-white' value="festivl">Film Festival</option>
                                        <option className='text-white' value="event">Event</option>
                                    </select>
                                    <div className='searchBox w-100 position-relative'>
                                        <input type='text' placeholder='search' onChange={handleSearchText} value={searchText.textSearch} name='textSearch' />
                                        <BiSearchAlt className='searchIcon' />
                                        {searchText.textSearch !== '' && searchWait ?
                                            <div
                                                className='searchsuggestion position-absolute bg-dark text-white w-100 font20'
                                            >
                                                <h5 className='text-white ps-2 py-2'>Loading...</h5>
                                            </div>
                                            :
                                            <>
                                                {searchText && Array.isArray(searchResult) ?
                                                    <div
                                                        className='searchsuggestion position-absolute bg-dark text-white w-100 font20'
                                                        onClick={() => setSearchText({ ...searchText, textSearch: '' })}
                                                    >
                                                        {searchText.select_for_search !== 'all' ?
                                                            <>
                                                                {Array.isArray(searchResult) &&
                                                                    searchResult?.map((val: any) => {
                                                                        return (
                                                                            <>
                                                                                {val.type === 0 ?
                                                                                    <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                                        <div
                                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white'
                                                                                            data-bs-dismiss="modal"
                                                                                        >
                                                                                            <p
                                                                                                className='m-0'
                                                                                            >
                                                                                                {val.title}
                                                                                            </p>
                                                                                            <span>
                                                                                                <BiSearchAlt />
                                                                                            </span>
                                                                                        </div>
                                                                                    </Link>
                                                                                    :
                                                                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                                        <div
                                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                                            data-bs-dismiss="modal"
                                                                                        >
                                                                                            <p
                                                                                                className='m-0'
                                                                                            >
                                                                                                {val.title}
                                                                                            </p>
                                                                                            <span>
                                                                                                <BiSearchAlt />
                                                                                            </span>
                                                                                        </div>
                                                                                    </Link>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {Array.isArray(searchResult) &&
                                                                    searchResult?.map((val: any) => {
                                                                        return (
                                                                            <>
                                                                                {val.type !== 1 ?
                                                                                    <Link to={`/profile-details/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                                        <div
                                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                                            data-bs-dismiss="modal"
                                                                                        >
                                                                                            <p
                                                                                                className='m-0'
                                                                                            >
                                                                                                {val.title}
                                                                                            </p>
                                                                                            <span>
                                                                                                <BiSearchAlt />
                                                                                            </span>
                                                                                        </div>
                                                                                    </Link>
                                                                                    :
                                                                                    <Link to={`/video-detail/${removeDotReplaceSpaceToHyphen(val.title)}-${val.id}`} className='text-decoration-none' key={val.id}>
                                                                                        <div
                                                                                            className='d-flex justify-content-between align-items-center my-2 px-2 text-white '
                                                                                            data-bs-dismiss="modal"
                                                                                        >
                                                                                            <p
                                                                                                className='m-0'
                                                                                            >
                                                                                                {val.title}
                                                                                            </p>
                                                                                            <span>
                                                                                                <BiSearchAlt />
                                                                                            </span>
                                                                                        </div>
                                                                                    </Link>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    : null
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* mobile screen menu sidebar */}

            <div className="offcanvas offcanvas-start w-75" data-bs-backdrop="static" tabIndex={-1} id="staticBackdrop" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header theme_color">
                    <Link className="navbar-brand ps-lg-3 ps-md-3 ps-0" to={'/'}>
                        <img src='/images/logo.png' alt='logo' width='auto' height='50' />
                    </Link>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body theme_color">
                    <div className="pe-lg-4 pe-md-4 pe-2 dropdown-menu-end" id="">
                        <ul className="navbar-nav mobile_menu_hover">
                            <li className="nav-item mx-lg-5 mx-md-5 text-white position-relative mb-3 d-flex justify-content-between align-items-center" onClick={() => setOpenMenuMobile(!openMenuMobile)}>
                                <span className='d-flex align-items-center pointer'>
                                    <span className='font16'>Menu</span>
                                </span>
                                <span className='ms-2'>
                                    <PiCaretDownBold />
                                </span>
                                {openMenuMobile && (
                                    <div className="text-white bg-dark menuBox2" ref={menuRef}>
                                        <ul className="p-2">
                                            {
                                                menu.map((menuItem) => {
                                                    return (
                                                        <NavLink to={`/menu/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList my-1 text-decoration-none">
                                                            <Typography
                                                                className="text-white"
                                                                variant="overline"
                                                                gutterBottom
                                                                component="li"
                                                                style={{ textTransform: 'none', fontSize: '14px' }}
                                                                data-bs-dismiss="offcanvas"
                                                            >
                                                                <span>{menuItem}</span>
                                                            </Typography>
                                                        </NavLink>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                                }
                            </li>

                            <li className="nav-item mx-lg-5 mx-md-5 text-white position-relative mb-3 d-flex justify-content-between align-items-center" onClick={() => setOpenIndustry(!openIndustry)}>
                                <span className='pointer'>
                                    <span className='pe-2 font16'>EN</span>
                                </span>
                                <span className='d-flex align-items-center pointer'>
                                    <PiCaretRightBold className='font22' />
                                </span>
                                {openIndustry && (
                                    <div className="text-white bg-dark menuBox2" ref={menuRef}>
                                        <ul className="p-2">
                                            {
                                                languages.map((lang) => {
                                                    return (
                                                        <li
                                                            key={lang}
                                                            className="menuList text-capitalize my-1 font14"
                                                            onClick={() => setOpenIndustry(false)}
                                                        >
                                                            {lang}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                                }
                            </li>

                            <li className='mb-3 d-flex justify-content-between align-items-center text-white'>
                                <div
                                    className='text-white font16 pointer'
                                    onClick={goNotification}
                                >
                                    Notification
                                </div>
                                <span className='d-flex align-items-center pointer'>
                                    <PiCaretRightBold className='font22' />
                                </span>
                            </li>

                            {(auth !== true && (Object.keys(profile_data).length === 0)) ?
                                <>
                                    <li onClick={() => setOpenLogin(true)} data-bs-dismiss="offcanvas" className="nav-item mx-lg-5 mx-md-5 text-white position-relative me-lg-0 pointer d-flex justify-content-between align-items-center font16">
                                        <p className='text-white m-0'>Sign-in</p>
                                        <span className='d-flex align-items-center pointer'>
                                            <PiCaretRightBold className='font22' />
                                        </span>
                                    </li>
                                    {/* <li style={{ minWidth: 'fit-content' }} className="nav-item mx-lg-5 mx-md-5 text-white position-relative me-lg-0 pointer font16" onClick={() => setOpenLogin(true)}>
                                    <p className='text-danger m-0'>Register Now</p>
                                </li> */}
                                </>
                                :
                                <li className="nav-item mx-lg-5 mx-md-5 text-white position-relative me-lg-0" onClick={() => setOpenProfileMobile(!openProfileMobile)}>
                                    <div className="h-screen position-relative pointer">
                                        {profile_data.avatar ?
                                            <img src={`${baseURL}/${profile_data?.avatar}`} className='user_avatar rounded-5' onClick={() => setOpenProfileMobile(!openProfileMobile)} alt={profile_data.name} width='50' height='50' />
                                            :
                                            <img src='../images/myprofileimage.png' className='user_avatar rounded-5' onClick={() => setOpenProfileMobile(!openProfileMobile)} alt={profile_data.name} width='50' height='50' />
                                        }

                                        {openProfileMobile && (
                                            <div className="text-white bg-dark userBox2" ref={menuRef}>
                                                <ul className="p-2">
                                                    {profile_data?.subscriber_type_id === 1 ?
                                                        <>
                                                            {
                                                                fraternityUser.map((menuItem: any) => {
                                                                    return (
                                                                        <NavLink to={`/profile/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList text-capitalize my-1 text-decoration-none">
                                                                            <Typography
                                                                                className="text-white"
                                                                                variant="overline"
                                                                                gutterBottom
                                                                                component="li"
                                                                                style={{ textTransform: 'none', fontSize: '14px' }}
                                                                                data-bs-dismiss="offcanvas"
                                                                            >
                                                                                <p>{menuItem}</p>
                                                                            </Typography>
                                                                        </NavLink>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                cinemaFans.map((menuItem: any) => {
                                                                    return (
                                                                        <NavLink to={`/profile/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList text-capitalize my-1 text-decoration-none font14">
                                                                            <Typography
                                                                                className="text-white"
                                                                                variant="overline"
                                                                                gutterBottom
                                                                                component="li"
                                                                                data-bs-dismiss="offcanvas"
                                                                            >
                                                                                <b>{menuItem}</b>
                                                                            </Typography>
                                                                        </NavLink>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    }
                                                    <NavLink to={`/`}
                                                        className="menuList text-capitalize my-1 text-decoration-none font14"
                                                        onClick={() => { setOpenModal(true) }}
                                                    >
                                                        <Typography
                                                            className="text-white"
                                                            variant="overline"
                                                            gutterBottom
                                                            component="li"
                                                        >
                                                            <b>Sign Out</b>
                                                        </Typography>
                                                    </NavLink>
                                                </ul>
                                            </div>
                                        )
                                        }
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>


            {/* mobile pop modal menu */}

            <div className="modal fade" id="mobalNavebar" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body theme_color px-0">
                            <div className="dropdown-menu-end" id="">
                                <ul className="dropdown-menu-end d-flex justify-content-between align-items-center mobile_menu_list p-0 px-3 m-0">
                                    <li style={{ minWidth: 'fit-content' }} className="nav-item text-white ms-lg-4 position-relative" onClick={() => setOpenIndustryModalMobileView(!openIndustryModalMobileView)}>
                                        {(Array.isArray(industry_name) && industry_name.length > 0) && industry_name[0]?.title ?
                                            <div
                                                className='pointer'
                                            >
                                                <span>
                                                    {industry_name[0]?.title}
                                                </span>
                                                <span className='ms-2'>
                                                    <PiCaretDownBold />
                                                </span>
                                            </div>
                                            : null
                                        }
                                    </li>
                                    <li style={{ minWidth: 'fit-content' }} className="nav-item text-white position-relative">
                                        <span className='d-flex align-items-center pointer' onClick={() => setOpenMenuMobile(!openMenuMobile)}>
                                            <span className='ps-1 font16'>Menu</span>
                                            <span className='ms-2'>
                                                <PiCaretDownBold />
                                            </span>
                                        </span>
                                        {openMenuMobile && (
                                            <div className="text-white bg-dark menuBox2" ref={mobilemenuRef}>
                                                <ul className="p-2">
                                                    {
                                                        menu.map((menuItem) => {
                                                            return (
                                                                <NavLink to={`/menu/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList my-1 text-decoration-none">
                                                                    <Typography
                                                                        data-bs-dismiss="modal"
                                                                        className="text-white"
                                                                        variant="overline"
                                                                        gutterBottom
                                                                        component="li"
                                                                        style={{ textTransform: 'none', fontSize: '14px' }}
                                                                    >
                                                                        <span>{menuItem}</span>
                                                                    </Typography>
                                                                </NavLink>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                        }
                                    </li>

                                    <li style={{ minWidth: 'fit-content' }} className="nav-item text-white position-relative" onClick={() => setOpenIndustry(!openIndustry)}>
                                        <span className='pointer'>
                                            <span className='pe-2 font16'>EN</span>
                                            <PiCaretDownBold />
                                        </span>
                                        {openIndustry && (
                                            <div className="text-white bg-dark menuBox2" ref={menuRef}>
                                                <ul className="p-2">
                                                    {
                                                        languages.map((lang) => {
                                                            return (
                                                                <li
                                                                    key={lang}
                                                                    className="menuList text-capitalize my-1 font14"
                                                                    onClick={() => setOpenIndustry(false)}
                                                                >
                                                                    {lang}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                        }
                                    </li>

                                    <li className=''>
                                        <div
                                            className='text-white font22 pointer'
                                            onClick={goNotification}
                                        >
                                            <FaRegBell />
                                        </div>
                                    </li>

                                    {(auth !== true && (Object.keys(profile_data).length === 0)) ?
                                        <>
                                            <li style={{ minWidth: 'fit-content' }} onClick={() => setOpenLogin(true)} data-bs-dismiss="modal" className="nav-item text-white position-relative me-lg-0 pointer font16">
                                                <p className='text-white m-0 text-center'>Sign-in</p>
                                            </li>
                                            {/* <li style={{ minWidth: 'fit-content' }} className="nav-item mx-lg-5 mx-md-5 text-white position-relative me-lg-0 pointer font16" onClick={() => setOpenLogin(true)}>
                                    <p className='text-danger m-0'>Register Now</p>
                                </li> */}
                                        </>
                                        :
                                        <li className="nav-item text-white position-relative" onClick={() => setOpenProfileMobile(!openProfileMobile)}>
                                            <div className="h-screen position-relative pointer">
                                                {profile_data.avatar ?
                                                    <img src={`${baseURL}/${profile_data.avatar}`} className='user_avatar rounded-5' onClick={() => setOpenProfileMobile(!openProfileMobile)} alt={profile_data.name} width='50' height='50' />
                                                    :
                                                    <img src='../images/myprofileimage.png' className='user_avatar rounded-5' onClick={() => setOpenProfileMobile(!openProfileMobile)} alt={profile_data.name} width='50' height='50' />
                                                }

                                                {openProfileMobile && (
                                                    <div className="text-white bg-dark userBox2" ref={mobileProfileRef}>
                                                        <ul className="p-2">
                                                            {profile_data?.subscriber_type_id === 1 ?
                                                                <>
                                                                    {
                                                                        fraternityUser.map((menuItem: any) => {
                                                                            return (
                                                                                <NavLink to={`/profile/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList text-capitalize my-1 text-decoration-none">
                                                                                    <Typography
                                                                                        data-bs-dismiss="modal"
                                                                                        className="text-white"
                                                                                        variant="overline"
                                                                                        gutterBottom
                                                                                        component="li"
                                                                                        style={{ textTransform: 'none', fontSize: '14px' }}
                                                                                    >
                                                                                        <p>{menuItem}</p>
                                                                                    </Typography>
                                                                                </NavLink>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        cinemaFans.map((menuItem: any) => {
                                                                            return (
                                                                                <NavLink to={`/profile/${menuItem.replace(/\s/g, '').toLowerCase()}`} key={menuItem} className="menuList text-capitalize my-1 text-decoration-none font14">
                                                                                    <Typography
                                                                                        data-bs-dismiss="modal"
                                                                                        className="text-white"
                                                                                        variant="overline"
                                                                                        gutterBottom
                                                                                        component="li"
                                                                                    >
                                                                                        <b>{menuItem}</b>
                                                                                    </Typography>
                                                                                </NavLink>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }
                                                            <NavLink to={`/`}
                                                                className="menuList text-capitalize my-1 text-decoration-none font14"
                                                                onClick={() => { setOpenModal(true) }}
                                                            >
                                                                <Typography
                                                                    data-bs-dismiss="modal"
                                                                    className="text-white"
                                                                    variant="overline"
                                                                    gutterBottom
                                                                    component="li"
                                                                >
                                                                    <b>Sign Out</b>
                                                                </Typography>
                                                            </NavLink>
                                                        </ul>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </li>
                                    }
                                    <li>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
