import React, { useEffect, useRef, useState } from 'react';
import { getAllLanguage, getAllFilmRights, getAllGenres, getFilmType, getProjectStatus } from '../../utils/utils';
import Select from 'react-dropdown-select';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';

const MovieSnippets = (props: any) => {

    const { project_data, project_id } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const updateSnippetRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true);
    const [languages, setLanguages] = useState<any>([]);
    const [selectedLang, setSelectedLang] = useState<any>([]);
    const [genre, setGenre] = useState<any>([]);
    const [selectedGenre, setSelectedGenre] = useState<any>([]);
    const [filmRight, setFilmRight] = useState<any>([]);
    const [checkedRight, setRights] = useState([]);
    const [projectStatus, setProjectstatus] = useState<any>([]);
    const [filmTypeList, setfilmTypeList] = useState<any>([]);

    const [movieSnippet, setMovieSnippet] = useState<any>({
        shooting_duration: '',
        filming_locations: '',
        movie_budget: '',
        project_status: '',
        release_date: '',
        gross_earning: '',
        net_earning: '',
        genre: [],
        language: [],
        state: '',
        country: '',
        type: '',
        cbfc_certificate: '',
        production_company_address: '',
        production_company_owner_name: '',
        production_company_owner_image: '',
        production_house_company_name: '',
        production_house_company_image: '',
        production_company_email: '',
        production_company_phone: '',
        full_movie_link: '',
        colored_movie_link: '',
        film_right: [],
        story_origin: '',
        cbfc_certificate_image: '',
        editor: '',
        cinematography: '',
        background_music: '',
        music_label: '',
        music_by: '',
        dialogue_writer: '',
        screenplay_writer: '',
        director_name: '',
        producer_name: '',
        days_weeks_run_in_theartres: '',
        story_writer: '',
    });

    const maxMobileNumber = 10;

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleMovieSnippetUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { shooting_duration, filming_locations, movie_budget, project_status, release_date, gross_earning, net_earning, genre, language, state, country, type, cbfc_certificate,
            production_company_address, production_company_owner_name, production_company_owner_image, production_house_company_name, production_house_company_image,
            production_company_email, production_company_phone, full_movie_link, colored_movie_link, film_right, story_origin, cbfc_certificate_image, editor, cinematography,
            background_music, music_label, music_by, dialogue_writer, screenplay_writer, director_name, producer_name, days_weeks_run_in_theartres, story_writer } = movieSnippet;
        if (connection_id && auth_code) {
            try {
                setActive(true);
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    shooting_duration: shooting_duration,
                    filming_locations,
                    budget: movie_budget,
                    release_date,
                    gross_earning,
                    net_earning,
                    genre: JSON.stringify(genre),
                    language: JSON.stringify(language),
                    type,
                    state,
                    country,
                    editor,
                    background_music,
                    cinematography,
                    music_label,
                    music_by,
                    dialogue_writer,
                    screenplay_writer,
                    director_name,
                    producer_name,
                    days_weeks_run_in_theartres,
                    story_writer,
                    cbfc_certificate,
                    production_house: production_house_company_name,
                    production_house_owner: production_company_owner_name,
                    production_house_address: production_company_address,
                    production_house_email: production_company_email,
                    production_house_contact: production_company_phone,
                    film_rights: JSON.stringify(film_right),
                    production_house_img: production_house_company_image,
                    production_house_owner_img: production_company_owner_image,
                    full_movie_link,
                    cbfc_certificate_image,
                    story_origin,
                    colored_movie_link,
                    status_of_project: project_status
                }
                let result = await axios(`${baseURL}/api/project/add-update/movieSnippets`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                // console.log(result.data.data.project_id);
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const getLanguage = async () => {
        setActive(true);
        const data = await getAllLanguage();
        setLanguages(data);
        setActive(false)
    }

    const getGenre = async () => {
        setActive(true);
        const data = await getAllGenres();
        setGenre(data);
        setActive(false);
    }

    const getFilmRight = async () => {
        setActive(true);
        const data = await getAllFilmRights();
        setFilmRight(data);
        setActive(false);
    }

    const getFilmTypeList = async () => {
        setActive(true);
        const data = await getFilmType();
        setfilmTypeList(data);
        setActive(false);
    }

    const getProjectStatusList = async () => {
        setActive(true);
        const data = await getProjectStatus();
        setProjectstatus(data);
        setActive(false);
    }

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;

        if (name === 'production_company_owner_image' || name === 'production_house_company_image' || name === 'cbfc_certificate_image') {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            if (name === 'production_company_owner_image') {
                setMovieSnippet({
                    ...movieSnippet,
                    production_company_owner_image: file,
                });
            } else if (name === 'production_house_company_image') {
                setMovieSnippet({
                    ...movieSnippet,
                    production_house_company_image: file,
                });
            } else if (name === 'cbfc_certificate_image') {
                setMovieSnippet({
                    ...movieSnippet,
                    cbfc_certificate_image: file,
                });
            }
        } else if (name === 'production_company_phone') {
            if (value.length <= maxMobileNumber) {
                setMovieSnippet((prevVal: any) => ({ ...prevVal, production_company_phone: value }));
            }
        } else {
            setMovieSnippet({ ...movieSnippet, [name]: value });
        }
    }

    const handleCheckBox = (e: any) => {
        const { value, checked } = e.target;
        const { film_right } = movieSnippet;
        console.log(value)
        if (checked === true) {
            setMovieSnippet({ ...movieSnippet, film_right: [...film_right, parseInt(value)] });
        } else {
            console.log('golu');
            setMovieSnippet({ ...movieSnippet, film_right: film_right.filter((e: any) => e !== parseInt(value)) });
        }
    }

    const getSetChecckedVal = () => {
        if (project_data?.project_rights && project_data?.project_rights?.length > 0) {
            const filmRight = project_data?.project_rights?.map((val: any) => {
                return val.id;
            })
            setRights(filmRight);
        }
    }

    const getSSelectedVal = () => {
        if (project_data?.language && project_data?.language.length > 0) {
            const lang = project_data?.language?.map((val: any) => {
                return val.id;
            })
            setSelectedLang(lang);
        }
    }

    const getSSelectedGenreVal = () => {
        if (project_data?.genre && project_data?.genre.length > 0) {
            const lang = project_data?.genre?.map((val: any) => {
                return val.id;
            })
            setSelectedGenre(lang);
        }
    }

    useEffect(() => {
        getSetChecckedVal();
        getSSelectedVal();
        getSSelectedGenreVal();
        setMovieSnippet({
            ...movieSnippet,
            shooting_duration: project_data?.shooting_duration || '',
            filming_locations: project_data?.filming_locations || '',
            movie_budget: project_data?.budget || '',
            project_status: project_data?.status_of_project || '',
            release_date: project_data?.release_date || '',
            gross_earning: project_data?.gross_earning || '',
            net_earning: project_data?.net_earning || '',
            state: project_data?.state || '',
            country: project_data?.country || '',
            type: project_data?.type || '',
            cbfc_certificate: project_data?.cbfc_certificate || '',
            production_company_address: project_data?.production_house_address || '',
            production_company_owner_name: project_data?.production_house_owner || '',
            production_company_owner_image: project_data?.production_house_owner_img || '',
            production_house_company_name: project_data?.production_house || '',
            production_house_company_image: project_data?.production_house_img || '',
            production_company_email: project_data?.production_house_email || '',
            production_company_phone: project_data?.production_house_contact || '',
            full_movie_link: project_data?.full_movie_link || '',
            colored_movie_link: project_data?.colored_movie_link || '',
            film_right: checkedRight && checkedRight.length > 0 ? [...checkedRight] : [...movieSnippet.film_right],
            language: selectedLang && selectedLang.length > 0 ? [...selectedLang] : [...movieSnippet.language],
            genre: selectedGenre && selectedGenre.length > 0 ? [...selectedGenre] : [...movieSnippet.genre],
            story_origin: project_data?.story_origin
        });
    }, [project_data]);

    useEffect(() => {
        const element = updateSnippetRef.current;

        const handleShow = () => {
            getFilmRight();
            getGenre();
            getLanguage();
            getProjectStatusList();
            getFilmTypeList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('snippet', project_data);
    // }, [project_data])

    return (
        <div className="accordion accordion-flush mt-4" id="movieSnippets">
            <div className="accordion-item rounded">
                <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsemovieSnippets" aria-expanded="false" aria-controls="flush-collapsemovieSnippets">
                        Movie Snippets
                    </button>
                </h2>
                <div id="flush-collapsemovieSnippets" ref={updateSnippetRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#movieSnippets">
                    <div className="accordion-body accordian_border_with_color border-top-0 mb-5 movie_snippet_form theme_color">
                        <LoadingOverlay
                            active={isActive}
                            spinner
                            text='Loading your content...'
                        >
                            <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border bg-transparent rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </button>
                                </div>
                            <div className="row movie_snippet_section p-0 d-flex justify-content-between align-items-center">
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Shooting Duration</label>
                                    <input type="text" onChange={handleChangeValue} name='shooting_duration' value={movieSnippet.shooting_duration} className="form-control mb-3" id="exampleFormControlInput1" placeholder='19days' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Budget of the movie</label>
                                    <input type="text" onChange={handleChangeValue} name='movie_budget' value={movieSnippet.movie_budget} className="form-control mb-3" id="exampleFormControlInput1" placeholder='3.75 Lakh' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Release Date</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.release_date} name='release_date' className="form-control mb-3" id="exampleFormControlInput1" placeholder='19 January 1971' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Net Earning</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.net_earning} name='net_earning' className="form-control mb-3" id="exampleFormControlInput1" placeholder='900000' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Language</label>
                                    <Select
                                        name='selected'
                                        className='mb-3 text-white'
                                        options={languages}
                                        labelField='name'
                                        valueField='id'
                                        multi
                                        placeholder='Select Multiple Languages...'
                                        onChange={(values: any) => {
                                            const selectedIds = values.map((option: any) => option.id);
                                            setMovieSnippet({ ...movieSnippet, language: selectedIds })
                                        }}
                                        values={project_data?.language}
                                        style={{
                                            height: '50px',
                                            borderRadius: '5px',
                                            border: '1px solid white'
                                        }}
                                    />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Country</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.country} name='country' className="form-control mb-3" id="exampleFormControlInput1" placeholder='India' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">CBFC Certificate</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.cbfc_certificate} name='cbfc_certificate' className="form-control mb-3" id="exampleFormControlInput1" placeholder='U' disabled={isDisabled} />
                                    <label htmlFor="cbfccertificateimage" className="form-label text-white m-0">CBFC Certificate Image</label>
                                    <input type="file" onChange={handleChangeValue} name='cbfc_certificate_image' className="form-control mb-3" id="exampleFormControlInput1" />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Production Company owner Name</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.production_company_owner_name} name='production_company_owner_name' className="form-control mb-3" id="exampleFormControlInput1" placeholder='K. C. N. Gowda' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Production House/Company Name</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.production_house_company_name} name='production_house_company_name' className="form-control mb-3" id="exampleFormControlInput1" placeholder='Anupam Movies' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Production Company Email Id</label>
                                    <input type="email" onChange={handleChangeValue} name='production_company_email' value={movieSnippet.production_company_email} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Email' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Full Movie Link (B/W)</label>
                                    <input type="text" onChange={handleChangeValue} name='full_movie_link' value={movieSnippet.full_movie_link} className="form-control mb-3" id="exampleFormControlInput1" placeholder='URL Linkl' disabled={isDisabled} />
                                    <label htmlFor="editor" className="form-label text-white m-0">Editor</label>
                                    <input type="text" onChange={handleChangeValue} name='editor' value={movieSnippet.editor} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="Background music" className="form-label text-white m-0">Background music</label>
                                    <input type="text" onChange={handleChangeValue} name='background_music' value={movieSnippet.background_music} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="music_by" className="form-label text-white m-0">Music by</label>
                                    <input type="text" onChange={handleChangeValue} name='music_by' value={movieSnippet.music_by} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' />
                                    <label htmlFor="screenplay_writer" className="form-label text-white m-0">Screenplay writer</label>
                                    <input type="text" onChange={handleChangeValue} name='screenplay_writer' value={movieSnippet.screenplay_writer} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="director_name" className="form-label text-white m-0">Director name</label>
                                    <input type="text" onChange={handleChangeValue} name='director_name' value={movieSnippet.director_name} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="days_weeks_run_in_theartres" className="form-label text-white m-0">Days/Weeks run in theaters</label>
                                    <input type="text" onChange={handleChangeValue} name='days_weeks_run_in_theartres' value={movieSnippet.days_weeks_run_in_theartres} className="form-control mb-3" disabled={isDisabled} />
                                </div>

                                <div className='col-lg-6 col-md-6 col-12'>
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Filming Locations</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.filming_locations} name='filming_locations' className="form-control mb-3" id="exampleFormControlInput1" placeholder='Mysore' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Status of the project </label>
                                    {/* <input type="text" onChange={handleChangeValue} value={movieSnippet.project_status} name='project_status' className="form-control mb-3" id="exampleFormControlInput1" placeholder='Released' disabled={isDisabled} /> */}
                                    <select className="form-select form-select-lg mb-3 text-white" name='project_status' value={movieSnippet.project_status} onChange={handleChangeValue} aria-label="Large select example" disabled={isDisabled}>
                                        <option selected>Select status of the project</option>
                                        {Array.isArray(projectStatus) &&
                                            projectStatus?.map((val: any) => {
                                                return (
                                                    <option key={val.id} value={val.name}>{val.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Gross Earning</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.gross_earning} name='gross_earning' className="form-control mb-3" id="exampleFormControlInput1" placeholder='1000000' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Genre</label>
                                    <Select
                                        name='selected'
                                        className='mb-3 text-white'
                                        options={genre}
                                        style={{
                                            height: '50px',
                                            borderRadius: '5px',
                                            border: '1px solid white'
                                        }}
                                        labelField='title'
                                        valueField='id'
                                        multi
                                        placeholder='Select Multiple Generes...'
                                        onChange={(values: any) => {
                                            const selectedIds = values.map((option: any) => option.id);
                                            setMovieSnippet({ ...movieSnippet, genre: selectedIds })
                                        }}
                                        values={project_data?.genre}
                                    />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">State</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.state} name='state' className="form-control mb-3" id="exampleFormControlInput1" placeholder='Karnataka' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Type</label>
                                    {/* <input type="text" onChange={handleChangeValue} value={movieSnippet.type} name='type' className="form-control mb-3" id="exampleFormControlInput1" placeholder='Feature Film' disabled={isDisabled} /> */}
                                    <select className="form-select form-select-lg mb-3 text-white" name='type' value={movieSnippet.type} onChange={handleChangeValue} aria-label="Large select example" disabled={isDisabled}>
                                        <option selected>Select film type</option>
                                        {Array.isArray(filmTypeList) &&
                                            filmTypeList?.map((val: any) => {
                                                return (
                                                    <option key={val.id} value={val.title}>{val.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Production Company Address</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.production_company_address} name='production_company_address' className="form-control mb-3" id="exampleFormControlInput1" placeholder='Bangalore' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Film Story Originated From</label>
                                    <input type="text" onChange={handleChangeValue} value={movieSnippet.story_origin} name='story_origin' className="form-control mb-3" disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Production Company owner Image</label>
                                    <input type="file" onChange={handleChangeValue} name='production_company_owner_image' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Production House/Company Logo/Image</label>
                                    <input type="file" onChange={handleChangeValue} name='production_house_company_image' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Production Company Contact Number</label>
                                    <input type="number" onChange={handleChangeValue} value={movieSnippet.production_company_phone} name='production_company_phone' className="form-control mb-3" id="exampleFormControlInput1" placeholder='0954xxxxxx' disabled={isDisabled} />
                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Colored Movie Link</label>
                                    <input type="text" onChange={handleChangeValue} name='colored_movie_link' value={movieSnippet.colored_movie_link} className="form-control mb-3" id="exampleFormControlInput1" placeholder='URL Linkl' disabled={isDisabled} />
                                    <label htmlFor="cinematography" className="form-label text-white m-0">Cinematography</label>
                                    <input type="text" onChange={handleChangeValue} name='cinematography' value={movieSnippet.cinematography} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="music_label" className="form-label text-white m-0">Music lable</label>
                                    <input type="text" onChange={handleChangeValue} name='music_label' value={movieSnippet.music_label} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="dialogue_writer" className="form-label text-white m-0">Dialogue writer</label>
                                    <input type="text" onChange={handleChangeValue} name='dialogue_writer' value={movieSnippet.dialogue_writer} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="dialogue_writer" className="form-label text-white m-0">Dialogue writer</label>
                                    <input type="text" onChange={handleChangeValue} name='dialogue_writer' value={movieSnippet.dialogue_writer} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="story_writer" className="form-label text-white m-0">Story writer</label>
                                    <input type="text" onChange={handleChangeValue} name='story_writer' value={movieSnippet.story_writer} className="form-control mb-3" id="exampleFormControlInput1" placeholder='Name' disabled={isDisabled} />
                                    <label htmlFor="producer_name" className="form-label text-white m-0">Producer name</label>
                                    <input type="text" onChange={handleChangeValue} name='producer_name' value={movieSnippet.producer_name} className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                </div>

                                <div>
                                    <p className='text-white m-0 mb-2'>Film Right Available</p>
                                    <ul className='list-unstyled d-flex breadcrumb'>
                                        {
                                            filmRight?.map((value: any, i: any) => {
                                                return (
                                                    <li key={value.id} style={{ minWidth: 'fit-content' }}>
                                                        <div className="form-check ms-3 text-white">
                                                            <input className="form-check-input"
                                                                checked={movieSnippet?.film_right.includes(value.id)}
                                                                name={value.title}
                                                                onChange={handleCheckBox}
                                                                value={value.id}
                                                                type="checkbox"
                                                                id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                {value.title}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border-0 rounded font_weight_600'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleMovieSnippetUpdate}
                                    >
                                        UPDATE
                                    </button>
                                </div>
                            </div>
                            <ToastContainer />
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MovieSnippets
