import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiCaretRightBold } from 'react-icons/pi';
import { getAllProjects } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';
import FilmographyModal from '../myprofilemodals/FilmographyModal';

const ProjectDetailForm = () => {

    const navigate = useNavigate();
    LoadingOverlay.propTypes = undefined;
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [allProject, setAllProject] = useState<any>([]);

    const handleId = (id: any) => {
        navigate('/profile/projectdetail', { state: { project_id: id } });
    }

    // get all projects
    const fetchAllProjects = async () => {
        setActive(true);
        const data = await getAllProjects();
        setAllProject(data);
        setActive(false);
    };

    // const [formData, setFormData] = useState<any>([0]);

    // const addForm = () => {
    //     setFormData([...formData, Math.random() * 11]);
    // }
    // const addForm = () => {
    //     setAllProject([...allProject, []]);
    // }

    const handleCreateFilmographydModal = () => {
        fetchAllProjects();
        setOpenAdd(!openAdd);
    }

    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            if (Object.keys(allProject).length === 0) {
                fetchAllProjects();
            }
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (Object.keys(allProject).length === 0) {
    //             fetchAllProjects();
    //         }
    //     }, 3000);
    // }, []);

    // useEffect(() => {
    //     // console.log('effect',allProject);
    // }, [allProject])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='personal_project_form_accordian_section theme_color mt-3'>

                <div className="accordion accordion-flush" id={`ProjectDetails`}>
                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id={`flush-headingProjectDetails`}>
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseProjectDetails`} aria-expanded="false" aria-controls={`flush-collapseProjectDetails`}>
                                Filmography
                            </button>
                        </h2>
                        <div id={`flush-collapseProjectDetails`} ref={myElementRef} className="accordion-collapse collapse" aria-labelledby={`flush-headingProjectDetails`} data-bs-parent={`#ProjectDetails`}>
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openAdd &&
                                    <FilmographyModal handleModalBox={handleCreateFilmographydModal} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleCreateFilmographydModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                <table className="table table-dark table-striped border">
                                    <thead>
                                        <tr>
                                            <th>
                                                ID
                                            </th>
                                            <th>
                                                Project name
                                            </th>
                                            <th>
                                                Movie log Line
                                            </th>
                                            <th>
                                                Movie synopsis
                                            </th>
                                            <th className='text-center'>
                                                View project
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            allProject?.map((val: any, i: any) => {
                                                return (
                                                    <>

                                                        <tr className="table-active" key={val.id}>
                                                            <td>{val.id}</td>
                                                            <td>{val.movie_title}</td>
                                                            <td>{val.movie_log_line}</td>
                                                            <td>{val.movie_synopsis}</td>
                                                            <td className='text-center'>
                                                                <button
                                                                    className='px-2 font22 border-0'
                                                                    style={{
                                                                        color: 'white',
                                                                        width: '150px',
                                                                    }}
                                                                    onClick={() => handleId(val.id)}
                                                                >
                                                                    <PiCaretRightBold className='text-white' />
                                                                </button>
                                                            </td>
                                                        </tr>

                                                        {/* <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        Add this movie to CDBS OTT
                                                                    </label>
                                                                </div> */}
                                                        {/* About Project */}
                                                        {/* <AboutProject project_id={val.id} /> */}
                                                        {/* Award Section */}
                                                        {/* <AwardForm project_id={val.id} /> */}
                                                        {/* Movie Snippets */}
                                                        {/* <MovieSnippets project_id={val.id} /> */}
                                                        {/* Sound Track */}
                                                        {/* <SongsForm project_id={val.id} /> */}
                                                        {/* Teaser and Trailer */}
                                                        {/* <TeaserTrailerLinks project_id={val.id} /> */}
                                                        {/* CAST section */}
                                                        {/* <CastForm project_id={val.id} /> */}
                                                        {/* CREW section */}
                                                        {/* <CrewForm project_id={val.id} /> */}
                                                        {/* Movie reference link */}
                                                        {/* <MovieRefrenceLink project_id={val.id} /> */}

                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default ProjectDetailForm
