import React, { useEffect, useState, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { getHappyMovementListAPI } from '../../utils/utils';
import { ImCross } from 'react-icons/im';
import { Table } from 'react-bootstrap';
import { FaRegEye } from 'react-icons/fa';
import { FiEdit } from "react-icons/fi";
import HappyMomentModal from '../myprofilemodals/HappyMomentModal';
import { IoTrash } from "react-icons/io5";

const HappyMovementForm = () => {

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [getId, setId] = useState<any>('');
    const [happy_moments_list, setHappyMovement_list] = useState<any>([]);
    const [happyMovement, setHappyMovement] = useState({
        title: '',
        description: '',
        image: ''
    });

    const getMovementList = async () => {
        setActive(true);
        const data = await getHappyMovementListAPI();
        setHappyMovement_list(data);
        setActive(false);
    }

    // const handleChangeVal = (e: any) => {
    //     const { name, value } = e.target;
    //     if (name === 'image') {
    //         const movement_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    //         setHappyMovement((prevVal: any) => ({ ...prevVal, image: movement_image }));
    //         handleSaveImage(movement_image);
    //     } else {
    //         setHappyMovement({ ...happyMovement, [name]: value });
    //     }
    // }

    // const handleEditHappyMovement = (e: any) => {
    //     e.preventDefault();
    //     if (isDisabled) {
    //         messageTxt('You can edit your profile...');
    //     } else {
    //         messageTxt("You can't edit your profile...");
    //     }
    //     setIsDisabled(!isDisabled);
    // }

    // const handleSaveHappyMovement = async (e: any) => {
    //     e.preventDefault();
    //     setActive(true);
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     const { title, description } = happyMovement;
    //     if (title === '' || description === '') {
    //         messageTxt('Please fill the title and description...');
    //         setActive(false);
    //     } else {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 title,
    //                 description,
    //                 moment_id: getId
    //             }
    //             const res = await axios(`${baseURL}/api/subscriber/moment/addUpdate`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             setId(res.data.data.id);
    //             messageTxt('Title and description saved successfully.')
    //             setHappyMovement({
    //                 title: '',
    //                 description: '',
    //                 image: ''
    //             });
    //             getMovementList();
    //             setActive(false);
    //         } catch (error) {
    //             console.log(error);
    //             setActive(false);
    //         }
    //     }
    // }

    // const handleSaveImage = async (image: any) => {
    //     setActive(true);
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');
    //     if (image === null || image === undefined) {
    //         messageTxt('Please select image.');
    //         setActive(false);
    //     } else {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 data_type: 'moment',
    //                 id: getId,
    //                 image
    //             }
    //             const res = await axios(`${baseURL}/api/subscriber/addAtachments`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             })
    //             messageTxt(res.data.message);
    //             setId('')
    //             setHappyMovement({
    //                 title: '',
    //                 description: '',
    //                 image: ''
    //             });
    //             getMovementList();
    //             setIsDisabled(true);
    //             setActive(false);
    //         } catch (error) {
    //             console.log(error)
    //             setActive(false);
    //         }
    //     }
    // }

    const deleteMoment = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'moment',
                    id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getMovementList();
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const takehMovementValuesForUpdate = (e: any, val: any) => {
        const data = val;
        setHappyMovement({
            ...happyMovement,
            title: data.title,
            description: data.description
        });
        setId(data.id);
        setOpenFormModal(!openFormModal);
    }

    const handleModalForm = () => {
        getMovementList();
        setOpenFormModal(!openFormModal);
    }

    const handleAddmoreModal = () => {
        getMovementList();
        setOpenAdd(!openAdd);
        setOpenFormModal(false);
    }

    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            getMovementList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log(happy_moments_list);
    // }, [happy_moments_list])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='happy_movement_form mt-3'>
                <div className="accordion accordion-flush" id="happyMovement">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsehappyMovement" aria-expanded="false" aria-controls="flush-collapsehappyMovement">
                                Happy movements
                            </button>
                        </h2>
                        <div id="flush-collapsehappyMovement" ref={myElementRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#happyMovement">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                                {openFormModal &&
                                    <HappyMomentModal handleModalBox={handleModalForm} dataId={getId} data={happyMovement} headingText={'Update'} />
                                }
                                {openAdd &&
                                    <HappyMomentModal handleModalBox={handleAddmoreModal} headingText={'Add New'} />
                                }
                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 personal_save_button rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleAddmoreModal}
                                    >
                                        + Add
                                    </button>
                                </div>
                                {Array.isArray(happy_moments_list) && happy_moments_list.length > 0 &&
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Title</th>
                                                <th>Image</th>
                                                <th>Happy movement description</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(happy_moments_list) && happy_moments_list.length > 0 &&
                                                happy_moments_list.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.title}</td>
                                                            <td
                                                                className='p-0'
                                                            >
                                                                {val.image?.path ?
                                                                    <img src={`${baseURL}/${val.image?.path}`} className='object-fit-contain' height={'50'} width={'50'} />
                                                                    :
                                                                    <img src={`../images/noimage.png`} className='object-fit-contain' height={'50'} width={'50'} />
                                                                }
                                                            </td>
                                                            <td>{val.description}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    <FiEdit className='pointer' onClick={(e) => takehMovementValuesForUpdate(e, val)} />
                                                                    <IoTrash className='pointer' onClick={(e) => deleteMoment(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                }
                                {/* {happy_moments_list.length > 0 && Array.isArray(happy_moments_list) ?
                                    happy_moments_list.map((val: any) => {
                                        return (
                                            <div className="row p-0 mb-5 d-flex justify-content-between align-items-center accordian_border_with_color mb-4" key={val.id}>
                                                <div
                                                    className='d-flex justify-content-end mt-2'
                                                >
                                                    {!isDisabled ?
                                                        <>
                                                            <button
                                                                className='py-2 px-2 ms-2 fw-bold rounded bg-danger'
                                                                onClick={(e) => takehMovementValuesForUpdate(e, val)}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                className='py-2 px-2 ms-2 fw-bold rounded bg-danger'
                                                                onClick={(e) => deleteMoment(e, val.id)}
                                                            >
                                                                <ImCross className='text-white' />
                                                            </button>
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Happy movement description</label>
                                                    <textarea value={val.description} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" placeholder='Eg I am Krishna'></textarea>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Title</label>
                                                    <input type="text" value={val.title} className="form-control mb-3" />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <div
                                                        style={{ height: '100px', width: '100px' }}
                                                    >
                                                        <img src={`${baseURL}/${val.image?.path}`} className='object-fit-contain' height={'100%'} width={'100%'} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                                }


                                <div className="row p-0 mb-5 d-flex justify-content-between align-items-center">
                                    <p className='m-0 mb-3 text-white font18'>Add/Update</p>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Happy movement description</label>
                                        <textarea name='description' onChange={handleChangeVal} value={happyMovement.description} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" disabled={isDisabled}></textarea>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Title</label>
                                        <input type="text" onChange={handleChangeVal} value={happyMovement.title} name='title' className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    {getId ?
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload happy movement image</label>
                                            <input type="file" onChange={handleChangeVal} name='image' className="form-control mb-3" disabled={isDisabled} />
                                        </div>
                                        : null
                                    }
                                </div>

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleEditHappyMovement}
                                    >
                                        Edit
                                    </button>
                                    {!isDisabled ?
                                        <button
                                            className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleSaveHappyMovement}
                                        >
                                            Save
                                        </button>
                                        : null
                                    }
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default HappyMovementForm
