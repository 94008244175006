import React, { useState, useEffect } from 'react';
import './bannerratingcss.css';
import { IoMdStar } from "react-icons/io";
import { FaRegStar } from 'react-icons/fa';
import { BiLike, BiDislike } from 'react-icons/bi';
import { BsFillBookmarkPlusFill } from "react-icons/bs";
import SignInModal from '../../signinmodal/SignInModal';
import { giveRatingAPI, likeAndViewAPI } from '../../../utils/utils';

const BannerRating = (props: any) => {

    const { data } = props;
    const auth_code = localStorage.getItem("auth_code");
    const maxRating = 5;
    const [rate, setRate] = useState(0);
    const stars = [<FaRegStar className='me-1' />, <FaRegStar className='me-1' />, <FaRegStar className='me-1' />, <FaRegStar className='me-1' />, <FaRegStar />];
    const [showLogin, setShowLogin] = useState(false);
    const [like_count, setLikeCount] = useState<any>(data?.like_count);
    const [toggleLike, setToggleLike] = useState<any>();

    const giveRatingToMovie = async (e: any, givenRating: any) => {
        e.stopPropagation();
        if (!auth_code) {
            setShowLogin(true);
        } else {
            const msg = await giveRatingAPI(data?.data_id, givenRating);
            setRate(givenRating);
            console.log(msg);
        }
    }

    const giveLike = async (id: any) => {
        if (!auth_code) {
            setShowLogin(true);
        } else if (!data?.data_id) {
            setToggleLike(0);
        } else {
            const msg = await likeAndViewAPI('like', id);
            setLikeCount(like_count + 1);
            setToggleLike(!toggleLike);
            console.log(msg);
        }
    }

    const handleLoginModalBox = (val: any) => {
        val ? setShowLogin(false) : setShowLogin(false);
    }

    useEffect(() => {
        // console.log(data)
        setLikeCount(data?.like_count);
        setToggleLike(data?.like);
    }, [like_count, toggleLike, data])

    return (
        <>
            {data?.data_id ?
                <>
                    {showLogin ?
                        <SignInModal handleModalBox={handleLoginModalBox} />
                        : null
                    }

                    <div
                        className='parentRating p-2 pt-lg-4 pt-md-4'
                    >
                        <div
                            className='likeBox d-lg-flex d-md-flex d-flex justify-content-lg-end justify-content-md-end justify-content-center'
                        >
                            <span
                                className={`font24 text-white position-relative pointer`}
                                onClick={() => giveLike(data?.data_id)}
                            >
                                <BiLike className={`${toggleLike ? 'text-primary' : 'text-white'}`} />
                                <span
                                    className={`font12 text-white position-absolute`}
                                    style={{
                                        left: '0px',
                                        top: '-10px'
                                    }}
                                >
                                    {like_count}
                                </span>
                            </span>
                            <span
                                className='font24 text-white position-relative mx-3'
                            >
                                <BiDislike />
                                <span
                                    className='font12 position-absolute text-white'
                                    style={{
                                        left: '20%',
                                        top: '-10px'
                                    }}
                                >
                                    1
                                </span>
                            </span>
                            <span
                                className='font24'
                                style={{
                                    color: '#D9D9D9'
                                }}
                            >
                                <BsFillBookmarkPlusFill />
                            </span>
                        </div>
                        <div
                            className='d-lg-flex d-md-flex d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-baseline lh-1'
                        >
                            <span
                                className='font14 font_weight_600 text-warning text-uppercase me-1'
                            >
                                Rate now
                            </span>
                            <span
                                className='font24 text-white ms-2'
                            >
                                {
                                    [...stars].map((star, index) => {
                                        const givenRating = index + 1;
                                        return (
                                            <FaRegStar
                                                className='pointer'
                                                onClick={() => {
                                                    setRate(givenRating);
                                                }}
                                                key={index}
                                                style={{
                                                    color: givenRating < rate || givenRating === rate ? '#FFC107' : '#D9D9D9'
                                                }}
                                            />
                                        )
                                    })
                                }
                            </span>
                        </div>
                        <div
                            className='d-lg-flex d-md-flex d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-baseline lh-1'
                        >
                            <span
                                className='font14 font_weight_600 text-white text-uppercase me-1'
                            >
                                CDBS RATING
                            </span>
                            <span
                                className='font28 text-warning'
                            >
                                {
                                    [...Array(maxRating)].map((_, index) => {
                                        const givenRating = index + 1;

                                        return (
                                            <IoMdStar
                                                key={index}
                                                className='pointer'
                                                onClick={() => setRate(givenRating)}
                                                style={{
                                                    color: givenRating <= (data?.cdbs_rating || 0) ? 'yellow' : '#D9D9D9'
                                                }}
                                            />
                                        );
                                    })
                                }
                            </span>
                        </div>
                    </div>
                </>
                :
                null
            }
        </>
    )
}

export default BannerRating
