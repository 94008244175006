import React from 'react'

const NewUploadCard = (props: any) => {
    const { id, title, image } = props;
    return (
        <div
            className='hover_zoom position-relative pointer my-3'
            style={{
                height: '300px',
                width: '200px'
            }}
        >
            <img src={`${image}`} alt={title} width={'100%'} height={'100%'} />
        </div>
    )
}

export default NewUploadCard
