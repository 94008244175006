import React, { useState, useEffect, useRef } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { sendBirthdayWishes } from '../../utils/utils';

const WishModal = (props: any) => {

    const { handleModalBox, data, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [showThanks, setShowThanks] = useState(false);
    const message = useRef<HTMLTextAreaElement>(null);

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const sendMessage = async () => {
        if (message.current) {
            const value = message.current.value;
            setActive(true);
            const res = await sendBirthdayWishes(data.id, value);
            if (res.status === 'success') {
                setShowThanks(true);
                messageTxt(res.message);
            } else {
                messageTxt('Something went wrong');
            }
            setActive(false);
        } else {
            messageTxt('Please type your wishes...');
        }
    }

    useEffect(() => {
        // console.log(data)
    }, [data])


    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`lg`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                {showThanks ?
                    <div></div>
                    :
                    <p className='m-0 text-warning font_weight_600 font18'>{headingText} <span className='text-white'>{data.name}</span></p>
                }
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    {showThanks ?
                        <div className='d-lg-flex d-md-flex justify-content-between align-items-center my-4'>
                            <div className="d-flex justify-content-center mb-lg-0 mb-md-0 mb-4">
                                <div
                                    style={{
                                        width: '200px',
                                        height: '200px'
                                    }}
                                >
                                    <img src={`../images/wishimage.png`} alt={data.name} width={`100%`} height={`100%`} />
                                </div>
                            </div>
                            <div className="w-50 ms-lg-5 ms-md-5 ms-0 text-center">
                                <h1
                                    className='m-0 text-warning'
                                >
                                    Wishes sent Successfully
                                </h1>
                            </div>
                        </div>
                        :
                        <>
                            <div className='d-lg-flex d-md-flex justify-content-between align-items-center'>
                                <div className="d-flex justify-content-center mb-lg-0 mb-md-0 mb-4">
                                    <div
                                        style={{
                                            width: '200px',
                                            height: '300px'
                                        }}
                                    >
                                        <img src={`${baseURL}/${data?.avatar}`} alt={data.name} width={`100%`} height={`100%`} />
                                    </div>
                                </div>
                                <div className="wish_modal w-100 ms-lg-5 ms-md-5 ms-0">
                                    <p
                                        className='m-0 text-warning'
                                    >
                                        Write a Message
                                    </p>
                                    <textarea name='about' ref={message} className="form-control scroll_bar" style={{ border: '1px solid gray', height: '275px', width: '100%' }} id="exampleFormControlTextarea1" placeholder='eg: Happy birthday'></textarea>
                                </div>
                            </div>

                            <div
                                className='d-flex justify-content-end'
                            >
                                <button
                                    className='rounded bg-danger my-3'
                                    onClick={sendMessage}
                                >
                                    Send wishes
                                </button>
                            </div>
                        </>
                    }

                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default WishModal
