import React, { Fragment, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let auth_code = localStorage.getItem("auth_code");

    useEffect(() => {
        // console.log('asas',location);
        
        // if (auth_code === null) {
        //     navigate("/");
        // }
    }, []);

    return (
        <div className="container-fluid">
            <div className="" style={{height: '100vh'}}>
                <h1 className="text-white">
                    404 Page not found
                </h1>
            </div>
        </div>
    );
};
export default ErrorPage;
