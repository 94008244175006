import React from 'react';
import FacebookLogin from 'react-facebook-login';

const FacebookLoginAuth = () => {
    const responseFacebook = (response: any) => {
        console.log(response);
    }

    const componentClicked = (response: any) => {
        console.log(response);
    }
    
    return (
        <div>
            <FacebookLogin
                appId="1101686427525556"
                fields="name,email,picture"
                onClick={componentClicked}
                callback={responseFacebook}
                cssClass="fb_icons_style"
                textButton='Sign in with Facebook'
                icon="fa-facebook"
            />
        </div>
    )
}

export default FacebookLoginAuth
