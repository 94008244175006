import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';
import Select from 'react-dropdown-select';
import { getAllIndustryTypeAPI, getAllLanguage } from '../../utils/utils';

const InstituteModal = (props: any) => {
    const { handleModalBox, data, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const profile_data = useSelector((state: any) => state.user.user);
    const [selectIndustry, setSelectIndustry] = useState<any>(profile_data.industries);
    const [languages, setLanguages] = useState<any>([]);
    const [industryList, setIndustryList] = useState<any>([]);
    const [instituteDetails, setInstituteDetails] = useState<any>({
        institute_name: '',
        address: '',
        location: '',
        city: '',
        start_date: '',
        end_date: '',
        pin_code: '',
        email: '',
        landline_number: '',
        phone: '',
        facebook_url: '',
        instagram_ur: '',
        linkedin_url: '',
        about: '',
        image: '',
        banner: '',
        language_id: '',
        industry_id: '',
        experience_required: '',
        experience_detail: '',
    });

    const maxMobileNumber = 10;

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const getIndustryList = async () => {
        setActive(true);
        const response = await getAllIndustryTypeAPI();
        const data = response.filter((val: any) => {
            return selectIndustry.includes(val.id);
        })
        setIndustryList(data);
        setActive(false);
    }

    const getLanguage = async () => {
        setActive(true);
        const data = await getAllLanguage();
        setLanguages(data);
        setActive(false);
    }

    const handleInstituteChangeValue = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setInstituteDetails({ ...instituteDetails, image: file });
        } else if (name === 'banner') {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setInstituteDetails({ ...instituteDetails, banner: file });
        } else if (name === 'phone') {
            if (value.length <= maxMobileNumber) {
                setInstituteDetails({ ...instituteDetails, phone: value });
            }
        } else {
            setInstituteDetails({ ...instituteDetails, [name]: value });
        }
    }

    const handleSaveInstituteDetails = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        const { institute_name, address, location, city, start_date, end_date, pin_code, email, landline_number, phone, facebook_url, instagram_ur, linkedin_url, about, image,
            banner, language_id, industry_id, experience_required, experience_detail } = instituteDetails;

        if (institute_name === '' || address === '' || city === '' || pin_code === '' || email === '' || landline_number === '' || facebook_url === '' || start_date === '' || end_date === '' ||
            instagram_ur === '' || phone === '' || linkedin_url === '' || about === '' || language_id === '' || image === '' || banner === '' ||
            location === '' || industry_id === '' || experience_required === '' || experience_detail === ''
        ) {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    institute_name, address, location, city, start_date, end_date, pin_code, email, landline_number, phone, facebook_url, instagram_ur, linkedin_url, about, image,
                    banner, language_id, industry_id, experience_required, experience_detail
                }
                const res = await axios(`${baseURL}/api/create_filmInstitute`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                messageTxt(res.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }


    useEffect(() => {
        getIndustryList();
        getLanguage();
    }, []);

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >

                    <div className="row p-0 d-flex justify-content-between align-items-center create_crew_form audition_form_modal">
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="about" className="form-label text-white m-0">About</label>
                            <textarea 
                                name="about" 
                                onChange={handleInstituteChangeValue} 
                                value={instituteDetails.about} 
                                placeholder='About institute' 
                                className="form-control mb-3" 
                                id="about" 
                                style={{
                                    height: '50px',
                                    border: '1px solid gray',
                                }}
                            >
                            </textarea>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="institute_name" className="form-label text-white m-0">Institute name</label>
                            <input type="text" name='institute_name' onChange={handleInstituteChangeValue} value={instituteDetails.institute_name} placeholder='Institute name' className="form-control mb-3" id="institute_name" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="language_id" className="form-label text-white m-0">Language</label>
                            <Select
                                name='selected'
                                className='mb-3 text-white'
                                options={languages}
                                labelField='name'
                                valueField='id'
                                multi
                                searchable={false}
                                placeholder='Select multiple languages...'
                                onChange={(values: any) => {
                                    const selectedIds = values.map((option: any) => option.id);
                                    setInstituteDetails({ ...instituteDetails, language_id: selectedIds })
                                }}
                                values={[]}
                                style={{
                                    height: '50px',
                                    borderRadius: '5px',
                                    border: '1px solid white'
                                }}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="industry_id" className="form-label text-white m-0">Industry type</label>
                            <select 
                                className="form-select form-select-lg mb-3 bg-transparent text-white" 
                                name='industry_id' 
                                value={instituteDetails.industry_id} 
                                onChange={handleInstituteChangeValue} 
                                aria-label="Large select example"
                            >
                                <option value="" disabled selected>Select industry</option> {/* Acts as a placeholder */}
                                {Array.isArray(industryList) &&
                                    industryList.map((val: any) => (
                                        <option key={val.id} value={val.id}>{val.title}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="experience_required" className="form-label text-white m-0">Select experience</label>
                            <select 
                                className="form-select form-select-lg mb-3 bg-transparent text-white" 
                                name='experience_required' 
                                value={instituteDetails.experience_required} 
                                onChange={handleInstituteChangeValue} 
                                aria-label="Large select example"
                            >
                                <option value="" disabled>Select experience</option> {/* Acts as a placeholder */}
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                            </select>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="about" className="form-label text-white m-0">About experience</label>
                            <textarea 
                                name="experience_detail" 
                                onChange={handleInstituteChangeValue} 
                                value={instituteDetails.experience_detail} 
                                placeholder='About experience' 
                                className="form-control mb-3" 
                                id="about" 
                                style={{
                                    height: '50px',
                                    border: '1px solid gray',
                                }}
                            >
                            </textarea>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="address" className="form-label text-white m-0">Address</label>
                            <input type='text' name="address" onChange={handleInstituteChangeValue} value={instituteDetails.address} placeholder='Institute address' className="form-control mb-3" id="address" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="location" className="form-label text-white m-0">Location</label>
                            <input type="text" name='location' onChange={handleInstituteChangeValue} value={instituteDetails.location} placeholder='Institute location' className="form-control mb-3" id="location" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="city" className="form-label text-white m-0">City</label>
                            <input type="text" onChange={handleInstituteChangeValue} name='city' value={instituteDetails.city} placeholder='eg: Bangalore' className="form-control mb-3" id="city" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="pin_code" className="form-label text-white m-0">Pin code</label>
                            <input type="number" onChange={handleInstituteChangeValue} name='pin_code' value={instituteDetails.pin_code} placeholder='eg: 208005' className="form-control mb-3" id="pin_code" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="start_date" className="form-label text-white m-0">Start date</label>
                            <input type="date" onChange={handleInstituteChangeValue} name='start_date' value={instituteDetails.start_date} className="form-control mb-3 cal_icon" id="start_date" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="end_date" className="form-label text-white m-0">End date</label>
                            <input type="date" onChange={handleInstituteChangeValue} name='end_date' value={instituteDetails.end_date} className="form-control mb-3 cal_icon" id="end_date" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="email" className="form-label text-white m-0">Email</label>
                            <input type="email" onChange={handleInstituteChangeValue} name='email' value={instituteDetails.email} placeholder='Institute official email' className="form-control mb-3" id="email" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="landline_number" className="form-label text-white m-0">Landline number</label>
                            <input type="number" onChange={handleInstituteChangeValue} name='landline_number' value={instituteDetails.landline_number} placeholder='telephone number' className="form-control mb-3" id="landline_number" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="phone" className="form-label text-white m-0">Phone</label>
                            <input type="number" onChange={handleInstituteChangeValue} name='phone' value={instituteDetails.phone} placeholder='Mobile number' className="form-control mb-3" id="phone" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="facebook_url" className="form-label text-white m-0">Facebook url</label>
                            <input type="text" onChange={handleInstituteChangeValue} name='facebook_url' value={instituteDetails.facebook_url} placeholder='eg: https://www.facebook.com/' className="form-control mb-3" id="facebook_url" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="instagram_ur" className="form-label text-white m-0">Instagram url</label>
                            <input type="text" onChange={handleInstituteChangeValue} name='instagram_ur' value={instituteDetails.instagram_ur} placeholder='eg: https://www.instagram.com/' className="form-control mb-3" id="instagram_ur" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="linkedin_url" className="form-label text-white m-0">Linkedin url</label>
                            <input type="text" onChange={handleInstituteChangeValue} name='linkedin_url' value={instituteDetails.linkedin_url} placeholder='eg: https://in.linkedin.com/?src=go-pa&trk=sem-ga' className="form-control mb-3" id="linkedin_url" />
                        </div>

                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="image" className="form-label text-white m-0">Cover image</label>
                            <input type="file" onChange={handleInstituteChangeValue} name='image' className="form-control mb-3" id="image" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="banner" className="form-label text-white m-0">Banner image</label>
                            <input type="file" onChange={handleInstituteChangeValue} name='banner' className="form-control mb-3" id="banner" />
                        </div>
                    </div>

                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                        <button
                            className='py-2 px-2 border-0 common_button rounded font_weight_600'
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                width: '150px'
                            }}
                            onClick={handleSaveInstituteDetails}
                        >
                            Save
                        </button>
                    </div>
                </LoadingOverlay>
            </div >
            <ToastContainer />
        </Modal>
    )
}

export default InstituteModal
