import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const ProjectCrewModal = (props: any) => {
    const { handleModalBox, data, project_id, dataId, headingText } = props;
    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [crewData, setcrewData] = useState({ name: '', role: '', image: '' });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setcrewData({ ...crewData, image: image })
        } else {
            setcrewData({ ...crewData, [name]: value })
        }
    };

    const handleAddCrew = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    project_id: project_id,
                    crew: [crewData]
                }
                let result = await axios(`${baseURL}/api/project/add-crew`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                setcrewData({ name: '', role: '', image: '' });
                messageTxt(result.data.message);
                setActive(false);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handleUpdateCrew = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    crew_id: dataId,
                    name: crewData.name,
                    role: crewData.role,
                    image: crewData.image
                }
                let result = await axios(`${baseURL}/api/project/update-crew`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setActive(false);
                // props.handleId(result.data.data.project_id);
                // console.log(result.data.data.project_id);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const takeCrewValuesForUpdate = () => {
        setActive(true);
        if (dataId) {
            setEnableUpdate(true);
            setcrewData({
                ...crewData,
                name: data.name,
                role: data.role,
                image: data.image
            });
        }
        setActive(false);
    }

    useEffect(() => {
        takeCrewValuesForUpdate();
    }, [data, dataId])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center cast_modal">
                        <div className="row p-0 d-flex justify-content-between align-items-center">
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                                <input type="text" name='name' value={crewData.name} onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="movieTitle" className="form-label text-white m-0">Role</label>
                                <input type="text" name='role' value={crewData.role} onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="movieTitle" className="form-label text-white m-0">Photo</label>
                                <input type="file" name='image' className="form-control mb-3" onChange={handleChange} id="exampleFormControlInput1" />
                            </div>
                        </div>

                        <div className='d-flex justify-content-end'>
                            {enableUpdate ?
                                <button
                                    className='py-2 px-2 border-0 rounded font_weight_600'
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        width: '150px'
                                    }}
                                    onClick={handleUpdateCrew}
                                >
                                    Update
                                </button>
                                :

                                <button
                                    className='py-2 px-2 border-0 rounded font_weight_600'
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        width: '150px'
                                    }}
                                    onClick={handleAddCrew}
                                >
                                    ADD
                                </button>
                            }
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default ProjectCrewModal
