import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { extractIdFromUrl, removeDotReplaceSpaceToHyphen } from '../../utils/jsfunctions';
import { shop_product_detail } from '../../utils/constaints';
import { PiCaretLeftBold } from "react-icons/pi";
import { LuStar } from "react-icons/lu";
import BuyNowModal from '../../components/shopcomponents/BuyNowModal';
import ProductImageGallery from '../../components/shopcomponents/ProductImageGallery';
import { FaRegStar } from 'react-icons/fa';
import { AiOutlineLike } from 'react-icons/ai';
import { ImShare2 } from 'react-icons/im';
import { IoMdStar } from 'react-icons/io';
import ProgressBar from "@ramonak/react-progress-bar";
import { addToCartProductAPI, getEcomProductDetailsAPI } from '../../utils/utils';
import SignInModal from '../../components/signinmodal/SignInModal';

const ShopProductDetail = () => {

    const params = useParams();
    const { id } = params;
    const urlId = extractIdFromUrl(id);
    const messageTxt = (message: any) => toast(message);
    const navigate = useNavigate();
    const auth_code = localStorage.getItem('auth_code');
    const [isActive, setActive] = useState(false);
    const [quantity, setQuantity] = useState<number>(1);
    const [openBuyModal, setOpenModal] = useState(false);
    const [productData, setProductData] = useState<any>([]);
    const [productSizes, setProductSizes] = useState<any>([]);
    const [selectedSize, setSelectedSize] = useState<any>();
    const [openLogin, setOpenLogin] = useState(false);
    const [rate, setRate] = useState(0);

    const getProductDetail = async () => {
        setActive(true);
        const data = await getEcomProductDetailsAPI(urlId);
        setProductData(data.inventory_detail);
        setSelectedSize(data.inventory_detail.attribute_value_id);
        setProductSizes(Object.values(data.sizes));
        setActive(false);
    }

    const handleAddToCart = async (inventory_id: any) => {
        setActive(true);
        if (!auth_code) {
            setOpenLogin(true);
        } else {
            const data = await addToCartProductAPI(inventory_id, quantity);
            messageTxt(data);
        }
        setActive(false);
    }

    const handleProductSize = (id: any) => {
        setSelectedSize(id);
        const data = productSizes.filter((val: any) => val.attribute_value_id.includes(id));
        setProductData(data[0])
    }

    const incrementQty = () => {
        setQuantity(quantity + 1);
    }

    const decrementQty = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        } else if (quantity <= 0) {
            setQuantity(1);
        }
    }

    const handleBuyNow = async (inventory_id: any) => {

        setActive(true);
        if (!auth_code) {
            setOpenLogin(true);
        } else {
            const data = await addToCartProductAPI(inventory_id, quantity);
            messageTxt(data);
            navigate('/menu/shopping-cart');
        }
        setActive(false);
    }

    const closeLogin = (val: any) => {
        setOpenLogin(val);
    }

    useEffect(() => {
        getProductDetail();
    }, [id])

    useEffect(() => {
        // console.log('aa', productData);
    }, [productData])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            {openLogin &&
                <SignInModal handleModalBox={closeLogin} />
            }
            {openBuyModal &&
                <BuyNowModal handleModalBox={handleBuyNow} />
            }
            <div
                className='shop_header_section d-flex justify-content-between align-items-center theme_color py-2 px-lg-4 px-md-4 px-2 mt-lg-5 mt-md-5 mt-2'
            >
                <div
                    className='text-warning d-flex align-items-center'
                >
                    <PiCaretLeftBold className='font20 me-2' />
                    <Link to={`/menu/shop`} className='text-decoration-none text-warning font_weight_600 font16'
                    >
                        Back
                    </Link>
                </div>
                <div
                    className='py-2'
                >
                    <Link to={`/menu/my-order`} className='text-decoration-none text-white'
                    >
                        My Order
                    </Link>
                    <Link to={`/menu/shopping-cart`} className='ms-3 text-decoration-none text-white'
                    >
                        My Cart
                    </Link>
                </div>
            </div>

            <div
                className='shop_product_detail_secton mt-4'
            >
                <div
                    className='row'
                >
                    <div
                        className='col-lg-6 col-md-6 col-12'
                    >
                        <ProductImageGallery images={productData?.images} />
                        {/* <div
                            className='mt-lg-3 mt-md-3 mt-2 d-flex justify-content-between align-items-center'
                        >
                            <div
                                className='product_rating_box'
                            >
                                <span
                                    className={`font12 font_weight_600 text-warning text-uppercase me-1`}
                                >
                                    Rate now
                                </span>
                                <span
                                    className='font20 text-white ms-2'
                                >
                                    {
                                        [...Array(5)].map((star, index) => {
                                            const givenRating = index + 1;
                                            return (
                                                <FaRegStar
                                                    className='me-1 pointer'
                                                    key={id}
                                                    style={{
                                                        color: givenRating < rate || givenRating === rate ? '#FFC107' : '#D9D9D9'
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </span>
                            </div>
                            <div
                                className='product_like_share_box'
                            >
                                <span>
                                    <AiOutlineLike
                                        className={`text-white me-3 font20`}
                                    />
                                </span>
                                <span>
                                    <ImShare2 className={`text-white font20`} />
                                </span>
                            </div>
                        </div> */}
                        {/* <div
                            className='mt-lg-5 mt-md-5 mt-3'
                        >
                            <p
                                className='m-0 text-white font18 font_weight_600'
                            >
                                Ratings
                            </p>
                            <div
                                className='row my-3'
                            >
                                <div
                                    className='col-lg-3 col-md-3 col-12'
                                >
                                    <div>
                                        <p
                                            className='text-warning font_weight_600 m-0 lh-1  ms-2'
                                            style={{
                                                fontSize: '72px'
                                            }}
                                        >
                                            4/5
                                        </p>
                                        <span
                                            className='font20 text-white'
                                        >
                                            {
                                                [...Array(5)].map((star, index) => {
                                                    const givenRating = index + 1;
                                                    return (
                                                        <IoMdStar
                                                            className='me-1 pointer'
                                                            key={id}
                                                            style={{
                                                                color: givenRating < rate || givenRating === rate ? '#FFC107' : '#D9D9D9'
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className='col-lg-9 col-md-9 col-12'
                                >
                                    <div
                                        className='mb-1'
                                    >
                                        <div>
                                            <ProgressBar
                                                completed={80}
                                                className="wrapper"
                                                barContainerClassName="product_progress_bar_container"
                                                completedClassName="barCompleted1"
                                                labelClassName="barlabel"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='mb-1'
                                    >
                                        <div>
                                            <ProgressBar
                                                completed={80}
                                                className="wrapper"
                                                barContainerClassName="product_progress_bar_container"
                                                completedClassName="barCompleted2"
                                                labelClassName="barlabel"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='mb-1'
                                    >
                                        <div>
                                            <ProgressBar
                                                completed={80}
                                                className="wrapper"
                                                barContainerClassName="product_progress_bar_container"
                                                completedClassName="barCompleted3"
                                                labelClassName="barlabel"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='mb-1'
                                    >
                                        <div>
                                            <ProgressBar
                                                completed={80}
                                                className="wrapper"
                                                barContainerClassName="product_progress_bar_container"
                                                completedClassName="barCompleted4"
                                                labelClassName="barlabel"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <ProgressBar
                                                completed={80}
                                                className="wrapper"
                                                barContainerClassName="product_progress_bar_container"
                                                completedClassName="barCompleted5"
                                                labelClassName="barlabel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p
                                className='m-0 text-white font18 font_weight_600'
                            >
                                1.6k Verified Buyers
                            </p>
                        </div>
                        <div
                            className='mt-lg-5 mt-md-5 mt-3'
                        >
                            <p
                                className='m-0 text-warning font_weight_600 font16'
                            >
                                Customer Reviews
                            </p>
                            <textarea className="form-control cart_text_area mt-2" placeholder='No reviews yet'></textarea>
                        </div> */}
                    </div>
                    <div
                        className='col-lg-6 col-md-6 col-12'
                    >
                        <div
                            className='border_bottom_with_color_shop py-3'
                        >
                            <p
                                className='m-0 text-warning font_weight_600 font20'
                            >
                                {productData?.name}
                            </p>
                            {/* <div
                                className='product_rating font14 bg-warning font_weight_600 px-2 mt-lg-3 mt-md-3 mt-2'
                            >
                                <span>
                                    <LuStar /> 4.2
                                </span>
                                <span>
                                    1.6k Ratings
                                </span>
                            </div> */}
                        </div>
                        <div
                            className='mt-lg-4 mt-md-4 mt-3'
                        >
                            <div
                                className='py-2'
                            >
                                <span
                                    className='text-warning font30 font_weight_600'
                                >
                                    &#x20B9; {productData?.sale_price}
                                </span>
                                <span
                                    className='text-white font24 ms-4 me-2'
                                >
                                    MRP
                                </span>
                                <span
                                    className='font24 text-secondary text-decoration-line-through'
                                >
                                    &#x20B9; {productData?.price}
                                </span>
                                <span
                                    className='text-warning font24 ms-4'
                                >
                                    (50% OFF)
                                </span>
                                <p
                                    className='m-0 mt-2 text-white font14'
                                >
                                    <i>
                                        Tax included. Shipping calculated at checkout.
                                    </i>
                                </p>
                            </div>

                            <div
                                className='theme_color p-3 w-75'
                            >
                                <div
                                    className='mb-4'
                                >
                                    <span
                                        className='text-white font16 text-uppercase'
                                    >
                                        COLOR
                                    </span>
                                    <div
                                        className='d-flex'
                                    >
                                        <div
                                            className={`product_detail_color_div mt-2`}
                                            style={{ backgroundColor: `${productData.color}` }}
                                        >
                                        </div>
                                        {/* {(Array.isArray(productData) && productData.length > 0) &&
                                            productData[0]?.colors.map((cols: any, i: number) => {
                                                const index = i + 1;
                                                return (
                                                    <div
                                                        key={cols}
                                                        className={`product_detail_color_div ${index % 2 === 0 ? 'mx-1' : ''}`}
                                                        style={{ backgroundColor: `${cols}` }}
                                                    >
                                                    </div>
                                                )
                                            })
                                        } */}
                                    </div>
                                </div>
                                <div
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <span
                                        className='text-white font16 text-uppercase'
                                    >
                                        select size
                                    </span>
                                    {/* <span
                                        className='text-white font16 text-capitalize text-decoration-underline'
                                    >
                                        size chart
                                    </span> */}
                                </div>

                                <div
                                    className='d-flex mt-2'
                                >
                                    {(Array.isArray(productSizes) && productSizes.length > 0) &&
                                        productSizes.map((val: any, i: number) => {
                                            const index = i + 1;
                                            return (
                                                <button
                                                    key={val.id}
                                                    onClick={() => handleProductSize(val.attribute_value_id)}
                                                    className={`product_size ${selectedSize === val.attribute_value_id ? 'bg-warning' : 'theme_color'} text-white text-uppercase p-2 ${index % 2 === 0 ? 'mx-2' : ''}`}
                                                >
                                                    {val.size}
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div
                                className='mt-lg-4 mt-md-4 mt-2'
                            >
                                {/* <div
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <span
                                        className='text-white font16 text-uppercase'
                                    >
                                        select size
                                    </span>
                                    <span
                                        className='text-white font16 text-capitalize text-decoration-underline'
                                    >
                                        size chart
                                    </span>
                                </div> */}

                                {/* <div
                                    className='d-flex mt-2'
                                >
                                    {(Array.isArray(productSizes) && productSizes.length > 0) &&
                                        productSizes.map((val: any, i: number) => {
                                            const index = i + 1;
                                            return (
                                                <button
                                                    key={val.id}
                                                    onClick={() => handleProductSize(val.attribute_value_id)}
                                                    className={`product_size ${selectedSize === val.attribute_value_id ? 'bg-warning' : 'theme_color'} text-white text-uppercase p-2 ${index % 2 === 0 ? 'mx-2' : ''}`}
                                                >
                                                    {val.size}
                                                </button>
                                            )
                                        })
                                    }
                                </div> */}

                                <div
                                    className='mt-lg-5 mt-md-5 mt-3 d-flex align-items-center'
                                >
                                    <p
                                        className='m-0 text-white text-uppercase me-3'
                                    >
                                        quantity
                                    </p>
                                    <div
                                        className='theme_color px-2'
                                    >
                                        <span
                                            className='qty_button font24 pointer'
                                            onClick={decrementQty}
                                        >
                                            -
                                        </span>
                                        <span className='text-white mx-4'>{quantity}</span>
                                        <span
                                            className='qty_button font24 pointer'
                                            onClick={incrementQty}
                                        >
                                            +
                                        </span>
                                    </div>
                                </div>

                                <div
                                    className='mt-lg-5 mt-md-5 mt-3 d-flex align-items-center'
                                >
                                    <button
                                        className='add_to_cart_button font_weight_600 me-lg-5 me-md-5 me-0'
                                        onClick={() => handleAddToCart(productData?.id)}
                                    >
                                        Add to Cart
                                    </button>
                                    <button
                                        className='by_now_button font_weight_600 ms-5'
                                        onClick={() => handleBuyNow(productData?.id)}
                                    >
                                        Buy Now
                                    </button>
                                </div>
                            </div>

                            <div
                                className='theme_color mt-lg-5 mt-md-5 mt-3 d-flex align-items-center justify-content-around py-3'
                            >
                                <img src='../../images/pquality.png' alt='quality' width={'70px'} height={'70px'} />
                                <img src='../../images/pshiping.png' alt='shiping' width={'70px'} height={'70px'} />
                                <img src='../../images/preturn.png' alt='return' width={'50px'} height={'70px'} />
                                <img src='../../images/pcheckout.png' alt='checkout' width={'60px'} height={'70px'} />
                            </div>

                            <div
                                className='mt-lg-5 mt-md-5 mt-3'
                            >
                                <p
                                    className='m-0 text-white font16'
                                >
                                    MATERIAL
                                </p>
                                <p
                                    className='m-0 text-white font16'
                                >
                                    {productData?.product_material}
                                </p>
                            </div>

                            <div
                                className='mt-lg-5 mt-md-5 mt-3'
                            >
                                <p
                                    className='m-0 text-white font16'
                                >
                                    PRODUCT INFO
                                </p>
                                <p
                                    className='m-0 text-white font16'
                                >
                                    {productData?.description}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default ShopProductDetail