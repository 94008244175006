import React, { useEffect, useState } from 'react';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import { getFestivals, getCategoryListAPI } from '../../utils/utils';

const CategoryForm = (props: any) => {

    const { festival_data, festival_id } = props;
    const { industry_reference } = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [festivalCategory, setCategory] = useState<string[]>([]);
    const [cat_data, setCatData] = useState<any>([]);

    const handleAddFestivalCat = (e: any) => {
        setCategory([...festivalCategory, '']);
    }

    const getCategoryList = async () => {
        const data = await getCategoryListAPI(festival_id);
        setCatData(data);
    }

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleChange = (e: any, i: any) => {
        const { value } = e.target;
        const updatedcastData = [...festivalCategory];

        if (typeof i === 'number' && i >= 0 && i < updatedcastData.length) {
            updatedcastData[i] = value;
            setCategory(updatedcastData);
        } else {
            console.error('Invalid index provided for array update.');
        }
    };

    const handleDeleteField = (e: any, i: any) => {
        e.preventDefault();
        const deletedData = [...festivalCategory];
        deletedData.splice(i, 1);
        setCategory(deletedData);
    }

    const handleDeleteData = (e: any, i: any, id:any) => {
        e.preventDefault();
        const deletedData = [...cat_data];
        deletedData.splice(i, 1);
        setCatData(deletedData);
        handleCategoryDelete(e, id);
    }

    const handleCategoryDelete = async (e: any, id:any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id: id,
                }
                let result = await axios(`${baseURL}/api/film_festival/category/delete`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getCategoryList();
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleCategoryUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    id: festival_id,
                    categories: JSON.stringify(festivalCategory)
                }
                let result = await axios(`${baseURL}/api/film_festival/category/create`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getCategoryList();
                setIsDisabled(!isDisabled);
                getFestivals();
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getCategoryList();
    }, []);

    // useEffect(() => {
    //     console.log('cat', cat_data);
    // }, [cat_data])

    return (
        <>
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="festivalCategory">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefestivalCategory" aria-expanded="false" aria-controls="flush-collapsefestivalCategory">
                                Category
                            </button>
                        </h2>
                        <div id="flush-collapsefestivalCategory" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#festivalCategory">
                            <div className="accordion-body border theme_color">
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <span className='m-0 text-white fw-bold font18'>Category List</span>
                                    {/* <button
                                        className='py-2 px-2 border-0'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleAddFestivalCat}
                                    >
                                        +Add More
                                    </button> */}
                                </div>

                                <div className="row p-0 create_cast_form biography_form mb-4">
                                {Array.isArray(cat_data) &&
                                    cat_data?.map((val: any, i: any) => {
                                        return (
                                            
                                                <div className='col-lg-6 col-md-6 col-12' key={val.id || i}>
                                                    <div
                                                        className='position-relative'
                                                    >
                                                        
                                                        <input type="text" value={val.name} className="form-control mb-3" disabled={isDisabled} />
                                                        <div
                                                            className='position-absolute'
                                                            style={{
                                                                right: '5px',
                                                                bottom: '5px'
                                                            }}
                                                        >
                                                            {!isDisabled ?
                                                                <button
                                                                    className='py-2 px-2 common_button rounded'
                                                                    style={{
                                                                        backgroundColor: 'red',
                                                                    }}
                                                                    onClick={(e) => handleDeleteData(e, i, val.id)}
                                                                >
                                                                    <ImCross className='text-white' />
                                                                </button>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        )
                                    })
                                }
                                </div>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <button
                                        className='py-2 btn btn-outline-danger px-2'
                                        style={{
                                            width: '200px'
                                        }}
                                        onClick={handleAddFestivalCat}
                                    >
                                        +Add More Category
                                    </button>
                                </div>
                                <div className="row p-0 create_cast_form biography_form mb-4">
                                {
                                    festivalCategory.map((val: any, i: any) => {
                                        return (
                                            
                                                <div className='col-lg-12 col-md-12 col-12' key={i}>
                                                    <div
                                                        className='position-relative'
                                                    >
                                                        <label htmlFor="movieTitle" className="form-label text-white m-0">Add new category</label>
                                                        <input type="text" name='category' value={val} onChange={(e) => handleChange(e, i)} className="form-control mb-3" disabled={isDisabled} />
                                                        <div
                                                            className='position-absolute'
                                                            style={{
                                                                right: '5px',
                                                                bottom: '5px'
                                                            }}
                                                        >
                                                            {!isDisabled ?
                                                                <button
                                                                    className='py-2 px-2 common_button rounded'
                                                                    style={{
                                                                        backgroundColor: 'red',
                                                                    }}
                                                                    onClick={(e) => handleDeleteField(e, i)}
                                                                >
                                                                    <ImCross className='text-white' />
                                                                </button>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        )
                                    })
                                }
                                </div>

                                <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        {isDisabled ? 'Edit' : 'Cancel'}
                                    </button>
                                    {!isDisabled ?
                                        <button
                                            className='py-2 px-2 common_button rounded fw-bold'
                                            style={{
                                                width: '150px'
                                            }}
                                            onClick={handleCategoryUpdate}
                                        >
                                            Save
                                        </button>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default CategoryForm
