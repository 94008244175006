import React, { useState, useEffect } from 'react'
import './shopcss.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import ShopCard from './ShopCard';
import { Link } from 'react-router-dom';
import { getEcomProductListAPI, totalPagesCalc } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { IoMenu } from "react-icons/io5";


const DisplayProduct = (props: any) => {

    const { selectedProduct, handleSideMenu } = props;
    const { pNumber } = useSelector((state: any) => state.pNumber);
    const [isActive, setActive] = useState(false);
    const [limit, setLimit] = useState<any>([]);
    const [activePage, setActivePage] = useState(pNumber);
    const [total_product, setTotal_product] = useState(0);
    const [products, setProducts] = useState<any>([]);

    const getProductDetails = async () => {
        setActive(true);
        const data = await getEcomProductListAPI(selectedProduct);
        setProducts(data.data);
        // console.log(data.data);
        setTotal_product(data.total);
        setLimit(data.limit);
        setActive(false);
    };

    useEffect(() => {
        getProductDetails();
    }, [selectedProduct])


    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className="product-display">
                {(Array.isArray(products) && products.length > 0) && (
                    <div className='text-white'>
                        <div
                            className='d-lg-flex d-md-flex d-flex justify-content-between align-items-center order_bottom_with_color mb-lg-4 mb-md-4 mb-3 py-2 px-lg-4 px-md-4 px-2 mt-lg-5 mt-md-5 mt-0'
                        >
                            <IoMenu className='text-white font24 d-block d-sm-none' onClick={handleSideMenu} />
                            <div
                                className='py-2'
                            >
                                <Link to={`/menu/my-order`} className='text-decoration-none text-white'
                                >
                                    My Order
                                </Link>
                                <Link to={`/menu/shopping-cart`} className='ms-3 text-decoration-none text-white'
                                >
                                    My Cart
                                </Link>
                            </div>
                        </div>
                        <div
                            className='row'
                        >
                            {(Array.isArray(products) && products.length > 0) &&
                                products.map((val: any) => {
                                    return (
                                        <div
                                            key={val.id}
                                            className='col-lg-3 col-md-6 col-12 mb-4'
                                        >
                                            <ShopCard id={val.inventory_id} image={val.img_url} title={val.name} type={val.subcategory_name} discountPrice={val.sale_price} colors={val.color} price={val.price} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )}
            </div>

            <div className='d-flex justify-content-center align-items-center py-5 my-lg-5 my-md-5'>
                <ul className='text-white d-flex justify-content-center align-items-center gap-lg-3 gap-md-3 gap-2 p-0 px-2 paginationUl'>
                    {
                        activePage !== 1 && <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage - 1)}>Previous</li>
                    }
                    {
                        totalPagesCalc(total_product, limit).slice(Math.max(0, activePage - 3), Math.min(total_product, activePage + 4)).map((val) => {
                            return <li className={`pageList py-1 px-2 ${val === activePage ? 'activePageList' : ''}`} onClick={() => setActivePage(val)} key={val}>{val}</li>
                        })
                    }
                    {activePage !== Math.ceil(total_product / limit) ? <li className='pageList py-1 px-2' onClick={() => setActivePage(activePage + 1)}>Next</li> : ''}
                </ul>
            </div>

        </LoadingOverlay>
    )
}

export default DisplayProduct
