import React, { useRef, useEffect, useState } from 'react';
import './movieslider.css';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import MovieCard from '../moviecard/MovieCard';
import ImageCard from '../imagecard/ImageCard';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

const MovieCardSlider = (props: any) => {

    const { heading, leftIndicator, horizontalImage, view_all_link, data, birthday } = props;
    // const [ data, setdata ] = useState(data);

    let sliderRef = useRef<any>(null);
    // const next = () => {
    //     sliderRef.current?.slickNext();
    // };
    // const previous = () => {
    //     sliderRef.current.slickPrev();
    // };

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: Math.min(data?.length || 0, horizontalImage ? 4 : 5),
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,

                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,

                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,

                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,

                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,

                },
            },
        ],
    };

    // useEffect(() => {
    //     console.log('ref',data)
    // }, [data])

    return (
        <div className=''>
            <div
                className='d-flex justify-content-between align-items-center mb-3 mx-lg-0 mx-md-0 mx-2'
            >
                <h5 className='m-0 text-warning text-capitalize fw-bold font26' dangerouslySetInnerHTML={{ __html: heading }}></h5>
                <Link to={view_all_link} className='text-decoration-none text-warning text-capitalize font_weight_600 text-center font16 ms-2 pointer'>
                    view all &gt;
                </Link>
            </div>
            {
                horizontalImage !== true ?
                    // <div className='col-lg-10 col-md-10 col-12'>
                    <Slider {...settings} ref={sliderRef} className='movie_card_slider'>
                        {
                            data?.map((e: any) => {
                                return (
                                    <div key={e?.id}>
                                        <MovieCard id={e?.id} cover={e?.cover} title={e?.title} genre={e?.genre} dob={e?.date} watch={e?.watch} like={e?.like} birthday={birthday} cdbs_rating={e?.cdbs_rating} rating={e?.rating} />
                                    </div>
                                );
                            })
                        }
                    </Slider>
                    // </div>
                    :
                    // <div className='col-lg-10 col-md-10 col-12'>
                    <Slider {...settings} ref={sliderRef}>
                        {
                            data?.map((e: any) => {
                                return (
                                    <div key={e?.id} className='d-grid justify-content-center slick-slide1'>
                                        <ImageCard id={e?.id} cover={e.cover} title={e.title} date={e.date} language={e.language} watch={e?.watch} like={e?.like} />
                                    </div>
                                );
                            })
                        }
                    </Slider>
                // </div>
            }

            {/* <div className='col-lg-2 col-md-2 col-12 d-flex justify-content-center align-items-center'>
                    <div className='w-100 d-lg-block d-md-block justify-content-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='viewAll_line w-100'></div>
                            <Link to={view_all_link} className='text-decoration-none text-warning text-capitalize text-center font12 ms-2 pointer w-25'>
                                view all &gt;
                            </Link>
                        </div>

                        <div className='my-5'>
                            <h5 className='text-warning text-capitalize fw-bold text-lg-start text-md-start text-center font26' dangerouslySetInnerHTML={{ __html: heading }}></h5>
                            <div className='text-white d-flex justify-content-center justify-content-lg-start align-items-center mt-2'>
                                <div className='border rounded-pill p-1 d-flex align-items-center justify-content-center' onClick={previous}>
                                    <PiCaretLeftBold />
                                </div>
                                <div className='border mx-4 rounded-pill p-1 d-flex align-items-center justify-content-center' onClick={next}>
                                    <PiCaretRightBold />
                                </div>
                            </div>
                        </div>
                        <div className='viewAll_line'></div>
                    </div>
                </div> */}
        </div>
    )
}

export default MovieCardSlider
