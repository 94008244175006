import React from 'react';
import './footer.style.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { Link, NavLink } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { footerNemu } from '../../utils/constaints';
import { FaThreads } from 'react-icons/fa6';
import { replaceUnderscoreAndCapitalize } from '../../utils/utils';

const Footer = () => {
    return (
        <footer>
                <div className='footer_body'>
                    <div className='text-white text-center py-5 d-flex justify-content-center align-items-end'>
                        <img src='/images/logo.png' alt='logo' width='auto' height='50' />
                        <p 
                            className='m-0 font16 ms-2'
                        >
                            BETA
                        </p>
                    </div>
                    <div
                        className='menu-explore-container d-lg-flex d-md-block d-block justify-content-lg-center justify-content-md-center justify-content-center breadcrumb'
                    >
                        {
                            footerNemu.map((menuItem) => {
                                return (
                                    <div key={menuItem}>
                                    <NavLink to={`/${menuItem.replace(/\s/g, '').toLowerCase()}`} className="text-center text-decoration-none">
                                        <Typography
                                            className="text-white footer_li_menu text-capitalize w-100 text-center"
                                            variant="overline"
                                            gutterBottom
                                            component="div"
                                            style={{width: 'max-content'}}
                                        >
                                            <b className='font16'>{replaceUnderscoreAndCapitalize(menuItem)}</b>
                                        </Typography>
                                    </NavLink>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className=" d-flex justify-content-center align-items-center w-100 h5 mt-3">
                        <span className='text-center w-75 font16 text-white'>
                            CinemaDBS.com is the website catering to every individuals belonging to the Indian film industry
                            to use and explore the content and features of this website. <br></br>For any more information, please contact
                            with the options given under contact us.
                        </span>
                    </div>

                    <div className="menu-explore-container px-0 d-flex justify-content-around align-items-center w-100 h5 m-0 overflow-hidden">
                        <div className='leftLine'></div>
                        <div className='socialIcons d-flex justify-content-center align-items-center mx-5'>
                            <Link to='https://www.facebook.com/profile.php?id=61553307071063&is_tour_completed=true' target='_blank'>
                                <span className='px-2 text-white pointer'><FaFacebookF /></span>
                            </Link>
                            <Link to='https://twitter.com/CinemaDBS' target='_blank'>
                                <span className='px-2 text-white pointer'><FaTwitter /></span>
                            </Link>
                            <Link to='https://www.linkedin.com/in/cinema-official-pro-3a996429b/' target='_blank'>
                                <span className='px-2 text-white pointer'><FaLinkedinIn /></span>
                            </Link>
                            <Link to='https://www.instagram.com/cinemadbs/' target='_blank'>
                                <span className='px-2 text-white pointer'><FaInstagram /></span>
                            </Link>
                            <Link to='https://www.threads.net/@cinemadbs' target='_blank'>
                                <span className='px-2 text-white pointer'><FaThreads /></span>
                            </Link>
                        </div>
                        <div className='rightLine'></div>
                    </div>
                </div>
            </footer>
    )
}

export default Footer
