import React, { useState, useEffect } from 'react';
import './profileform.css';
import axios from 'axios';
import { baseURL } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';

const PersonalInformationForm = (props: any) => {

    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [profile_data, setProfile_data] = useState<any>({});
    const [activeSave, setActiveSave] = useState(false);

    const [profile, setProfile] = useState({
        name: '',
        nickname: '',
        gender: '',
        address: '',
        birth_date: '',
        phone: '',
        email: '',
        attach_in_industry: '',
        whatsapp_number: '',
        industry_reference: '',
        reason_for_attaching_in_industry: '',
        avatar: ''
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'avatar') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setProfile((prevVal: any) => ({ ...prevVal, avatar: document_image }));
        } else {
            setProfile({ ...profile, [name]: value });
        }
    }

    const handleEditUserPersonal = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const getUserDetails = async () => {
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code
                }
                let result = await axios.post(`${baseURL}/api/subscriber/details`, body);
                setProfile_data(result.data.data);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleUpdate = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { name, nickname, gender, address, birth_date, phone, email, attach_in_industry, whatsapp_number, industry_reference, reason_for_attaching_in_industry, avatar } = profile;
        if (phone === '') {
            messageTxt('Please enter a phone number');
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    name, nickname, gender, address, birth_date, phone, email, attach_in_industry, whatsapp_number, industry_reference, reason_for_attaching_in_industry, avatar
                }
                setActiveSave(true);
                let result = await axios(`${baseURL}/api/subscriber/update-personnal_information`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                setIsDisabled(!isDisabled);
                setActiveSave(false);
                // console.log(result);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        setProfile({
            ...profile,
            name: profile_data.name || '',
            nickname: profile_data.nickname || '',
            gender: profile_data.gender || '',
            address: profile_data.address || '',
            birth_date: profile_data.birth_date || '',
            phone: profile_data.phone || '',
            email: profile_data.email || '',
            attach_in_industry: profile_data.attach_in_industry || '',
            whatsapp_number: profile_data.whatsapp_number || '',
            industry_reference: profile_data.industry_reference || '',
            reason_for_attaching_in_industry: profile_data.reason_for_attaching_in_industry || '',
            avatar: profile_data.avatar || ''
        });

    }, [profile_data]);

    useEffect(() => {
        getUserDetails();
    }, []);

    // useEffect(()=>{
    //     // console.log(profile);
    // },[profile])

    return (
        <>
            <div className='personal_info_form_accordian_section mt-5'>
                <div className="accordion accordion-flush" id="accordionFlushExample">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
                            >
                                Personal information
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse mb-1" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body accordian_border_with_color border-top-0" style={{ backgroundColor: '#242424' }}>
                                <form className='px-5 personal_info_form'>
                                    <div className="row p-0 mb-5 d-flex justify-content-between align-items-center">
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Name</label>
                                            <input type="text" onChange={handleChange} name='name' value={profile.name} className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">What other names you are called in the industry?</label>
                                            <input type="text" onChange={handleChange} value={profile.nickname} name='nickname' className="form-control mb-3" id="exampleFormControlInput1" placeholder="Power star" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Address</label>
                                            <input type="text" onChange={handleChange} value={profile.address} name='address' className="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter Your Address" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Contact number</label>
                                            <input type="text" onChange={handleChange} value={profile.phone} name='phone_number' className="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter Your Contact Number" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">From when you are into the film industry?</label>
                                            <input type="date" onChange={handleChange} value={profile.attach_in_industry} name='attach_in_industry' className="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter Your Date" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Give 2 reference contacts from the fillm industry</label>
                                            <input type="text" onChange={handleChange} value={profile.industry_reference} name='industry_reference' className="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter your Number" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Upload your passport picture</label>
                                            <input type="file" name='avatar' onChange={handleChange} className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Gender</label>
                                            <select className="form-select mb-3" onChange={handleChange} value={profile.gender} name='gender' aria-label="Default select example" disabled={isDisabled}>
                                                <option selected>Select your gender</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="3">Other</option>
                                            </select>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Date of birth</label>
                                            <input type="date" onChange={handleChange} value={profile.birth_date} name='birth_date' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Email id</label>
                                            <input type="email" onChange={handleChange} value={profile.email} name='email' className="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter Your Email" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Whatsapp number</label>
                                            <input type="number" onChange={handleChange} value={profile.whatsapp_number} name='whatsapp_number' className="form-control mb-3" id="exampleFormControlInput1" disabled={isDisabled} />
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">What made you to be in the film industry?</label>
                                            <input type="text" onChange={handleChange} value={profile.reason_for_attaching_in_industry} name='reason_for_attaching_in_industry' className="form-control mb-3" id="exampleFormControlInput1" placeholder='Enter your Reason ' disabled={isDisabled} />
                                        </div>
                                    </div>

                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                                        <button
                                            className='py-2 px-2 border me-3'
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                width: '150px'
                                            }}
                                            onClick={handleEditUserPersonal}
                                        >
                                            Edit
                                        </button>

                                        {activeSave ?
                                            <button
                                                className='py-2 px-2 border-0 personal_save_button rounded font16 font_weight_600'
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    width: '150px'
                                                }}
                                            >
                                                Updating...
                                            </button>
                                            :
                                            <>
                                                {!isDisabled ?
                                                    <button
                                                        className='py-2 px-2 border-0 personal_save_button rounded font16 font_weight_600'
                                                        style={{
                                                            backgroundColor: 'red',
                                                            color: 'white',
                                                            width: '150px'
                                                        }}
                                                        onClick={handleUpdate}
                                                    >
                                                        Save
                                                    </button>
                                                    : null
                                                }
                                            </>
                                        }
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default PersonalInformationForm
