import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';
import { extractIdFromUrl } from '../../utils/jsfunctions';

const InstituteDetailModal = (props: any) => {

    const { handleModalBox, data, dataId, getId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [detail, setDetail] = useState<any>({
        name: '',
        duration: '',
        location: '',
        fees: '',
        description: ''
    })

    const handleChangeVal = (e: any) => {
        const { name, value } = e.target;
        setDetail({ ...detail, [name]: value });
    }

    const handleSaveUpdateCourse = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const urlId = extractIdFromUrl(dataId);

        const { name, duration, location, fees, description } = detail;

        if (name === '' || duration === '' || location === '' || fees === '' || description === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    institute_id: urlId,
                    name,
                    duration,
                    location,
                    fees,
                    description,
                    id: getId
                }
                const res = await axios(`${baseURL}/api/add_instituteCourses`, {
                    method: 'POST',
                    data: body,
                    headers: { 'Content-Type': "multipart/form-data" }
                })
                setDetail({
                    name: '',
                    duration: '',
                    location: '',
                    fees: '',
                    description: ''
                })
                messageTxt(res.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }
    }

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    useEffect(() => {
        if (getId) {
            setDetail({
                name: data.name,
                duration: data.duration,
                location: data.location,
                fees: data.fees,
                description: data.description
            })
        }
    }, [data])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <div className="row p-0 d-flex justify-content-between align-items-center create_crew_form audition_form_modal">
                        <div className='col-12'>
                            <label htmlFor="description" className="form-label text-white m-0">Description</label>
                            <textarea 
                                name="description" 
                                onChange={handleChangeVal} 
                                value={detail.description} 
                                className="form-control mb-3" 
                                id="description"
                                style={{
                                    height: '50px',
                                    border: '1px solid gray',
                                }} ></textarea>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="name" className="form-label text-white m-0">Course name</label>
                            <input type="text" name='name' onChange={handleChangeVal} value={detail.name} className="form-control mb-3" id="name" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="duration" className="form-label text-white m-0">Duration</label>
                            <input type='text' name="duration" onChange={handleChangeVal} value={detail.duration} className="form-control mb-3" id="duration" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="location" className="form-label text-white m-0">Location</label>
                            <input type="text" name='location' onChange={handleChangeVal} value={detail.location} className="form-control mb-3" id="location" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="fees" className="form-label text-white m-0">fees</label>
                            <input type="text" onChange={handleChangeVal} name='fees' value={detail.fees} className="form-control mb-3" id="fees" />
                        </div>
                    </div>

                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                        <button
                            className='py-2 px-2 border-0 common_button rounded font_weight_600'
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                width: '150px'
                            }}
                            onClick={handleSaveUpdateCourse}
                        >
                            Save
                        </button>
                    </div>

                </LoadingOverlay>
            </div >
            <ToastContainer />
        </Modal>
    )
}

export default InstituteDetailModal
