import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const DiscographyModal = (props: any) => {

    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [disco_id, setDiscoId] = useState<any>();
    const [discograpgy, setDiscography] = useState<any>({
        song_name: '',
        film: '',
        year: '',
        composer: '',
        notes: '',
        id: '',
        co_singer: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setDiscography({ ...discograpgy, [name]: value });
    }

    const handleDiscographySubmit = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { song_name, film, year, composer, notes, co_singer, id } = discograpgy;
        if (song_name === '') {
            messageTxt('Please fill song name...');
            setActive(false);
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    song_name,
                    film,
                    year,
                    composer,
                    notes,
                    co_singer,
                    discography_id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/discography/addUpdate`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                setDiscography({
                    song_name: '',
                    film: '',
                    year: '',
                    composer: '',
                    notes: '',
                    id: '',
                    co_singer: '',
                });
                messageTxt(result.data.message);
                setActive(false);
                // console.log(result);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const getData = () => {
        setActive(true);
        if (data) {
            setDiscoId(data.id);
            setDiscography(data);
        }
        setActive(false);
    }

    useEffect(() => {
        getData();
    }, [data])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <form className='px-5 modal_form_add_update'>
                        <div className="row p-0 mb-5 d-flex justify-content-between align-items-center">
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Composer</label>
                                <input type="text" onChange={handleChange} value={discograpgy.composer} name='composer' placeholder='Yuvan Shankar Raja' className="form-control mb-3" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Co-Singer name</label>
                                <input type="text" onChange={handleChange} value={discograpgy.co_singer} name='co_singer' placeholder='eg: Sunitha' className="form-control mb-3" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Remarks ( 100 words )</label>
                                <textarea name='notes' onChange={handleChange} value={discograpgy.notes} className="form-control" placeholder='Describe about song/Lyrics' style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1"></textarea>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Song name</label>
                                <input type="text" onChange={handleChange} value={discograpgy.song_name} name='song_name' placeholder='eg: Butta Bomma, Kuchh is tarah etc.' className="form-control mb-3" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Film name</label>
                                <input type="text" onChange={handleChange} value={discograpgy.film} name='film' placeholder='eg: K.G.F, Album etc.' className="form-control mb-3" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Year</label>
                                <input type="number" onChange={handleChange} value={discograpgy.year} name='year' placeholder='eg: 2005' className="form-control mb-3" />
                            </div>
                        </div>

                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                            <button
                                className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleDiscographySubmit}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default DiscographyModal
