import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useLocation } from 'react-router-dom';
import { getAllMoviesAPI, getSingleFestivalAPI } from '../../utils/utils';
import BasicDetailForm from '../../components/filmfestivalforms/BasicDetailForm';
import CategoryForm from '../../components/filmfestivalforms/CategoryForm';
import SubCategory from '../../components/filmfestivalforms/SubCategory';
import NominationForm from '../../components/filmfestivalforms/NominationForm';

const FilmFestivalForms = () => {

    LoadingOverlay.propTypes = undefined;
    const location = useLocation();
    const { festival_id } = location.state;
    const [isActive, setActive] = useState(false);
    const [formDetails, setFormDetails] = useState<any>([]);

    const getDetail = async () => {
        setActive(true);
        const data = await getSingleFestivalAPI(festival_id);
        setFormDetails(data);
        setActive(false);
    }

    useEffect(() => {
        getDetail();
    }, []);

    // useEffect(() => {
    //     console.log('single fest', formDetails);
    // }, [formDetails]);

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
            >
                <BasicDetailForm festival_data={formDetails} festival_id={festival_id} />
                <CategoryForm festival_data={formDetails} festival_id={festival_id} />
                <SubCategory festival_data={formDetails} festival_id={festival_id} />
                <NominationForm festival_data={formDetails} festival_id={festival_id} />
            </LoadingOverlay>
        </>
    )
}

export default FilmFestivalForms
