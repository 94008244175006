import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import VideoPlayer from '../videoplayer/VideoPlayer';

const MoviePlayerModal = (props: any) => {
    const { handleModalBox, link } = props;
    const [show, setShow] = useState(true);

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className='text-warning'
                >
                    Playing video
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <VideoPlayer videoUrl={link} width={'100%'} height={'40rem'} />
            </Modal.Body>
        </Modal>
    )
}

export default MoviePlayerModal
