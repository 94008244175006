import React, { useState, useEffect } from 'react';
import './modalformcss.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import LoadingOverlay from 'react-loading-overlay-ts';

const UserAwardModal = (props: any) => {
    const { handleModalBox, data, dataId, headingText } = props;

    LoadingOverlay.propTypes = undefined;
    const messageTxt = (message: any) => toast(message);
    const [show, setShow] = useState(true);
    const [isActive, setActive] = useState(false);
    const [award_id, setAwardId] = useState<any>();
    const [awards, setAward] = useState<any>({
        award_details: '',
        award_name: '',
        award_category: '',
        result: '',
        award_image: '',
        award_for: '',
        award_year: ''
    });

    const handlClosedModal = () => {
        handleModalBox();
        setShow(false);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'award_image') {
            const document_image = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setAward((prevVal: any) => ({ ...prevVal, award_image: document_image }));
        } else {
            setAward({ ...awards, [name]: value });
        }
    }

    const handleUserAwardSubmit = async (e: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        const { award_name, award_details, award_category, award_image, result, award_for, award_year } = awards;
        if (award_name === '' || award_category === '') {
            messageTxt('Please fill award name and category...');
            setActive(false);
        } else if (award_year.length > 4 && award_year.length < 4) {
            messageTxt('Please fill award year should be 4 digits...');
            setActive(false);
        } else if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    award_name,
                    award_details,
                    award_category,
                    award_image,
                    award_result: result,
                    award_for,
                    award_year,
                    subscriber_award_id: award_id
                }
                let res = await axios(`${baseURL}/api/subscriber/awards/addUpdate`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                setAward({
                    award_details: '',
                    award_name: '',
                    award_category: '',
                    result: '',
                    award_image: '',
                    award_for: '',
                    award_year: ''
                });
                messageTxt(res.data.message);
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const getData = () => {
        setActive(true);
        if (data) {
            setAwardId(data.id);
            setAward(data);
        }
        setActive(false);
    }

    useEffect(() => {
        getData();
    }, [data])

    return (
        <Modal
            show={show}
            onHide={handlClosedModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            className={`opacity-75`}
            // fullscreen={true}
            size={`xl`}
        >
            <div
                className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-4'
            >
                <p className='m-0 text-white font_weight_600 font18'>{headingText}</p>
                <button
                    className=''
                    onClick={handlClosedModal}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <ImCross />
                </button>
            </div>
            <div className={`mx-lg-5 mx-md-5 mx-4 my-2 happy_movement_form pb-4`}>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading your content...'
                >
                    <form className='px-5 modal_form_add_update'>
                        <div className="row p-0 mb-5 d-flex justify-content-between align-items-center">
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award details ( 100 words )</label>
                                <textarea name='award_details' onChange={handleChange} value={awards.award_details} className="form-control" style={{ border: '1px solid gray' }} id="exampleFormControlTextarea1" placeholder='About award' ></textarea>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award name</label>
                                <input type="text" onChange={handleChange} value={awards.award_name} name='award_name' className="form-control mb-3" placeholder='Award name' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award category</label>
                                <input type="text" onChange={handleChange} value={awards.award_category} name='award_category' className="form-control mb-3" placeholder='Award category' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award for</label>
                                <input type="text" onChange={handleChange} value={awards.award_for} name='award_for' className="form-control mb-3" placeholder='eg: movie, acting, singing etx.' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award year</label>
                                <input type="number" onChange={handleChange} value={awards.award_year} name='award_year' className="form-control mb-3" placeholder='eg: 2024' />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Award Picture</label>
                                <input type="file" onChange={handleChange} name='award_image' className="form-control mb-3" />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Result</label>
                                <input type="text" onChange={handleChange} value={awards.result} name='result' className="form-control mb-3" placeholder='eg: won' />
                            </div>
                        </div>

                        <div className='d-lg-flex d-md-block d-block justify-content-lg-end'>
                            <button
                                className='py-2 px-2 border-0 personal_save_button rounded fw-bold'
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: '150px'
                                }}
                                onClick={handleUserAwardSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </LoadingOverlay>
            </div>
            <ToastContainer />
        </Modal>
    )
}

export default UserAwardModal
