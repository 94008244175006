import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Profile = () => {
    const profile_data = useSelector((state: any) => state.user.user);
    const auth = localStorage.getItem('auth_code');

    useEffect(() => {
        // console.log('profile', profile_data , auth)
    }, [profile_data, auth])

    return (
        <>
            {(auth && profile_data) ?
                <div className='container mt-4 mb-5'>
                    <Outlet />
                </div> 
                : 
                <Navigate to='/' />
            }
        </>
    )
}

export default Profile
