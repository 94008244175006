import React, { useState, useEffect } from 'react';
import './profileform.css';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SocialMediaLinkModal from '../myprofilemodals/SocialMediaLinkModal';

const SocialMediaLinks = () => {

    const social_links = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [socialLinks, setSocialLinks] = useState({
        fb_url: '',
        insta_url: '',
        linkedIn_url: '',
        tw_url: ''
    });

    // const handleChangeValue = (e: any) => {
    //     const { name, value } = e.target;
    //     setSocialLinks({ ...socialLinks, [name]: value });
    // }

    const handleEdit = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    // const handleSocialMediaLinks = async (e: any) => {
    //     e.preventDefault();
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');

    //     if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 facebook_link: socialLinks.fb_url,
    //                 instagram_link: socialLinks.insta_url,
    //                 linkedin_link: socialLinks.linkedIn_url,
    //                 twitter_link: socialLinks.tw_url
    //             }
    //             let result = await axios.post(`${baseURL}/api/subscriber/update-social_media_links`, body);
    //             messageTxt(result.data.message);
    //             setIsDisabled(!isDisabled);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const handleModalForm = (val: any) => {
        setOpenFormModal(!openFormModal);
    }

    useEffect(() => {
        setSocialLinks((prevData) => ({
            ...prevData,
            fb_url: social_links.facebook_link || '',
            insta_url: social_links.instagram_link || '',
            linkedIn_url: social_links.linkedin_link || '',
            tw_url: social_links.twitter_link || ''
        }));
    }, [social_links]);

    // useEffect(()=>{
    //     console.log('socialmedia',socialLinks);
    // },[socialLinks])

    return (
        <>
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="SocialMeadiaLinks">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingSocialMeadiaLinks">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSocialMeadiaLinks" aria-expanded="false" aria-controls="flush-collapseSocialMeadiaLinks">
                                Social media links
                            </button>
                        </h2>
                        <div id="flush-collapseSocialMeadiaLinks" className="accordion-collapse collapse" aria-labelledby="flush-headingSocialMeadiaLinks" data-bs-parent="#SocialMeadiaLinks">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">
                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                {openFormModal &&
                                    <SocialMediaLinkModal handleModalBox={handleModalForm} data={socialLinks} headingText={'Social media link'} />
                                }
                                    <button
                                        className='py-2 px-2 border me-3 rounded font_weight_600'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleModalForm}
                                    >
                                        Edit
                                    </button>
                                </div>
                                <form className='px-5 social_form'>
                                    <div className="row p-0 d-flex justify-content-between align-items-center">
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Facebook account url</label>
                                            <input type="text" defaultValue={socialLinks.fb_url} className="form-control mb-3" placeholder='eg: https://www.facebook.com/' id="exampleFormControlInput1" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Instagram account url</label>
                                            <input type="text" defaultValue={socialLinks.insta_url} className="form-control mb-3" placeholder='eg: https://www.instagram.com/' id="exampleFormControlInput1" disabled={isDisabled} />
                                        </div>
                                    </div>

                                    <div className='row p-0 mb-5 d-flex justify-content-between align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Linkedin account url</label>
                                            <input type="text" defaultValue={socialLinks.linkedIn_url} className="form-control mb-3" placeholder='eg: https://in.linkedin.com/?src=go-pa&trk=sem-ga_' id="exampleFormControlInput1" disabled={isDisabled} />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <label htmlFor="exampleInputEmail1" className="form-label text-white m-0">Twitter account url</label>
                                            <input type="text" defaultValue={socialLinks.tw_url} className="form-control mb-3" placeholder='eg: https://twitter.com/i/flow/login' id="exampleFormControlInput1" disabled={isDisabled} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default SocialMediaLinks
