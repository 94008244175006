import React, { useEffect, useState } from 'react';
import './homemoviefilter.css';
import { getAllGenres } from '../../utils/utils';
import { PiCaretDownBold } from "react-icons/pi";

const HomeMovieFilter = (props: any) => {
    const { handleFilterFunction } = props;
    const [today, setToday] = useState(false);
    const [week, setWeek] = useState(false);
    const [month, setMonth] = useState(true);
    const [genre, setGenres] = useState<any>();
    const [gen, setGen] = useState<any>('');
    const [days, setDays] = useState<any>('this_month');

    const getGenre = async () => {
        const data = await getAllGenres();
        setGenres(data);
    }

    const handleChange = (e: any) => {
        const { value } = e.target;
        setGen(value);
        sendDataToParent();
    }

    const handleFilter = (e: any, gen: any) => {
        const val = e.target.id;
        setDays(val);
        if (val === 'today') {
            setDays(val);
            setToday(true);
            setWeek(false);
            setMonth(false);
            sendDataToParent();
        } else if (val === 'this_week') {
            setDays(val);
            setWeek(true);
            setToday(false);
            setMonth(false);
            sendDataToParent();
        } else if (val === 'this_month') {
            setDays(val);
            setMonth(true);
            setToday(false);
            setWeek(false);
            sendDataToParent();
        }
    }

    const sendDataToParent = () => {
        handleFilterFunction(days, gen);
    }

    useEffect(() => {
        getGenre();
        sendDataToParent();
    }, [])

    useEffect(() => {
        // console.log('asasa',gen, days)
        sendDataToParent();
    }, [gen, days])

    return (
        <>
            <div
                className='d-lg-flex justify-content-lg-between justify-content-md-between align-items-lg-center d-md-flex d-none d-sm-block hstack'
            >
                <div
                    className='d-lg-flex d-md-flex d-flex justify-content-between justify-content-md-start align-items-md-center d-none'
                >
                    <p
                        className={`m-0 font18 font_weight_600 pointer ${today ? 'text-danger' : 'text-white'}`}
                        onClick={(e) => handleFilter(e, gen)}
                        id='today'
                    >
                        Today
                    </p>
                    <span className='text-white font18 font_weight_600'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</span>
                    <p
                        className={`m-0 font18 font_weight_600 pointer mx-lg-3 mx-md-3 ${week ? 'text-danger' : 'text-white'}`}
                        onClick={(e) => handleFilter(e, gen)}
                        id='this_week'
                    >
                        This Week
                    </p>
                    <span className='text-white font18 font_weight_600'>/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <p
                        className={`m-0 font18 font_weight_600 pointer ${month ? 'text-danger' : 'text-white'}`}
                        onClick={(e) => handleFilter(e, gen)}
                        id='this_month'
                    >
                        Last 30 days
                    </p>
                </div>
                <div
                    className='d-lg-flex d-md-flex d-flex justify-content-between justify-content-md-start align-items-md-center d-lg-none d-md-none'
                >
                    <select
                        className="form-select filter_home_select text-white border-0 ps-1"
                        aria-label="Default select example" name='days' onChange={handleChange}>
                        <option className='theme_color font_weight_600' value={'this_month'} selected>This month</option>
                        <option className='theme_color font_weight_600' value={'today'}>Today</option>
                        <option className='theme_color font_weight_600' value={'this_week'}>This week</option>
                    </select>
                </div>
                <div
                    className='mt-lg-0 mt-md-0 mt-3 position-relative w-25'
                >
                    <select
                        className="form-select filter_home_select text-white position-relative position-absolute Select_filter_width"
                        style={{
                            top: '-19px',
                        }}
                        aria-label="Default select example" onChange={handleChange}>
                        <option className='theme_color font_weight_600' value={''} selected>Genre</option>
                        {genre && Array.isArray(genre) ?
                            genre.map((val: any) => {
                                return (
                                    <option className='theme_color' value={val.id} key={val.id}>{val.title}</option>
                                )
                            })
                            : null
                        }
                    </select>
                    <PiCaretDownBold
                        className='text-white position-absolute font20'
                        style={{
                            top: '-10px',
                            right: '10px'
                        }}
                    />
                </div>
                {/* <PiCaretDownBold
                        className='text-white position-absolute font20'
                        style={{
                            top: '3px',
                            left: '60%'
                        }}
                    /> */}
            </div>


            {/* mobile view */}


            <div
                className='row m-0 d-lg-none d-md-none d-visible'
            >
                <div
                    className='col-6 ms-lg-4 ms-md-4 ms-0 mt-lg-0 mt-md-0'
                >
                    <div
                        className='position-relative'
                    >
                        <select
                            className="form-select filter_home_select text-white ps-1 position-absolute position-relative"
                            aria-label="Default select example" name='days' onChange={handleChange}
                            style={{
                                top: '-15px',
                                left: '0px'
                            }}
                        >
                            <option className='theme_color font_weight_600' value={'this_month'} selected>This month</option>
                            <option className='theme_color font_weight_600' value={'today'}>Today</option>
                            <option className='theme_color font_weight_600' value={'this_week'}>This week</option>
                        </select>
                        <PiCaretDownBold
                            className='text-white position-absolute font20'
                            style={{
                                top: '-5px',
                                right: '10%'
                            }}
                        />
                    </div>
                </div>

                <div
                    className='col-6 ms-lg-4 ms-md-4 ms-0 mt-lg-0 mt-md-0'
                >
                    <div
                        className='position-relative'
                    >
                        <select
                            className="form-select filter_home_select text-white ps-1 position-absolute position-relative"
                            aria-label="Default select example" onChange={handleChange}
                            style={{
                                top: '-15px',
                                left: '0px'
                            }}
                        >
                            <option className='theme_color font_weight_600' value={''} selected>Genre</option>
                            {genre && Array.isArray(genre) ?
                                genre.map((val: any) => {
                                    return (
                                        <option className='theme_color' value={val.id} key={val.id}>{val.title}</option>
                                    )
                                })
                                : null
                            }
                        </select>
                        <PiCaretDownBold
                            className='text-white position-absolute font20'
                            style={{
                                top: '-5px',
                                right: '10%'
                            }}
                        />
                    </div>
                </div>
                {/* <PiCaretDownBold
                        className='text-white position-absolute font20'
                        style={{
                            top: '3px',
                            left: '60%'
                        }}
                    /> */}
            </div>
        </>
    )
}

export default HomeMovieFilter
