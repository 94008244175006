import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { getControversyListAPI, getShowReelListAPI } from '../../utils/utils';
import { ImCross } from 'react-icons/im';
import ShowreelModal from '../myprofilemodals/ShowreelModal';
import { FiEdit } from 'react-icons/fi';
import { IoTrash } from 'react-icons/io5';
import { Table } from 'react-bootstrap';

const ShowreelForm = () => {

    const messageTxt = (message: any) => toast(message);
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [isActive, setActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [showReelList, setShowReelList] = useState<any>([]);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [showrell, setShowrell] = useState({
        title: '',
        link: '',
        video: ''
    });

    const getReelList = async () => {
        setActive(true);
        const data = await getShowReelListAPI();
        setShowReelList(data);
        setActive(false);
    }

    const handleEditShowreel = (e: any) => {
        e.preventDefault();
        if (isDisabled) {
            messageTxt('You can edit your profile...');
        } else {
            messageTxt("You can't edit your profile...");
        }
        setIsDisabled(!isDisabled);
    }

    const handleChangeValShowreel = (e: any) => {
        const { name, value } = e.target;
        if (name === 'image') {
            const video = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            setShowrell((prevVal: any) => ({ ...prevVal, video: video }));
        } else {
            setShowrell({ ...showrell, [name]: value });
        }
    }

    const handlSubmitValShowreel = async (e: any) => {
        e.preventDefault();
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');

        const { title, link } = showrell;

        if (title === '' || link === '') {
            messageTxt('Please fill all fields.');
            setActive(false);
        } else {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    title,
                    link
                }
                const res = await axios(`${baseURL}/api/subscriber/showreels/add`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(res.data.message);
                setShowrell({
                    title: '',
                    link: '',
                    video: ''
                });
                getReelList();
            } catch (error) {
                console.log(error);
                setActive(false);
            }
        }

    }

    const deleteShowReel = async (e: any, id: any) => {
        e.preventDefault();
        setActive(true);
        const connection_id = localStorage.getItem('connection_id');
        const auth_code = localStorage.getItem('auth_code');
        if (connection_id && auth_code) {
            try {
                let body = {
                    connection_id,
                    auth_code,
                    data_type: 'showreel',
                    id: id
                }
                let result = await axios(`${baseURL}/api/subscriber/delete/ff/data`, {
                    method: 'POST',
                    data: body,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                messageTxt(result.data.message);
                getReelList();
                setActive(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleModalForm = (val: any) => {
        setOpenAdd(!openAdd);
        getReelList();
    }

    useEffect(() => {
        const element = myElementRef.current;

        const handleShow = () => {
            getReelList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(()=> {
    //     console.log(showrell);
    // },[showrell])

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'
        >
            <div className='happy_movement_form mt-3'>
                <div className="accordion accordion-flush" id="showReels">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseshowReels" aria-expanded="false" aria-controls="flush-collapseshowReels">
                                Show reels
                            </button>
                        </h2>
                        <div id="flush-collapseshowReels" ref={myElementRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#showReels">
                            <div className="accordion-body accordian_border_with_color border-top-0 theme_color">

                                {openAdd &&
                                    <ShowreelModal handleModalBox={handleModalForm} headingText={'Add New'} />
                                }

                                <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                    <button
                                        className='py-2 border-0 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            width: '100px'
                                        }}
                                        onClick={handleModalForm}
                                    >
                                        + Add
                                    </button>
                                </div>

                                {Array.isArray(showReelList) && showReelList.length > 0 ?
                                    <Table striped bordered hover responsive variant="dark">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Title</th>
                                                <th>Video link</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(Array.isArray(showReelList) && showReelList.length > 0) &&
                                                showReelList.map((val: any, i: any) => {
                                                    return (
                                                        <tr
                                                            key={val.id}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{val.title}</td>
                                                            <td>{val.link}</td>
                                                            <td>
                                                                <div
                                                                    className='font20 d-flex justify-content-between align-items-center'
                                                                >
                                                                    {/* <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} /> */}
                                                                    <IoTrash className='pointer' onClick={(e) => deleteShowReel(e, val.id)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p
                                        className="m-0 text-white font_weight_600 font16 text-center"
                                    >
                                        Data not found, add new data.
                                    </p>
                                }

                                {/* <div className="row p-0 mb-5 d-flex justify-content-between align-items-center show_reels_section">
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="title" className="form-label text-white m-0">Title</label>
                                        <input type="text" onChange={handleChangeValShowreel} value={showrell.title} name='title' placeholder='Enter video name' className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <label htmlFor="video_link" className="form-label text-white m-0">Video link</label>
                                        <input type="text" onChange={handleChangeValShowreel} value={showrell.link} name='link' placeholder='URL link' className="form-control mb-3" disabled={isDisabled} />
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </LoadingOverlay>
    )
}

export default ShowreelForm
