import React, { useEffect, useState, useRef } from 'react';
import './profileform.css';
import { baseURL } from '../../utils/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import { Table } from 'react-bootstrap';
import { IoTrash } from 'react-icons/io5';
import WhoIntroduceModal from '../myprofilemodals/WhoIntroduceModal';
import { WhoIntroduceListAPI } from '../../utils/utils';
import LoadingOverlay from 'react-loading-overlay-ts';

const WhoIntroduceYou = () => {

    const { introduced_by_to_industry } = useSelector((state: any) => state.user.user);
    const messageTxt = (message: any) => toast(message);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isActive, setActive] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const whoIntroRef = useRef<HTMLDivElement | null>(null);
    const [introList, setIntroList] = useState<any>([]);
    const [whoIntro, setWhoIntro] = useState<any>([{
        name: '',
        relation: ''
    }]);

    const getList = async () => {
        setActive(true);
        const data = await WhoIntroduceListAPI();
        setIntroList(data);
        setActive(false);
    }

    // const handleEdit = (e: any) => {
    //     e.preventDefault();
    //     if (isDisabled) {
    //         messageTxt('You can edit your profile...');
    //     } else {
    //         messageTxt("You can't edit your profile...");
    //     }
    //     setIsDisabled(!isDisabled);
    // }

    // const handleChange = (e: any, i: any) => {
    //     const { name, value } = e.target;
    //     const updatedcastData = [...whoIntro];
    //     if (typeof i === 'number' && i >= 0 && i < updatedcastData.length) {
    //         updatedcastData[i] = { ...updatedcastData[i], [name]: value };
    //         setWhoIntro(updatedcastData);
    //     } else {
    //         console.error('Invalid index provided for array update.');
    //     }
    // };

    const handleDeleteData = (e: any, i: any) => {
        e.preventDefault();
        const deletedData = [...whoIntro];
        deletedData.splice(i, 1);
        setWhoIntro(deletedData);
        getList();
    }

    // const handleWhoIntroUpdate = async (e: any) => {
    //     e.preventDefault();
    //     const connection_id = localStorage.getItem('connection_id');
    //     const auth_code = localStorage.getItem('auth_code');

    //     if (connection_id && auth_code) {
    //         try {
    //             let body = {
    //                 connection_id,
    //                 auth_code,
    //                 introduced_by_to_industry: JSON.stringify(whoIntro)
    //             }
    //             let result = await axios(`${baseURL}/api/subscriber/update-biography`, {
    //                 method: 'POST',
    //                 data: body,
    //                 headers: { "Content-Type": "multipart/form-data" }
    //             });
    //             messageTxt(result.data.message);
    //             setIsDisabled(!isDisabled);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    const handleAddIntroduce = () => {
        setOpenAdd(!openAdd);
        getList();
    }

    useEffect(() => {
        if (introduced_by_to_industry.length > 0 && Array.isArray(introduced_by_to_industry)) {
            setWhoIntro(introduced_by_to_industry);
        }
    }, [])

    useEffect(() => {
        const element = whoIntroRef.current;

        const handleShow = () => {
            getList();
        };

        // const handleHide = () => {
        //     console.log('Accordion is hidden. Add your logic here.');
        // };

        // Add event listeners for Bootstrap collapse events
        if (element) {
            element.addEventListener('show.bs.collapse', handleShow);
            // element.addEventListener('hide.bs.collapse', handleHide);
        }

        // Cleanup: remove event listeners when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('show.bs.collapse', handleShow);
                // element.removeEventListener('hide.bs.collapse', handleHide);
            }
        };
    }, []);

    // useEffect(() => {
    //     console.log('as', introList)
    // }, [introList])

    return (
        <>
            <div className='personal_biography_form_accordian_section mt-3'>
                <div className="accordion accordion-flush" id="introduceForm">

                    <div className="accordion-item rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed rounded accordian_border_with_color text-white" style={{ backgroundColor: '#3a3a3c' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseintroduceForm" aria-expanded="false" aria-controls="flush-collapseintroduceForm">
                                Who introduced you to the film industry?
                            </button>
                        </h2>
                        <div id="flush-collapseintroduceForm" ref={whoIntroRef} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#introduceForm">
                            <div className="accordion-body border theme_color border-top-0">

                                {openAdd &&
                                    <WhoIntroduceModal handleModalBox={handleAddIntroduce} headingText={'Add New'} />
                                }

                                <LoadingOverlay
                                    active={isActive}
                                    spinner
                                    text='Loading your content...'
                                >
                                    <div className='d-lg-flex d-md-block d-block justify-content-lg-end mb-3'>
                                        <button
                                            className='py-2 border-0 rounded fw-bold'
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                width: '100px'
                                            }}
                                            onClick={handleAddIntroduce}
                                        >
                                            + Add
                                        </button>
                                    </div>
                                    {Array.isArray(introList) && introList.length > 0 ?
                                        <Table striped bordered hover responsive variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Name</th>
                                                    <th>Relation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(introList) && introList.length > 0 &&
                                                    introList.map((val: any, i: any) => {
                                                        return (
                                                            <tr
                                                                key={val.id}
                                                            >
                                                                <td>{i + 1}</td>
                                                                <td>{val.name}</td>
                                                                <td>{val.relation}</td>
                                                                <td>
                                                                    <div
                                                                        className='font20 d-flex justify-content-between align-items-center'
                                                                    >
                                                                        {/* <FiEdit className='pointer' onClick={(e) => handleModalForm(val)} /> */}
                                                                        <IoTrash className='pointer' onClick={(e) => handleDeleteData(e, val.id)} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        :
                                        <p
                                            className="m-0 text-white text-center font_weight_600"
                                        >
                                            No data found.
                                        </p>
                                    }
                                    {/* {
                                    whoIntro.map((val: any, i: any) => {
                                        return (
                                            <div className="row p-0 d-flex justify-content-between align-items-center create_cast_form biography_form" key={i}>
                                                <div className='col-lg-5 col-md-5 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Name</label>
                                                    <input type="text" name='name' value={val.name} onChange={(e) => handleChange(e, i)} placeholder='Name' className="form-control mb-3" disabled={isDisabled} />
                                                </div>
                                                <div className='col-lg-5 col-md-5 col-12'>
                                                    <label htmlFor="movieTitle" className="form-label text-white m-0">Relation</label>
                                                    <input type="text" name='relation' value={val.relation} onChange={(e) => handleChange(e, i)} placeholder='Name' className="form-control mb-3" disabled={isDisabled} />
                                                </div>
                                                <div className='col-lg-1 col-md-1 col-12'>
                                                    {!isDisabled ?
                                                        <button
                                                            className='py-2 px-2 common_button'
                                                            style={{
                                                                backgroundColor: 'red',
                                                            }}
                                                            onClick={(e) => handleDeleteData(e, i)}
                                                        >
                                                            <ImCross className='text-white' />
                                                        </button>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                } */}

                                    {/* <div className='mt-5 d-lg-flex d-md-block d-block justify-content-lg-end'>
                                    <button
                                        className='py-2 px-2 border me-3 rounded fw-bold'
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            width: '150px'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        {isDisabled ? 'Edit' : 'Cancel'}
                                    </button>
                                    {!isDisabled ?
                                        <button
                                            className='py-2 px-2 common_button rounded fw-bold'
                                            style={{
                                                width: '150px'
                                            }}
                                            onClick={handleWhoIntroUpdate}
                                        >
                                            Save
                                        </button>
                                        : null
                                    }
                                </div> */}
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default WhoIntroduceYou
